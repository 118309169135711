import setFieldTouched from 'final-form-set-field-touched';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import RadioInput from '../../radio-button';
import { useIsDatalistValid } from '../hooks';
import { ClientFieldReference, DataSourceField, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField, useOptionItems } from '../shared';
import arrayMutators from 'final-form-arrays';
import { touchErrors } from '../../utils/form';
import { useUpdateEffect } from '../../hooks/use-update-effect';
const MultipleChoice = ({
  schema,
  disabled,
  menu,
  style,
  className,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const [items] = useOptionItems(schema);
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  return <Box style={style} className={className} data-sentry-element="Box" data-sentry-component="MultipleChoice" data-sentry-source-file="index.tsx">
      <Field name={name} validate={value => {
      if (schema.required && (!value || !value.length)) {
        return 'Select an option';
      }
    }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
        {({
        input,
        meta
      }) => <Flex mb={2} justifyContent="space-between">
            <Label sx={{
          flexGrow: 1
        }}>
              {schema.label}
            </Label>
            {menu}
          </Flex>}
      </Field>

      {items.filter((_, idx) => idx < 100) // Prevent bad things from happening
    .map(({
      value,
      label
    }, idx) => <Box as="label" mb={2} key={idx} display="block">
            <Field type="radio" name={name} value={value} tabIndex={disabled ? -1 : undefined}>
              {({
          input
        }) => <RadioInput {...input} />}
            </Field>{' '}
            {label}
          </Box>)}

      <Field name={name} validate={value => {
      if (schema.required && (!value || !value.length)) {
        return 'Select an option';
      }
    }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
        {({
        input,
        meta
      }) => <Box>
            {meta.error && <FormError>{meta.error}</FormError>}

            {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
          </Box>}
      </Field>
    </Box>;
};
export default MultipleChoice;
export const toString = value => {
  return value || '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{value}</Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  const wasTouchedRef = React.useRef(false);
  useUpdateEffect(() => {
    wasTouchedRef.current = false;
  }, [value]);
  const isDataListMissing = useIsDatalistValid(value.data_list_id);
  return <>
      {!isEditing ? <Form mutators={{
      ...arrayMutators,
      setFieldTouched
    }} onSubmit={() => null} validate={() => {
      const errors = {};
      if (value.data_source === 'data_list_id' && isDataListMissing) {
        errors['onlyField'] = `Missing data list`;
      }
      return errors;
    }}>
          {({
        form
      }) => {
        if (wasTouchedRef.current === false) {
          touchErrors(form);
          wasTouchedRef.current = true;
        }
        return <MultipleChoice schema={{
          ...value,
          name: 'onlyField'
        }} disabled={true} menu={menu} style={style} className={className} />;
      }}
        </Form> : <Grid sx={{
      display: !isEditing ? 'none' : null
    }}>
          <LabelField prefix={fieldPrefix} />
          <DataSourceField prefix={fieldPrefix} />
          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button onClick={toggleMore} size="small" width="100%" variant="outlined">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};