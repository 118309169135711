import { Global } from 'shared/styled/global';
import { GoogleMap, StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import axios from '../../anaxios';
import { getIn } from 'final-form';
import debounce from 'lodash.debounce';
import React from 'react';
import { Field, Form, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import countries from 'shared/countries';
import Loader from 'shared/loader';
import Select from 'shared/select';
import { Link } from 'shared/typography';
import { disableAutoCompleteInput } from 'shared/utils/form';
import Button from '../../button';
import Divider from '../../divider';
import FormError from '../../form-error';
import { Box, Flex, Grid } from '../../grid';
import { useDebounce } from '../../hooks';
import Input, { StyledInput } from '../../input';
import Label from '../../label';
import Switch from '../../switch';
import Textarea from '../../textarea';
import provinces_ from '../../utils/za-provinces';
import { ClientFieldReference, FieldPrefixContext, LabelField, NameField, PrivateField, RequiredField } from '../shared';
import { FormattedMessage } from 'react-intl';
const PIN_PATH = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;
const PinMarker = ({
  size = 20,
  color
}) => {
  return <svg height={size} viewBox="0 0 24 24" style={{
    pointerEvents: 'none',
    cursor: 'pointer',
    stroke: '#fff',
    strokeWidth: 2,
    fill: color,
    transform: `translate(${-size / 2}px,${-size}px)`
  }} data-sentry-element="svg" data-sentry-component="PinMarker" data-sentry-source-file="index.tsx">
      <path d={PIN_PATH} data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;
};
const geocodeToFormItems = item => {
  const location = item.address_components.reduce((agg, val) => {
    if (val.types.includes('street_number')) {
      agg['street_number'] = val.long_name;
    }
    if (val.types.includes('route')) {
      agg['street_name'] = val.long_name;
    }
    if (val.types.includes('sublocality_level_2')) {
      agg['suburb'] = val.long_name;
    }
    if (val.types.includes('locality')) {
      agg['city'] = val.long_name;
    }
    if (val.types.includes('administrative_area_level_1')) {
      agg['province'] = val.long_name;
    }
    if (val.types.includes('country')) {
      agg['country'] = val.short_name;
    }
    if (val.types.includes('postal_code')) {
      agg['postal_code'] = val.short_name;
    }
    return agg;
  }, {
    street_number: '',
    street_name: '',
    suburb: '',
    city: '',
    province: '',
    country: '',
    postal_code: '',
    gps: [item.geometry.location.lat, item.geometry.location.lng]
  });
  const {
    street_number,
    street_name,
    suburb,
    ...rest
  } = location;
  return {
    street_address: [[street_number, street_name].filter(v => v).join(' '), suburb].filter(v => v).join('\n'),
    ...rest
  };
};
const provinces = provinces_.map(p => ({
  label: p,
  value: p
})).sort((a, b) => a.label < b.label ? -1 : 1);
const loadPrefs: any = {
  googleMapsApiKey: __GOOGLE_MAPS_KEY__,
  libraries: ['places']
};
export const Address = ({
  schema,
  disabled,
  menu,
  style,
  className,
  disableAutoComplete,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  const form = useForm();
  const values = useFormState().values;
  const {
    latitude: defaultLatitude,
    longitude: defaultLongitude
  } = useSelector((state: any) => state.geoip);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const geocode = React.useCallback(debounce(({
    lat,
    lng
  }) => {
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: {
        // proximity: `${state.geoip.longitude},${state.geoip.latitude}`,
        latlng: `${lat},${lng}`,
        key: __GOOGLE_MAPS_KEY__
      }
    }).then(({
      data
    }) => {
      const results = data.results.map(r => ({
        ...r,
        text: r.formatted_address
      }));
      if (results.length) {
        const parts = geocodeToFormItems(results[0]);
        form.batch(() => {
          for (const [k, v] of Object.entries<any>(parts)) {
            form.change(`${name}.${k}`, v);
          }
        });
      }
    });
  }, 1000), [form, name]);
  const setCenter = React.useCallback(({
    lat,
    lng
  }) => {
    geocode({
      lat,
      lng
    });
    form.change(`${name}.gps`, [lat, lng]);
  }, [form, geocode, name]);
  const [lat, lng] = getIn(values, `${name}.gps`) || [];
  const center = React.useMemo(() => ({
    lat: lat || defaultLatitude || 0,
    lng: lng || defaultLongitude || 0
  }), [defaultLatitude, defaultLongitude, lat, lng]);
  const debouncedCenter = useDebounce(center, 1000);
  const mapRef = React.useRef<google.maps.Map>(null);
  const searchBoxRef = React.useRef<google.maps.places.SearchBox>(null);
  const onPlacesChanged = React.useCallback(() => {
    const place = searchBoxRef.current.getPlaces()[0];
    const parts = geocodeToFormItems(place);
    parts.gps = parts.gps.map(v => v());
    form.batch(() => {
      for (const [k, v] of Object.entries<any>(parts)) {
        form.change(`${name}.${k}`, v);
      }
    });
  }, [form, name]);
  const {
    isLoaded,
    loadError
  } = useLoadScript(loadPrefs);
  const bounds = React.useMemo(() => isLoaded ? new google.maps.LatLngBounds(new google.maps.LatLng(defaultLatitude, defaultLongitude), new google.maps.LatLng(defaultLatitude, defaultLongitude)) : null, [defaultLatitude, defaultLongitude, isLoaded]);
  if (!isLoaded && !loadError) {
    return <Loader />;
  }
  return <Box data-sentry-element="Box" data-sentry-component="Address" data-sentry-source-file="index.tsx">
      <Global styles={{
      '.pac-container': {
        zIndex: 60003
      }
    }} data-sentry-element="Global" data-sentry-source-file="index.tsx" />

      <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Label sx={{
        flexGrow: 1
      }} data-sentry-element="Label" data-sentry-source-file="index.tsx">
          {schema.label}
        </Label>
        {menu}
      </Flex>
      <Divider mt={1} mb={3} gradient={false} data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
      <Grid style={style} className={`${className}`} gridTemplateColumns={['1fr', '1fr 1fr']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {schema.show_map && <>
            <Field name={`${name}.gps`}>
              {({
            input,
            meta
          }) => <Box gridColumn="1 / -1" sx={{
            height: '70vh',
            width: '100%',
            position: 'relative',
            zIndex: 1
          }}>
                  <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 1
            }}>
                    <PinMarker color="#dc4a3d" size={30} />
                  </Box>

                  {!loadError && <GoogleMap center={debouncedCenter} zoom={13} mapContainerStyle={{
              height: '70vh',
              width: '100%'
            }} options={{
              fullscreenControl: false,
              zoomControl: true,
              gestureHandling: 'cooperative',
              // scaleControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT
              }
            }} onCenterChanged={() => {
              const center = mapRef.current?.getCenter?.();
              if (center) {
                setCenter({
                  lng: center.lng(),
                  lat: center.lat()
                });
              }
            }} onLoad={map => {
              mapRef.current = map;
            }}>
                      {/* @ts-ignore */}
                      <StandaloneSearchBox bounds={bounds} onLoad={v => {
                searchBoxRef.current = v;
              }} onPlacesChanged={onPlacesChanged}>
                        <Box p={3} sx={{
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  pointerEvents: 'none'
                }}>
                          <StyledInput placeholder="Search..." sx={{
                    pointerEvents: 'all',
                    textOverflow: 'ellipses'
                  }} />
                        </Box>
                      </StandaloneSearchBox>
                    </GoogleMap>}
                </Box>}
            </Field>
          </>}

        <Field name={`${name}.street_address`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.streetAddressRequired" defaultMessage="Enter a street address" />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box gridColumn="1 / -1">
              <Label mb={2} invalid={meta.touched && meta.error}>
                <FormattedMessage id="DynamicFields.Address.streetAddress" defaultMessage="Street address" />
              </Label>

              <Textarea {...disableAutoCompleteInput(input, disableAutoComplete)} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error} />

              {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
            </Box>}
        </Field>

        <Field name={`${name}.city`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.cityRequired" defaultMessage="Enter a city" />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box>
              <Input {...disableAutoCompleteInput(input, disableAutoComplete)} label={<FormattedMessage id="DynamicFields.Address.city" defaultMessage="City" />} tabIndex={disabled ? -1 : undefined} type="text" error={meta.touched && meta.error} />
            </Box>}
        </Field>

        {values[name]?.country === 'ZA' ? <Field name={`${name}.province`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.provinceRequired" defaultMessage="Select a province" />;
        }
      }}>
            {({
          input,
          meta
        }) => <Box>
                {/* <Autocomplete
                        {...input}
                        label="Province"
                        tabIndex={disabled ? -1 : undefined}
                        itemToString={(v) => v || ''}
                        items={provinces}
                        invalid={meta.touched && meta.error}
                      /> */}

                <Select {...input} label={<FormattedMessage id="DynamicFields.Address.province" defaultMessage="Province" />} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error}>
                  <option></option>
                  {provinces.map(({
              value,
              label
            }) => <option key={value} value={value}>
                      {label}
                    </option>)}
                </Select>

                {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
              </Box>}
          </Field> : <Field name={`${name}.province`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.provinceorStateRequired" defaultMessage="Enter a province or state." />;
        }
      }}>
            {({
          input,
          meta
        }) => <Box>
                <Input {...disableAutoCompleteInput(input, disableAutoComplete)} label={<FormattedMessage id="DynamicFields.Address.provinceOrState" defaultMessage="Province/State" />} tabIndex={disabled ? -1 : undefined} type="text" error={meta.touched && meta.error} />
              </Box>}
          </Field>}

        <Field name={`${name}.postal_code`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.postalCodeRequired" defaultMessage="Enter a postal code." />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box>
              <Input {...disableAutoCompleteInput(input, disableAutoComplete)} label={<FormattedMessage id="DynamicFields.Address.postalCode" defaultMessage="Postal code" />} tabIndex={disabled ? -1 : undefined} type="text" error={meta.touched && meta.error} />
            </Box>}
        </Field>

        <Field name={`${name}.country`} validate={value => {
        if (schema.required && !value) {
          return <FormattedMessage id="DynamicFields.Address.countryRequired" defaultMessage="Select a country." />;
        }
      }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
          {({
          input,
          meta
        }) => <Box>
              {/* <Autocomplete
                      {...input}
                      label="Country"
                      tabIndex={disabled ? -1 : undefined}
                       itemToString={(v) =>
                        countries.find((c) => c.value === v)?.label || ''
                      }
                      items={countries}
                      invalid={meta.touched && meta.error}
                    /> */}

              <Select {...input} label={<FormattedMessage id="DynamicFields.Address.country" defaultMessage="Country" />} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error}>
                <option></option>
                {countries.map(({
              value,
              label
            }) => <option key={value} value={value}>
                    {label}
                  </option>)}
              </Select>

              {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
            </Box>}
        </Field>
      </Grid>
      <Divider my={3} gradient={false} data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
    </Box>;
};
export default Address;
export const toString = value => {
  return value ? ['street_address', 'city', 'province', 'country', 'postal_code'].map(v => value[v]).filter(v => v).map((v, idx) => v).join(', ') : '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">
      {schema.show_map && value?.gps && <Box>
          <Link rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/maps?&z=16&q=${value.gps[0]}+${value.gps[1]}&ll=${value.gps[0]}+${value.gps[1]}`} sx={{
        width: '100%',
        display: 'inline-block'
      }}>
            <Box as="img" alt="map" borderRadius={2} src={`https://maps.google.com/maps/api/staticmap?key=${__GOOGLE_MAPS_KEY__}&center=${value.gps[0]},${value.gps[1]}&zoom=14&size=200x200&sensor=false&markers=color:red|${value.gps[0]},${value.gps[1]}`} />
          </Link>
        </Box>}
      <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {value && ['street_address', 'city', 'province', 'country', 'postal_code'].map(v => value[v]).filter(v => v).map((v, idx) => <Box key={idx}>{v}</Box>)}
      </Box>
    </Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style,
  disableAutoComplete
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Form onSubmit={value => null}>
          {() => <Address schema={value} disabled={true} menu={menu} style={style} className={className} disableAutoComplete={disableAutoComplete} />}
        </Form> : <Grid sx={{
      display: !isEditing ? 'none' : null
    }}>
          <LabelField prefix={fieldPrefix} />
          <Field name={fieldPrefix ? `${fieldPrefix}.show_map` : 'show_map'}>
            {({
          input
        }) => <Flex alignItems="center">
                <Switch size="small" {...input} />
                <Box ml={2}>Show map</Box>
              </Flex>}
          </Field>
          {!hideRequired && <RequiredField prefix={fieldPrefix} />}

          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button type="button" onClick={toggleMore} width="100%" variant="outlined" size="small">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};