import { keyframes, Theme, ThemeContext } from '@emotion/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeGet } from '@styled-system/theme-get';
import Tippy from 'shared/tooltip';
import Color from 'color';
import React from 'react';
import * as StyledSystem from 'styled-system';
import { variant } from 'styled-system';
import { Box, BoxKnownProps, BoxProps } from '../grid';
const rotate = keyframes({
  '0%': {
    transform: 'rotate(0deg) scale(1)'
  },
  '50%': {
    transform: 'rotate(180deg) scale(1)'
  },
  '100%': {
    transform: 'rotate(360deg) scale(1)'
  }
});
const loadingStyle = theme => ({
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    border: `4px solid ${theme.colors.gray[3]}`,
    borderLeftColor: 'white',
    borderRadius: '50%',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
    width: '24px',
    height: '24px',
    animation: `${rotate} 1s linear infinite`
  }
});
interface ButtonKnownProps extends BoxKnownProps, StyledSystem.ButtonStyleProps {}
export interface ButtonProps extends ButtonKnownProps, Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, keyof ButtonKnownProps> {
  color?: string;
  colorScheme?: 'brand0' | 'brand1' | 'brand2' | 'brand3' | 'gray' | 'green' | 'yellow' | 'red';
  // colorOffset?: number;
  iconColor?: string;
  variant?: 'solid' | 'outlined' | 'flat';
  size?: 'large' | 'medium' | 'small';
  active?: boolean;
  loading?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  fixedWidth?: boolean;
}
const sizeVariant = variant({
  prop: 'size',
  scale: 'buttonSizes',
  variants: {
    // can be blank to enable the new API
    large: {
      fontSize: 3,
      px: ['1.2em', '1.2em'],
      py: ['0.5em', '0.7em']
    },
    medium: {
      fontSize: 2,
      px: ['1.2em', '1.2em'],
      py: ['0.5em', '0.7em']
    },
    small: {
      fontSize: 1,
      px: ['1.2em', '1.2em'],
      py: ['0.5em', '0.7em']
    }
  }
});
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
export function Button({
  ref,
  children,
  color: buttonColor = 'primary',
  iconColor = buttonColor,
  active,
  loading,
  iconLeft,
  iconRight,
  type = 'button',
  disabled,
  fixedWidth,
  variant: variant_ = 'solid',
  ...props
}: ButtonProps & {
  ref?: React.Ref<HTMLButtonElement>;
}) {
  const theme = React.useContext(ThemeContext) as Theme;
  const isDark = theme.mode === 'dark';

  // if (variant_ === 'outlined') {
  //   variant_ = 'solid';
  // }

  const variant: any = variant_ || 'solid';
  let activeStyle = {};
  let bg = 'transparent';
  let color;
  let borderColor = 'transparent';
  const borderStyle = 'solid';
  if (buttonColor.startsWith('#')) {
    // eslint-disable-next-line no-debugger
    debugger;
  }
  const [scale, index] = buttonColor.split('.');
  const colorScale = {
    success: 'green',
    warning: 'yellow',
    alert: 'red',
    primary: 'primary_',
    secondary: 'gray'
  }[scale] || scale;
  let scaleIndex;
  switch (variant) {
    default:
    case 'solid':
      {
        if (isDark) {
          scaleIndex = index ? Number(index) : {
            gray: 3,
            red: 6,
            primary_: 5,
            brand1: 6
          }[colorScale] || 8;
        } else {
          scaleIndex = index ? Number(index) : {
            gray: 1,
            red: 6,
            brand1: 4
          }[colorScale] || 8;
        }
        const baseColorN = clamp(scaleIndex + (isDark ? -1 : 0), 0, 9);
        const activeColorN = clamp(baseColorN + (isDark ? -1 : 1), 0, 9);
        const baseColor = `${colorScale}.${baseColorN}`;
        const darkenColor = `${colorScale}.${activeColorN}`;
        bg = baseColor;
        borderColor = baseColor;
        if (isDark ? baseColorN > 6 : baseColorN < 4) {
          color = theme.colors['black'];
        } else {
          color = theme.colors['white'];
        }
        activeStyle = {
          color,
          backgroundColor: darkenColor,
          borderColor: darkenColor
        };
        break;
      }
    case 'outlined':
      {
        if (isDark) {
          scaleIndex = index ? Number(index) : {
            primary_: 7,
            red: 7
          }[colorScale] || 7;
        } else {
          scaleIndex = index ? Number(index) : {
            red: 7
          }[colorScale] || 8;
        }
        const baseColorN = clamp(scaleIndex + (isDark ? -1 : 0), 0, 9);
        const fadeColorN = isDark ? 0 : 2;
        const baseColor = `${colorScale}.${baseColorN}`;
        const fadeColor = `${colorScale}.${fadeColorN}`;
        color = baseColor;
        borderColor = baseColor;
        activeStyle = {
          backgroundColor: fadeColor,
          color
        };
        break;
      }
    case 'flat':
      {
        if (isDark) {
          scaleIndex = index ? Number(index) : {
            primary_: 7,
            red: 7
          }[colorScale] || 7;
        } else {
          scaleIndex = index ? Number(index) : {
            red: 7
          }[colorScale] || 8;
        }
        const baseColorN = clamp(scaleIndex + (isDark ? -1 : 0), 0, 9);
        const fadeColorN = isDark ? 0 : 2;
        color = `${colorScale}.${baseColorN}`;
        activeStyle = {
          backgroundColor: `${colorScale}.${fadeColorN}`,
          color
        };
        break;
      }
  }
  return (
    // @ts-ignore
    <Box ref={ref} as="button" tx="buttons" type={type} disabled={disabled || loading} __css={{
      display: 'block',
      appearance: 'none',
      textAlign: 'center',
      lineHeight: 'inherit',
      textDecoration: 'none',
      cursor: 'pointer',
      overflow: 'hidden',
      transition: 'all 0.25s ease-out, color 0.25s ease-out',
      '&:disabled': {
        opacity: 0.5
      },
      borderRadius: 2,
      '&, *': {
        lineHeight: 1
      },
      borderWidth: 1,
      color,
      bg,
      borderColor,
      borderStyle,
      '&:active, &:focus, &:hover': activeStyle,
      '&:focus': {
        outline: `solid 2px ${theme.colors.brand0[isDark ? 7 : 3]}`
      },
      ...sizeVariant({
        ...props,
        size: props.size || 'medium',
        theme
      }),
      ...(active ? activeStyle : {}),
      ...(loading ? loadingStyle(theme) : '')
    }} {...props} data-sentry-element="Box" data-sentry-component="Button" data-sentry-source-file="component.tsx">
      {iconLeft && <Box sx={{
        mr: children ? '0.5em' : 0,
        display: 'inline'
      }}>
          <FontAwesomeIcon icon={iconLeft} fixedWidth={fixedWidth} />
        </Box>}
      {children}
      {iconRight && <Box sx={{
        ml: children ? '0.5em' : 0,
        display: 'inline'
      }}>
          <FontAwesomeIcon icon={iconRight} fixedWidth={fixedWidth} />
        </Box>}
    </Box>
  );
}
export function ButtonGroup({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return <Box ref={ref} {...props} __css={{
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    '& > *': {
      flexGrow: '1',
      // flexBasis: '50px',

      '&:not(:first-of-type):not(:last-of-type)': {
        borderRadius: 0
      },
      '&:not(:last-of-type):not(:first-of-type)': {
        borderRadius: 0
      },
      '&:not(:last-of-type):not(:only-of-type)': {
        marginRight: '1px'
      },
      '&:first-of-type:not(:last-of-type)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      '&:last-of-type:not(:first-of-type)': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      }
    }
  }} data-sentry-element="Box" data-sentry-component="ButtonGroup" data-sentry-source-file="component.tsx" />;
}
interface RoundButtonProps {
  tooltip;
  icon: any;
  onClick?: (any) => void;
  color?: string;
  className?: string;
  inactiveColor?: string;
}
export const RoundButton = function RoundButton({
  ref,
  tooltip,
  icon,
  onClick,
  color: buttonColor = 'primary',
  inactiveColor = 'gray.5',
  className
}: RoundButtonProps & {
  ref?: React.Ref<HTMLButtonElement>;
}) {
  const variant = 'solid';
  const theme = React.useContext(ThemeContext) as Theme;
  const isDark = theme.mode === 'dark';
  const colorScale = {
    success: 'green',
    warning: 'yellow',
    alert: 'red',
    primary: 'primary_',
    secondary: 'gray'
  }[buttonColor.split('.')[0]] || buttonColor;
  const darkness = {
    gray: 1,
    red: 5
  }[colorScale] || 8;
  const baseColorN = darkness + (isDark ? variant !== 'solid' ? 4 : 1 : 0);
  const activeColorN = clamp(baseColorN + (isDark ? 1 : 1), 0, 9);
  const fadeColorN = isDark ? 1 : 2;
  const baseColor = themeGet(`colors.${colorScale}.${baseColorN}`)({
    theme
  });
  const darkenColor = themeGet(`colors.${colorScale}.${activeColorN}`)({
    theme
  });
  const bg = baseColor;
  const borderColor = baseColor;
  let color;
  if (Color(bg).contrast(Color(theme.colors['black'])) - Color(bg).contrast(Color(theme.colors['white'])) > 3.9) {
    color = theme.colors['black'];
  } else {
    color = theme.colors['white'];
  }
  const activeStyle = {
    color,
    backgroundColor: darkenColor,
    borderColor: darkenColor
  };
  return <Tippy content={tooltip}>
      <Box as="button" className={className} ref={ref} onClick={onClick} __css={{
      borderRadius: '99px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '48px',
      width: '48px',
      color,
      bg,
      borderColor,
      borderStyle: 'solid',
      boxShadow: '2px 2px 24px #444',
      '&:active, &:focus, &:hover': activeStyle,
      '&:focus': {
        outline: `solid 2px ${theme.colors['brand0'][isDark ? 7 : 3]}`
      }
    }}>
        <FontAwesomeIcon icon={icon} fixedWidth size="lg" />
      </Box>
    </Tippy>;
};
export default Button;