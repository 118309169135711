import { ScheduleItem } from './booking-tools';
import { expandRule, Schedule, ScheduleSubject } from './time';

function buildOrList(tree, bookableData) {
  const blist = [];
  for (const child of tree.children) {
    if (typeof child === 'object') {
      throw Error('Nesting inside or not supported');
    }

    const bookableId = child.split(':')[1];
    if (
      bookableData[bookableId]?.is_enabled &&
      !bookableData[bookableId]?.is_deleted
    ) {
      blist.push(bookableId);
    }
  }
  return blist;
}

function buildAndList(tree, bookableData) {
  const alist = [];
  for (const child of tree.children) {
    if (typeof child !== 'object') {
      const bookableId = child.split(':')[1];
      if (
        bookableData[bookableId]?.is_enabled &&
        !bookableData[bookableId]?.is_deleted
      ) {
        alist.push([bookableId]);
      } else {
        alist.push([]);
      }
    } else if (child.op === 'and') {
      throw new Error('Nested and not supported');
    } else if (child.op === 'or') {
      const list = buildOrList(child, bookableData);
      if (list.length) {
        alist.push(list);
      }
    }
  }
  return alist;
}

export function buildBookableOptionsList(tree, bookableData) {
  if (tree.op === 'or') {
    const list = buildOrList(tree, bookableData);
    return list.length > 0 ? [list] : [];
  }
  if (tree.op === 'and') {
    return buildAndList(tree, bookableData);
  }
  throw Error('Bad requirements');
}

export const isMatch = (queryId, ruleId) => {
  if (typeof queryId === 'number') {
    throw new Error('queryId cannot be a number');
  }

  if (typeof ruleId === 'number') {
    throw new Error('ruleId cannot be a number');
  }
  // rule === '*' matches all
  // query === '*' matches all
  // query === null matches none
  if (queryId === '*') {
    // Get rules that apply to all entity ids
    return true;
  } else {
    if (ruleId === '*') {
      return true;
    } else if (ruleId === queryId) {
      return true;
    } else {
      return false;
    }
  }
};

export const anyMatch = (queryIds, ruleId) => {
  const ids = Array.isArray(queryIds) ? queryIds : [queryIds];
  for (const queryId of ids) {
    if (isMatch(queryId, ruleId)) {
      return true;
    }
  }
  return false;
};

const allMatch = (queryIds, ruleId) => {
  const ids = Array.isArray(queryIds) ? queryIds : [queryIds];
  for (const queryId of ids) {
    if (!isMatch(queryId, ruleId)) {
      return false;
    }
  }
  return !!queryIds.length;
};

export const isBookableIncluded = (
  includes: ScheduleSubject[],
  excludes: ScheduleSubject[],
  qBookableId: string,
  qServiceId: string, /// qServiceId = null means ignore
  qLocationId: string = null
) => {
  let ruleApplies = false;
  for (const include of includes) {
    if (
      isMatch(qBookableId || '*', include.bookable_id) &&
      isMatch(qServiceId || '*', include.service_id) &&
      isMatch(qLocationId || '*', include.location_id)
    ) {
      ruleApplies = true;
      break;
    }
  }
  if (!ruleApplies) {
    return false;
  }

  for (const exclude of excludes) {
    if (
      isMatch(qBookableId, exclude.bookable_id) &&
      isMatch(qServiceId, exclude.service_id) &&
      isMatch(qLocationId, exclude.location_id)
    ) {
      ruleApplies = false;
      break;
    }
  }
  return ruleApplies;
};

const getMatches = (queryIds, ruleId) => {
  // TODO: check logic around 0
  const ids = Array.isArray(queryIds) ? queryIds : [queryIds];
  return ids
    .map((queryId) =>
      isMatch(queryId, ruleId) ? [queryId || ruleId || 0] : []
    )
    .reduce((agg, curr) => agg.concat(curr), []);
};

const bookableIdsIncluded = (
  includes: ScheduleSubject[],
  excludes: ScheduleSubject[],
  qBookableIds: string[],
  qServiceId: string,
  qLocationId: string = null
) => {
  const matches = [];

  for (const include of includes) {
    const matchedBookableIds = getMatches(qBookableIds, include.bookable_id);

    if (
      matchedBookableIds.length &&
      isMatch(qServiceId || '*', include.service_id) &&
      isMatch(qLocationId || '*', include.location_id)
    ) {
      matches.push(matchedBookableIds);
    }
  }

  return matches
    .map((bookableIds) => {
      return bookableIds.filter((bookableId) => {
        for (const exclude of excludes) {
          if (
            isMatch(bookableId, exclude.bookable_id) &&
            isMatch(qServiceId, exclude.service_id) &&
            isMatch(qLocationId, exclude.location_id)
          ) {
            return false;
          }
        }
        return true;
      });
    })
    .reduce((agg, cur) => agg.concat(cur), []);
};

// VERY IMPORTANT: null in query means its not queried
const doesRuleApply = (
  includes: ScheduleSubject[],
  excludes: ScheduleSubject[],
  qBookableIds: string[],
  qServiceId: string,
  qLocationId: string = null
) => {
  let ruleApplies = false;
  for (const include of includes) {
    if (
      anyMatch(
        qBookableIds.map((v) => v || '*'),
        include.bookable_id
      ) &&
      isMatch(qServiceId || '*', include.service_id) &&
      isMatch(qLocationId || '*', include.location_id)
    ) {
      ruleApplies = true;
      break;
    }
  }
  if (!ruleApplies) {
    return false;
  }

  for (const exclude of excludes) {
    if (
      allMatch(qBookableIds, exclude.bookable_id) &&
      isMatch(qServiceId, exclude.service_id) &&
      isMatch(qLocationId, exclude.location_id)
    ) {
      ruleApplies = false;
      break;
    }
  }
  return ruleApplies;
};

export const rulesToScheduleItems = (
  rules: Schedule[],
  startUTC: string,
  endUTC: string,
  timezone: string,
  locationId: string,
  serviceId: string,
  bookableIds: string[]
): ScheduleItem[] =>
  rules
    .filter((rule) =>
      doesRuleApply(
        rule['include'],
        rule['exclude'],
        bookableIds,
        serviceId,
        locationId
      )
    )
    .reduce((slots, r) => {
      const applicableBookableIds = bookableIdsIncluded(
        r.include,
        r.exclude,
        bookableIds,
        serviceId,
        locationId
      );

      if (!applicableBookableIds.length) {
        return slots;
      }

      const expanded = expandRule(startUTC, endUTC, timezone, r).map((er) => ({
        start: er.start,
        end: er.end,
        type: r.type,
        rule: r,
        bookableIds: applicableBookableIds,
        serviceId,
      }));

      return expanded.concat(slots);
    }, []);
