import Color from 'color';
import { BASE_FONT_SIZE, desktopfontSizes, mobileFontSizes } from './theme';
export const reset = theme => ({
  html: {
    lineHeight: 1.15,
    WebkitTextSizeAdjust: '100%'
  },
  body: {
    margin: 0
  },
  a: {
    backgroundColor: 'transparent'
  },
  'b, strong': {
    fontWeight: 'bolder'
  },
  small: {
    fontSize: '80%'
  },
  img: {
    borderStyle: 'none'
  },
  button: {
    all: 'unset',
    fontSize: 'inherit',
    cursor: 'pointer'
  },
  'button, input, optgroup, select, textarea': {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: 1.15,
    margin: 0
  },
  'button, input': {
    overflow: 'visible'
  },
  'button, select': {
    textTransform: 'none'
  },
  "button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner": {
    borderStyle: 'none',
    padding: 0
  },
  "button:-moz-focusring,  [type='button']:-moz-focusring,  [type='reset']:-moz-focusring,  [type='submit']:-moz-focusring": {
    outline: `1px dotted ${theme.colors.text}`
  },
  "[type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button": {
    height: 'auto'
  },
  "[type='search']": {
    WebkitAppearance: 'none'
    // outlineOffset: '-2px',
  },
  "[type='search']::-webkit-search-decoration": {
    WebkitAppearance: 'none'
  },
  '&::-webkit-file-upload-button': {
    WebkitAppearance: 'none',
    font: 'inherit'
  },
  input: {
    /* https://stackoverflow.com/questions/43314921/strange-input-widths-in-firefox-vs-chrome */
    minWidth: 0
  },
  "[data-whatintent='mouse'] *:focus, [data-whatintent='touch'] *:focus": {
    outline: 'none'
  },
  '*': {
    boxSizing: 'border-box'
  }
});
const table = theme => ({
  'table.bookem-table': {
    borderCollapse: 'collapse',
    width: '100%',
    borderRadius: `${theme.radii[1]}px`,
    'thead, tbody, tfoot': {
      border: 'none',
      backgroundColor: theme.colors.background
    },
    thead: {
      backgroundColor: theme.colors.background,
      color: theme.colors.text
    },
    tfoot: {
      backgroundColor: theme.colors.background,
      color: theme.colors.text
    },
    'thead, tfoot': {
      tr: {
        background: 'transparent'
      },
      'th, td': {
        padding: '16px',
        fontWeight: theme.fontWeights.heading,
        textAlign: 'left'
      }
    },
    tbody: {
      'th, td': {
        padding: '16px'
      },
      tr: {
        '&:nth-of-type(odd)': {
          backgroundColor: Color(theme.colors.background).darken(0.02).string()
        }
      }
    },
    'thead tr': {
      /* Darkens the table header rows on hover. */
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    'tfoot tr': {
      /* Darkens the table footer rows on hover. */
      '&:hover': {
        backgroundColor: Color(theme.colors.background).darken(0.04).string()
      }
    },
    'tbody tr': {
      /* Darkens the non-striped table rows on hover. */
      '&:hover': {
        backgroundColor: Color(theme.colors.background).darken(0.02).string()
      }
    },
    /* Darkens the odd striped table rows. */

    'tr:nth-of-type(odd):hover': {
      backgroundColor: Color(theme.colors.background).darken(0.04).string()
    },
    '&.flush-right-edge tr': {
      'td:last-child, th:last-child': {
        textAlign: 'right'
      }
    }
  }
});
const visibility = theme => ({
  '.show-for-medium, .hide-for-small-only': {
    [`@media ${theme.mediaQueries.smallOnly}`]: {
      display: 'none !important'
    }
  },
  '.hide-for-medium, .show-for-small-only': {
    [`@media ${theme.mediaQueries.mediumUp}`]: {
      display: 'none !important'
    }
  },
  '.hide-for-large': {
    [`@media ${theme.mediaQueries.largeUp}`]: {
      display: 'none !important'
    }
  },
  '.show-for-large': {
    [`@media ${theme.mediaQueries.mediumDown}`]: {
      display: 'none !important'
    }
  }
});
const print = theme => ({
  '.show-for-print': {
    display: 'none !important'
  },
  '@media print': {
    '*': {
      background: 'transparent !important',
      boxShadow: 'none !important',
      /* Black prints faster: h5bp.com/s */
      color: 'black !important',
      textShadow: 'none !important'
    },
    /* Display the URL of a link after the text */
    'a, a:visited': {
      textDecoration: 'underline'
    },
    'a[href]:after': {
      content: "' (' attr(href) ')'"
    },
    /* Don't display the URL for images or JavaScript/internal links */
    ".ir a:after, a[href^='javascript:']:after, a[href^='#']:after": {
      content: "''"
    },
    /* Display what an abbreviation stands for after the text */
    'abbr[title]:after': {
      content: "' (' attr(title) ')'"
    },
    /* Prevent page breaks in the middle of a blockquote or preformatted text block */
    'pre, blockquote': {
      border: `1px solid ${theme.colors.gray[6]}`,
      pageBreakInside: 'avoid'
    },
    /* h5bp.com/t */
    thead: {
      display: 'table-header-group'
    },
    'tr, img': {
      pageBreakInside: 'avoid'
    },
    img: {
      maxWidth: '100% !important'
    },
    '@page': {
      margin: '0.5cm'
    },
    'p, h2, h3': {
      orphans: 3,
      widows: 3
    },
    /* Avoid page breaks after a heading */
    'h2, h3': {
      pageBreakAfter: 'avoid'
    },
    /* Custom */
    '.hide-for-large, .hide-for-medium': {
      display: 'none !important'
    },
    body: {
      backgroundColor: 'white !important'
    },
    a: {
      textDecoration: 'none'
    },
    '.button': {
      border: 'none'
    }
  }
});
const misc = theme => ({
  html: {
    backgroundColor: theme.colors.bodyBackground
  },
  'body:fullscreen': {
    width: '100% !important',
    overflowY: 'auto',
    overflowX: 'auto',
    '.side-panel-layout': {
      backgroundColor: theme.colors.bodyBackground
    }
  },
  'body, html, #root': {
    /* Really bad for mobile: */
    /* min-height: 100vh; */
    flexGrow: 1,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  /* Fontawesome icon colors */
  'svg.warning': {
    color: theme.colors.warning
  },
  'svg.alert': {
    color: theme.colors.alert
  },
  'svg.success': {
    color: theme.colors.success
  },
  'svg.info': {
    color: theme.colors.brand3[8]
  }
});
export const bookemStyle = theme => {
  // theme.primary_.map((color) => {
  //   return {
  //     [`.${color}`]: {
  //       color: theme.colors[color],
  //     },
  //     [`.bg-${color}`]: {
  //       backgroundColor: theme.colors[color],
  //     },
  //   };
  // });

  const colors = Object.entries<string[]>({
    primary: theme.colors.primary_,
    green: theme.colors.green,
    yellow: theme.colors.yellow,
    red: theme.colors.red
  }).reduce((agg, [key, colors]) => {
    colors.forEach((color, idx) => {
      agg[`--${key}-${idx}`] = color;
    });
    return agg;
  }, {});
  return {
    body: {
      ...colors
    },
    '.EditorTheme__table': {
      backgroundColor: theme.colors.background
    },
    img: {
      display: 'inline-block',
      verticalAlign: 'middle',
      maxWidth: '100%',
      height: 'auto',
      MsInterpolationMode: 'bicubic'
    },
    '.map_canvas img, .mqa-display img, .map_canvas embed, .mqa-display embed, .map_canvas object, .mqa-display object': {
      maxWidth: 'none !important'
    },
    button: {
      padding: 0,
      appearance: 'none',
      WebkitAppearance: 'none',
      border: 0,
      background: 'transparent',
      cursor: 'pointer',
      // color: theme.colors.text,
      color: 'inherit'
    },
    pre: {
      overflow: 'auto'
    },
    'button, input, optgroup, select, textarea': {
      fontFamily: 'inherit'
    },
    "select, textarea, input[type='text'], input[type='password'], input[type='date'], input[type='datetime'], input[type='datetime-local'], input[type='month'], input[type='week'], input[type='email'], input[type='number'], input[type='search'], input[type='tel'], input[type='time'], input[type='url'], input[type='color']": {
      appearance: 'none',
      WebkitAppearance: 'none'
    },
    /* Hack around IOS bug: https://github.com/twbs/bootstrap/issues/23307 */
    "input[type='date']": {
      minHeight: '45px'
    },
    'div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td': {
      margin: 0,
      padding: 0
    },
    /* Paragraphs */
    /* Todo create paragraph component */
    p: {
      fontSize: 'inherit',
      lineHeight: '1.6',
      textRendering: 'optimizeLegibility'
    },
    /* Emphasized text */
    'em, i': {
      fontStyle: 'italic',
      lineHeight: 'inherit'
    },
    /* Strong text */
    'strong, b': {
      fontWeight: theme.fontWeights.heading,
      lineHeight: 'inherit'
    },
    /* Lists */
    'ul, ol, dl': {
      listStylePosition: 'outside',
      lineHeight: '1.6',
      marginLeft: `${theme.space[3]}px`
    },
    /* List items */
    li: {
      fontSize: 'inherit'
    },
    /* Links */
    a: {
      lineHeight: 'inherit',
      textDecoration: 'none',
      color: 'inherit',
      img: {
        border: 0
      }
    },
    ...table(theme),
    /* Remove Arrows/Spinners */
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },
    /* Firefox */
    "input[type='number']": {
      MozAppearance: 'textfield'
    }
  };
};
const globalStyle = theme => [reset(theme), visibility(theme), {
  body: {
    backgroundColor: theme.colors.bodyBackground
  },
  '.grecaptcha-badge': {
    visibility: 'hidden'
  },
  '#floating-ui-root': {
    zIndex: 6002
  },
  'html, body': {
    colorScheme: theme.mode == 'dark' ? 'dark' : 'auto',
    fontSize: `${BASE_FONT_SIZE}px`,
    WebkitOverflowScrolling: 'touch'
  },
  // 14px fonts for the app
  'body:not(.app)': {
    [`@media ${theme.mediaQueries.smallOnly}`]: {
      fontSize: '16px',
      ...mobileFontSizes.reduce((agg, size, idx) => {
        agg[`--font-size${idx}`] = size;
        return agg;
      }, [])
    }
  },
  // 'body.app': {
  //   height: '100svh',
  // },

  'body, :host, :root': {
    margin: 0,
    padding: 0,
    fontFamily: theme.fonts.body,
    fontWeight: 'normal',
    lineHeight: '1.6',
    color: theme.colors.text,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    ...desktopfontSizes.reduce((agg, size, idx) => {
      agg[`--font-size${idx}`] = size;
      return agg;
    }, []),
    // [`@media ${theme.mediaQueries.smallOnly}`]: {
    //   fontSize: '16px',
    //   ...mobileFontSizes.reduce((agg, size, idx) => {
    //     agg[`--font-size${idx}`] = size;
    //     return agg;
    //   }, []),
    // },

    [`@media ${theme.mediaQueries.smallOnly}`]: {
      [`input[type="color"],
        input[type="date"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="email"],
        input[type="month"],
        input[type="number"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="text"],
        input[type="time"],
        input[type="url"],
        input[type="week"],
        select:focus,
        textarea,
        [contenteditable="true"]`]: {
        '&:focus, &:focus-within': {
          fontSize: '16px'
        }
      }
    }
  }
}, bookemStyle(theme), misc(theme), print(theme)];
export default globalStyle;