interface Options {
  url: string;
  protocols?: string | string[];
  repeatLimit?: number;
  hearbeatMessageFn?: () => Record<string, any>;
  heartbeatInterval?: number;
  heartbeatTimeout?: number;
  reconnectTimeout?: number;
}

class HeartbeatWebsocket {
  opts: Options;
  ws: WebSocket;
  isReconnecting;
  repeat = 0;
  isClosed?: boolean;
  heartbeatTimeoutId?: ReturnType<typeof setTimeout>;
  onclose = () => null;
  onerror = () => null;
  onopen = () => null;
  onmessage = (e: Record<string, any>) => null;
  onreconnect = () => null;
  bufferedMessages: Array<Record<string, any>> = [];

  lastMessage?: number;

  heartbeatIntervalId?: ReturnType<typeof setTimeout>;
  heartbeatCheckIntervalId?: ReturnType<typeof setTimeout>;

  constructor(opts: Options) {
    this.opts = {
      heartbeatInterval: 2000,
      heartbeatTimeout: 3000,
      reconnectTimeout: 2000,
      ...opts,
    };
    this.ws = null;
    this.isReconnecting = false;
    this.createWebSocket();
  }

  createWebSocket() {
    try {
      if (this.opts.protocols) {
        this.ws = new WebSocket(this.opts.url, this.opts.protocols);
      } else {
        this.ws = new WebSocket(this.opts.url);
      }
      this.initEventHandle();
    } catch (e) {
      this.reconnect();
      throw e;
    }
  }

  initEventHandle() {
    this.ws.onclose = () => {
      this.onclose();
      clearInterval(this.heartbeatIntervalId);
      clearInterval(this.heartbeatCheckIntervalId);
      this.reconnect();
    };

    this.ws.onerror = () => {
      this.onerror();
      this.reconnect();
    };

    this.ws.onopen = () => {
      this.repeat = 0;
      this.lastMessage = new Date().getTime();
      this.onopen();
      for (const msg of this.bufferedMessages) {
        this.send(msg);
      }

      const sendHeartbeat = () => {
        if (this.opts.hearbeatMessageFn) {
          this.send(this.opts.hearbeatMessageFn());
        }
      };

      this.heartbeatIntervalId = setInterval(
        sendHeartbeat,
        this.opts.heartbeatInterval
      );

      const checkHeartbeat = () => {
        // Explicitly close the connection if the heartbeat is not received and the connection is open
        if (
          this.ws?.readyState === WebSocket.OPEN &&
          this.lastMessage &&
          Date.now() - this.lastMessage > this.opts.heartbeatTimeout
        ) {
          this.ws.close();
        }
      };

      this.heartbeatCheckIntervalId = setInterval(
        checkHeartbeat,
        this.opts.heartbeatInterval
      );
    };

    this.ws.onmessage = (event) => {
      this.onmessage(JSON.parse(event.data));
      this.lastMessage = new Date().getTime();
    };
  }

  reconnect() {
    if (this.opts.repeatLimit > 0 && this.opts.repeatLimit <= this.repeat)
      return;
    if (this.isReconnecting || this.isClosed) return;
    this.isReconnecting = true;
    this.repeat++;
    this.onreconnect();

    setTimeout(() => {
      this.createWebSocket();
      this.isReconnecting = false;
    }, this.opts.reconnectTimeout);
  }

  send(msg) {
    if (this.ws?.readyState !== WebSocket.OPEN) {
      this.bufferedMessages.push(msg);
      return;
    }
    this.ws.send(JSON.stringify(msg));
  }

  close() {
    this.isClosed = true;
    this.ws.close();
  }
}

export default HeartbeatWebsocket;
