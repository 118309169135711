export const BOOKABLE_FETCH_REQUESTED = 'BOOKABLE_FETCH_REQUESTED';
export const BOOKABLE_FETCH_SUCCEEDED = 'BOOKABLE_FETCH_SUCCEEDED';
export const BOOKABLE_FETCH_FAILED = 'BOOKABLE_FETCH_FAILED';

export const BOOKABLE_SAVE_REQUESTED = 'BOOKABLE_SAVE_REQUESTED';
export const BOOKABLE_SAVE_SUCCEEDED = 'BOOKABLE_SAVE_SUCCEEDED';
export const BOOKABLE_SAVE_FAILED = 'BOOKABLE_SAVE_FAILED';

export const BOOKABLE_DELETE_REQUESTED = 'BOOKABLE_DELETE_REQUESTED';
export const BOOKABLE_DELETE_SUCCEEDED = 'BOOKABLE_DELETE_SUCCEEDED';
export const BOOKABLE_DELETE_FAILED = 'BOOKABLE_DELETE_FAILED';

export const BOOKABLE_UPDATED = 'BOOKABLE_UPDATED';
