import { Theme, ThemeContext } from '@emotion/react';
import { faCalendar, faEnvelope, faMapMarker, faPhone } from '@fortawesome/pro-regular-svg-icons';
import Tippy from 'shared/tooltip';
import deepEqual from 'fast-deep-equal';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import Button from 'shared/button';
import Callout from 'shared/callout';
import DropdownSelect from 'shared/dropdown-select';
import { Box, Flex, Grid } from 'shared/grid';
import IconWrapper from 'shared/icon-wrapper';
import { fetchService } from 'shared/redux/services/actions';
import settings from 'shared/settings';
import { CustomElement } from 'shared/slate-shared/custom-types';
import RenderSlate from 'shared/slate-shared/render';
import { CroppedText, Heading, Link, Text } from 'shared/typography';
import { getBookingUrl, getVideoCallLink, getVideoCallUrl, statusToColorScale } from 'shared/utils/booking';
import { useAppDispatch, useAppSelector } from '../../store';
import { BorderBox } from '../shared';
import { useCantCancelBookingReason } from './hooks';
const ContactDetails = ({
  location
}) => <>
    {location.email && <Flex as="a" href={`mailto:${location.email}`} alignItems="center" fontSize={2}>
        <IconWrapper icon={faEnvelope} size={1} mr={2} /> {location.email}
      </Flex>}
    {location.tel && <Flex as="a" href={`tel:${location.tel}`} alignItems="center" fontSize={2}>
        <IconWrapper icon={faPhone} size={1} mr={2} /> {location.tel}
      </Flex>}
  </>;
const EMPTY_VALUE: CustomElement[] = [{
  type: 'paragraph',
  children: [{
    text: ''
  }]
}];
const isSlateBlank = (value: any[]) => {
  if (!value) {
    return true;
  }
  return deepEqual(value, EMPTY_VALUE);
};
export default function BookingDefaultView({
  booking,
  location,
  canCancel,
  isOwner,
  setShowCancelModal,
  showCancelModal
}) {
  const dispatch = useAppDispatch();
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  const language = useAppSelector(state => state.userPreferences.language);
  const serviceData = useAppSelector(state => state.services.data);
  const serviceName = (booking?.services || []).map(v => v.service_name || '').join(' + ');
  const offsite = booking?.services.some(({
    service_id
  }) => serviceData[service_id]?.offsite !== 'on_premises');
  const timezone = offsite && booking.client_timezone || location.timezone;
  const [timeToStart, setTimeToStart] = React.useState('');
  const earliestStart = booking.services.map(s => s.start).sort((a, b) => a < b ? -1 : 1)[0];
  const latestEnd = booking.services.map(s => s.end).sort((a, b) => a < b ? 1 : -1)[0];
  React.useEffect(() => {
    booking.services.forEach(({
      service_id
    }) => {
      if (service_id && !serviceData[service_id]) {
        dispatch(fetchService(service_id));
      }
    });
  }, [booking.services, dispatch, serviceData]);
  React.useEffect(() => {
    const set = () => {
      const tts = DateTime.fromISO(earliestStart).toRelative();
      setTimeToStart(tts);
    };
    const id = setInterval(set, 60000);
    set();
    return () => clearInterval(id);
  }, [earliestStart]);
  const startTime = DateTime.fromISO(earliestStart, {
    zone: timezone
    // locale: language,
  }).toFormat('t');
  const startDate = DateTime.fromISO(earliestStart, {
    zone: timezone
    // locale: language,
  }).toFormat('DDDD');
  const startDateTime = DateTime.fromISO(earliestStart, {
    zone: timezone
    // locale: language,
  }).toFormat("DDDD 'at' t (ZZZZ)");
  const bookingMsgValues = {
    startTime,
    startDate,
    serviceName,
    locationName: location.name || '[removed location]'
  };
  const hasVideoCall = !!booking.third_party_video_call_url || booking.video_call_enabled || booking.services.map(({
    service_id
  }) => serviceData[service_id]).filter(Boolean).some(s => s.type === 'video');
  const hasOnsite = booking.services.map(({
    service_id
  }) => serviceData[service_id]).filter(Boolean).some(s => s.type === 'on_premises');
  const showLocation = !location.is_virtual && hasOnsite;
  const videoCallLink = React.useMemo(() => getVideoCallLink(booking), [booking]);
  const statusToMsg = {
    confirmed: <FormattedMessage id="Public.BookingPage.confirmedBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} was confirmed.`} values={bookingMsgValues} />,
    no_show: <FormattedMessage id="Public.BookingPage.noShowBookingDesciption" defaultMessage={`It was noted that you did not show up for your {serviceName} booking that was scheduled for {startTime} on {startDate}.`} values={bookingMsgValues} />,
    cancelled: <FormattedMessage id="Public.BookingPage.cancelledBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} was cancelled.`} values={bookingMsgValues} />,
    requested: <FormattedMessage id="Public.BookingPage.requestedBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} is pending approval.`} values={bookingMsgValues} />,
    pencilled_in: <FormattedMessage id="Public.BookingPage.pencilledInBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} has been pencilled in.`} values={bookingMsgValues} />,
    awaiting_payment: <FormattedMessage id="Public.BookingPage.awaitingPaymentBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} is awaiting payment.`} values={bookingMsgValues} />,
    denied: <FormattedMessage id="Public.BookingPage.deniedBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} was declined.`} values={bookingMsgValues} />
  };
  const theme = React.useContext<Theme>(ThemeContext as any);
  const featureFlags = business?.feature_flags || [];
  const videoCallsEnabled = featureFlags.includes('video_calls');
  const locationData = useAppSelector(state => state.locations.data);
  const businessData = useAppSelector(state => state.businesses.data);
  const calendarUrls = React.useMemo(() => {
    const business = businessData[booking.business_id];
    const location = locationData[booking.location_id];
    const urls = {
      google: '',
      outlook: '',
      yahoo: ''
    };
    booking.services.forEach(service => {
      let description = '';
      let locationAddress = '';
      const serviceStart = DateTime.fromISO(service.start, {
        zone: 'UTC'
      });
      const serviceEnd = DateTime.fromISO(service.end, {
        zone: 'UTC'
      });
      let serviceName = service.service_name || 'Custom';
      serviceName = serviceName + ` (${business.name})`;
      const serviceType = serviceData[service.service_id]?.type;
      const bookingLink = getBookingUrl(booking.id);
      if (serviceType == 'video' || booking.third_party_video_call_url || booking.video_call_enabled) {
        const videoLink = getVideoCallUrl(booking.id);
        locationAddress = videoLink;
        description = `ℹ️  ${bookingLink}\n\n📍  ${videoLink}`;
      } else if (serviceType === 'on_premises' && location && !location.is_virtual) {
        locationAddress = [...location.street_address.split(','), location.city, location.postal_code, location.province].filter(Boolean).join(', ');
        const gps = location?.gps?.[0] && location?.gps?.[1] ? location.gps : null;
        const mapLink = gps ? `\n\n📍 https://maps.google.com/maps?&z=16&q=${gps[0]}+${gps[1]}&ll=${gps[0]}+${gps[1]}` : '';
        description = location ? `ℹ️  ${bookingLink}${mapLink}` : '';
      } else {
        description = `ℹ️  ${bookingLink}`;
        locationAddress = '';
      }
      const title = serviceName;
      description = encodeURIComponent(description);
      const startDatetimeGoogleYahoo = serviceStart.toFormat("yyyyMMdd'T'HHmmss") + 'Z';
      const endDatetimeGoogleYahoo = serviceEnd.toFormat("yyyyMMdd'T'HHmmss") + 'Z';
      const startDatetimeOutlook = serviceStart.toFormat("yyyy'-'MM'-'dd'T'HH':'mm':'ss") + 'Z';
      const endDatetimeOutlook = serviceEnd.toFormat("yyyy'-'MM'-'dd'T'HH':'mm':'ss") + 'Z';

      // console.log(serviceStart.toISO(), booking.timezone);

      urls.google = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${startDatetimeGoogleYahoo}/${endDatetimeGoogleYahoo}&text=${title}&location=${locationAddress}&details=${description}`;
      urls.outlook = `https://outlook.live.com/calendar/action/compose?rru=addevent&startdt=${startDatetimeOutlook}&enddt=${endDatetimeOutlook}&subject=${title}&body=${description}&location=${locationAddress}`;
      urls.yahoo = `https://calendar.yahoo.com/?v=60&TITLE=${title}&ST=${startDatetimeGoogleYahoo}&ET=${endDatetimeGoogleYahoo}&DESC=${description}&in_loc=${locationAddress}`;
    });
    return urls;
  }, [booking, businessData, locationData, serviceData]);
  const cantCancelReason = useCantCancelBookingReason(canCancel, business?.settings.disable_changes, booking?.services, isOwner);
  return <Box data-sentry-element="Box" data-sentry-component="BookingDefaultView" data-sentry-source-file="view.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="view.tsx">
        <Callout color={statusToColorScale(theme, booking.status)} mx={[3, 0]} data-sentry-element="Callout" data-sentry-source-file="view.tsx">
          <CroppedText data-sentry-element="CroppedText" data-sentry-source-file="view.tsx">{statusToMsg[booking.status]}</CroppedText>
        </Callout>

        <Grid gridTemplateAreas={[`
          'content'
          'sidepanel'
        `, `
          'content sidepanel'
        `]} gridTemplateColumns={['1fr', '1fr 300px']} data-sentry-element="Grid" data-sentry-source-file="view.tsx">
          <BorderBox p={3} pt={[0, 3]} gridArea="content" data-sentry-element="BorderBox" data-sentry-source-file="view.tsx">
            <Grid gridGap={3} data-sentry-element="Grid" data-sentry-source-file="view.tsx">
              <Heading data-sentry-element="Heading" data-sentry-source-file="view.tsx">{serviceName}</Heading>

              <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="view.tsx">
                <IconWrapper icon={faCalendar} size={1} mr={2} flexShrink={0} data-sentry-element="IconWrapper" data-sentry-source-file="view.tsx" />
                <Text data-sentry-element="Text" data-sentry-source-file="view.tsx">{startDateTime}</Text>
              </Flex>

              {showLocation && <>
                  <Flex as="a" href={`https://maps.google.com/maps?&z=16&q=${location.gps[0]}+${location.gps[1]}&ll=${location.gps[0]}+${location.gps[1]}`} alignItems="flex-start">
                    <IconWrapper icon={faMapMarker} size={1} mr={2} flexShrink={0} />
                    <Text>
                      {[...(location.street_address || '').split('\n'), location.city, location.postal_code, location.province].map((line, idx) => <Text key={idx}>{line}</Text>)}

                      {!isSlateBlank(location.directions) && <>
                          <br />
                          <Text fontWeight="heading">
                            <FormattedMessage id="Public.BookingPage.directions" defaultMessage={`Directions`} />
                          </Text>
                          <RenderSlate value={location.directions} />
                        </>}
                    </Text>
                  </Flex>
                </>}

              <ContactDetails location={location} data-sentry-element="ContactDetails" data-sentry-source-file="view.tsx" />
            </Grid>
          </BorderBox>

          <Box gridArea="sidepanel" data-sentry-element="Box" data-sentry-source-file="view.tsx">
            <BorderBox as={Flex} flexDirection="column" data-sentry-element="BorderBox" data-sentry-source-file="view.tsx">
              <Grid p={3} data-sentry-element="Grid" data-sentry-source-file="view.tsx">
                {booking.status === 'confirmed' && videoCallsEnabled && hasVideoCall && <Button as="a" color="success" target="_blank" rel="nofollow" href={videoCallLink}>
                      <FormattedMessage id="Public.BookingPage.joinVideoCall" defaultMessage={`Join video call`} values={{
                  timeToStart
                }} />
                    </Button>}

                {booking.status === 'confirmed' && <>
                    <DropdownSelect value={null} items={[{
                  label: 'Add to your calendar (iCal)',
                  value: 'ics'
                }, {
                  label: 'Add to Google calendar',
                  value: 'google'
                }, {
                  label: 'Add to Outlook calendar',
                  value: 'outlook'
                }, {
                  label: 'Add to Yahoo calendar',
                  value: 'yahoo'
                }]} itemToString={() => ''} onChange={v => {
                  if (v == 'ics') {
                    window.open(`${settings.api2Root}/ical/b/${booking.id}/booking.ics`);
                  } else if (v == 'google') {
                    window.open(calendarUrls['google']);
                  } else if (v == 'outlook') {
                    window.open(calendarUrls['outlook']);
                  } else if (v == 'yahoo') {
                    window.open(calendarUrls['yahoo']);
                  }
                }} renderToggle={props => <Button {...props} variant="outlined">
                          Add to your calendar
                        </Button>} />
                  </>}

                {business.online && <Button as={ReachLink} variant="outlined" to={`/` + (booking ? `?lid=${booking.location_id}` : '')}>
                    <FormattedMessage id="Public.BookingPage.makeAnotherBooking" defaultMessage="Make another booking" />
                  </Button>}

                <Tippy disabled={canCancel} content={cantCancelReason} data-sentry-element="Tippy" data-sentry-source-file="view.tsx">
                  <Box data-sentry-element="Box" data-sentry-source-file="view.tsx">
                    <Button color="alert" variant="outlined" onClick={() => setShowCancelModal(true)} width="100%" disabled={!canCancel} data-sentry-element="Button" data-sentry-source-file="view.tsx">
                      <FormattedMessage id="Cancel booking" defaultMessage="Cancel booking" data-sentry-element="FormattedMessage" data-sentry-source-file="view.tsx" />
                    </Button>
                  </Box>
                </Tippy>
              </Grid>

              {showLocation && <Box px={3} py={3} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1">
                  <Link rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/maps?&z=16&q=${location.gps[0]}+${location.gps[1]}&ll=${location.gps[0]}+${location.gps[1]}`}>
                    <Box as="img" alt="map" width="100%" src={`https://maps.google.com/maps/api/staticmap?key=${__GOOGLE_MAPS_KEY__}&center=${location.gps[0]},${location.gps[1]}&zoom=14&size=300x300&sensor=false&markers=color:red|${location.gps[0]},${location.gps[1]}`} />
                  </Link>
                </Box>}
            </BorderBox>
          </Box>
        </Grid>
      </Grid>
    </Box>;
}