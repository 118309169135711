import React from 'react';
import { DateTime } from 'luxon';
export const useCantCancelBookingReason = (canCancel, businessDoesNotAllowCancellations, bookingServices, isOwner) => {
  const cantCancelReason = React.useMemo(() => {
    if (canCancel) {
      return;
    }
    if (businessDoesNotAllowCancellations) {
      return 'We do not allow online booking cancellations. Please contact us.';
    }
    if (!bookingServices.every(s => s.start > DateTime.utc().toISO().substring(0, 19) + 'Z')) {
      return 'Past booking cannot be cancelled.';
    }
    if (!isOwner) {
      return 'You cannot cancel this booking. Please follow the link sent to you.';
    }
  }, [bookingServices, businessDoesNotAllowCancellations, canCancel, isOwner]);
  return cantCancelReason;
};