import { FSA } from 'flux-standard-action';
import {
  FORM_FETCH_REQUESTED,
  FORM_FETCH_SUCCEEDED,
  FORM_FETCH_FAILED,
  FORM_SAVE_REQUESTED,
  FORM_SAVE_SUCCEEDED,
  FORM_SAVE_FAILED,
  FORM_DELETE_REQUESTED,
  FORM_DELETE_SUCCEEDED,
  FORM_DELETE_FAILED,
} from './action-types';

type state = {
  status: string;
  data: { [key: string]: any };
};

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case FORM_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case FORM_FETCH_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case FORM_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case FORM_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case FORM_DELETE_SUCCEEDED:
    case FORM_SAVE_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case FORM_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case FORM_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    // case FORM_DELETE_SUCCEEDED: {
    //   const { [action.payload]: _, ...nonDeleted } = state.data;
    //   return {
    //     ...state,
    //     status: 'done',
    //     data: {
    //       ...nonDeleted,
    //     },
    //   };
    // }
    case FORM_DELETE_FAILED:
      return { ...state, status: 'failed' };

    default:
      return state;
  }
}
