import React from 'react';
import { DateTime } from 'luxon';
import Input from 'shared/input';
import { BoxProps } from '../grid';

// Only hours and minutes, no timezone or UTC
const validateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d)?(?:Z|\+00:00)?$/;
interface TimePickerProps
// extends Omit<
//   React.InputHTMLAttributes<HTMLInputElement>,
//   'onChange' | 'prefix'
// > {
extends Omit<BoxProps, 'onChange' | 'prefix'> {
  value: string;
  onChange: (value: string) => any;
  style?: Record<string, any>;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  displayTimezone?: string;
  useLocalTime: boolean;
  defaultDate?: string;
  invalid?: boolean;
}
const timeFmt = 'HH:mm';
const isValidDate = v => (v || '').match(validateRegex);
const isValidTime = v => (v || '').match(/^[0-2]\d:[0-5]\d(:[0-5]\d)?$/);
const toDisplayText = (value, displayTimezone) => {
  try {
    return value && DateTime.fromISO(value, {
      zone: 'UTC'
    }) // If no tz interpret as utc
    .setZone(displayTimezone).toFormat(timeFmt);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

/* eslint-disable-next-line react/display-name */
const TimePicker = ({
  ref,
  value,
  onChange,
  // Discard
  onBlur,
  displayTimezone = 'UTC',
  useLocalTime = false,
  defaultDate,
  ...rest
}: TimePickerProps & {
  ref?: React.Ref<HTMLInputElement>;
}) => {
  if (useLocalTime && value?.endsWith('Z')) {
    console.error("Don't input UTC times when using localtime");
  }
  if (useLocalTime && displayTimezone !== 'UTC') {
    console.error("Don't specify a timezone for localtimes");
  }
  const dateInputType = 'time';
  const [displayText, setDisplayText_] = React.useState('');
  const setDisplayText = React.useCallback(newDisplayText => {
    setDisplayText_(newDisplayText);
    const valueDisplayText = toDisplayText(value, displayTimezone);
    if (newDisplayText === valueDisplayText) {
      return;
    }
    if (!isValidTime(newDisplayText)) {
      return;
    }
    const timeStringNoSeconds = newDisplayText.split(':').splice(0, 2).join(':');
    const dateTime = DateTime.fromFormat(timeStringNoSeconds, timeFmt, {
      zone: displayTimezone || 'UTC'
    });
    let dt: DateTime;
    if (isValidDate(value)) {
      dt = DateTime.fromISO(value, {
        zone: 'UTC'
      }).setZone(displayTimezone);
    } else {
      dt = (defaultDate && DateTime.fromISO(defaultDate, {
        zone: 'UTC'
      }) || DateTime.utc()).setZone(displayTimezone);
    }
    dt = dt.set({
      hour: dateTime.hour,
      minute: dateTime.minute
    });
    const newValue = `${dt.setZone('UTC').set({
      second: 0
    }).toISO().substring(0, 19)}${useLocalTime ? '' : 'Z'}`;
    onChange(newValue);
  }, [defaultDate, displayTimezone, onChange, useLocalTime, value]);

  // Update display text when value changes
  React.useEffect(() => {
    if (!isValidDate(value)) {
      return;
    }
    const displayText = DateTime.fromISO(value, {
      zone: 'UTC'
    }).setZone(displayTimezone).toFormat(timeFmt);
    setDisplayText_(displayText);
  }, [displayTimezone, value]);
  return <Input {...rest as any} required sx={{
    '&::-webkit-clear-button': {
      display: 'none'
    },
    '&::-ms-clear': {
      display: 'none'
    },
    '&::-webkit-calendar-picker-indicator': {
      display: 'none'
    }
  }} step="300" ref={ref} placeholder={'HH:MM'} type={dateInputType} value={displayText} onBlur={e => {
    if (onBlur) {
      onBlur(e);
    }
  }} onChange={v => setDisplayText(v.target.value)} data-sentry-element="Input" data-sentry-component="TimePicker" data-sentry-source-file="index.tsx" />;
};
export default React.memo(TimePicker);