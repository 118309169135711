import { toBase58, toBase16 } from 'shared/utils/b58';
import settings from 'shared/settings';
export function getVideoCallLink(booking) {
  const shortBookingId = toBase58(booking?.id || '');
  const videoCallLink = settings.getShortUrl(`/v/${shortBookingId}`);
  return videoCallLink;
}
export const statusToColorScale = (theme, status) => {
  let color;
  switch (status) {
    case 'confirmed':
      color = 'green';
      break;
    case 'awaiting_payment':
      color = 'yellow';
      break;
    case 'pencilled_in':
      color = 'yellow';
      break;
    case 'requested':
      color = 'yellow';
      break;
    case 'cancelled':
      color = 'red';
      break;
    case 'denied':
      color = 'red';
      break;
    case 'no_show':
      color = 'red';
      break;
  }
  return color;
};
import { DateTime } from 'luxon';
import { getTranslation } from './text';

// export const bookingToString = (
//   booking,
//   serviceData,
//   locationData,
//   businessLanguage
// ) => {
//   return `${booking.services
//     .map((bs) =>
//       bs.service_id
//         ? getTranslation(serviceData[bs.service_id]?.name, businessLanguage)
//         : bs.service_name || 'Removed'
//     )
//     .join(', ')} (${DateTime.fromISO(booking.services[0].start, {
//     zone: locationData[booking.location_id]?.timezone || 'UTC',
//   }).toFormat('DDDD t ZZZZ')})`;
// };

export const bookingToString = (booking, serviceData, locationData, businessLanguage, showStatus = false) => {
  return `${[booking.services[0]].map(bs => bs.service_id ? getTranslation(serviceData[bs.service_id]?.name, businessLanguage) : bs.service_name || 'Removed').join(', ')} ${DateTime.fromISO(booking.services[0].start, {
    zone: locationData[booking.location_id]?.timezone || 'UTC'
  }).toFormat('ff')} ${showStatus ? ` - ${booking.status}` : ''}`;
};
export const getBookingUrl = bookingId_ => {
  const formId = toBase58(bookingId_ || '');
  const url = settings.getShortUrl(`/b/${formId}`);
  return url;
};
export const getVideoCallUrl = bookingId_ => {
  const formId = toBase58(bookingId_ || '');
  const url = settings.getShortUrl(`/v/${formId}`);
  return url;
};