import Color from 'color';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import DrawPalette from '../../draw-palette';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import useComponentSize from '../../hooks/component-size';
import { useLocalState } from '../../hooks/persisted-state';
import ImageInput from '../../image-input';
import Label from '../../label';
import Select from '../../select';
import useDraw, { toSVG } from '../../use-draw';
import { EMPTY_ARRAY } from '../../utils/constants';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from './../shared';
const DiagramDraw = React.memo(function Diagramdraw({
  value,
  onChange,
  imageUrl,
  disabled = false,
  name
}: any) {
  const data = value || EMPTY_ARRAY;
  const setData = onChange;
  const containerRef = React.useRef<HTMLImageElement>(null);
  const {
    width: imageWidth,
    height: imageHeight
  } = useComponentSize(containerRef);
  const [penWidth, setPenWidth] = useLocalState<number>(1, 'penWidth');
  const [penColor, setPenColor] = useLocalState<string>('#000000', 'penColor');
  const [penType, setPenType] = useLocalState<'fountain' | 'ballpoint' | 'highlight'>('ballpoint', 'penType');
  const [redoStack, setRedoStack] = React.useState([]);
  const onStroke = React.useCallback(() => {
    setRedoStack([]);
  }, []);
  const minPenWidth = {
    fountain: 0.5,
    ballpoint: 1,
    highlight: 5
  };
  const maxPenWidth = {
    fountain: 2,
    ballpoint: 1,
    highlight: 5
  };
  const {
    setCanvas,
    fromData
  } = useDraw(containerRef, data, setData, penType === 'highlight' ? Color(penColor).alpha(0.25).string() : penColor, minPenWidth[penType] * penWidth, maxPenWidth[penType] * penWidth, onStroke);
  const redo = React.useCallback(() => {
    if (redoStack.length) {
      const item = redoStack[redoStack.length - 1];
      setRedoStack(redoStack.filter((_, idx) => idx !== redoStack.length - 1));
      const newData = [...data, item];
      setData(newData);
      fromData(newData);
    }
  }, [data, fromData, redoStack, setData]);
  const undo = React.useCallback(() => {
    if (data.length) {
      const newData = data.filter((_, idx) => idx !== data.length - 1);
      setRedoStack([...redoStack, data[data.length - 1]]);
      setData(newData);
      fromData(newData);
    }
  }, [data, fromData, redoStack, setData]);
  return <Box position="relative" width="100%" bg="gray.0" name={name} sx={{
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
    canvas: {
      pointerEvents: disabled ? 'none' : 'all',
      position: 'absolute'
    }
  }}>
      <canvas height={imageHeight} width={imageWidth} ref={setCanvas}></canvas>
      <Box as="img" ref={containerRef} src={imageUrl} alt="Content" width="100%" flexGrow={1} display="block" maxWidth="100%" />
      <Box p={3} sx={{
      position: 'absolute',
      bottom: 0,
      right: 0
    }} backgroundColor="rgba(255, 255, 255, 0.4)" borderTopLeftRadius={2}>
        <DrawPalette penType={penType} setPenType={setPenType} penColor={penColor} setPenColor={setPenColor} setPenWidth={setPenWidth} penWidth={penWidth} undo={undo} undoStack={data} redo={redo} redoStack={redoStack} />
      </Box>
    </Box>;
});
const Diagram = ({
  schema,
  menu,
  disabled,
  style,
  className,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  return <Field
  // defaultValue={EMPTY_ARRAY}
  name={name} validate={value => {
    if (schema.required && (!value || value.length === 0)) {
      return 'Diagram required';
    }
  }} data-sentry-element="Field" data-sentry-component="Diagram" data-sentry-source-file="index.tsx">
      {({
      input,
      meta
    }) => <Box style={style} className={className}>
          <Flex mb={2} justifyContent="space-between">
            <Label sx={{
          flexGrow: 1
        }}>
              {schema.label}
            </Label>
            {menu}
          </Flex>

          <DiagramDraw disabled={disabled} imageUrl={schema.image_url} {...input} />

          {meta.error && meta.touched && <FormError>{meta.error}</FormError>}

          {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
        </Box>}
    </Field>;
};
export default Diagram;
export const toString = value => {
  return value || '';
};
export const View = ({
  value,
  schema
}) => {
  const ref = React.useRef(null);
  const {
    width,
    height
  } = useComponentSize(ref);
  const svgDataUri = React.useMemo(() => {
    const strokes = value || [];
    return toSVG(strokes, width, height).replace('\n', ' ');
  }, [value, width, height]);
  return <Box position="relative" data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">
      <Box position="absolute" as="img" width="100%" height="100%" alt="drawing" src={`${svgDataUri}`} data-sentry-element="Box" data-sentry-source-file="index.tsx" />
      {schema.image_url && <Box as="img" width="100%" ref={ref} alt="image" src={schema.image_url} />}
    </Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style,
  disabled
}) => {
  // const form = useForm();
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Form onSubmit={value => null}>
          {({
        form
      }) => <Diagram schema={value} disabled={disabled} menu={menu} style={style} className={className} />}
        </Form> : <Grid display={!isEditing ? 'none' : undefined}>
          <LabelField prefix={fieldPrefix} />

          <Field name="image_url" validate={value => {
        if (!value) {
          return 'Image required';
        }
      }}>
            {({
          input,
          meta
        }) => <Box>
                <Label mb={2}>Image</Label>
                <ImageInput {...input} />
                {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
              </Box>}
          </Field>

          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              <Field name="print_width">
                {({
            input
          }) => <Box>
                    <Select {...input} label="Print width">
                      <option value="">100%</option>
                      <option value="75%">75%</option>
                      <option value="50%">50%</option>
                    </Select>
                  </Box>}
              </Field>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}
          <Button onClick={toggleMore} size="small" width="100%" variant="outlined">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};