import merge from 'deepmerge';
import { FSA } from 'flux-standard-action';
import { LOGOUT } from '../session/action-types';
import {
  USER_PREFERENCES_FETCH_SUCCEEDED,
  USER_PREFERENCES_UPDATE,
} from './action-types';

const browserLang =
  (typeof navigator === 'object' && navigator['languages']?.[0]) ||
  (typeof navigator === 'object' && navigator['language']) ||
  (typeof navigator === 'object' && navigator['userLanguage']) ||
  'en';

export interface LocationState {
  bookableIds: string[];
  displayInterval: number;
}

export interface UserPreferencesState {
  bookingView: 'modal' | 'sidepanel';
  language: string;
  caldendarMode: 'month' | 'week' | 'daysheet' | 'agenda';
  currentBusinessId: string;
  currentLocationId: string;
  locationState: Record<string, LocationState>;
  lowTokenBusinessIds: string[];
  zeroTokensBusinessIds: string[];
  lastNoteSortKey: 'updated_at' | 'created_at';
  lastNoteSortOrder: 'asc' | 'desc';
}

const initialState = {
  bookingView: 'modal',
  language: browserLang.substring(0, 2), // TODO: deal with locale
  caldendarMode: 'week',
  currentBusinessId: null,
  currentLocationId: null,
  locationState: {},
  lowTokenBusinessIds: [],
  zeroTokensBusinessIds: [],
} as UserPreferencesState;

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

// // @ts-ignore
// window.merge = merge;

export default function reducer(
  state = initialState,
  action: FSA<any, any>
): UserPreferencesState {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        currentBusinessId: null,
      };
    case USER_PREFERENCES_UPDATE:
      return merge(state, action.payload, { arrayMerge: overwriteMerge });
    case USER_PREFERENCES_FETCH_SUCCEEDED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
