import { FSA } from 'flux-standard-action';
import {
  PASSWORD_CHANGE_COMPLETED,
  USER_DELETE_FAILED,
  USER_DELETE_REQUESTED,
  USER_DELETE_SUCCEEDED,
  USER_FETCH_FAILED,
  USER_FETCH_REQUESTED,
  USER_FETCH_SUCCEEDED,
  USER_SAVE_FAILED,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCEEDED,
} from './action-types';

type state = {
  status: string;
  data: { [key: string]: any };
};

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case USER_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case USER_FETCH_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case USER_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case USER_SAVE_REQUEST:
      return { ...state, status: 'saving' };
    case USER_SAVE_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case USER_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case USER_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case USER_DELETE_SUCCEEDED: {
      const { [action.payload]: _, ...nonDeleted } = state.data;
      return {
        ...state,
        status: 'done',
        data: {
          ...nonDeleted,
        },
      };
    }
    case USER_DELETE_FAILED:
      return { ...state, status: 'failed' };
    case PASSWORD_CHANGE_COMPLETED: {
      const userId = action.payload;
      const user = state.data[userId];
      return {
        ...state,
        data: {
          ...state.data,
          [user.id]: {
            ...user,
            need_password: false,
          },
        },
      };
    }
    default:
      return state;
  }
}
