import { FormattedMessage } from 'react-intl';
import Logo from 'shared/images/logo-black.svg';
// import settings from 'shared/settings';
import { Grid, Box, BoxProps } from './grid';
interface Props {
  medium?: string;
  source?: string;
}
const PoweredBy = ({
  medium = 'public-booking-page',
  // Embed / public-booking-page
  source = 'public-booking-page',
  // Handle goes here
  ...props
}: BoxProps & Props) => <Grid gridGap={2} flex="0 1 auto" textAlign="center" fontSize={1} mb={2} {...props} data-sentry-element="Grid" data-sentry-component="PoweredBy" data-sentry-source-file="powered-by.tsx">
    <FormattedMessage id="Powered by" defaultMessage="Powered by" data-sentry-element="FormattedMessage" data-sentry-source-file="powered-by.tsx" />
    <Box lineHeight={1} display="block" color="gray.8" as="a" href={`https://bookem.com?utm_source=${source}&utm_medium=${medium}&utm_campaign=powered-by-link`} target="blank" data-sentry-element="Box" data-sentry-source-file="powered-by.tsx">
      <Logo alt="Bookem" width={91} data-sentry-element="Logo" data-sentry-source-file="powered-by.tsx" />
    </Box>
  </Grid>;
export default PoweredBy;