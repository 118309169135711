import React from 'react';
import { Box } from 'shared/grid';

// https://codepen.io/jensimmons/pen/KKPzxJa?editors=1100

const size = 16;
const RadioButton = props => {
  return <Box as="input" type="radio" sx={{
    WebkitAppearance: 'none',
    width: `${size}px`,
    height: `${size}px`,
    cursor: 'pointer',
    borderRadius: '50%',
    padding: 0,
    outline: 'none',
    display: 'inline-block',
    verticalAlign: 'text-top',
    backgroundColor: 'gray.2',
    '&:not(:hover):not(:focus):not(:checked)': {
      boxShadow: 'inset 0 0 0 ${size * 0.15}px rgba(0, 0, 0, 0.12)'
    },
    '&:hover': {
      backgroundColor: 'primary'
    },
    '&:focus, &:hover': {
      boxShadow: `0 0 0 ${size * 0.15}px rgba(0, 0, 0, 0.12)`
    },
    '&:checked': {
      position: 'relative',
      backgroundColor: 'primary'
    },
    '&:checked::after': {
      position: 'absolute',
      left: `${Math.round(size * 0.25)}px`,
      top: `${Math.round(size * 0.25)}px`,
      content: '" "',
      width: `${Math.round(size * 0.5)}px`,
      height: `${Math.round(size * 0.5)}px`,
      backgroundColor: 'white',
      borderRadius: '50%'
    }
  }} {...props} data-sentry-element="Box" data-sentry-component="RadioButton" data-sentry-source-file="index.tsx" />;
};
export default RadioButton;