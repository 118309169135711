import { FSA } from 'flux-standard-action';
import {
  BOOKABLE_FETCH_REQUESTED,
  BOOKABLE_FETCH_SUCCEEDED,
  BOOKABLE_FETCH_FAILED,
  BOOKABLE_SAVE_REQUESTED,
  BOOKABLE_SAVE_SUCCEEDED,
  BOOKABLE_SAVE_FAILED,
  BOOKABLE_DELETE_REQUESTED,
  BOOKABLE_DELETE_SUCCEEDED,
  BOOKABLE_DELETE_FAILED,
} from './action-types';

import { STAFF_SAVE_SUCCEEDED } from '../staff/action-types';

type state = { status: string; data: { [key: string]: any } };

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case STAFF_SAVE_SUCCEEDED: {
      const staffMember = action.payload;
      const bookable = Object.values<any>(state.data).find(
        (b) => b.staff_member_id === staffMember.id
      );

      if (bookable) {
        return {
          ...state,
          data: {
            ...state.data,
            [bookable.id]: {
              ...bookable,
              firstname: staffMember.firstname,
              lastname: staffMember.lastname,
            },
          },
        };
      }
      return state;
    }
    case BOOKABLE_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case BOOKABLE_FETCH_SUCCEEDED: {
      let data = action.payload;

      if (__USE_FAKE_DATA__) {
        const {
          getRandomName,
          getRandomLastName,
          getRandomFirstName,
        } = require('shared/random-name');
        data = Object.values<any>(action.payload).reduce((agg, cur) => {
          agg[cur.id] = {
            ...cur,
            name: getRandomFirstName() + ' ' + getRandomLastName(),
          };
          return agg;
        }, {});
      }

      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case BOOKABLE_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case BOOKABLE_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case BOOKABLE_SAVE_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case BOOKABLE_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case BOOKABLE_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case BOOKABLE_DELETE_SUCCEEDED: {
      const { [action.payload]: _, ...nonDeleted } = state.data;
      return {
        ...state,
        status: 'done',
        data: {
          ...nonDeleted,
        },
      };
    }
    case BOOKABLE_DELETE_FAILED:
      return { ...state, status: 'failed' };

    default:
      return state;
  }
}
