import { FSA } from 'flux-standard-action';
import {
  BILLABLE_ITEM_FETCH_REQUESTED,
  BILLABLE_ITEM_FETCH_SUCCEEDED,
  BILLABLE_ITEM_FETCH_FAILED,
  BILLABLE_ITEM_SAVE_REQUESTED,
  BILLABLE_ITEM_SAVE_SUCCEEDED,
  BILLABLE_ITEM_SAVE_FAILED,
  BILLABLE_ITEM_DELETE_REQUESTED,
  BILLABLE_ITEM_DELETE_SUCCEEDED,
  BILLABLE_ITEM_DELETE_FAILED,
  BILLABLE_ITEMS_DELETE_REQUESTED,
  BILLABLE_ITEMS_DELETE_SUCCEEDED,
  BILLABLE_ITEMS_DELETE_FAILED,
} from './action-types';

interface State {
  status: string;
  data: { [key: string]: any };
}

const initialState: State = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case BILLABLE_ITEM_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case BILLABLE_ITEM_FETCH_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case BILLABLE_ITEM_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case BILLABLE_ITEM_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case BILLABLE_ITEM_SAVE_SUCCEEDED: {
      const location = action.payload;
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          [location.id]: location,
        },
      };
    }
    case BILLABLE_ITEM_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case BILLABLE_ITEM_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case BILLABLE_ITEM_DELETE_SUCCEEDED: {
      const { [action.payload]: _, ...nonDeleted } = state.data;
      return {
        ...state,
        status: 'done',
        data: {
          ...nonDeleted,
        },
      };
    }
    case BILLABLE_ITEM_DELETE_FAILED:
      return { ...state, status: 'failed' };
    case BILLABLE_ITEMS_DELETE_SUCCEEDED: {
      const nonDeleted = Object.fromEntries(
        Object.entries(state.data).filter(
          ([k, v]) => !action.payload.includes(v.id)
        )
      );

      return {
        ...state,
        status: 'done',
        data: nonDeleted,
      };
    }
    default:
      return state;
  }
}
