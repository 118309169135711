import { FSA } from 'flux-standard-action';
import { BUSINESS_FETCH_SUCCEEDED } from '../businesses/action-types';
import { PUBLIC_UPDATE } from './action-types';

type State = {
  businessId: string;
  locationId: string;
};

const initialState: State = {
  businessId: '',
  locationId: '',
};

export default function (state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case BUSINESS_FETCH_SUCCEEDED: {
      const businessId = Object.values<any>(action.payload)[0].id;
      return { ...state, businessId };
    }
    case PUBLIC_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
