import Color from 'color';
import React from 'react';
import { Box, BoxProps } from 'shared/grid';
import FormError from '../form-error';
import { invalidInput } from '../input-style';
import Label from '../label';
import { Theme, ThemeContext } from '@emotion/react';
interface ExtraStyledSelectProps {
  invalid?: boolean;
}
type StyledSelectProps = Omit<React.HTMLProps<HTMLSelectElement>, keyof BoxProps> & ExtraStyledSelectProps & BoxProps;
export const selectArrowStyle = theme => ({
  backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='16' viewBox='0 0 32 16'><polygon points='0,0 32,0 16,16' style='fill: ${Color(theme.colors.text).rgb().string().replace('%', '%29')}'></polygon></svg>")`,
  backgroundOrigin: 'content-box',
  backgroundPosition: `right -${16}px center`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '9px 6px'
});
export function StyledSelect({
  ref,
  invalid,
  as = 'select',
  ...props
}: StyledSelectProps & {
  ref?: React.Ref<HTMLSelectElement>;
}) {
  const theme = React.useContext<Theme>(ThemeContext as any);
  return <Box ref={ref} tx="inputs" variant="default" as={as} __css={{
    ...(invalid ? invalidInput : {}),
    '&::-ms-expand': {
      display: 'none'
    },
    '&[multiple]': {
      height: 'auto',
      backgroundImage: 'none'
    },
    paddingRight: `${32}px !important`,
    fontSize: 2,
    ...selectArrowStyle(theme)
  }} {...props} data-sentry-element="Box" data-sentry-component="StyledSelect" data-sentry-source-file="index.tsx" />;
}
type SelectProps = {
  label?: any;
  error?: string;
} & React.InputHTMLAttributes<HTMLSelectElement> & React.LabelHTMLAttributes<HTMLLabelElement> & Omit<StyledSelectProps, 'label'>;
export function Select({
  ref,
  ...props
}: SelectProps & {
  ref?: React.Ref<HTMLSelectElement>;
}) {
  const [isFocused, setFocus] = React.useState(false);
  const {
    label,
    onFocus,
    onBlur,
    error,
    invalid
  } = props;
  return <>
      {props.label && <Label mb={2} invalid={invalid || !!error} isFocused={isFocused}>
          {label}
        </Label>}
      <StyledSelect ref={ref} {...props} invalid={invalid || !!error} onFocus={e => {
      setFocus(true);
      if (onFocus) onFocus(e);
    }} onBlur={e => {
      setFocus(false);
      if (onBlur) onBlur(e);
    }} data-sentry-element="StyledSelect" data-sentry-source-file="index.tsx" />
      {error && <FormError>{error}</FormError>}
    </>;
}
export default Select;