export const INVOICE_SETTINGS_PREFERENCES_UPDATE =
  'INVOICE_SETTINGS_PREFERENCES_UPDATE';

export const INVOICE_SETTINGS_FETCH_REQUESTED =
  'INVOICE_SETTINGS_FETCH_REQUESTED';
export const INVOICE_SETTINGS_FETCH_SUCCEEDED =
  'INVOICE_SETTINGS_FETCH_SUCCEEDED';
export const INVOICE_SETTINGS_FETCH_FAILED = 'INVOICE_SETTINGS_FETCH_FAILED';

export const INVOICE_SETTINGS_PATCH_REQUESTED =
  'INVOICE_SETTINGS_PATCH_REQUESTED';
export const INVOICE_SETTINGS_PATCH_SUCCEEDED =
  'INVOICE_SETTINGS_PATCH_SUCCEEDED';
export const INVOICE_SETTINGS_PATCH_FAILED = 'INVOICE_SETTINGS_PATCH_FAILED';

export const INVOICE_SETTINGS_SAVE_REQUESTED =
  'INVOICE_SETTINGS_SAVE_REQUESTED';
export const INVOICE_SETTINGS_SAVE_SUCCEEDED =
  'INVOICE_SETTINGS_SAVE_SUCCEEDED';
export const INVOICE_SETTINGS_SAVE_FAILED = 'INVOICE_SETTINGS_SAVE_FAILED';
