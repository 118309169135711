import { FSA } from 'flux-standard-action';
import {
  BUSINESS_DELETE_FAILED,
  BUSINESS_DELETE_REQUESTED,
  BUSINESS_DELETE_SUCCEEDED,
  BUSINESS_FETCH_FAILED,
  BUSINESS_FETCH_REQUESTED,
  BUSINESS_FETCH_SUCCEEDED,
  BUSINESS_PATCH_FAILED,
  BUSINESS_PATCH_REQUESTED,
  BUSINESS_PATCH_SUCCEEDED,
  BUSINESS_SAVE_FAILED,
  BUSINESS_SAVE_REQUESTED,
  BUSINESS_SAVE_SUCCEEDED,
  BUSINESS_TOKEN_BALANCE_UPDATED,
} from './action-types';

type state = { status: string; data: { [key: string]: any } };

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case BUSINESS_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case BUSINESS_FETCH_SUCCEEDED: {
      let data = action.payload;

      if (__USE_FAKE_DATA__) {
        data = Object.values<any>(action.payload).reduce((agg, cur) => {
          agg[cur.id] = {
            ...cur,
            name: 'ACME Inc',
          };
          return agg;
        }, {});
      }

      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case BUSINESS_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case BUSINESS_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case BUSINESS_SAVE_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case BUSINESS_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case BUSINESS_PATCH_REQUESTED:
      return { ...state, status: 'saving' };
    case BUSINESS_PATCH_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case BUSINESS_PATCH_FAILED:
      return { ...state, status: 'failed' };

    case BUSINESS_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case BUSINESS_DELETE_SUCCEEDED: {
      const { [action.payload]: _, ...nonDeleted } = state.data;
      return {
        ...state,
        status: 'done',
        data: {
          ...nonDeleted,
        },
      };
    }
    case BUSINESS_DELETE_FAILED:
      return { ...state, status: 'failed' };
    case BUSINESS_TOKEN_BALANCE_UPDATED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          [action.payload.business_id]: {
            ...state.data[action.payload.business_id],
            token_balance: action.payload.token_balance,
          },
        },
      };

    default:
      return state;
  }
}
