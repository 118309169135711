import axios from '../../anaxios';
import settings from '../../settings';

import {
  DATA_LIST_FETCH_REQUESTED,
  DATA_LIST_FETCH_SUCCEEDED,
  DATA_LIST_FETCH_FAILED,
  DATA_LIST_SAVE_REQUESTED,
  DATA_LIST_SAVE_SUCCEEDED,
  DATA_LIST_SAVE_FAILED,
  DATA_LIST_DELETE_REQUESTED,
  DATA_LIST_DELETE_SUCCEEDED,
  DATA_LIST_DELETE_FAILED,
} from './action-types';

export const fetchDataLists = ({ businessId }) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: DATA_LIST_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/data-lists`, {
        params: {
          business_id: businessId,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const dataLists = data.reduce((acc, dataList) => {
          acc[dataList.id] = dataList;
          return acc;
        }, {});

        dispatch({ type: DATA_LIST_FETCH_SUCCEEDED, payload: dataLists });
      })
      .catch((error) => {
        dispatch({ type: DATA_LIST_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const fetchDataList = (dataListId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: DATA_LIST_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/data-lists/${dataListId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const dataLists = {
          [data.id]: data,
        };

        dispatch({ type: DATA_LIST_FETCH_SUCCEEDED, payload: dataLists });
        return data;
      })
      .catch((error) => {
        dispatch({ type: DATA_LIST_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const saveDataList = (dataList) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: DATA_LIST_SAVE_REQUESTED });

    return axios
      .put(`${settings.api2Root}/data-lists/${dataList.id}`, dataList, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const dataLists = { [data.id]: data };

        dispatch({ type: DATA_LIST_SAVE_SUCCEEDED, payload: dataLists });
      })
      .catch((error) => {
        dispatch({ type: DATA_LIST_SAVE_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const patchDataList = (id, changes) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: DATA_LIST_SAVE_REQUESTED });

    return axios
      .patch(`${settings.api2Root}/data-lists/${id}`, changes, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        const store = getState();
        const dataList = store.dataLists.data[id];
        const dataLists = { [id]: { ...dataList, ...changes } };

        dispatch({ type: DATA_LIST_SAVE_SUCCEEDED, payload: dataLists });
      })
      .catch((error) => {
        dispatch({ type: DATA_LIST_SAVE_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const createDataList = (dataList) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: DATA_LIST_SAVE_REQUESTED });

    return axios
      .post(`${settings.api2Root}/data-lists`, dataList, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const dataLists = { [data.id]: data };

        dispatch({ type: DATA_LIST_SAVE_SUCCEEDED, payload: dataLists });
        return data;
      })
      .catch((error) => {
        dispatch({ type: DATA_LIST_FETCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const deleteDataList = (dataListId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: DATA_LIST_DELETE_REQUESTED });

    return axios
      .delete(`${settings.api2Root}/data-lists/${dataListId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        dispatch({ type: DATA_LIST_DELETE_SUCCEEDED, payload: dataListId });
      })
      .catch((error) => {
        dispatch({ type: DATA_LIST_DELETE_FAILED });
        return Promise.reject(error);
      });
  };
};
