import { faChevronDown, faChevronUp, faClock, faCube, faMapMarker, faStopwatch, faTags, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';
import IntervalTree from 'rb-interval-tree';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import seedrandom from 'seedrandom';
import { Avatar } from 'shared/avatar';
import Button from 'shared/button';
import CalendarPicker from 'shared/calendar-picker';
import DropdownSelect from 'shared/dropdown-select';
import EmptyState from 'shared/empty-state';
import { Box, Column, Flex, Grid, Row } from 'shared/grid';
import { useCurrentDateTime } from 'shared/hooks';
import IconWrapper from 'shared/icon-wrapper';
import Loader from 'shared/loader';
import Modal, { CloseButton } from 'shared/modal';
import { fetchService } from 'shared/redux/services/actions';
import timezones from 'shared/timezones';
import Tippy from 'shared/tooltip';
import { CroppedText, Heading, Link, Text } from 'shared/typography';
import { buildBookableDropdowns, groupSubstitute, ScheduleItem } from 'shared/utils/booking-tools';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'shared/utils/constants';
import { currencyFormat } from 'shared/utils/currency';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { buildBookableOptionsList, rulesToScheduleItems } from 'shared/utils/schedule';
import * as shuffleSeed from 'shared/utils/shuffle-seed';
import { getTranslation } from 'shared/utils/text';
import { BookableAvailability, ExternalBusy, getBookableAvailability, Schedule } from 'shared/utils/time';
import { isUUID } from 'shared/utils/uuid';
import { useAppDispatch, useAppSelector } from '../../store';
import { BookStepHeading } from '../shared';
import { useBookableIds, useDuration, useLocationId } from '../../hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import LocationSelect from '../location-selector';
import { toBase16 } from 'shared/utils/b58';
const messages = defineMessages({
  'No preference': {
    id: 'No preference',
    defaultMessage: 'No preference',
    description: ''
  },
  timeConjunction: {
    id: 'timeConjunction',
    defaultMessage: 'and',
    description: ''
  }
});
function getAssociatedBookableIds(locationId: string, serviceId: string = null, serviceData: Record<string, any>, bookableData: Record<string, any>, bookableGroupData: Record<string, any>) {
  if (locationId && serviceId && serviceData[serviceId]) {
    const serviceLocation = serviceData[serviceId].locations.find(sl => sl.location_id === locationId);
    const requirements = groupSubstitute(serviceLocation.requirements, locationId, bookableData, bookableGroupData);
    const ids = buildBookableOptionsList(requirements, bookableData).reduce((agg, cur) => {
      return agg.concat(cur);
    }, []);
    return [...new Set(ids)];
  } else {
    return Object.values<any>(bookableData).filter(b => b.location_ids.includes(locationId)).filter(b => b.is_enabled).map(b => b.id);
  }
}
function slotStatus(bookableAvailability, optionsList, requiredBookableIds = []) {
  // TODO: make sure requirements are satisfyable here!!!!!!!!

  // Sometimes bookables from other branches will be in the requirements
  // but not in the bookableAvailability list
  // let ba = bookableAvailability;

  if (!optionsList || optionsList.length === 0) {
    throw new Error('optionsList not specified');
  }
  const BUSY = 0;
  const PRIVATE = 1;
  const AVAILABLE = 2;
  const usedBookableIds = new Set();
  const bookableIds = [...new Set([].concat.apply([], optionsList))]; // eslint-disable-line prefer-spread

  const bookableAv = bookableIds.reduce((obj, bid: string) => {
    const av = bookableAvailability[bid] || {};
    if (av.busy) {
      obj[bid] = BUSY;
    } else if (av.available) {
      obj[bid] = AVAILABLE;
    } else {
      obj[bid] = PRIVATE;
    }
    return obj;
  }, {});
  const optionStatuses = optionsList.map((bookableIds, i) => {
    const requiredBookableId = requiredBookableIds[i];
    const options = bookableIds.filter(bid => !usedBookableIds.has(bid));
    let maxStatus = 0;
    let usedId = 0;
    if (requiredBookableId !== '*') {
      maxStatus = bookableAv[requiredBookableId] || 0;
      usedId = requiredBookableId;
    } else {
      const statusList = options.map(bid => bookableAv[bid]);
      maxStatus = Math.max(...statusList);
      usedId = options[statusList.indexOf(maxStatus)];
    }
    usedBookableIds.add(usedId);
    return maxStatus;
  });
  const minStatus = Math.min(...optionStatuses);
  return ['busy', 'private', 'available'][minStatus];
}
interface DayPage {
  date: DateTime;
  slots: any[];
}
function getDayPages(startUTC: DateTime, endUTC: DateTime, timezone: string): DayPage[] {
  const start = startUTC.setZone(timezone).startOf('day');
  const end = endUTC.setZone(timezone).startOf('day');
  const calendarDays: DayPage[] = [];
  for (let day = start; day < end; day = day.plus({
    days: 1
  })) {
    calendarDays.push({
      date: day,
      slots: []
    });
  }
  return calendarDays;
}
interface PageSlot extends DayPage {
  slots: (SlotType & {
    locationId: string;
    bookableIds: string[];
  })[];
}
function slotsToPages(bookableSlots: SlotType[], startUTC: DateTime, endUTC: DateTime, timezone: string, locationId: string, bookableIds: string[]): PageSlot[] {
  const pages: PageSlot[] = getDayPages(startUTC, endUTC, timezone);
  for (const slot of bookableSlots) {
    if (slot.status !== 'available') {
      continue;
    }
    const event = {
      ...slot,
      locationId,
      bookableIds
    };

    // This this to avoid timezone weirdness
    for (const page of pages) {
      if (page.date.hasSame(DateTime.fromISO(event.start).setZone(timezone), 'day')) {
        page.slots.push(event);
        break;
      }
    }
  }
  return pages;
}
interface SlotType {
  status: string;
  start: string;
  end: string;
  availability: BookableAvailability;
  reservable: boolean;
}
const getSlotsPublic = ({
  itree = null,
  startUTC = null,
  endUTC = null,
  duration = null,
  bookingWindow = null,
  requiredResources = [],
  bookableOptions = null,
  serviceId,
  bufferBefore = 0,
  bufferAfter = 0,
  slotInterval = 0,
  startTimeScheduling = false,
  locationTimezone
}: {
  itree?: any;
  startUTC?: DateTime;
  endUTC?: DateTime;
  duration?: number;
  bookingWindow?: DateTime[];
  requiredResources?: string[];
  bookableOptions?: any;
  serviceId: string;
  bufferBefore: number;
  bufferAfter: number;
  slotInterval: number;
  startTimeScheduling: boolean;
  locationTimezone: string;
}): SlotType[] => {
  if (!bookableOptions) {
    throw new Error('bookableOptions needs to be specified');
  }
  if (requiredResources.length !== bookableOptions.length) {
    throw new Error('requiredResources needs to be the same length as bookableOptions');
  }
  const slots: SlotType[] = [];
  const searchStart = startUTC;
  const searchEnd = endUTC;
  const searchInterval = 5;
  const postSlotSkipAhead = slotInterval ? slotInterval : duration + bufferAfter;
  let candidateStart;
  let candidateEnd;
  for (candidateStart = searchStart, candidateEnd = candidateStart.plus({
    minutes: duration
  }); startTimeScheduling ? candidateStart < searchEnd : candidateEnd < searchEnd; candidateEnd = candidateStart.plus({
    minutes: duration
  })) {
    // const lastSlotEnd = slots[slots.length - 1]?.end;
    // const notFirstSlotOfDay =
    //   lastSlotEnd &&
    //   DateTime.fromISO(lastSlotEnd)
    //     .setZone(locationTimezone)
    //     .hasSame(
    //       DateTime.fromISO(lastSlotEnd).setZone(locationTimezone),
    //       'day'
    //     );

    // const postSlotSkipAhead =
    //   (slotInterval || duration) + (notFirstSlotOfDay ? bufferAfter : 0);
    const inWindow = bookingWindow[0] < candidateStart && candidateEnd < bookingWindow[1];
    if (!inWindow) {
      candidateStart = candidateStart.plus({
        minutes: searchInterval
      });
      continue;
    }
    const matches = itree.search(candidateStart.toISO().substring(0, 19) + 'Z', candidateEnd.toISO().substring(0, 19) + 'Z');
    const availability = getBookableAvailability(candidateStart.toISO().substring(0, 19) + 'Z', candidateEnd.toISO().substring(0, 19) + 'Z', matches, bookingWindow.map(v => v.toISO().substring(0, 19) + 'Z'), serviceId);
    const status = slotStatus(availability, bookableOptions, requiredResources);
    if (status !== 'available') {
      if (startTimeScheduling && status !== 'busy') {
        // Check if the slot starts in available time
        const availability = getBookableAvailability(candidateStart.toISO().substring(0, 19) + 'Z', candidateStart.plus({
          minutes: slotInterval
        }).toISO().substring(0, 19) + 'Z', matches, bookingWindow.map(v => v.toISO().substring(0, 19) + 'Z'), serviceId);
        const status = slotStatus(availability, bookableOptions, requiredResources);
        if (status !== 'available') {
          candidateStart = candidateStart.plus({
            minutes: searchInterval
          });
          continue;
        }
      } else {
        candidateStart = candidateStart.plus({
          minutes: searchInterval
        });
        continue;
      }
    }

    // Check that buffer times are not busy
    if (bufferBefore || bufferAfter) {
      const bStart = candidateStart.minus({
        minutes: bufferBefore
      });
      const bEnd = candidateStart.plus({
        minutes: bufferAfter + duration
      });
      const matches = itree.search(bStart.toISO().substring(0, 19) + 'Z', bEnd.toISO().substring(0, 19) + 'Z');
      let isBusy = false;
      for (const [start, end] of [[bStart, candidateStart], [candidateEnd, bEnd]]) {
        const availability = getBookableAvailability(start.toISO().substring(0, 19) + 'Z', end.toISO().substring(0, 19) + 'Z', matches, bookingWindow.map(v => v.toISO().substring(0, 19) + 'Z'), serviceId);
        const status = slotStatus(availability, bookableOptions, requiredResources);
        if (status === 'busy') {
          // const localTime = candidateStart.setZone(locationTimezone);
          // if (
          //   localTime.day === 18 &&
          //   localTime.hour > 13
          //   // &&
          //   // localTime.minute === 0
          // ) {
          //   debugger;
          // }
          isBusy = true;
          break;
        }
      }
      if (isBusy) {
        candidateStart = candidateStart.plus({
          minutes: searchInterval
        });
        continue;
      }
    }
    slots.push({
      status: status === 'private' && startTimeScheduling ? 'available' : status,
      start: candidateStart.toISO().substring(0, 19) + 'Z',
      end: candidateEnd.toISO().substring(0, 19) + 'Z',
      availability,
      reservable: false
    });
    candidateStart = candidateStart.plus({
      minutes: postSlotSkipAhead
    });
  }
  return slots;
};
function getValidSelection(locationId, serviceId, bookableIds = EMPTY_ARRAY, wildcardBookableOption = false, serviceData, bookableData, bookableGroupData) {
  // TODO: ensure no duplicate bookable selections

  const serviceLocation = serviceData[serviceId].locations.find(sl => sl.location_id === locationId);
  const requirements = groupSubstitute(serviceLocation.requirements, locationId, bookableData, bookableGroupData);
  let bookableOptions = buildBookableOptionsList(requirements, bookableData);
  const bookableOptionLists = buildBookableDropdowns(requirements, wildcardBookableOption, bookableData);
  if (wildcardBookableOption) {
    bookableOptions = bookableOptions.map((list, idx) => {
      if (list.length > 1) {
        if (bookableOptionLists[idx].hide_for_clients) {
          return ['*', ...list];
        } else {
          return [null, '*', ...list];
        }
      } else {
        return list;
      }
    });
  }
  const selectedBookableIds = bookableOptions.map((list, idx) => bookableIds[idx] || list[0]).map((id, idx) => bookableOptions[idx].includes(id) ? id : bookableOptions[idx][0]);
  if (selectedBookableIds.some(id => id === undefined)) {
    return EMPTY_ARRAY;
  }
  return selectedBookableIds;
}
const ServiceView = () => {
  const rrLocation = useLocation();
  const [locationId, setLocationId] = useLocationId();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const dt = useCurrentDateTime(60 * 1000);
  const [bookableIds, setBookableIds] = useBookableIds();
  const [duration, setDuration] = useDuration();
  const {
    serviceId: serviceId_
  } = useParams();
  const serviceId = React.useMemo(() => {
    let serviceId = serviceId_;
    if (!isUUID(serviceId)) {
      if (serviceId.length > 1 && serviceId.length < 32) {
        serviceId = toBase16(serviceId);
      } else if (serviceId.length !== 32) {
        // Not uuid
        serviceId = null;
      }
    }
    return serviceId;
  }, [serviceId_]);
  const navigate = useNavigate();
  const [localDate, setLocalDate] = React.useState('');
  const businessId = useAppSelector(state => state.public.businessId);
  const service = useAppSelector(state => state.services.data[serviceId] || EMPTY_OBJECT);
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  const locationData = useAppSelector(state => state.locations.data);
  const serviceData = useAppSelector(state => state.services.data);
  const locations = React.useMemo(() => Object.values<any>(locationData).filter(l => !l.is_deleted).filter(l => l.business_id === businessId).filter(l => serviceData?.[serviceId]?.locations?.find(sl => sl.location_id === l?.id)).sort((a, b) => a.name < b.name ? -1 : 1), [locationData, businessId, serviceData, serviceId]);
  const isLoading = useAppSelector(state => [locationId, service.id, state.businesses.status, state.locations.status, state.services.status, state.bookables.status, state.bookableGroups.status, state.bookableBusy.status, state.schedules.status].map(v => v || 'loading').includes('loading'));
  const location = useAppSelector(state => state.locations.data[locationId]);
  const bookableData = useAppSelector(state => state.bookables.data);
  const bookableGroupData = useAppSelector(state => state.bookableGroups.data);
  const bookableBusyData = useAppSelector(state => state.bookableBusy.data);
  const scheduleData = useAppSelector(state => state.schedules.data);
  const language = useAppSelector(state => state.userPreferences.language);
  const geoipDetectedTimezone = useAppSelector(state => {
    try {
      DateTime.utc().setZone(state.geoip.time_zone);
      return state.geoip.time_zone;
    } catch {
      return null;
    }
  });
  const serviceLocation = service?.locations?.find(sl => sl.location_id === locationId);
  const serviceDurations = serviceLocation?.service_durations || EMPTY_ARRAY;
  const durations = React.useMemo(() => serviceDurations.map(d => d.duration) || [], [serviceDurations]);
  const bufferBefore = serviceLocation?.buffer_time_before || 0;
  const bufferAfter = serviceLocation?.buffer_time_after || 0;
  const slotInterval = serviceLocation?.slot_interval || 0;
  const startTimeScheduling = serviceLocation?.start_time_scheduling;
  const billableItemData = useAppSelector(state => state.billableItems.data);
  const invoiceSettings = useAppSelector(state => state.invoiceSettings.data[state.public.businessId]);
  const serviceDurationPricing = React.useMemo(() => {
    return serviceDurations.reduce((agg, sd) => {
      if (sd.pricing_model === 'hidden') {
        agg[sd.duration] = null;
        return agg;
      }
      const taxRate = invoiceSettings ? invoiceSettings.location_settings[invoiceSettings.is_location_specific ? locationId : '*'].default_tax_rate : 0;
      if (sd.pricing_model === 'simple') {
        agg[sd.duration] = sd.price * (1 + taxRate / 100);
        return agg;
      }
      agg[sd.duration] = sd.billable_items.reduce((total, {
        billable_item_id,
        quantity
      }) => {
        const item = billableItemData[billable_item_id];
        if (!item) {
          return total;
        }
        return total + (quantity || 0) * (item.unit_price[locationId] || 0);
      }, 0) * (1 + taxRate / 100);
      return agg;
    }, []);
  }, [billableItemData, invoiceSettings, locationId, serviceDurations]);
  const price = serviceDurationPricing[duration];
  const requirements = React.useMemo(() => serviceLocation && groupSubstitute(serviceLocation.requirements, locationId, bookableData, bookableGroupData), [serviceLocation, locationId, bookableData, bookableGroupData]);
  const requirementsList = React.useMemo(() => serviceLocation && buildBookableOptionsList(requirements, bookableData) || EMPTY_ARRAY, [serviceLocation, requirements, bookableData]);
  const durationOptions = {
    units: ['d', 'h', 'm'],
    conjunction: ` ${intl.formatMessage(messages.timeConjunction)} `,
    language,
    fallbacks: [language, 'en']
  };
  const locationTimezone = location?.timezone;
  const defaultTimezone = service.type !== 'on_premises' ? geoipDetectedTimezone || DateTime.local().zoneName || locationTimezone : locationTimezone;
  const [clientTimezone, setClientTimezone] = React.useState(defaultTimezone);
  const searchStartLocal = React.useMemo(() => DateTime.utc().plus({
    minutes: business.settings.booking_window[0]
  }).setZone(clientTimezone).set({
    weekday: 1
  }).startOf('day'), [business.settings.booking_window, clientTimezone]);
  const searchEndLocal = React.useMemo(() => DateTime.utc().plus({
    minutes: business.settings.booking_window[1]
  }).setZone(clientTimezone).set({
    weekday: 7
  }).endOf('day'), [business.settings.booking_window, clientTimezone]);
  const itree = React.useMemo(() => {
    const bookableIds = getAssociatedBookableIds(locationId, serviceId, serviceData, bookableData, bookableGroupData);
    const timezone = locationData[locationId]?.timezone;
    const expandedRules: ScheduleItem[] = rulesToScheduleItems(Object.values<Schedule>(scheduleData), searchStartLocal.startOf('day').setZone('UTC').toISO().substring(0, 19) + 'Z', searchEndLocal.endOf('day').setZone('UTC').toISO().substring(0, 19) + 'Z', timezone, locationId, serviceId, bookableIds);
    const itree = new IntervalTree<string, ScheduleItem>();
    const slots: ScheduleItem[] = expandedRules;
    for (const ebs of Object.values<ExternalBusy[]>(bookableBusyData)) {
      ebs.filter(eb => !eb.location_id || eb.location_id === locationId).forEach(eb => {
        slots.push({
          start: eb.start,
          end: eb.end,
          type: 'busy',
          bookableIds: [eb.bookable_id],
          notes: eb.description,
          booking_id: eb.booking_id
        });
      });
    }
    slots.forEach(s => itree.insert(s.start, s.end, s));
    return itree;
  }, [bookableBusyData, bookableData, bookableGroupData, locationData, locationId, scheduleData, searchEndLocal, searchStartLocal, serviceData, serviceId]);
  React.useEffect(() => {
    setClientTimezone(defaultTimezone);
  }, [defaultTimezone]);

  ///////////////////////// END STATE ///////////////////////////////

  const selectSlot = React.useCallback((locationId, serviceId, selectedBookableIds, start, end, availability, timezone) => {
    // Find available bookables for *

    let bookableIds;
    if (selectedBookableIds.includes('*')) {
      let availableIds = Object.entries<any>(availability).filter(([id, {
        available,
        busy
      }]) => startTimeScheduling ? !busy : available && !busy) // This available & busy shit is confusing
      .map(([id]) => id);
      const randomRequirementsList = requirementsList.map(ids => shuffleSeed.shuffle(ids, new Date().valueOf()));
      bookableIds = randomRequirementsList.map(options => {
        const availableId = options.find(o => availableIds.includes(o));
        availableIds = availableIds.filter(v => v != availableId);
        return availableId;
      });
    } else {
      bookableIds = selectedBookableIds;
    }
    navigate(`/services/${serviceId}/book?` + queryString.stringify({
      ...queryString.parse(rrLocation.search),
      lid: locationId,
      bids: bookableIds,
      start,
      end,
      timezone
    }, {
      arrayFormat: 'comma'
    }));
  }, [rrLocation.search, navigate, requirementsList, startTimeScheduling]);
  React.useEffect(() => {
    if (!duration && durations.length === 1) {
      setDuration(durations[0]);
    }
  }, [duration, durations, setDuration]);
  React.useEffect(() => {
    if (service.id) {
      return;
    }
    dispatch(fetchService(serviceId));
  }, [service.id, serviceId, dispatch]);
  const includeAny = true;
  const bookableOptionLists = React.useMemo(() => buildBookableDropdowns(requirements, includeAny, bookableData), [requirements, includeAny, bookableData]);
  const wildcard = null; // this is null to force selection

  React.useEffect(() => {
    if (bookableOptionLists.length > 0 && bookableIds.length === 0) {
      setBookableIds(bookableOptionLists.map(boption => boption.hide_for_clients ? '*' : wildcard));
    }
  }, [requirementsList, bookableIds, bookableOptionLists, setBookableIds]);
  const dayStartBookingWindow = React.useMemo(() => {
    return [dt.plus({
      minutes: business.settings.booking_window[0]
    }).setZone(clientTimezone).startOf('day').setZone('UTC'), dt.plus({
      minutes: business.settings.booking_window[1]
    })];
  }, [business.settings.booking_window, clientTimezone, dt]);
  const bookingWindow = React.useMemo(() => business.settings.booking_window.map(v => dt.plus({
    minutes: v
  })), [business.settings.booking_window, dt]);

  // Set initial date
  React.useEffect(() => {
    if (localDate || isLoading || bookableIds.filter(Boolean).length === 0 || !durations.includes(duration)) {
      return;
    }
    for (let dt = searchStartLocal.startOf('day'); dt < searchEndLocal; dt = dt.plus({
      days: 7
    })) {
      const startUTC = dt.setZone('UTC');
      const endUTC = dt.plus({
        days: 7
      }).setZone('UTC');
      const slots = getSlotsPublic({
        itree,
        startUTC,
        endUTC,
        duration,
        bookingWindow: dayStartBookingWindow,
        requiredResources: bookableIds,
        bookableOptions: requirementsList,
        serviceId,
        bufferBefore,
        bufferAfter,
        slotInterval,
        startTimeScheduling,
        locationTimezone
      }).filter(slot => bookingWindow[0].toISO() < slot.start && slot.end < bookingWindow[1].toISO());
      if (slots.length) {
        const date = dt.toISO().substring(0, 19);
        setLocalDate(date);
        return;
      }
    }

    // No slots found
    setLocalDate(searchStartLocal.toISO().substring(0, 19));
  }, [localDate, isLoading, duration, durations, bookableIds, business, locationId, serviceId, locationData, serviceData, bookableData, bookableGroupData, scheduleData, bookableBusyData, bufferAfter, bufferBefore, slotInterval, requirementsList, clientTimezone, searchStartLocal, searchEndLocal, itree, startTimeScheduling, locationTimezone, dayStartBookingWindow, bookingWindow]);
  React.useEffect(() => {
    if (!isLoading) {
      const wildcardBookableOption = true;
      const validBookableIds = getValidSelection(locationId, serviceId, bookableIds, wildcardBookableOption, serviceData, bookableData, bookableGroupData);
      if (bookableIds.length !== validBookableIds.length || !bookableIds.every((id, idx) => id === validBookableIds[idx])) {
        return setBookableIds(validBookableIds);
      }
    }
  }, [bookableData, bookableGroupData, bookableIds, isLoading, locationId, serviceData, serviceId, setBookableIds]);
  const [openBookableId, setOpenBookableId] = React.useState(null);
  const openBookable = bookableData[openBookableId];
  const isServiceRequirementsValid = React.useMemo(() => {
    const isRequirementsListValid = requirementsList => {
      if (requirementsList.length == 0) {
        return false;
      }
      for (const requirement of requirementsList) {
        if (requirement.length == 0) {
          return false;
        }
      }
      return true;
    };
    const serviceLocation = service.locations.find(sl => sl.location_id === locationId);
    if (!serviceLocation) {
      return false;
    }
    const requirements = groupSubstitute(serviceLocation.requirements, locationId, bookableData, bookableGroupData);
    const requirementsList = buildBookableOptionsList(requirements, bookableData);
    return isRequirementsListValid(requirementsList);
  }, [bookableData, bookableGroupData, locationId, service.locations]);

  ///////////////////////////////////// END HOOKS //////////////////////////////////////////////////////////////

  if (!locationId) {
    return <LocationSelect />;
  }
  if (!isServiceRequirementsValid) {
    return <EmptyState heading={'Service is not currently available'} subheading={`Please contact us for more information.`} callToAction={<Button as={ReachLink} color="gray" variant="outlined" to={`/` + (locationId ? `?lid=${locationId}` : '')}>
            <FormattedMessage id="Back" defaultMessage={`Back`} />
          </Button>} />;
  }
  if (requirementsList.length === 0) {
    return <EmptyState heading="Service is not currently available" subheading={`Please contact us for more information.`} callToAction={<Button as={ReachLink} color="gray" variant="outlined" to={`/` + (locationId ? `?lid=${locationId}` : '')}>
            <FormattedMessage id="Back" defaultMessage={`Back`} />
          </Button>} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (bookableIds.some(v => v === null)) {
    const idx = bookableIds.indexOf(null);
    const options = bookableOptionLists[idx];
    const label = options.label;
    const bookables = options.bookables.filter(({
      id
    }) => id !== '*');
    const select = bid => {
      setOpenBookableId(null);
      setBookableIds(bookableIds.map((v, idIdx) => idx === idIdx ? bid : v));
    };
    return <Grid gridGap={4}>
        <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')}>
          <FormattedMessage id="Public.BookView.selectServicePrompt" defaultMessage={`Select "{label}"`} values={{
          label
        }} />
        </BookStepHeading>

        <Grid gridTemplateColumns={['repeat( auto-fit, minmax(150px, 1fr) )']} justifyItems="center">
          {bookables.map(({
          name,
          id: bid
        }) => <Grid onClick={() => select(bid)} onKeyPress={e => e.key === 'Enter' && select(bid)} tabIndex={0} role="button" key={bid} sx={{
          cursor: 'pointer'
        }} justifyItems="center">
              <Avatar size={100} image={bookableData[bid]?.image_id ? getCdnImageUrlFromId(bookableData[bid].image_id) : null} icon={faUser} name={name} />
              <Grid gridGap={2}>
                <Heading fontWeight={500} fontSize={3} textAlign="center">
                  {name}
                </Heading>
                {bookableData[bid]?.description && <Flex justifyContent="center">
                    <Button onClick={e => {
                e.stopPropagation();
                setOpenBookableId(bid);
              }} as="button" type="button" color="primary" variant="outlined" size="small">
                      <FormattedMessage id="About" defaultMessage="About" />
                    </Button>
                  </Flex>}
              </Grid>
            </Grid>)}
        </Grid>

        {bookables.length > 1 && <Flex justifyContent="flex-end">
            <Button variant="outlined" onClick={() => select('*')}>
              {intl.formatMessage(messages['No preference'])}
            </Button>
          </Flex>}

        <Modal
      // title={openBookable?.name}
      isOpen={!!openBookableId} close={() => setOpenBookableId(null)} size="medium" props={{
        openBookable: openBookable || EMPTY_OBJECT
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}>
          {({
          openBookable,
          close
        }) => <Grid borderRadius={2} p={3} gridGap={4}>
              <Box sx={{
            position: 'absolute',
            top: 0,
            right: 0
          }} p={3}>
                <CloseButton isDark={true} onClick={close} />
              </Box>
              <Grid>
                <Flex justifyContent="center">
                  <Avatar size={150} image={openBookable.image_id ? getCdnImageUrlFromId(openBookable.image_id) : null} name={openBookable.name} />
                </Flex>
                <Flex justifyContent="center">
                  <Box sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                overflowWrap: 'break-word',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical'
              }}>
                    <Text fontSize={3} fontFamily="heading">
                      {openBookable.name}
                    </Text>
                  </Box>
                </Flex>
              </Grid>

              <Grid>
                {openBookable.description.split('\n').map((v, idx) => <CroppedText key={idx}>{v}</CroppedText>)}
              </Grid>

              <Flex justifyContent="flex-end">
                <Button variant="flat" mr={2} onClick={close}>
                  Close
                </Button>
                <Button onClick={() => select(openBookableId)}>Select</Button>
              </Flex>
            </Grid>}
        </Modal>
      </Grid>;
  }
  if (!duration) {
    return <Grid gridGap={4}>
        <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')}>
          <FormattedMessage id="selectServiceDurationPublic" defaultMessage="Select duration" />
        </BookStepHeading>

        <Grid gridTemplateColumns={['repeat( auto-fit, minmax(150px, 1fr) )']} justifyItems="center">
          {durations.sort((a, b) => a < b ? -1 : 1).map((duration, idx) => <Grid onClick={() => setDuration(duration)} onKeyPress={e => e.key === 'Enter' && setDuration(duration)} tabIndex={0} role="button" key={idx} sx={{
          cursor: 'pointer'
        }} justifyItems="center">
                <Avatar size={100} icon={faClock} name={humanizeDuration(duration * 1000 * 60, durationOptions)} />
                <Grid gridGap={2}>
                  <Heading fontWeight={500} fontSize={3} textAlign="center">
                    {humanizeDuration(duration * 1000 * 60, durationOptions)}
                  </Heading>
                </Grid>
              </Grid>)}
        </Grid>
      </Grid>;
  }
  if (bookableIds.filter(Boolean).length === 0 || !localDate) {
    return <Loader />;
  }
  const startUTC = DateTime.fromISO(localDate, {
    zone: clientTimezone
  }).setZone('UTC');
  const endUTC = DateTime.fromISO(localDate, {
    zone: clientTimezone
  }).plus({
    days: 7
  }).setZone('UTC');
  const slots = getSlotsPublic({
    itree,
    startUTC,
    endUTC,
    duration,
    bookingWindow: dayStartBookingWindow,
    requiredResources: bookableIds,
    bookableOptions: requirementsList,
    serviceId,
    bufferBefore,
    bufferAfter,
    slotInterval,
    startTimeScheduling,
    locationTimezone
  }).filter(slot => bookingWindow[0].toISO() < slot.start && slot.end < bookingWindow[1].toISO());
  const maxSlots = business.settings.max_slots_per_day;
  const minSlots = business.settings.min_slots_per_day;
  const days = slotsToPages(slots, startUTC, endUTC, clientTimezone, locationId, bookableIds).map(v => {
    let slots = v.slots;
    if (maxSlots) {
      const rng = seedrandom(v.date.valueOf());
      const count = Math.floor(rng() * (maxSlots - minSlots + 1)) + minSlots;
      slots = shuffleSeed.shuffle(v.slots, v.date.valueOf()).filter((_, idx) => idx < count).sort((a, b) => a.start < b.start ? -1 : 1);
    }
    return {
      ...v,
      slots: slots
    };
  });
  const filteredBookableOptionLists = bookableOptionLists.map((boption, index) => ({
    ...boption,
    index
  })).filter(boption => !boption.hide_for_clients).filter(boption => boption.bookables.length > 1 || boption.bookables[0]?.id !== 0);
  return <>
      <Grid gridGap={3} data-sentry-element="Grid" data-sentry-source-file="component.tsx">
        <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')} data-sentry-element="BookStepHeading" data-sentry-source-file="component.tsx">
          {getTranslation(service.name, language)}
        </BookStepHeading>
        {(locations.length > 0 || filteredBookableOptionLists.length > 0 || durations.length > 0) && <Row>
            {locations.length > 1 && <Column>
                <DropdownSelect placement="bottom-start" onChange={v => setLocationId(v)} value={locationId || '*'} itemToString={v => locationData[v] ? locationData[v].name : ''} items={locations.map(l => ({
            label: l.name,
            value: l.id,
            iconElement: <IconWrapper icon={faMapMarker} size={1} />
          }))} renderToggle={(props, {
            isOpen,
            displayString,
            items
          }) => <Link {...props} as="button" type="button" variant="discreetLink" textAlign="left" display="flex" alignItems="center" fontSize={2}>
                      <Tippy content={<FormattedMessage id="Location" defaultMessage={`Location`} />}>
                        <IconWrapper icon={faMapMarker} size={1} mr={2} />
                      </Tippy>
                      {displayString}
                      {!(items.length === 1 && displayString) && <Text ml={2}>
                          <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />
                        </Text>}
                    </Link>} />
              </Column>}

            {filteredBookableOptionLists.map(boption => <Column key={boption.index}>
                <DropdownSelect placement="bottom-start" onChange={v => {
            setBookableIds(bookableIds.map((id, idx_) => boption.index === idx_ ? v : id));
          }} value={bookableIds[boption.index] || '*'} itemToString={v => bookableData[bookableIds[boption.index]] ? bookableData[bookableIds[boption.index]].name : intl.formatMessage(messages['No preference'])} items={boption.bookables.map(b => ({
            label: b.id !== '*' ? b.name : intl.formatMessage(messages['No preference']),
            value: b.id,
            iconElement: <Avatar name={bookableData[b.id]?.name} icon={bookableData[b.id]?.staff_member_id ? faUser : faCube} image={bookableData[b.id]?.image_id ? getCdnImageUrlFromId(bookableData[b.id].image_id) : null} size={30} />
          }))} renderToggle={(props, {
            isOpen,
            displayString,
            items
          }) => <Link {...props} as="button" type="button" variant="discreetLink" textAlign="left" display="flex" alignItems="center">
                      <Tippy content={boption.label && <>
                              {boption.label == 'Resource' ? <FormattedMessage id="Resource" defaultMessage={`Resource`} /> : boption.label}
                            </>}>
                        <Avatar name={bookableData[bookableIds[boption.index]]?.name} icon={bookableData[bookableIds[boption.index]]?.staff_member_id ? faUser : faCube} image={bookableData[bookableIds[boption.index]]?.image_id ? getCdnImageUrlFromId(bookableData[bookableIds[boption.index]].image_id) : null} size={30} mr={2} />
                      </Tippy>
                      <Text>{displayString}</Text>
                      {!(items.length === 1 && displayString) && <Text ml={2}>
                          <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />
                        </Text>}
                    </Link>} />
              </Column>)}
          </Row>}

        {durations.length > 1 && <Flex alignItems="center">
            <DropdownSelect placement="bottom-start" name="duration-select" onChange={v => setDuration(v)} value={duration || null} itemToString={v => humanizeDuration(v * 1000 * 60, durationOptions)} items={durations.sort((a, b) => a < b ? -1 : 1).map(v => ({
          label: humanizeDuration(v * 1000 * 60, durationOptions),
          value: v,
          iconElement: <IconWrapper icon={faStopwatch} size={1} />
        }))} renderToggle={(props, {
          isOpen,
          displayString,
          items
        }) => <Link {...props} as="button" type="button" variant="discreetLink" textAlign="left" display="flex" alignItems="center" fontSize={2}>
                  <Tippy content={<FormattedMessage id="Duration" defaultMessage={`Duration`} />}>
                    <IconWrapper icon={faStopwatch} size={1} mr={2} />
                  </Tippy>
                  {displayString}
                  {!(items.length === 1 && displayString) && <Text ml={2}>
                      <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />
                    </Text>}
                </Link>} />
          </Flex>}

        {serviceDurations.length === 1 && <Flex alignItems="center">
            <Tippy content={<FormattedMessage id="Duration" defaultMessage={`Duration`} />}>
              <IconWrapper icon={faStopwatch} size={1} mr={2} />
            </Tippy>{' '}
            <Text>
              {humanizeDuration(duration * 1000 * 60, durationOptions)}
            </Text>
          </Flex>}
        {business.settings.show_pricing && price !== null && <Flex alignItems="center">
            <Tippy content={<FormattedMessage id="Price" defaultMessage="Price" />}>
              <IconWrapper icon={faTags} size={1} mr={2} />
            </Tippy>{' '}
            {price > 0 ? <Text>
                {currencyFormat(price, locationData[locationId].currency)}
              </Text> : <Text>No charge</Text>}
          </Flex>}

        {service.type !== 'on_premises' && <Box>
            <DropdownSelect placement="bottom-start" width={['100%', 'auto']} flexGrow={[1, 'initial']} onChange={setClientTimezone} value={clientTimezone} itemToString={v => v?.replace('_', ' ')} items={timezones} renderToggle={(props, {
          isOpen,
          displayString,
          items
        }) => <Link {...props} as="button" type="button" variant="discreetLink" textAlign="left" display="flex" justifyContent="center" fontSize={2}>
                  <Tippy content="Time zone">
                    <IconWrapper icon={faClock} size={1} mr={2} />
                  </Tippy>
                  {displayString}{' '}
                  {!(items.length === 1 && displayString) && <Box ml={2}>
                      <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />
                    </Box>}
                </Link>} />
          </Box>}
        <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="component.tsx">
          <IconWrapper icon={faInfo} size={1} mr={2} color="success" data-sentry-element="IconWrapper" data-sentry-source-file="component.tsx" />
          <CroppedText fontFamily="body" data-sentry-element="CroppedText" data-sentry-source-file="component.tsx">
            <FormattedMessage id="Public.ServiceView.selectSlotBelow" defaultMessage="Select an available slot below" data-sentry-element="FormattedMessage" data-sentry-source-file="component.tsx" />
          </CroppedText>
        </Flex>

        <Flex justifyContent="center" data-sentry-element="Flex" data-sentry-source-file="component.tsx">
          <CalendarPicker variant="flat" color="text" mode="week" value={localDate} language={language} onChange={setLocalDate} timezone={clientTimezone} data-sentry-element="CalendarPicker" data-sentry-source-file="component.tsx" />
        </Flex>
        <Box minHeight="300px" data-sentry-element="Box" data-sentry-source-file="component.tsx">
          {isLoading ? <>
              <Loader isContained />
              <Box height="10rem" />
            </> : <>
              {slots.length === 0 ? <EmptyState heading={<FormattedMessage id="Public.ServiceView.noOpenings" defaultMessage="No openings available for this week" />} subheading={<FormattedMessage id="Public.ServiceView.noOpeningsSubtitle" defaultMessage="Please try another" />} /> : <Box width="100%" overflowX="auto">
                  <Box>
                    <Flex mb={2} minWidth="100%">
                      {days.map((day, idx) => <Flex key={idx} width={`${100 / 7}%`} justifyContent="center" flexDirection={['column', 'row']} flexGrow={1} fontWeight={400} textAlign="center" backgroundColor="none" sx={{
                  whiteSpace: 'nowrap'
                }}>
                          <Text mr={[0, 1]}>
                            {day.date.setLocale(language).toFormat('ccc')}
                          </Text>

                          <Text>
                            {day.date.setLocale(language).toFormat('d')}
                          </Text>
                        </Flex>)}
                    </Flex>
                    <Flex minWidth="100%">
                      {days.map((day, idx) => <Box key={idx} flexGrow={1} width={`${100 / 7}%`} p="1px" sx={{
                  verticalAlign: 'top'
                }}>
                          {day.slots.map((slot, idx) => <Button variant="solid" color="gray.0" size="small" key={idx} mb={1} p={1} width="100%" fontSize={[0, 2]} minHeight={['36px', 'unset']} sx={{
                    paddingLeft: '6px!important',
                    paddingRight: '6px!important'
                  }} onClick={() => selectSlot(slot.locationId, serviceId, slot.bookableIds, slot.start, slot.end, slot.availability, service.type !== 'on_premises' ? clientTimezone : undefined)}>
                              <Box>
                                {DateTime.fromISO(slot.start, {
                        // locale: language,
                        zone: clientTimezone
                      }).toFormat('t').split(' ').map((v, idx) => <React.Fragment key={idx}>
                                      {idx ? ' ' : ''}
                                      <Box sx={{
                          display: ['block', 'inline']
                        }}>
                                        {v}
                                      </Box>
                                    </React.Fragment>)}
                              </Box>
                            </Button>)}
                        </Box>)}
                    </Flex>
                  </Box>
                </Box>}
            </>}
        </Box>
      </Grid>
    </>;
};
export default ServiceView;