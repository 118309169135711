import React from 'react';
import { Flex, BoxProps, Grid } from '../grid';
import { CroppedText, Heading } from '../typography';
interface Props {
  heading: any;
  subheading?: any;
  callToAction?: any;
  className?: string;
}
const EmptyState = ({
  heading,
  subheading = '',
  callToAction = '',
  ...rest
}: Props & BoxProps) => <Flex alignSelf="center" justifyContent="center" alignItems="center" flexDirection="column" p={3} sx={{
  width: '100%',
  height: '100%',
  textAlign: 'center'
}} {...rest} data-sentry-element="Flex" data-sentry-component="EmptyState" data-sentry-source-file="index.tsx">
    <Grid gridGap={3} sx={{
    justifyItems: 'center',
    width: '100%'
  }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
      <Heading fontSize={3} data-sentry-element="Heading" data-sentry-source-file="index.tsx">{heading}</Heading>
      {subheading && <CroppedText sx={{
      maxWidth: '700px'
    }}>
          {subheading}
        </CroppedText>}
      {callToAction && <Flex flexWrap="wrap" justifyContent="center" sx={{
      maxWidth: '700px',
      width: '100%'
    }}>
          {callToAction}
        </Flex>}
    </Grid>
  </Flex>;
export default EmptyState;