import seedrandom from 'seedrandom';

const seedify = function (seed) {
  if (
    /(number|string)/i.test(
      Object.prototype.toString.call(seed).match(/^\[object (.*)\]$/)[1]
    )
  )
    return seed;
  if (isNaN(seed))
    return Number(
      String((this.strSeed = seed))
        .split('')
        .map(function (x) {
          return x.charCodeAt(0);
        })
        .join('')
    );
  return seed;
};

const seedRand = function (func, min, max) {
  return Math.floor(func() * (max - min + 1)) + min;
};

export const shuffle = (arr, seed) => {
  if (!Array.isArray(arr)) return null;
  seed = seedify(seed) || 'none';

  const size = arr.length;
  const rng = seedrandom(seed);
  const resp = [];
  const keys = [];

  for (let i = 0; i < size; i++) keys.push(i);
  for (let i = 0; i < size; i++) {
    const r = seedRand(rng, 0, keys.length - 1);
    const g = keys[r];
    keys.splice(r, 1);
    resp.push(arr[g]);
  }
  return resp;
};

export const unshuffle = (arr, seed) => {
  if (!Array.isArray(arr)) return null;
  seed = seedify(seed) || 'none';

  const size = arr.length;
  const rng = seedrandom(seed);
  const resp = [];
  const map = [];
  const keys = [];

  for (let i = 0; i < size; i++) {
    resp.push(null);
    keys.push(i);
  }

  for (let i = 0; i < size; i++) {
    const r = seedRand(rng, 0, keys.length - 1);
    const g = keys[r];
    keys.splice(r, 1);
    resp[g] = arr[i];
  }

  return resp;
};
