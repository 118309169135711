import { FSA } from 'flux-standard-action';
import {
  CLIENT_IMPORT_REQUESTED,
  CLIENT_IMPORT_SUCCEEDED,
  CLIENT_IMPORT_FAILED,
  CLIENT_FETCH_REQUESTED,
  CLIENT_FETCH_SUCCEEDED,
  CLIENT_FETCH_FAILED,
  CLIENT_SAVE_REQUESTED,
  CLIENT_SAVE_SUCCEEDED,
  CLIENT_SAVE_FAILED,
  CLIENT_DELETE_REQUESTED,
  CLIENT_DELETE_SUCCEEDED,
  CLIENT_DELETE_FAILED,
  CLIENTS_DELETE_REQUESTED,
  CLIENTS_DELETE_SUCCEEDED,
  CLIENTS_DELETE_FAILED,
} from './action-types';

type state = {
  status: string;
  data: { [key: string]: any };
  serverTotal: number;
};

const initialState: state = {
  status: '',
  serverTotal: null,
  data: {},
};

export default function reducer(
  state = initialState,
  action: FSA<any, any, any>
) {
  switch (action.type) {
    case CLIENT_IMPORT_REQUESTED:
      return { ...state, status: 'importing' };
    case CLIENT_IMPORT_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        serverTotal: state.serverTotal || 1,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case CLIENT_IMPORT_FAILED:
      return { ...state, status: 'failed' };

    case CLIENT_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case CLIENT_FETCH_SUCCEEDED: {
      const serverTotal = action.meta
        ? action.meta.serverTotal
        : state.serverTotal;

      let data = action.payload;

      if (__USE_FAKE_DATA__) {
        const {
          getRandomName,
          getRandomLastName,
          getRandomFirstName,
        } = require('shared/random-name');
        data = Object.values<any>(action.payload).reduce((agg, cur) => {
          agg[cur.id] = {
            ...cur,
            firstname: getRandomFirstName(),
            lastname: getRandomLastName(),
          };
          return agg;
        }, {});
      }

      return {
        ...state,
        status: 'done',
        serverTotal,
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case CLIENT_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case CLIENT_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case CLIENT_SAVE_SUCCEEDED: {
      const client = action.payload;
      return {
        ...state,
        serverTotal: state.serverTotal || 1,
        status: 'done',
        data: {
          ...state.data,
          [client.id]: client,
        },
      };
    }
    case CLIENT_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case CLIENTS_DELETE_REQUESTED:
    case CLIENT_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case CLIENT_DELETE_SUCCEEDED: {
      const { [action.payload]: deletedItem, ...nonDeleted } = state.data;
      return {
        ...state,
        serverTotal: (state.serverTotal || 1) - 1,
        status: 'done',
        data: {
          ...nonDeleted,
          [action.payload]: { ...deletedItem, is_deleted: true },
        },
      };
    }
    case CLIENTS_DELETE_FAILED:
    case CLIENT_DELETE_FAILED:
      return { ...state, status: 'failed' };
    case CLIENTS_DELETE_SUCCEEDED: {
      const nonDeleted = Object.fromEntries(
        Object.entries(state.data).map(([k, v]) =>
          !action.payload.includes(v.id)
            ? [k, v]
            : [k, { ...v, is_deleted: true }]
        )
      );

      return {
        ...state,
        serverTotal: (state.serverTotal || 1) - action.payload.length,
        status: 'done',
        data: nonDeleted,
      };
    }
    default:
      return state;
  }
}
