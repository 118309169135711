import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouteError } from 'react-router-dom';
import { Box, Flex, Grid } from 'shared/grid';
import NotFoundImage from 'shared/images/404-1.gif';
import { CroppedText, Link } from 'shared/typography';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import Layout from '../layout';
export default function ErrorPage() {
  const error = useRouteError() as any;
  // console.error(error);

  //   return (
  //     <div id="error-page">
  //       <h1>Oops!</h1>
  //       <p>Sorry, an unexpected error has occurred.</p>
  //       <p>
  //         <i>{error.statusText || error.message}</i>
  //       </p>
  //     </div>
  //   );

  return <Layout data-sentry-element="Layout" data-sentry-component="ErrorPage" data-sentry-source-file="error-view.tsx">
      <Flex justifyContent="center" alignItems="center" flex={1} height="100vh" data-sentry-element="Flex" data-sentry-source-file="error-view.tsx">
        {error?.status === 404 ? <Grid textAlign="center">
            <CroppedText as="h1" fontSize={5} color="text">
              Page not found
            </CroppedText>
            <Link variant="discreetLink" href="/">
              <FontAwesomeIcon icon={faChevronLeft} /> Take me back
            </Link>
          </Grid> : <Grid textAlign="center">
            <CroppedText as="h1" fontSize={5} color="text">
              Something went wrong: {error?.status || ''}{' '}
              {error?.statusText || ''}
            </CroppedText>
            <Link variant="discreetLink" href="/">
              <FontAwesomeIcon icon={faChevronLeft} /> Take me back
            </Link>
          </Grid>}

        {error?.status === 404 && <Box as="img" sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        height: '40%'
      }} src={NotFoundImage} alt="Not found" title="Not found" />}
      </Flex>
    </Layout>;
}