import { FSA } from 'flux-standard-action';
import {
  STAFF_FETCH_REQUESTED,
  STAFF_FETCH_SUCCEEDED,
  STAFF_FETCH_FAILED,
  STAFF_SAVE_REQUESTED,
  STAFF_SAVE_SUCCEEDED,
  STAFF_SAVE_FAILED,
  STAFF_DELETE_REQUESTED,
  STAFF_DELETE_SUCCEEDED,
  STAFF_DELETE_FAILED,
} from './action-types';

type state = {
  status: string;
  data: { [key: string]: any };
};

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case STAFF_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case STAFF_FETCH_SUCCEEDED: {
      let data = action.payload;

      if (__USE_FAKE_DATA__) {
        const {
          getRandomName,
          getRandomLastName,
          getRandomFirstName,
        } = require('shared/random-name');
        data = Object.values<any>(action.payload).reduce((agg, cur) => {
          agg[cur.id] = {
            ...cur,
            firstname: getRandomFirstName(),
            lastname: getRandomLastName(),
          };
          return agg;
        }, {});
      }

      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case STAFF_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case STAFF_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case STAFF_SAVE_SUCCEEDED: {
      const staffMember = action.payload;
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          [staffMember.id]: staffMember,
        },
      };
    }
    case STAFF_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case STAFF_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case STAFF_DELETE_SUCCEEDED: {
      const { [action.payload]: _, ...nonDeleted } = state.data;
      return {
        ...state,
        status: 'done',
        data: {
          ...nonDeleted,
        },
      };
    }
    case STAFF_DELETE_FAILED:
      return { ...state, status: 'failed' };

    default:
      return state;
  }
}
