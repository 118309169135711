// export const isInWebAppiOS = () => window.navigator['standalone'] === true;
// export const isInWebAppChrome = () =>
//   window.matchMedia('(display-mode: standalone)').matches;

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // https://stackoverflow.com/questions/57765958/how-to-detect-ipad-and-ipad-os-version-in-ios-13-and-up
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  const iPad =
    userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1); /* iPad OS 13 */

  if (iPad) {
    return 'iPadOS';
  }

  return '';
};

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
export function iOS() {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

// export const iOSVersion = () => {
//   // @ts-ignore
//   if (window.MSStream) {
//     // There is some iOS in Windows Phone...
//     // https://msdn.microsoft.com/en-us/library/hh869301(v=vs.85).aspx
//     return false;
//   }
//   const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
//   let version;

//   if (match !== undefined && match !== null) {
//     version = [
//       parseInt(match[1], 10),
//       parseInt(match[2], 10),
//       parseInt(match[3] || '0', 10),
//     ];
//     return parseFloat(version.join('.'));
//   }

//   return false;
// };

export function isAndroid() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export function usesChromeAutofill() {
  // See: https://caniuse.com/#feat=input-autocomplete-onoff
  // Source: https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
  // please note,
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition
  const isChromium = !!window['chrome'];
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window['opr'] !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');

  if (isIOSChrome) {
    return false;
  } else if (isChromium && vendorName === 'Google Inc.' && isOpera === false) {
    return true;
  } else {
    return false;
  }
}
