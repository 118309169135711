import { Theme, ThemeContext } from '@emotion/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Grid } from 'shared/grid';
import { removeToast } from 'shared/toaster/redux/actions';
import { Text } from 'shared/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
const Toaster = ({
  width = '100%',
  topOffset = 0,
  inModal = false
}) => {
  const dispatch = useDispatch<any>();
  const toasts = useSelector((state: any) => state.toaster.toasts);
  const theme = React.useContext<Theme>(ThemeContext as any);
  const isDark = theme.mode === 'dark';
  const colorScales = {
    success: 'green',
    warning: 'yellow',
    alert: 'red',
    primary: 'primary_'
  };
  return <Flex flex="1" justifyContent="center" sx={{
    zIndex: 6007,
    top: inModal ? 0 : theme.topbar.height + topOffset,
    position: 'fixed',
    width: width,
    // transition: 'width 1s',
    pointerEvents: 'none'
  }} data-sentry-element="Flex" data-sentry-component="Toaster" data-sentry-source-file="index.tsx">
      <Grid flex="1" sx={{
      maxWidth: '700px'
    }} p={2} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {toasts.map((toast, idx) => {
        const colorScale = colorScales[toast.className || 'success'];
        return <Text p={3} sx={{
          pointerEvents: 'all',
          textAlign: 'center',
          boxShadow: theme.shadows.large,
          fontWeight: theme.fontWeights.heading,
          fontFamily: theme.fonts.heading,
          borderRadius: `${theme.radii[2]}px`,
          backgroundColor: `${colorScale}.${isDark ? 5 : 4}`,
          color: `white`
        }} key={idx}>
              <Box as="button" onClick={() => dispatch(removeToast(toast))} className="toaster-close-button" zIndex={1} sx={{
            lineHeight: '1.5rem',
            float: 'right',
            fontSize: '2rem',
            textDecoration: 'none',
            cursor: 'pointer'
          }}>
                <FontAwesomeIcon size="xs" icon={faTimes} />
              </Box>
              {toast.body}
            </Text>;
      })}
      </Grid>
    </Flex>;
};
export default Toaster;