import { faPen, faPenFancy } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faChevronDown, faChevronUp, faRedo, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from 'shared/tooltip';
import React from 'react';
import Button from 'shared/button';
import Dropdown from 'shared/dropdown';
import DropdownSelect from 'shared/dropdown-select';
import { Box, Flex, Grid } from 'shared/grid';
export const useUndo = (fromData, data, setData, redoStack, setRedoStack) => {
  const redo = React.useCallback(() => {
    if (redoStack.length) {
      const item = redoStack[redoStack.length - 1];
      setRedoStack(redoStack.filter((_, idx) => idx !== redoStack.length - 1));
      const newData = [...data, item];
      setData(newData);
      fromData(newData);
    }
  }, [data, fromData, redoStack, setData, setRedoStack]);
  const undo = React.useCallback(() => {
    if (data.length) {
      const newData = data.filter((_, idx) => idx !== data.length - 1);
      setRedoStack([...redoStack, data[data.length - 1]]);
      setData(newData);
      fromData(newData);
    }
  }, [data, fromData, redoStack, setData, setRedoStack]);
  return [undo, redo];
};
const colors = ['#000000', '#fe5252', '#ffc400', '#08e576', '#00b0ff', '#d503f9', '#ffffff'];
const DrawPalette = ({
  penType,
  setPenType,
  penColor,
  setPenColor,
  setPenWidth,
  penWidth,
  undo,
  undoStack,
  redo,
  redoStack,
  fit = null,
  setFit = null
}) => {
  return <Flex alignItems="center" justifyContent="center" data-sentry-element="Flex" data-sentry-component="DrawPalette" data-sentry-source-file="draw-palette.tsx">
      <Dropdown toggle={(props, isOpen) => <Box mr={3} as="button" type="button" sx={{
      cursor: 'pointer'
    }} {...props} color="secondary">
            <FontAwesomeIcon size="lg" color="black" style={{
        // '--fa-primary-color': penColor,
        [penType !== 'fountain' ? '--fa-primary-color' : '--fa-secondary-color']: penColor === '#ffffff' ? '#ccc' : penColor,
        '--fa-primary-opacity': 1,
        '--fa-secondary-opacity': 1
      } as any} icon={penType === 'fountain' ? faPenFancy : faPen} />
          </Box>} data-sentry-element="Dropdown" data-sentry-source-file="draw-palette.tsx">
        <Box p={3} data-sentry-element="Box" data-sentry-source-file="draw-palette.tsx">
          <Flex mb={3} data-sentry-element="Flex" data-sentry-source-file="draw-palette.tsx">
            <Grid gridTemplateColumns="repeat(2, 30px)" gridGap={1} alignItems="center" justifyContent="start" data-sentry-element="Grid" data-sentry-source-file="draw-palette.tsx">
              <Tippy content="Ballpoint pen" data-sentry-element="Tippy" data-sentry-source-file="draw-palette.tsx">
                <Box flexShrink={0} variant="outlined" as="button" type="button" sx={{
                cursor: 'pointer'
              }} onClick={() => setPenType('ballpoint')} data-sentry-element="Box" data-sentry-source-file="draw-palette.tsx">
                  <FontAwesomeIcon size="lg" icon={faPen} style={{
                  '--fa-primary-color': penType === 'ballpoint' ? penColor === '#ffffff' || penColor === '#000000' ? '#ccc' : penColor : '#000000',
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-opacity': 1
                } as any} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="draw-palette.tsx" />
                </Box>
              </Tippy>
              <Tippy content="Fountain pen" data-sentry-element="Tippy" data-sentry-source-file="draw-palette.tsx">
                <Box flexShrink={0} variant="outlined" as="button" type="button" sx={{
                cursor: 'pointer'
              }} onClick={() => setPenType('fountain')} data-sentry-element="Box" data-sentry-source-file="draw-palette.tsx">
                  <FontAwesomeIcon size="lg" icon={faPenFancy} style={{
                  '--fa-secondary-color': penType === 'fountain' ? penColor === '#ffffff' || penColor === '#000000' ? '#ccc' : penColor : '#000000',
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-opacity': 1
                } as any} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="draw-palette.tsx" />
                </Box>
              </Tippy>

              {/* <Tippy content="Highlight">
               <Box
               flexShrink={0}
               variant="outlined"
               as="button"
               type="button"
               sx={{
                cursor: 'pointer',
               }}
               onClick={() => setPenType('highlight')}
                         >
               <FontAwesomeIcon
                size={penType === 'highlight' ? '2x' : 'lg'}
                icon={faHighlighter}
               />
               </Box>
               </Tippy> */}
            </Grid>

            <Tippy content="Stroke width" data-sentry-element="Tippy" data-sentry-source-file="draw-palette.tsx">
              <Grid pl={3} flexGrow={1} gridGap={0} data-sentry-element="Grid" data-sentry-source-file="draw-palette.tsx">
                {[0.5, 1, 2, 4, 8].map(width => <Box py={2} key={width} flexShrink={0} variant="outlined" as="button" type="button" sx={{
                cursor: 'pointer'
              }} onClick={() => setPenWidth(width)}>
                    <Box width="100%" height={width} bg={width === penWidth ? penColor === '#ffffff' ? '#000000' : penColor : '#ccc'} />
                  </Box>)}
              </Grid>
            </Tippy>
          </Flex>

          <Grid gridTemplateColumns="repeat(7, 30px)" gridGap={1} alignItems="center" justifyContent="center" data-sentry-element="Grid" data-sentry-source-file="draw-palette.tsx">
            {colors.map(v => <Box key={v} as="button" type="button" sx={{
            cursor: 'pointer',
            color: penColor === '#ffffff' ? 'black' : 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} onClick={() => setPenColor(v)} bg={v} width="30px" height="30px" borderRadius="50%" borderColor={v === '#ffffff' ? 'gray.2' : 'transparent'} borderWidth="2px" borderStyle="solid">
                {penColor === v && <FontAwesomeIcon icon={faCheck} />}
              </Box>)}
          </Grid>
        </Box>
      </Dropdown>

      <Tippy content="Undo" data-sentry-element="Tippy" data-sentry-source-file="draw-palette.tsx">
        <Box mr={3} flexShrink={0} disabled={undoStack.length === 0} variant="outlined" as="button" type="button" sx={{
        cursor: 'pointer'
      }} onClick={undo} color={undoStack.length === 0 ? 'gray.2' : 'black'} data-sentry-element="Box" data-sentry-source-file="draw-palette.tsx">
          <FontAwesomeIcon size="lg" icon={faUndo} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="draw-palette.tsx" />
        </Box>
      </Tippy>
      <Tippy content="Redo" data-sentry-element="Tippy" data-sentry-source-file="draw-palette.tsx">
        <Box flexShrink={0} disabled={redoStack.length === 0} variant="outlined" as="button" type="button" sx={{
        cursor: 'pointer'
      }} onClick={redo} color={redoStack.length === 0 ? 'gray.2' : 'black'} data-sentry-element="Box" data-sentry-source-file="draw-palette.tsx">
          <FontAwesomeIcon size="lg" icon={faRedo} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="draw-palette.tsx" />
        </Box>
      </Tippy>
      {fit && setFit && <Tippy content="Fit">
          <DropdownSelect value={fit} onChange={v => setFit(v)} items={[{
        label: 'Fit width',
        value: 'fit_width'
      }, {
        label: 'Fit height',
        value: 'fit_height'
      }]} itemToString={v => ({
        fit_width: 'Fit width',
        fit_height: 'Fit height'
      })[v]} renderToggle={(props, {
        isOpen,
        displayString,
        items
      }) => <Button {...props} ml={3} display="flex" alignItems="center" as="button" type="button" variant="flat" textAlign="left" fontWeight="body">
                {displayString}
                {!(items.length === 1 && displayString) && <Box ml={2}>
                    <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />
                  </Box>}
              </Button>} />
        </Tippy>}
    </Flex>;
};
export default DrawPalette;