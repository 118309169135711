import styled from 'shared/styled';
import Color from 'color';
import { space, SpaceProps, width, WidthProps } from 'styled-system';
type Props = {
  text?: string;
  theme?: any;
  gradient?: boolean;
} & WidthProps & SpaceProps;
export const Divider = styled.hr<Props>`
  ${({
  theme,
  text = '',
  gradient = true
}) => ({
  lineHeight: 0,
  position: 'relative',
  outline: 0,
  border: 0,
  color: theme.colors.text,
  textAlign: 'center',
  opacity: '0.5',
  width: '100%',
  margin: 0,
  '&:before': {
    content: "''",
    background: gradient ? `linear-gradient(
              to right,
              ${Color(theme.colors.text).alpha(0).string()},
              ${theme.colors.text},
              ${Color(theme.colors.text).alpha(0).string()}
            )` : theme.colors.gray[3],
    position: 'absolute',
    left: 0,
    top: '50%',
    width: '100%',
    height: '1px'
  },
  '&:after': {
    content: `'${text.replace("'", "\\'")}'`,
    position: 'relative',
    display: 'inline-block',
    padding: '0 0.5em',
    lineHeight: '1em',
    color: theme.colors.text,
    backgroundColor: theme.colors.background
  }
})}

  ${space}
  ${width}
`;
export default Divider;