export const BUSINESS_PREFERENCES_UPDATE = 'BUSINESS_PREFERENCES_UPDATE';

export const BUSINESS_FETCH_REQUESTED = 'BUSINESS_FETCH_REQUESTED';
export const BUSINESS_FETCH_SUCCEEDED = 'BUSINESS_FETCH_SUCCEEDED';
export const BUSINESS_FETCH_FAILED = 'BUSINESS_FETCH_FAILED';

export const BUSINESS_PATCH_REQUESTED = 'BUSINESS_PATCH_REQUESTED';
export const BUSINESS_PATCH_SUCCEEDED = 'BUSINESS_PATCH_SUCCEEDED';
export const BUSINESS_PATCH_FAILED = 'BUSINESS_PATCH_FAILED';

export const BUSINESS_SAVE_REQUESTED = 'BUSINESS_SAVE_REQUESTED';
export const BUSINESS_SAVE_SUCCEEDED = 'BUSINESS_SAVE_SUCCEEDED';
export const BUSINESS_SAVE_FAILED = 'BUSINESS_SAVE_FAILED';

export const BUSINESS_DELETE_REQUESTED = 'BUSINESS_DELETE_REQUESTED';
export const BUSINESS_DELETE_SUCCEEDED = 'BUSINESS_DELETE_SUCCEEDED';
export const BUSINESS_DELETE_FAILED = 'BUSINESS_DELETE_FAILED';

export const BUSINESS_UPDATED = 'BUSINESS_UPDATED';
export const BUSINESS_TOKEN_BALANCE_UPDATED = 'BUSINESS_TOKEN_BALANCE_UPDATED';
