export const SCHEDULES_FETCH_REQUESTED = 'SCHEDULES_FETCH_REQUESTED';
export const SCHEDULES_FETCH_SUCCEEDED = 'SCHEDULES_FETCH_SUCCEEDED';
export const SCHEDULES_FETCH_FAILED = 'SCHEDULES_FETCH_FAILED';

export const SCHEDULES_DELETE_REQUESTED = 'SCHEDULES_DELETE_REQUESTED';
export const SCHEDULES_DELETE_SUCCEEDED = 'SCHEDULES_DELETE_SUCCEEDED';
export const SCHEDULES_DELETE_FAILED = 'SCHEDULES_DELETE_FAILED';

export const SCHEDULES_SAVE_REQUESTED = 'SCHEDULES_SAVE_REQUESTED';
export const SCHEDULES_SAVE_SUCCEEDED = 'SCHEDULES_SAVE_SUCCEEDED';
export const SCHEDULES_SAVE_FAILED = 'SCHEDULES_SAVE_FAILED';
