import { faNoteMedical } from '@fortawesome/pro-regular-svg-icons';
import humanizeDuration from 'humanize-duration';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import { Avatar } from 'shared/avatar';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Box, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import Select from 'shared/select';
import Stamp from 'shared/stamp';
import { Text } from 'shared/typography';
import { groupSubstitute } from 'shared/utils/booking-tools';
import { getDarkColor } from 'shared/utils/color';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { buildBookableOptionsList } from 'shared/utils/schedule';
import { getTranslation } from 'shared/utils/text';
import { useAppSelector } from '../../store';
import { BorderBox } from '../shared';
import { useLocationId } from '../../hooks';
const Service = ({
  service,
  locationId
}) => {
  return <BorderBox borderColor="borderColor" p={3} as={ReachLink} to={`/services/${service.id}` + (locationId ? `?lid=${locationId}` : '')} display="flex" sx={{
    '&:hover': {
      borderColor: 'gray.3'
    }
  }} data-sentry-element="BorderBox" data-sentry-component="Service" data-sentry-source-file="component.tsx">
      <Avatar flex="0 0 60px" name={service.name} image={service.image_id ? getCdnImageUrlFromId(service.image_id) : null} color={getDarkColor(service.color)} mr={3} size={60} data-sentry-element="Avatar" data-sentry-source-file="component.tsx" />
      <Grid gridGap={2} data-sentry-element="Grid" data-sentry-source-file="component.tsx">
        <Text display="block" fontSize={3} fontFamily="heading" data-sentry-element="Text" data-sentry-source-file="component.tsx">
          {service.name}
        </Text>

        {(service.description || '').trim() && <Grid gridGap={1}>
            {service.description.trim().split('\n').filter(v => v).map((v, idx) => <Text key={idx}>{v}</Text>)}
          </Grid>}

        <Box mb={-1} mr={-1} data-sentry-element="Box" data-sentry-source-file="component.tsx">
          {service.durations.map((v, idx) => <Stamp color="gray.6" key={idx} mr={1} mb={1}>
              {v}
            </Stamp>)}
        </Box>
      </Grid>
    </BorderBox>;
};
const ServicesView = () => {
  const [locationId, setLocationId] = useLocationId();
  const [a, setA] = React.useState(null);
  const serviceData = useAppSelector(state => state.services.data);
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  const hasClientRequests = business.feature_flags?.includes('client_requests');
  const bookableData = useAppSelector(state => state.bookables.data);
  const bookableGroupData = useAppSelector(state => state.bookableGroups.data);
  const language = useAppSelector(state => state.userPreferences.language);
  const allServices = React.useMemo(() => Object.values<any>(serviceData).filter(v => !v.is_deleted).filter(v => v.is_public == true).map(v => ({
    ...v,
    name: getTranslation(v.name, language)
  })).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).sort((a, b) => a.index < b.index ? -1 : 1), [serviceData, language]);
  const services = React.useMemo(() => allServices.filter(service => {
    const locationIds = locationId ? [locationId] : service.locations.map(sl => sl.location_id);
    return locationIds.some(locationId => {
      const serviceLocation = service.locations.find(sl => sl.location_id === locationId);
      if (!serviceLocation) {
        return false;
      }
      const requirements = groupSubstitute(serviceLocation.requirements, locationId, bookableData, bookableGroupData);
      const requirementsList = buildBookableOptionsList(requirements, bookableData);
      return requirementsList.length > 0;
    });
  }).map(service => ({
    ...service,
    durations: [...new Set<number>(service.locations.flatMap(serviceDuration => serviceDuration.service_durations).map(sd => sd.duration))].sort((a, b) => a < b ? -1 : 1).map(mins => humanizeDuration(mins * 1000 * 60, {
      delimiter: ' and '
    })),
    locations: service.locations.map(serviceLocation => {
      return {
        ...serviceLocation,
        has_missing_pricing: serviceLocation.service_durations.some(serviceDuration => serviceDuration.pricing_model === 'hidden' || serviceDuration.pricing_model === 'simple' && serviceDuration.price === undefined || serviceDuration.pricing_model === 'billable_items' && serviceDuration.billable_items.length === 0)
      };
    }, {})
  })).filter(service => business.settings.default_public_booking_status !== 'awaiting_payment' || !service.locations.every(sl => sl.has_missing_pricing)), [allServices, bookableData, bookableGroupData, business.settings.default_public_booking_status, locationId]);
  const locationData = useAppSelector(state => state.locations.data);
  const locations = React.useMemo(() => (business?.location_ids || []).map(id => locationData[id]).filter(l => allServices.some(service => !!service.locations.find(sl => l?.id === sl.location_id))), [allServices, business?.location_ids, locationData]);
  const isLoading = useAppSelector(state => [state.businesses.status, state.services.status].includes('loading'));
  if (isLoading) {
    return <Loader />;
  }
  if (business.is_deleted || business.subscription_status === 'cancelled' || !business.online || allServices.length === 0 && locations.length > 1) {
    return <>
        <EmptyState heading={<FormattedMessage id="Public.ServicesView.notTakingBookings" defaultMessage="Unfortunately we are not currently taking online bookings." />} subheading={<FormattedMessage id="Public.ServicesView.notTakingBookingsSubheading" defaultMessage="Contact us to enquire about availability." />} callToAction={<Button as={ReachLink} className="hide-for-small-only" mr={2} variant="outlined" color="gray" to={`/contact` + (locationId ? `?lid=${locationId}` : '')}>
              <FormattedMessage id="Contact us" defaultMessage="Contact us" />
            </Button>} />
      </>;
  }
  return <Box flex="1" mt={[2, 3]} data-sentry-element="Box" data-sentry-component="ServicesView" data-sentry-source-file="component.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="component.tsx">
        {locations.length > 1 && <Select variant="outlined" onChange={e => setLocationId(e.target.value)} value={locationId || ''}>
            <FormattedMessage id="All" defaultMessage="All locations">
              {text => <option value="">{text}</option>}
            </FormattedMessage>

            {locations.map((location, idx) => <option key={idx} value={location.id}>
                {location.name}
              </option>)}
          </Select>}
        {services.map((service, idx) => <Service key={idx} service={service} locationId={locationId} />)}
        {hasClientRequests && <BorderBox borderColor="borderColor" p={3} as={ReachLink} to={`/request-prescription` + (locationId ? `?lid=${locationId}` : '')} display="flex" sx={{
        '&:hover': {
          borderColor: 'gray.3'
        }
      }}>
            <Avatar flex="0 0 60px" icon={faNoteMedical} mr={3} size={60} />
            <Grid gridGap={2}>
              <Text display="block" fontSize={3} fontFamily="heading">
                <FormattedMessage id="Public.RequestPrescription.requestPrescription" defaultMessage="Request a prescription" />
              </Text>
            </Grid>
          </BorderBox>}
      </Grid>
      {services.length === 0 && <EmptyState heading={<FormattedMessage id="Public.ServicesView.notServicesAvailable" defaultMessage="No services available" />} subheading={<FormattedMessage id="Public.ServicesView.notTakingBookingsSubheading" defaultMessage="Contact us to enquire about availability." />} callToAction={<Button as={ReachLink} className="hide-for-small-only" mr={2} variant="outlined" color="gray" to={`/contact` + (locationId ? `?lid=${locationId}` : '')}>
              <FormattedMessage id="Contact us" defaultMessage="Contact us" />
            </Button>} />}
    </Box>;
};
export default ServicesView;