// @generated
// prettier-ignore
const timezones = [
  {
    "label": "GMT-12",
    "value": "Etc/GMT+12"
  },
  {
    "label": "GMT-11",
    "value": "Etc/GMT+11"
  },
  {
    "label": "(GMT-11:00) Niue",
    "value": "Pacific/Niue"
  },
  {
    "label": "(GMT-11:00) Pago Pago",
    "value": "Pacific/Pago_Pago"
  },
  {
    "label": "GMT-10",
    "value": "Etc/GMT+10"
  },
  {
    "label": "(GMT-10:00/-09:00) Adak",
    "value": "America/Adak"
  },
  {
    "label": "(GMT-10:00) HST",
    "value": "HST"
  },
  {
    "label": "(GMT-10:00) Honolulu",
    "value": "Pacific/Honolulu"
  },
  {
    "label": "(GMT-10:00) Rarotonga",
    "value": "Pacific/Rarotonga"
  },
  {
    "label": "(GMT-10:00) Tahiti",
    "value": "Pacific/Tahiti"
  },
  {
    "label": "(GMT-10:30) Marquesas",
    "value": "Pacific/Marquesas"
  },
  {
    "label": "GMT-9",
    "value": "Etc/GMT+9"
  },
  {
    "label": "(GMT-09:00/-08:00) Anchorage",
    "value": "America/Anchorage"
  },
  {
    "label": "(GMT-09:00) Gambier",
    "value": "Pacific/Gambier"
  },
  {
    "label": "(GMT-09:00/-08:00) Juneau",
    "value": "America/Juneau"
  },
  {
    "label": "(GMT-09:00/-08:00) Metlakatla",
    "value": "America/Metlakatla"
  },
  {
    "label": "(GMT-09:00/-08:00) Nome",
    "value": "America/Nome"
  },
  {
    "label": "(GMT-09:00/-08:00) Sitka",
    "value": "America/Sitka"
  },
  {
    "label": "(GMT-09:00/-08:00) Yakutat",
    "value": "America/Yakutat"
  },
  {
    "label": "GMT-8",
    "value": "Etc/GMT+8"
  },
  {
    "label": "(GMT-08:00/-07:00) Los Angeles",
    "value": "America/Los_Angeles"
  },
  {
    "label": "(GMT-08:00) Pitcairn",
    "value": "Pacific/Pitcairn"
  },
  {
    "label": "(GMT-08:00/-07:00) Tijuana",
    "value": "America/Tijuana"
  },
  {
    "label": "(GMT-08:00/-07:00) Vancouver",
    "value": "America/Vancouver"
  },
  {
    "label": "GMT-7",
    "value": "Etc/GMT+7"
  },
  {
    "label": "(GMT-07:00/-06:00) Boise",
    "value": "America/Boise"
  },
  {
    "label": "(GMT-07:00/-06:00) Cambridge Bay",
    "value": "America/Cambridge_Bay"
  },
  {
    "label": "(GMT-07:00/-06:00) Chihuahua",
    "value": "America/Chihuahua"
  },
  {
    "label": "(GMT-07:00) Creston",
    "value": "America/Creston"
  },
  {
    "label": "(GMT-07:00) Dawson",
    "value": "America/Dawson"
  },
  {
    "label": "(GMT-07:00) Dawson Creek",
    "value": "America/Dawson_Creek"
  },
  {
    "label": "(GMT-07:00/-06:00) Denver",
    "value": "America/Denver"
  },
  {
    "label": "(GMT-07:00/-06:00) Edmonton",
    "value": "America/Edmonton"
  },
  {
    "label": "(GMT-07:00) Fort Nelson",
    "value": "America/Fort_Nelson"
  },
  {
    "label": "(GMT-07:00) Hermosillo",
    "value": "America/Hermosillo"
  },
  {
    "label": "(GMT-07:00/-06:00) Inuvik",
    "value": "America/Inuvik"
  },
  {
    "label": "(GMT-07:00) MST",
    "value": "MST"
  },
  {
    "label": "(GMT-07:00/-06:00) Mazatlan",
    "value": "America/Mazatlan"
  },
  {
    "label": "(GMT-07:00/-06:00) Ojinaga",
    "value": "America/Ojinaga"
  },
  {
    "label": "(GMT-07:00) Phoenix",
    "value": "America/Phoenix"
  },
  {
    "label": "(GMT-07:00) Whitehorse",
    "value": "America/Whitehorse"
  },
  {
    "label": "(GMT-07:00/-06:00) Yellowknife",
    "value": "America/Yellowknife"
  },
  {
    "label": "GMT-6",
    "value": "Etc/GMT+6"
  },
  {
    "label": "(GMT-06:00/-05:00) Bahia Banderas",
    "value": "America/Bahia_Banderas"
  },
  {
    "label": "(GMT-06:00) Belize",
    "value": "America/Belize"
  },
  {
    "label": "(GMT-06:00/-05:00) Chicago",
    "value": "America/Chicago"
  },
  {
    "label": "(GMT-06:00) Costa Rica",
    "value": "America/Costa_Rica"
  },
  {
    "label": "(GMT-06:00/-05:00) Easter",
    "value": "Pacific/Easter"
  },
  {
    "label": "(GMT-06:00) El Salvador",
    "value": "America/El_Salvador"
  },
  {
    "label": "(GMT-06:00) Galapagos",
    "value": "Pacific/Galapagos"
  },
  {
    "label": "(GMT-06:00) Guatemala",
    "value": "America/Guatemala"
  },
  {
    "label": "(GMT-06:00/-05:00) Indiana/Knox",
    "value": "America/Indiana/Knox"
  },
  {
    "label": "(GMT-06:00/-05:00) Indiana/Tell City",
    "value": "America/Indiana/Tell_City"
  },
  {
    "label": "(GMT-06:00) Managua",
    "value": "America/Managua"
  },
  {
    "label": "(GMT-06:00/-05:00) Matamoros",
    "value": "America/Matamoros"
  },
  {
    "label": "(GMT-06:00/-05:00) Menominee",
    "value": "America/Menominee"
  },
  {
    "label": "(GMT-06:00/-05:00) Merida",
    "value": "America/Merida"
  },
  {
    "label": "(GMT-06:00/-05:00) Mexico City",
    "value": "America/Mexico_City"
  },
  {
    "label": "(GMT-06:00/-05:00) Monterrey",
    "value": "America/Monterrey"
  },
  {
    "label": "(GMT-06:00/-05:00) North Dakota/Beulah",
    "value": "America/North_Dakota/Beulah"
  },
  {
    "label": "(GMT-06:00/-05:00) North Dakota/Center",
    "value": "America/North_Dakota/Center"
  },
  {
    "label": "(GMT-06:00/-05:00) North Dakota/New Salem",
    "value": "America/North_Dakota/New_Salem"
  },
  {
    "label": "(GMT-06:00/-05:00) Rainy River",
    "value": "America/Rainy_River"
  },
  {
    "label": "(GMT-06:00/-05:00) Rankin Inlet",
    "value": "America/Rankin_Inlet"
  },
  {
    "label": "(GMT-06:00) Regina",
    "value": "America/Regina"
  },
  {
    "label": "(GMT-06:00/-05:00) Resolute",
    "value": "America/Resolute"
  },
  {
    "label": "(GMT-06:00) Swift Current",
    "value": "America/Swift_Current"
  },
  {
    "label": "(GMT-06:00) Tegucigalpa",
    "value": "America/Tegucigalpa"
  },
  {
    "label": "(GMT-06:00/-05:00) Winnipeg",
    "value": "America/Winnipeg"
  },
  {
    "label": "GMT-5",
    "value": "Etc/GMT+5"
  },
  {
    "label": "(GMT-05:00) Atikokan",
    "value": "America/Atikokan"
  },
  {
    "label": "(GMT-05:00) Bogota",
    "value": "America/Bogota"
  },
  {
    "label": "(GMT-05:00) Cancun",
    "value": "America/Cancun"
  },
  {
    "label": "(GMT-05:00/-04:00) Detroit",
    "value": "America/Detroit"
  },
  {
    "label": "(GMT-05:00) EST",
    "value": "EST"
  },
  {
    "label": "(GMT-05:00) Eirunepe",
    "value": "America/Eirunepe"
  },
  {
    "label": "(GMT-05:00/-04:00) Fort Wayne",
    "value": "America/Fort_Wayne"
  },
  {
    "label": "(GMT-05:00/-04:00) Grand Turk",
    "value": "America/Grand_Turk"
  },
  {
    "label": "(GMT-05:00) Guayaquil",
    "value": "America/Guayaquil"
  },
  {
    "label": "(GMT-05:00/-04:00) Havana",
    "value": "America/Havana"
  },
  {
    "label": "(GMT-05:00/-04:00) Indiana/Marengo",
    "value": "America/Indiana/Marengo"
  },
  {
    "label": "(GMT-05:00/-04:00) Indiana/Petersburg",
    "value": "America/Indiana/Petersburg"
  },
  {
    "label": "(GMT-05:00/-04:00) Indiana/Vevay",
    "value": "America/Indiana/Vevay"
  },
  {
    "label": "(GMT-05:00/-04:00) Indiana/Vincennes",
    "value": "America/Indiana/Vincennes"
  },
  {
    "label": "(GMT-05:00/-04:00) Indiana/Winamac",
    "value": "America/Indiana/Winamac"
  },
  {
    "label": "(GMT-05:00/-04:00) Iqaluit",
    "value": "America/Iqaluit"
  },
  {
    "label": "(GMT-05:00) Jamaica",
    "value": "America/Jamaica"
  },
  {
    "label": "(GMT-05:00/-04:00) Kentucky/Louisville",
    "value": "America/Kentucky/Louisville"
  },
  {
    "label": "(GMT-05:00/-04:00) Kentucky/Monticello",
    "value": "America/Kentucky/Monticello"
  },
  {
    "label": "(GMT-05:00) Lima",
    "value": "America/Lima"
  },
  {
    "label": "(GMT-05:00/-04:00) Nassau",
    "value": "America/Nassau"
  },
  {
    "label": "(GMT-05:00/-04:00) New York",
    "value": "America/New_York"
  },
  {
    "label": "(GMT-05:00/-04:00) Nipigon",
    "value": "America/Nipigon"
  },
  {
    "label": "(GMT-05:00) Panama",
    "value": "America/Panama"
  },
  {
    "label": "(GMT-05:00/-04:00) Pangnirtung",
    "value": "America/Pangnirtung"
  },
  {
    "label": "(GMT-05:00/-04:00) Port-au-Prince",
    "value": "America/Port-au-Prince"
  },
  {
    "label": "(GMT-05:00) Rio Branco",
    "value": "America/Rio_Branco"
  },
  {
    "label": "(GMT-05:00/-04:00) Thunder Bay",
    "value": "America/Thunder_Bay"
  },
  {
    "label": "(GMT-05:00/-04:00) Toronto",
    "value": "America/Toronto"
  },
  {
    "label": "GMT-4",
    "value": "Etc/GMT+4"
  },
  {
    "label": "(GMT-04:00/-03:00) Asuncion",
    "value": "America/Asuncion"
  },
  {
    "label": "(GMT-04:00) Barbados",
    "value": "America/Barbados"
  },
  {
    "label": "(GMT-04:00/-03:00) Bermuda",
    "value": "Atlantic/Bermuda"
  },
  {
    "label": "(GMT-04:00) Blanc-Sablon",
    "value": "America/Blanc-Sablon"
  },
  {
    "label": "(GMT-04:00) Boa Vista",
    "value": "America/Boa_Vista"
  },
  {
    "label": "(GMT-04:00) Campo Grande",
    "value": "America/Campo_Grande"
  },
  {
    "label": "(GMT-04:00) Caracas",
    "value": "America/Caracas"
  },
  {
    "label": "(GMT-04:00) Cuiaba",
    "value": "America/Cuiaba"
  },
  {
    "label": "(GMT-04:00) Curacao",
    "value": "America/Curacao"
  },
  {
    "label": "(GMT-04:00/-03:00) Glace Bay",
    "value": "America/Glace_Bay"
  },
  {
    "label": "(GMT-04:00/-03:00) Goose Bay",
    "value": "America/Goose_Bay"
  },
  {
    "label": "(GMT-04:00) Guyana",
    "value": "America/Guyana"
  },
  {
    "label": "(GMT-04:00/-03:00) Halifax",
    "value": "America/Halifax"
  },
  {
    "label": "(GMT-04:00) La Paz",
    "value": "America/La_Paz"
  },
  {
    "label": "(GMT-04:00) Manaus",
    "value": "America/Manaus"
  },
  {
    "label": "(GMT-04:00) Martinique",
    "value": "America/Martinique"
  },
  {
    "label": "(GMT-04:00/-03:00) Moncton",
    "value": "America/Moncton"
  },
  {
    "label": "(GMT-04:00) Port of Spain",
    "value": "America/Port_of_Spain"
  },
  {
    "label": "(GMT-04:00) Porto Velho",
    "value": "America/Porto_Velho"
  },
  {
    "label": "(GMT-04:00) Puerto Rico",
    "value": "America/Puerto_Rico"
  },
  {
    "label": "(GMT-04:00/-03:00) Santiago",
    "value": "America/Santiago"
  },
  {
    "label": "(GMT-04:00) Santo Domingo",
    "value": "America/Santo_Domingo"
  },
  {
    "label": "(GMT-04:00/-03:00) Thule",
    "value": "America/Thule"
  },
  {
    "label": "(GMT-04:30/-03:30) St Johns",
    "value": "America/St_Johns"
  },
  {
    "label": "GMT-3",
    "value": "Etc/GMT+3"
  },
  {
    "label": "(GMT-03:00) Araguaina",
    "value": "America/Araguaina"
  },
  {
    "label": "(GMT-03:00) Argentina/Buenos Aires",
    "value": "America/Argentina/Buenos_Aires"
  },
  {
    "label": "(GMT-03:00) Argentina/Catamarca",
    "value": "America/Argentina/Catamarca"
  },
  {
    "label": "(GMT-03:00) Argentina/Cordoba",
    "value": "America/Argentina/Cordoba"
  },
  {
    "label": "(GMT-03:00) Argentina/Jujuy",
    "value": "America/Argentina/Jujuy"
  },
  {
    "label": "(GMT-03:00) Argentina/La Rioja",
    "value": "America/Argentina/La_Rioja"
  },
  {
    "label": "(GMT-03:00) Argentina/Mendoza",
    "value": "America/Argentina/Mendoza"
  },
  {
    "label": "(GMT-03:00) Argentina/Rio Gallegos",
    "value": "America/Argentina/Rio_Gallegos"
  },
  {
    "label": "(GMT-03:00) Argentina/Salta",
    "value": "America/Argentina/Salta"
  },
  {
    "label": "(GMT-03:00) Argentina/San Juan",
    "value": "America/Argentina/San_Juan"
  },
  {
    "label": "(GMT-03:00) Argentina/San Luis",
    "value": "America/Argentina/San_Luis"
  },
  {
    "label": "(GMT-03:00) Argentina/Tucuman",
    "value": "America/Argentina/Tucuman"
  },
  {
    "label": "(GMT-03:00) Argentina/Ushuaia",
    "value": "America/Argentina/Ushuaia"
  },
  {
    "label": "(GMT-03:00) Bahia",
    "value": "America/Bahia"
  },
  {
    "label": "(GMT-03:00) Belem",
    "value": "America/Belem"
  },
  {
    "label": "(GMT-03:00) Cayenne",
    "value": "America/Cayenne"
  },
  {
    "label": "(GMT-03:00) Fortaleza",
    "value": "America/Fortaleza"
  },
  {
    "label": "(GMT-03:00) Maceio",
    "value": "America/Maceio"
  },
  {
    "label": "(GMT-03:00/-02:00) Miquelon",
    "value": "America/Miquelon"
  },
  {
    "label": "(GMT-03:00) Montevideo",
    "value": "America/Montevideo"
  },
  {
    "label": "(GMT-03:00/-02:00) Nuuk",
    "value": "America/Nuuk"
  },
  {
    "label": "(GMT-03:00) Palmer",
    "value": "Antarctica/Palmer"
  },
  {
    "label": "(GMT-03:00) Paramaribo",
    "value": "America/Paramaribo"
  },
  {
    "label": "(GMT-03:00) Punta Arenas",
    "value": "America/Punta_Arenas"
  },
  {
    "label": "(GMT-03:00) Recife",
    "value": "America/Recife"
  },
  {
    "label": "(GMT-03:00) Rothera",
    "value": "Antarctica/Rothera"
  },
  {
    "label": "(GMT-03:00) Santarem",
    "value": "America/Santarem"
  },
  {
    "label": "(GMT-03:00) Sao Paulo",
    "value": "America/Sao_Paulo"
  },
  {
    "label": "(GMT-03:00) Stanley",
    "value": "Atlantic/Stanley"
  },
  {
    "label": "(GMT-02:00) Noronha",
    "value": "America/Noronha"
  },
  {
    "label": "(GMT-02:00) South Georgia",
    "value": "Atlantic/South_Georgia"
  },
  {
    "label": "GMT-1",
    "value": "Etc/GMT+1"
  },
  {
    "label": "(GMT-01:00/+00:00) Azores",
    "value": "Atlantic/Azores"
  },
  {
    "label": "(GMT-01:00) Cape Verde",
    "value": "Atlantic/Cape_Verde"
  },
  {
    "label": "(GMT-01:00/+00:00) Scoresbysund",
    "value": "America/Scoresbysund"
  },
  {
    "label": "GMT+0",
    "value": "Etc/GMT-0"
  },
  {
    "label": "(GMT+00:00) Abidjan",
    "value": "Africa/Abidjan"
  },
  {
    "label": "(GMT+00:00) Accra",
    "value": "Africa/Accra"
  },
  {
    "label": "(GMT+00:00) Bissau",
    "value": "Africa/Bissau"
  },
  {
    "label": "(GMT+00:00/+01:00) Canary",
    "value": "Atlantic/Canary"
  },
  {
    "label": "(GMT+00:00/+01:00) Casablanca",
    "value": "Africa/Casablanca"
  },
  {
    "label": "(GMT+00:00) Danmarkshavn",
    "value": "America/Danmarkshavn"
  },
  {
    "label": "(GMT+00:00/+01:00) Dublin",
    "value": "Europe/Dublin"
  },
  {
    "label": "(GMT+00:00/+01:00) El Aaiun",
    "value": "Africa/El_Aaiun"
  },
  {
    "label": "(GMT+00:00/+01:00) Faroe",
    "value": "Atlantic/Faroe"
  },
  {
    "label": "(GMT+00:00/+01:00) Lisbon",
    "value": "Europe/Lisbon"
  },
  {
    "label": "(GMT+00:00/+01:00) London",
    "value": "Europe/London"
  },
  {
    "label": "(GMT+00:00/+01:00) Madeira",
    "value": "Atlantic/Madeira"
  },
  {
    "label": "(GMT+00:00) Monrovia",
    "value": "Africa/Monrovia"
  },
  {
    "label": "(GMT+00:00) Reykjavik",
    "value": "Atlantic/Reykjavik"
  },
  {
    "label": "(GMT+00:00) Sao Tome",
    "value": "Africa/Sao_Tome"
  },
  {
    "label": "(GMT+00:00/+02:00) Troll",
    "value": "Antarctica/Troll"
  },
  {
    "label": "(GMT+00:00) UTC",
    "value": "Etc/UTC"
  },
  {
    "label": "(GMT+00:00/+01:00) WET",
    "value": "WET"
  },
  {
    "label": "GMT+1",
    "value": "Etc/GMT-1"
  },
  {
    "label": "(GMT+01:00) Algiers",
    "value": "Africa/Algiers"
  },
  {
    "label": "(GMT+01:00/+02:00) Amsterdam",
    "value": "Europe/Amsterdam"
  },
  {
    "label": "(GMT+01:00/+02:00) Andorra",
    "value": "Europe/Andorra"
  },
  {
    "label": "(GMT+01:00/+02:00) Belgrade",
    "value": "Europe/Belgrade"
  },
  {
    "label": "(GMT+01:00/+02:00) Berlin",
    "value": "Europe/Berlin"
  },
  {
    "label": "(GMT+01:00/+02:00) Brussels",
    "value": "Europe/Brussels"
  },
  {
    "label": "(GMT+01:00/+02:00) Budapest",
    "value": "Europe/Budapest"
  },
  {
    "label": "(GMT+01:00/+02:00) CET",
    "value": "CET"
  },
  {
    "label": "(GMT+01:00/+02:00) Ceuta",
    "value": "Africa/Ceuta"
  },
  {
    "label": "(GMT+01:00/+02:00) Copenhagen",
    "value": "Europe/Copenhagen"
  },
  {
    "label": "(GMT+01:00/+02:00) Gibraltar",
    "value": "Europe/Gibraltar"
  },
  {
    "label": "(GMT+01:00) Lagos",
    "value": "Africa/Lagos"
  },
  {
    "label": "(GMT+01:00/+02:00) Luxembourg",
    "value": "Europe/Luxembourg"
  },
  {
    "label": "(GMT+01:00/+02:00) MET",
    "value": "MET"
  },
  {
    "label": "(GMT+01:00/+02:00) Madrid",
    "value": "Europe/Madrid"
  },
  {
    "label": "(GMT+01:00/+02:00) Malta",
    "value": "Europe/Malta"
  },
  {
    "label": "(GMT+01:00/+02:00) Monaco",
    "value": "Europe/Monaco"
  },
  {
    "label": "(GMT+01:00) Ndjamena",
    "value": "Africa/Ndjamena"
  },
  {
    "label": "(GMT+01:00/+02:00) Oslo",
    "value": "Europe/Oslo"
  },
  {
    "label": "(GMT+01:00/+02:00) Paris",
    "value": "Europe/Paris"
  },
  {
    "label": "(GMT+01:00/+02:00) Prague",
    "value": "Europe/Prague"
  },
  {
    "label": "(GMT+01:00/+02:00) Rome",
    "value": "Europe/Rome"
  },
  {
    "label": "(GMT+01:00/+02:00) Stockholm",
    "value": "Europe/Stockholm"
  },
  {
    "label": "(GMT+01:00/+02:00) Tirane",
    "value": "Europe/Tirane"
  },
  {
    "label": "(GMT+01:00) Tunis",
    "value": "Africa/Tunis"
  },
  {
    "label": "(GMT+01:00/+02:00) Vienna",
    "value": "Europe/Vienna"
  },
  {
    "label": "(GMT+01:00/+02:00) Warsaw",
    "value": "Europe/Warsaw"
  },
  {
    "label": "(GMT+01:00/+02:00) Zurich",
    "value": "Europe/Zurich"
  },
  {
    "label": "GMT+2",
    "value": "Etc/GMT-2"
  },
  {
    "label": "(GMT+02:00/+03:00) Amman",
    "value": "Asia/Amman"
  },
  {
    "label": "(GMT+02:00/+03:00) Athens",
    "value": "Europe/Athens"
  },
  {
    "label": "(GMT+02:00/+03:00) Beirut",
    "value": "Asia/Beirut"
  },
  {
    "label": "(GMT+02:00/+03:00) Bucharest",
    "value": "Europe/Bucharest"
  },
  {
    "label": "(GMT+02:00) Cairo",
    "value": "Africa/Cairo"
  },
  {
    "label": "(GMT+02:00/+03:00) Chisinau",
    "value": "Europe/Chisinau"
  },
  {
    "label": "(GMT+02:00/+03:00) Damascus",
    "value": "Asia/Damascus"
  },
  {
    "label": "(GMT+02:00/+03:00) EET",
    "value": "EET"
  },
  {
    "label": "(GMT+02:00/+03:00) Famagusta",
    "value": "Asia/Famagusta"
  },
  {
    "label": "(GMT+02:00/+03:00) Gaza",
    "value": "Asia/Gaza"
  },
  {
    "label": "(GMT+02:00/+03:00) Hebron",
    "value": "Asia/Hebron"
  },
  {
    "label": "(GMT+02:00/+03:00) Helsinki",
    "value": "Europe/Helsinki"
  },
  {
    "label": "(GMT+02:00/+03:00) Jerusalem",
    "value": "Asia/Jerusalem"
  },
  {
    "label": "(GMT+02:00) Johannesburg",
    "value": "Africa/Johannesburg"
  },
  {
    "label": "(GMT+02:00) Juba",
    "value": "Africa/Juba"
  },
  {
    "label": "(GMT+02:00) Kaliningrad",
    "value": "Europe/Kaliningrad"
  },
  {
    "label": "(GMT+02:00) Khartoum",
    "value": "Africa/Khartoum"
  },
  {
    "label": "(GMT+02:00/+03:00) Kiev",
    "value": "Europe/Kiev"
  },
  {
    "label": "(GMT+02:00) Maputo",
    "value": "Africa/Maputo"
  },
  {
    "label": "(GMT+02:00/+03:00) Nicosia",
    "value": "Asia/Nicosia"
  },
  {
    "label": "(GMT+02:00/+03:00) Riga",
    "value": "Europe/Riga"
  },
  {
    "label": "(GMT+02:00/+03:00) Sofia",
    "value": "Europe/Sofia"
  },
  {
    "label": "(GMT+02:00/+03:00) Tallinn",
    "value": "Europe/Tallinn"
  },
  {
    "label": "(GMT+02:00) Tripoli",
    "value": "Africa/Tripoli"
  },
  {
    "label": "(GMT+02:00/+03:00) Uzhgorod",
    "value": "Europe/Uzhgorod"
  },
  {
    "label": "(GMT+02:00/+03:00) Vilnius",
    "value": "Europe/Vilnius"
  },
  {
    "label": "(GMT+02:00) Windhoek",
    "value": "Africa/Windhoek"
  },
  {
    "label": "(GMT+02:00/+03:00) Zaporozhye",
    "value": "Europe/Zaporozhye"
  },
  {
    "label": "GMT+3",
    "value": "Etc/GMT-3"
  },
  {
    "label": "(GMT+03:00) Baghdad",
    "value": "Asia/Baghdad"
  },
  {
    "label": "(GMT+03:00) Istanbul",
    "value": "Europe/Istanbul"
  },
  {
    "label": "(GMT+03:00) Kirov",
    "value": "Europe/Kirov"
  },
  {
    "label": "(GMT+03:00) Minsk",
    "value": "Europe/Minsk"
  },
  {
    "label": "(GMT+03:00) Moscow",
    "value": "Europe/Moscow"
  },
  {
    "label": "(GMT+03:00) Nairobi",
    "value": "Africa/Nairobi"
  },
  {
    "label": "(GMT+03:00) Qatar",
    "value": "Asia/Qatar"
  },
  {
    "label": "(GMT+03:00) Riyadh",
    "value": "Asia/Riyadh"
  },
  {
    "label": "(GMT+03:00) Simferopol",
    "value": "Europe/Simferopol"
  },
  {
    "label": "(GMT+03:00) Syowa",
    "value": "Antarctica/Syowa"
  },
  {
    "label": "(GMT+03:00) Volgograd",
    "value": "Europe/Volgograd"
  },
  {
    "label": "(GMT+03:30/+04:30) Tehran",
    "value": "Asia/Tehran"
  },
  {
    "label": "GMT+4",
    "value": "Etc/GMT-4"
  },
  {
    "label": "(GMT+04:00) Astrakhan",
    "value": "Europe/Astrakhan"
  },
  {
    "label": "(GMT+04:00) Baku",
    "value": "Asia/Baku"
  },
  {
    "label": "(GMT+04:00) Dubai",
    "value": "Asia/Dubai"
  },
  {
    "label": "(GMT+04:00) Mahe",
    "value": "Indian/Mahe"
  },
  {
    "label": "(GMT+04:00) Mauritius",
    "value": "Indian/Mauritius"
  },
  {
    "label": "(GMT+04:00) Reunion",
    "value": "Indian/Reunion"
  },
  {
    "label": "(GMT+04:00) Samara",
    "value": "Europe/Samara"
  },
  {
    "label": "(GMT+04:00) Saratov",
    "value": "Europe/Saratov"
  },
  {
    "label": "(GMT+04:00) Tbilisi",
    "value": "Asia/Tbilisi"
  },
  {
    "label": "(GMT+04:00) Ulyanovsk",
    "value": "Europe/Ulyanovsk"
  },
  {
    "label": "(GMT+04:00) Yerevan",
    "value": "Asia/Yerevan"
  },
  {
    "label": "(GMT+04:30) Kabul",
    "value": "Asia/Kabul"
  },
  {
    "label": "GMT+5",
    "value": "Etc/GMT-5"
  },
  {
    "label": "(GMT+05:00) Aqtau",
    "value": "Asia/Aqtau"
  },
  {
    "label": "(GMT+05:00) Aqtobe",
    "value": "Asia/Aqtobe"
  },
  {
    "label": "(GMT+05:00) Ashgabat",
    "value": "Asia/Ashgabat"
  },
  {
    "label": "(GMT+05:00) Atyrau",
    "value": "Asia/Atyrau"
  },
  {
    "label": "(GMT+05:00) Dushanbe",
    "value": "Asia/Dushanbe"
  },
  {
    "label": "(GMT+05:00) Karachi",
    "value": "Asia/Karachi"
  },
  {
    "label": "(GMT+05:00) Kerguelen",
    "value": "Indian/Kerguelen"
  },
  {
    "label": "(GMT+05:00) Maldives",
    "value": "Indian/Maldives"
  },
  {
    "label": "(GMT+05:00) Mawson",
    "value": "Antarctica/Mawson"
  },
  {
    "label": "(GMT+05:00) Oral",
    "value": "Asia/Oral"
  },
  {
    "label": "(GMT+05:00) Qyzylorda",
    "value": "Asia/Qyzylorda"
  },
  {
    "label": "(GMT+05:00) Samarkand",
    "value": "Asia/Samarkand"
  },
  {
    "label": "(GMT+05:00) Tashkent",
    "value": "Asia/Tashkent"
  },
  {
    "label": "(GMT+05:00) Yekaterinburg",
    "value": "Asia/Yekaterinburg"
  },
  {
    "label": "(GMT+05:30) Colombo",
    "value": "Asia/Colombo"
  },
  {
    "label": "(GMT+05:30) Kolkata",
    "value": "Asia/Kolkata"
  },
  {
    "label": "(GMT+05:45) Kathmandu",
    "value": "Asia/Kathmandu"
  },
  {
    "label": "GMT+6",
    "value": "Etc/GMT-6"
  },
  {
    "label": "(GMT+06:00) Almaty",
    "value": "Asia/Almaty"
  },
  {
    "label": "(GMT+06:00) Bishkek",
    "value": "Asia/Bishkek"
  },
  {
    "label": "(GMT+06:00) Chagos",
    "value": "Indian/Chagos"
  },
  {
    "label": "(GMT+06:00) Dhaka",
    "value": "Asia/Dhaka"
  },
  {
    "label": "(GMT+06:00) Omsk",
    "value": "Asia/Omsk"
  },
  {
    "label": "(GMT+06:00) Qostanay",
    "value": "Asia/Qostanay"
  },
  {
    "label": "(GMT+06:00) Thimphu",
    "value": "Asia/Thimphu"
  },
  {
    "label": "(GMT+06:00) Urumqi",
    "value": "Asia/Urumqi"
  },
  {
    "label": "(GMT+06:00) Vostok",
    "value": "Antarctica/Vostok"
  },
  {
    "label": "(GMT+06:30) Cocos",
    "value": "Indian/Cocos"
  },
  {
    "label": "(GMT+06:30) Rangoon",
    "value": "Asia/Rangoon"
  },
  {
    "label": "(GMT+07:00) Bangkok",
    "value": "Asia/Bangkok"
  },
  {
    "label": "(GMT+07:00) Barnaul",
    "value": "Asia/Barnaul"
  },
  {
    "label": "(GMT+07:00) Christmas",
    "value": "Indian/Christmas"
  },
  {
    "label": "(GMT+07:00) Davis",
    "value": "Antarctica/Davis"
  },
  {
    "label": "(GMT+07:00) Ho Chi Minh",
    "value": "Asia/Ho_Chi_Minh"
  },
  {
    "label": "(GMT+07:00) Hovd",
    "value": "Asia/Hovd"
  },
  {
    "label": "(GMT+07:00) Jakarta",
    "value": "Asia/Jakarta"
  },
  {
    "label": "(GMT+07:00) Krasnoyarsk",
    "value": "Asia/Krasnoyarsk"
  },
  {
    "label": "(GMT+07:00) Novokuznetsk",
    "value": "Asia/Novokuznetsk"
  },
  {
    "label": "(GMT+07:00) Novosibirsk",
    "value": "Asia/Novosibirsk"
  },
  {
    "label": "(GMT+07:00) Pontianak",
    "value": "Asia/Pontianak"
  },
  {
    "label": "(GMT+07:00) Tomsk",
    "value": "Asia/Tomsk"
  },
  {
    "label": "GMT+8",
    "value": "Etc/GMT-8"
  },
  {
    "label": "(GMT+08:00) Brunei",
    "value": "Asia/Brunei"
  },
  {
    "label": "(GMT+08:00) Choibalsan",
    "value": "Asia/Choibalsan"
  },
  {
    "label": "(GMT+08:00) Hong Kong",
    "value": "Asia/Hong_Kong"
  },
  {
    "label": "(GMT+08:00) Irkutsk",
    "value": "Asia/Irkutsk"
  },
  {
    "label": "(GMT+08:00) Kuala Lumpur",
    "value": "Asia/Kuala_Lumpur"
  },
  {
    "label": "(GMT+08:00) Kuching",
    "value": "Asia/Kuching"
  },
  {
    "label": "(GMT+08:00) Macau",
    "value": "Asia/Macau"
  },
  {
    "label": "(GMT+08:00) Makassar",
    "value": "Asia/Makassar"
  },
  {
    "label": "(GMT+08:00) Manila",
    "value": "Asia/Manila"
  },
  {
    "label": "(GMT+08:00) Perth",
    "value": "Australia/Perth"
  },
  {
    "label": "(GMT+08:00) Shanghai",
    "value": "Asia/Shanghai"
  },
  {
    "label": "(GMT+08:00) Taipei",
    "value": "Asia/Taipei"
  },
  {
    "label": "(GMT+08:00) Ulaanbaatar",
    "value": "Asia/Ulaanbaatar"
  },
  {
    "label": "(GMT+08:45) Eucla",
    "value": "Australia/Eucla"
  },
  {
    "label": "(GMT+09:00) Chita",
    "value": "Asia/Chita"
  },
  {
    "label": "(GMT+09:00) Dili",
    "value": "Asia/Dili"
  },
  {
    "label": "(GMT+09:00) Jayapura",
    "value": "Asia/Jayapura"
  },
  {
    "label": "(GMT+09:00) Khandyga",
    "value": "Asia/Khandyga"
  },
  {
    "label": "(GMT+09:00) Palau",
    "value": "Pacific/Palau"
  },
  {
    "label": "(GMT+09:00) Pyongyang",
    "value": "Asia/Pyongyang"
  },
  {
    "label": "(GMT+09:00) Seoul",
    "value": "Asia/Seoul"
  },
  {
    "label": "(GMT+09:00) Tokyo",
    "value": "Asia/Tokyo"
  },
  {
    "label": "(GMT+09:00) Yakutsk",
    "value": "Asia/Yakutsk"
  },
  {
    "label": "(GMT+09:30/+10:30) Adelaide",
    "value": "Australia/Adelaide"
  },
  {
    "label": "(GMT+09:30/+10:30) Broken Hill",
    "value": "Australia/Broken_Hill"
  },
  {
    "label": "(GMT+09:30) Darwin",
    "value": "Australia/Darwin"
  },
  {
    "label": "(GMT+10:00) Brisbane",
    "value": "Australia/Brisbane"
  },
  {
    "label": "(GMT+10:00) Chuuk",
    "value": "Pacific/Chuuk"
  },
  {
    "label": "(GMT+10:00) DumontDUrville",
    "value": "Antarctica/DumontDUrville"
  },
  {
    "label": "(GMT+10:00) Guam",
    "value": "Pacific/Guam"
  },
  {
    "label": "(GMT+10:00/+11:00) Hobart",
    "value": "Australia/Hobart"
  },
  {
    "label": "(GMT+10:00) Lindeman",
    "value": "Australia/Lindeman"
  },
  {
    "label": "(GMT+10:00/+11:00) Macquarie",
    "value": "Antarctica/Macquarie"
  },
  {
    "label": "(GMT+10:00/+11:00) Melbourne",
    "value": "Australia/Melbourne"
  },
  {
    "label": "(GMT+10:00) Port Moresby",
    "value": "Pacific/Port_Moresby"
  },
  {
    "label": "(GMT+10:00/+11:00) Sydney",
    "value": "Australia/Sydney"
  },
  {
    "label": "(GMT+10:00) Ust-Nera",
    "value": "Asia/Ust-Nera"
  },
  {
    "label": "(GMT+10:00) Vladivostok",
    "value": "Asia/Vladivostok"
  },
  {
    "label": "(GMT+10:30/+11:00) Lord Howe",
    "value": "Australia/Lord_Howe"
  },
  {
    "label": "GMT+11",
    "value": "Etc/GMT-11"
  },
  {
    "label": "(GMT+11:00) Bougainville",
    "value": "Pacific/Bougainville"
  },
  {
    "label": "(GMT+11:00) Casey",
    "value": "Antarctica/Casey"
  },
  {
    "label": "(GMT+11:00) Efate",
    "value": "Pacific/Efate"
  },
  {
    "label": "(GMT+11:00) Guadalcanal",
    "value": "Pacific/Guadalcanal"
  },
  {
    "label": "(GMT+11:00) Kosrae",
    "value": "Pacific/Kosrae"
  },
  {
    "label": "(GMT+11:00) Magadan",
    "value": "Asia/Magadan"
  },
  {
    "label": "(GMT+11:00/+12:00) Norfolk",
    "value": "Pacific/Norfolk"
  },
  {
    "label": "(GMT+11:00) Noumea",
    "value": "Pacific/Noumea"
  },
  {
    "label": "(GMT+11:00) Pohnpei",
    "value": "Pacific/Pohnpei"
  },
  {
    "label": "(GMT+11:00) Sakhalin",
    "value": "Asia/Sakhalin"
  },
  {
    "label": "(GMT+11:00) Srednekolymsk",
    "value": "Asia/Srednekolymsk"
  },
  {
    "label": "(GMT+12:00) Anadyr",
    "value": "Asia/Anadyr"
  },
  {
    "label": "(GMT+12:00/+13:00) Auckland",
    "value": "Pacific/Auckland"
  },
  {
    "label": "(GMT+12:00/+13:00) Fiji",
    "value": "Pacific/Fiji"
  },
  {
    "label": "(GMT+12:00) Kamchatka",
    "value": "Asia/Kamchatka"
  },
  {
    "label": "(GMT+12:00) Kwajalein",
    "value": "Pacific/Kwajalein"
  },
  {
    "label": "(GMT+12:00) Majuro",
    "value": "Pacific/Majuro"
  },
  {
    "label": "(GMT+12:00) Nauru",
    "value": "Pacific/Nauru"
  },
  {
    "label": "(GMT+12:00) Tarawa",
    "value": "Pacific/Tarawa"
  },
  {
    "label": "(GMT+12:45/+13:45) Chatham",
    "value": "Pacific/Chatham"
  },
  {
    "label": "GMT+13",
    "value": "Etc/GMT-13"
  },
  {
    "label": "(GMT+13:00/+14:00) Apia",
    "value": "Pacific/Apia"
  },
  {
    "label": "(GMT+13:00) Enderbury",
    "value": "Pacific/Enderbury"
  },
  {
    "label": "(GMT+13:00) Fakaofo",
    "value": "Pacific/Fakaofo"
  },
  {
    "label": "(GMT+13:00) Tongatapu",
    "value": "Pacific/Tongatapu"
  },
  {
    "label": "GMT+14",
    "value": "Etc/GMT-14"
  },
  {
    "label": "(GMT+14:00) Kiritimati",
    "value": "Pacific/Kiritimati"
  }
];
export default timezones;
