import { faLandmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PayfastLogo from 'shared/images/payfast.svg';
import PayPalLogo from 'shared/images/paypal.svg';
import SnapscanLogo from 'shared/images/snapscan.svg';
import WigWagLogo from 'shared/images/wigwag.svg';
import YocoLogo from 'shared/images/yoco.svg';
export const paymentMeta = {
  eft: {
    icon: <FontAwesomeIcon icon={faLandmark} size="lg" />,
    name: 'Bank transfer',
    integrated: false,
    inPage: false
  },
  snapscan: {
    icon: <SnapscanLogo height={24} width={24} />,
    name: 'Snapscan',
    integrated: true,
    inPage: true
  },
  payfast: {
    icon: <PayfastLogo height={24} width={24} />,
    name: 'Payfast',
    integrated: true,
    inPage: false
  },
  paypal: {
    icon: <PayPalLogo height={24} width={24} />,
    name: 'Paypal',
    integrated: true,
    inPage: true
  },
  wigwag: {
    icon: <WigWagLogo height={24} width={24} />,
    name: 'WigWag',
    integrated: true,
    inPage: false
  },
  yoco: {
    icon: <YocoLogo height={24} width={24} />,
    name: 'Yoco',
    integrated: true,
    inPage: false
  }
};
export const formPost = (url, data: Record<string, string>, filterBlanks = true) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = url;
  const formEntries = Object.entries<string>(data || {});
  if (!formEntries?.length) {
    return;
  }
  formEntries.forEach(([k, v]) => {
    if (!v && filterBlanks) {
      return;
    }
    const el = document.createElement('input');
    el.type = 'hidden';
    el.value = v;
    el.name = k;
    form.appendChild(el);
  });
  document.body.appendChild(form);
  form.submit();
};
export const payfastRedirect = (host, formData) => {
  formPost(`https://${host}/eng/process`, formData, false);
};