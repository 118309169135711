export const DATA_LIST_FETCH_REQUESTED = 'DATA_LIST_FETCH_REQUESTED';
export const DATA_LIST_FETCH_SUCCEEDED = 'DATA_LIST_FETCH_SUCCEEDED';
export const DATA_LIST_FETCH_FAILED = 'DATA_LIST_FETCH_FAILED';

export const DATA_LIST_SAVE_REQUESTED = 'DATA_LIST_SAVE_REQUESTED';
export const DATA_LIST_SAVE_SUCCEEDED = 'DATA_LIST_SAVE_SUCCEEDED';
export const DATA_LIST_SAVE_FAILED = 'DATA_LIST_SAVE_FAILED';

export const DATA_LIST_DELETE_REQUESTED = 'DATA_LIST_DELETE_REQUESTED';
export const DATA_LIST_DELETE_SUCCEEDED = 'DATA_LIST_DELETE_SUCCEEDED';
export const DATA_LIST_DELETE_FAILED = 'DATA_LIST_DELETE_FAILED';
