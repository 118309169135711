import { faPlusCircle, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from 'shared/tooltip';
import setFieldTouched from 'final-form-set-field-touched';
import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { LabeledCheckbox } from 'shared/checkbox';
import Autocomplete from '../autocomplete';
import Button from '../button';
import FormError from '../form-error';
import { Box, Flex, Grid } from '../grid';
import InfoIcon from '../info-icon';
import Input from '../input';
import InputGroup from '../input-group';
import Label from '../label';
import { fetchDataList, fetchDataLists } from '../redux/data-lists/actions';
import Select from '../select';
import Switch from '../switch';
import { Link } from '../typography';
import { useIsDatalistValid } from './hooks';
export const FieldPrefixContext = React.createContext('');
export const LabelField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.label` : 'label'} validate={value => {
  if (!value) {
    return 'Enter a label';
  }
}} data-sentry-element="Field" data-sentry-component="LabelField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label="Label" type="text" error={meta.touched && meta.error} />
      </Box>}
  </Field>;
export const PlaceholderField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.placeholder` : 'placeholder'} data-sentry-element="Field" data-sentry-component="PlaceholderField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label={<>
              Placeholder text{' '}
              <InfoIcon value="Placeholder text that appears when the field is empty" />
            </>} error={meta.touched && meta.error} type="text" />
      </Box>}
  </Field>;
export const HelpTextField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.helptext` : 'helptext'} data-sentry-element="Field" data-sentry-component="HelpTextField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label={<>
              Help text{' '}
              <InfoIcon value="Provides guidance as to how to fill in this field" />
            </>} type="text" error={meta.touched && meta.error} />
      </Box>}
  </Field>;
export const PrivateField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.private` : 'private'} type="checkbox" data-sentry-element="Field" data-sentry-component="PrivateField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Label>
          <LabeledCheckbox {...input} mr={2} label="For office use only (not visible to clients)" />
          {meta.touched && meta.error && <FormError>{meta.error}</FormError>}
        </Label>
      </Box>}
  </Field>;
export const NameField = ({
  prefix,
  isUniqueFieldName,
  removable
}) => <Field parse={value => value?.toLowerCase?.()} name={prefix ? `${prefix}.name` : 'name'} validate={(value, values: any) => {
  if (!value) {
    return 'Enter a field name';
  }
  if (value.match(/[^0-9a-zA-Z_$]/)) {
    return `A field name may only contain letters, numbers, and '_'`;
  }
  if (['business_id', 'client_id', 'form_id', 'id'].includes(value)) {
    return `${value} is reserved and cannot be used`;
  }
  if (removable && !isUniqueFieldName(value, values.id)) {
    return `Another field named "${value}" id is already used`;
  }
}} data-sentry-element="Field" data-sentry-component="NameField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label={<>
              Field name{' '}
              <InfoIcon value="A unique identifier that can be used to reference this field" />
            </>} disabled={!removable} type="text" error={meta.touched && meta.error} />
      </Box>}
  </Field>;
export const RequiredField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.required` : 'required'} data-sentry-element="Field" data-sentry-component="RequiredField" data-sentry-source-file="shared.tsx">
    {({
    input
  }) => <Flex alignItems="center">
        <Switch size="small" {...input} />
        <Box ml={2}>Required</Box>
      </Flex>}
  </Field>;
export const ClientFieldReference = ({
  prefix,
  schema
}) => {
  const clientSchema = useSelector((state: any) => state.businesses.data[state.userPreferences.currentBusinessId].client_schema);
  const options = React.useMemo(() => clientSchema.filter(s => s.type === schema.type).map(s => ({
    value: s.name,
    label: s.label
  })), [clientSchema, schema.type]);
  const valueToLabel = React.useMemo(() => clientSchema.reduce((agg, cur) => {
    agg[cur.name] = cur.label;
    return agg;
  }, {}), [clientSchema]);
  return <Field name={prefix ? `${prefix}.associated_client_field_name` : 'associated_client_field_name'} data-sentry-element="Field" data-sentry-component="ClientFieldReference" data-sentry-source-file="shared.tsx">
      {({
      input,
      meta
    }) => <Box>
          <Label mb={2} invalid={meta.touched && meta.error}>
            Link to client field{' '}
            <InfoIcon value="When the form is submitted, this client field will be updated" />
          </Label>

          <InputGroup>
            <Autocomplete {...input} sx={{
          display: 'block',
          width: '100%'
        }} invalid={meta.touched && meta.error} itemToString={v => valueToLabel[v] || ''} items={options} />
            {input.value && <Button onClick={e => input.onChange('')}>Unlink</Button>}
          </InputGroup>

          {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
        </Box>}
    </Field>;
};
export const DataSourceField = ({
  prefix
}) => {
  const values = useFormState().values;
  const dispatch = useDispatch<any>();
  const businessId = useSelector((state: any) => state.userPreferences.currentBusinessId);
  React.useEffect(() => {
    dispatch(fetchDataLists({
      businessId
    }));
  }, [businessId, dispatch]);
  const dataListData = useSelector((state: any) => state.dataLists.data);
  const dataListsItems = React.useMemo(() => Object.values<any>(dataListData).filter(l => l.business_id === businessId).map(({
    id,
    label
  }) => ({
    value: id,
    label: label
  })), [dataListData, businessId]);
  const dataSource = prefix ? values[prefix].data_source : values.data_source;
  return <>
      <Box data-sentry-element="Box" data-sentry-source-file="shared.tsx">
        <Field name={prefix ? `${prefix}.data_source` : 'data_source'} data-sentry-element="Field" data-sentry-source-file="shared.tsx">
          {({
          input,
          meta
        }) => <Select {...input}
        // value={input.value || 'options'}
        label="List source">
              <option value="options">Options list</option>
              <option value="data_list_id">Data list</option>
            </Select>}
        </Field>
      </Box>

      {dataSource === 'data_list_id' ? <Box>
          <Label mb={2}>Data list</Label>

          <Field name={prefix ? `${prefix}.data_list_id` : 'data_list_id'}>
            {({
          input,
          meta
        }) => {
          return <>
                  <Box>
                    <Autocomplete {...input} truncate={100} items={dataListsItems} itemToString={v => dataListData[v]?.label || ''} />
                  </Box>
                  {meta.touched && meta.error && <FormError>{meta.error}</FormError>}
                </>;
        }}
          </Field>
        </Box> : <Box>
          <Label mb={2}>Options</Label>
          <FieldArray name={prefix ? `${prefix}.options` : 'options'}>
            {({
          fields
        }) => <Grid>
                {fields.map((name, index) => <Field key={index} name={name} validate={value => {
            if (!value) {
              return 'Enter ';
            }
          }}>
                    {({
              input,
              meta
            }) => <Box>
                        <Input {...input} type="text" invalid={meta.touched && meta.error} suffix={<Tippy content="Remove">
                              <Link color="alert" as="button" type="button" onClick={() => fields.remove(index)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </Link>
                            </Tippy>} />

                        {meta.error && meta.touched &&
              // eslint-disable-next-line react/jsx-no-undef
              <FormError>{meta.error}</FormError>}
                      </Box>}
                  </Field>)}

                <Link as="button" type="button" onClick={() => fields.push('')}>
                  <FontAwesomeIcon icon={faPlusCircle} /> Add option
                </Link>
              </Grid>}
          </FieldArray>
        </Box>}
    </>;
};
export const useOptionItems = schema => {
  const dispatch = useDispatch<any>();
  const dataListData = useSelector((state: any) => state.dataLists.data);
  const valueToDescription = React.useMemo(() => schema.data_source === 'data_list_id' ? (dataListData[schema.data_list_id]?.items || []).reduce((agg, v) => {
    agg[v.value] = v.description;
    return agg;
  }, {}) : {}, [dataListData, schema.data_list_id, schema.data_source]);
  const items = React.useMemo(() => schema.data_source === 'data_list_id' ? (dataListData[schema.data_list_id]?.items || []).map(v => ({
    label: `${v.description} - ${v.value}`,
    value: v.value
  })) : (schema.options || []).map(v => ({
    label: v,
    value: v
  })), [dataListData, schema.data_list_id, schema.options, schema.data_source]);
  React.useEffect(() => {
    if (schema.data_list_id && items.length === 0) {
      dispatch(fetchDataList(schema.data_list_id));
    }
  }, [dispatch, schema.data_list_id, items.length]);
  return [items, valueToDescription];
};