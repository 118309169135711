import axios from '../../anaxios';
import settings from '../../settings';

import {
  BOOKABLE_BUSY_FETCH_REQUESTED,
  BOOKABLE_BUSY_FETCH_SUCCEEDED,
  BOOKABLE_BUSY_FETCH_FAILED,
} from './action-types';

export const fetchBookableBusy = (businessId, from, until) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: BOOKABLE_BUSY_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/bookable-busy`, {
        params: {
          business_id: businessId,
          from,
          until,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({ type: BOOKABLE_BUSY_FETCH_SUCCEEDED, payload: data });
        return data;
      })
      .catch((error) => {
        dispatch({ type: BOOKABLE_BUSY_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};
