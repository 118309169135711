import React from 'react';
import { Box, Grid } from './grid';
import { keyframes } from '@emotion/react';
const on = 'rotateZ(00deg)';
const off = 'rotateZ(90deg)';
const prop = 'transform';
const b2 = keyframes({
  '0%': {
    [prop]: off
  },
  '33.3%': {
    [prop]: on
  },
  '66.3%': {
    [prop]: off
  },
  '100%': {
    [prop]: off
  }
});
const b3 = keyframes({
  '0%': {
    [prop]: off
  },
  '33.3%': {
    [prop]: off
  },
  '66.6%': {
    [prop]: on
  },
  '100%': {
    [prop]: off
  }
});
const sx = {
  borderRadius: 2,
  width: '100%',
  height: '100%'
};
const size = 50;
const Loader = ({
  isContained = false
}) => <Box className={`loader-overlay${isContained ? ' contained' : ''}`} sx={{
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 5000
}} data-sentry-element="Box" data-sentry-component="Loader" data-sentry-source-file="loader.tsx">
    <Box position="absolute" backgroundColor="background" opacity="0.2" width="100%" height="100%" data-sentry-element="Box" data-sentry-source-file="loader.tsx"></Box>
    <Grid className="loader" gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr 1fr" gridGap={`${size / 13}px`} sx={{
    position: isContained ? 'absolute' : 'fixed',
    top: '50%',
    left: '50%',
    width: `${size}px`,
    height: `${size}px`,
    marginLeft: `-${size / 2}px`,
    marginTop: `-${size / 2}px`,
    transition: 'transform 100ms'
  }} data-sentry-element="Grid" data-sentry-source-file="loader.tsx">
      <Box sx={{
      ...sx,
      bg: 'loaderColors.0',
      gridRow: '1/-1',
      transition: 'ease 0.5s',
      transformOrigin: 'bottom left'
    }} data-sentry-element="Box" data-sentry-source-file="loader.tsx" />
      <Box sx={{
      ...sx,
      bg: 'loaderColors.1',
      animation: `${b2} 2s ease infinite`
    }} data-sentry-element="Box" data-sentry-source-file="loader.tsx" />
      <Box sx={{
      ...sx,
      bg: 'loaderColors.2',
      animation: `${b3} 2s ease infinite`
    }} data-sentry-element="Box" data-sentry-source-file="loader.tsx" />
    </Grid>
  </Box>;
export default Loader;