export const BILLABLE_ITEM_FETCH_REQUESTED = 'BILLABLE_ITEM_FETCH_REQUESTED';
export const BILLABLE_ITEM_FETCH_SUCCEEDED = 'BILLABLE_ITEM_FETCH_SUCCEEDED';
export const BILLABLE_ITEM_FETCH_FAILED = 'BILLABLE_ITEM_FETCH_FAILED';

export const BILLABLE_ITEM_SAVE_REQUESTED = 'BILLABLE_ITEM_SAVE_REQUESTED';
export const BILLABLE_ITEM_SAVE_SUCCEEDED = 'BILLABLE_ITEM_SAVE_SUCCEEDED';
export const BILLABLE_ITEM_SAVE_FAILED = 'BILLABLE_ITEM_SAVE_FAILED';

export const BILLABLE_ITEM_DELETE_REQUESTED = 'BILLABLE_ITEM_DELETE_REQUESTED';
export const BILLABLE_ITEM_DELETE_SUCCEEDED = 'BILLABLE_ITEM_DELETE_SUCCEEDED';
export const BILLABLE_ITEM_DELETE_FAILED = 'BILLABLE_ITEM_DELETE_FAILED';

export const BILLABLE_ITEMS_DELETE_REQUESTED =
  'BILLABLE_ITEMS_DELETE_REQUESTED';
export const BILLABLE_ITEMS_DELETE_SUCCEEDED =
  'BILLABLE_ITEMS_DELETE_SUCCEEDED';
export const BILLABLE_ITEMS_DELETE_FAILED = 'BILLABLE_ITEMS_DELETE_FAILED';
