import React from 'react';
import { Box } from './grid';
export function Arrow2({
  ref,
  styles,
  placement,
  arrowSize = 8,
  borderWidth = 1,
  color = 'background',
  borderColor = 'gray.1'
}) {
  const p = placement?.split('-')?.[0] || 'bottom';
  const s = {
    top: {
      borderWidth: `${arrowSize}px ${arrowSize}px 0 ${arrowSize}px`,
      borderColor: 'transparent',
      borderTopColor: borderColor,
      bottom: `-${arrowSize}px`,
      '&:before': {
        content: "' '",
        pointerEvents: 'none',
        border: 'solid transparent',
        bottom: `${-(arrowSize - borderWidth * 2 - 1)}px`,
        left: `${-(arrowSize - borderWidth)}px`,
        position: 'absolute',
        borderColor: 'rgba(194, 225, 245, 0)',
        borderTopColor: color,
        borderWidth: `${arrowSize - borderWidth}px`
      }
    },
    right: {
      borderWidth: `${arrowSize}px ${arrowSize}px ${arrowSize}px 0`,
      borderColor: 'transparent',
      borderRightColor: borderColor,
      left: `-${arrowSize}px`,
      '&:before': {
        content: "' '",
        pointerEvents: 'none',
        border: 'solid transparent',
        top: `-${arrowSize - borderWidth}px`,
        left: `${-(arrowSize - borderWidth * 2 - 1)}px`,
        position: 'absolute',
        borderColor: 'rgba(194, 225, 245, 0)',
        borderRightColor: color,
        borderWidth: `${arrowSize - borderWidth}px`
      }
    },
    bottom: {
      borderWidth: `0 ${arrowSize}px ${arrowSize}px ${arrowSize}px`,
      borderColor: 'transparent',
      borderBottomColor: borderColor,
      top: `-${arrowSize}px`,
      '&:before': {
        content: "' '",
        pointerEvents: 'none',
        border: 'solid transparent',
        top: `${-(arrowSize - borderWidth * 2 - 1)}px`,
        left: `${-(arrowSize - borderWidth)}px`,
        position: 'absolute',
        borderColor: 'rgba(194, 225, 245, 0)',
        borderBottomColor: color,
        borderWidth: `${arrowSize - borderWidth}px`
      }
    },
    left: {
      borderWidth: `${arrowSize}px 0 ${arrowSize}px ${arrowSize}px`,
      borderColor: 'transparent',
      borderLeftColor: borderColor,
      right: `-${arrowSize}px`,
      '&:before': {
        content: "' '",
        pointerEvents: 'none',
        border: 'solid transparent',
        top: `-${arrowSize - borderWidth}px`,
        right: `${-(arrowSize - borderWidth * 2 - 1)}px`,
        position: 'absolute',
        borderColor: 'rgba(194, 225, 245, 0)',
        borderLeftColor: color,
        borderWidth: `${arrowSize - borderWidth}px`
      }
    }
  };
  return <Box ref={ref} sx={{
    width: '1px',
    height: 0,
    borderStyle: 'solid',
    position: 'absolute',
    ...s[p]
  }} style={styles} data-sentry-element="Box" data-sentry-component="Arrow2" data-sentry-source-file="popper-arrow.tsx" />;
}