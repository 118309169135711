// export const palette = {
//   dark: [0.4, 0.75],
//   light: [0.16, 0.93],
// };

export const palette = {
  dark: [0.55, 0.6],
  light: [0.26, 0.93],
};

export function hashStr(str) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function hsv2rgb(h, s, v) {
  let r, g, b;
  if (h && s === undefined && v === undefined) {
    (s = h.s), (v = h.v), (h = h.h);
  }
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      (r = v), (g = t), (b = p);
      break;
    case 1:
      (r = q), (g = v), (b = p);
      break;
    case 2:
      (r = p), (g = v), (b = t);
      break;
    case 3:
      (r = p), (g = q), (b = v);
      break;
    case 4:
      (r = t), (g = p), (b = v);
      break;
    case 5:
      (r = v), (g = p), (b = q);
      break;
  }
  const rgb = [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];

  function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  return '#' + rgb.map(componentToHex).join('');
}

export function pctToColor(pct, s, v) {
  return hsv2rgb(pct, s, v);
}

export function colorForString(str, dark = true) {
  const fudge = 50;
  const hash = hashStr(str) + fudge;
  const pct = Math.abs(Math.sin(hash)) * 100;
  if (dark) {
    return pctToColor(pct, palette.dark[0], palette.dark[1]);
  } else {
    return pctToColor(pct, palette.light[0], palette.light[1]);
  }
}

export function randomColors(total, s, v) {
  s = s || 0.5;
  v = v || 0.5;
  const r = []; // hold the generated colors
  for (let x = 0; x < total; x++) {
    r.push(hsv2rgb(x / total, s, v)); // you can also alternate the saturation and value for even more contrast between the colors
  }
  return r;
}

export function hexToRGBA(hex, alpha = 1) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
}

export function getDarkColor(pct) {
  return pctToColor(pct, palette.dark[0], palette.dark[1]);
}

export function getLightColor(pct) {
  return pctToColor(pct, palette.light[0], palette.light[1]);
}
