import React from 'react';
import escapeHtml from 'escape-html';
import { Text } from 'slate';
import { BoxProps, Box } from '../grid';
import { Link, Heading } from '../typography';
import isUrl from 'is-url';
import { Link as ReachLink } from 'react-router-dom';
import { Text as TextE } from '../typography';
import { EMPTY_OBJECT } from 'shared/utils/constants';
export const RenderCommon = ({
  element,
  children,
  attributes = {}
}) => {
  switch (element.type) {
    case 'block-quote':
      return <Box as="blockquote" borderLeftColor="gray.5" borderLeftStyle="solid" borderLeftWidth="3px" p="1em" mt={1} mb="1em" {...attributes}>
          {children}
        </Box>;
    case 'heading-one':
      return <Heading fontSize={5} as="h1" mt="0.5em" mb="0.5em" {...attributes}>
          {children}
        </Heading>;
    case 'title':
    case 'heading-two':
      return <Heading fontSize={4} as="h2" mt="0.75em" mb="0.5em" {...attributes}>
          {children}
        </Heading>;
    case 'heading-three':
      return <Heading fontSize={3} as="h3" mt="0.75em" mb="0.5em" {...attributes}>
          {children}
        </Heading>;
    case 'list-item':
      return <Box as="li" ml="1em" sx={{
        '& p': {
          mb: '0.5em'
        }
      }} {...attributes}>
          {children}
        </Box>;
    case 'bulleted-list':
      return <Box as="ul" mb="1em" ml="1em" {...attributes}>
          {children}
        </Box>;
    case 'numbered-list':
      return <Box as="ol" mb="1em" {...attributes}>
          {children}
        </Box>;
    case 'paragraph':
      return <Box as="p" mb="1em" {...attributes}>
          {children}
        </Box>;
    default:
      return <>{children}</>;
  }
};
export const RenderElement = ({
  value: element,
  elementOverrides = EMPTY_OBJECT,
  variables = EMPTY_OBJECT
}) => {
  if (Text.isText(element)) {
    const props: BoxProps = {};
    if (element['bold']) {
      props.fontWeight = 'heading';
    }
    if (element['italic']) {
      props.fontStyle = 'italic';
    }
    if (element['underline']) {
      props.sx = {
        textDecoration: 'underline'
      };
    }
    return <TextE as="span" fontSize="inherit" {...props}>
        {element.text}
      </TextE>;
  }
  const children = element.children.map((v, idx) => <RenderElement key={idx} value={v} elementOverrides={elementOverrides} variables={variables} />);
  const override = elementOverrides[element.type];
  if (override) {
    return override(element, children);
  }
  switch (element.type) {
    case 'link':
      {
        if (isUrl(element.url)) {
          const u = new URL(element.url);
          if (u.host.includes('app.bookem.')) {
            const path = u.pathname;
            return <Link as={ReachLink} to={path}>
              {children}
            </Link>;
          }
        }
        return <Link target="_blank" href={escapeHtml(element.url)}>
          {children}
        </Link>;
      }
    case 'image':
      return <Box as="img" alt="image" width={['100%', `${element.width ?? 100}%`]} maxWidth={`min(${element.naturalWidth}px, 100%)`}
      // maxWidth="100%"
      // minWidth="50%"
      src={element.url} mb="1em" />;
    case 'video':
      return <Box as="iframe" sx={{
        display: 'block',
        width: '100%',
        height: '56.25vw',
        maxHeight: 'calc(1068px / 16 * 9)',
        '@supports(aspect-ratio: 16 / 9)': {
          aspectRatio: '16 / 9',
          height: 'unset',
          maxHeight: 'unset'
        }
      }} src={element.url} frameBorder={0} allowFullScreen={true} mb="1em"></Box>;
    case 'placeholder':
      // console.log(variables[element.item.value], element.item.value, variables);
      return variables[element.item.value] || <Box as="span" sx={{
        padding: '2px 4px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: 'brand0.1',
        color: 'primary',
        fontSize: '0.9em'
      }}>
            {element.item.label}
          </Box>;
    default:
      return <RenderCommon element={element}>{children}</RenderCommon>;
  }
};
const Render = ({
  value,
  elementOverrides = EMPTY_OBJECT,
  variables = EMPTY_OBJECT
}) => {
  return <>
      {value.map((v, idx) => <RenderElement value={v} key={idx} elementOverrides={elementOverrides} variables={variables} />)}
    </>;
};
export default Render;