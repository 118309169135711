import { DateTime } from 'luxon';
import IntervalTree from 'rb-interval-tree';
import { buildBookableOptionsList, rulesToScheduleItems } from './schedule';
import { getTranslation } from './text';
import {
  Booking,
  ExtendedBookingService,
  ExternalBusy,
  intersects,
  Schedule,
  ScheduleOverride,
} from './time';

export interface BookingServiceItem {
  start: string;
  end: string;
  bookableIds: string[];
  booking: Booking;
}

export const getBookingItree = (
  fromUTC,
  untilUTC,
  businessId,
  bookingData: { [s: string]: Booking }
) => {
  const itree = new IntervalTree<string, BookingServiceItem>();

  const fromUTCDayStart =
    fromUTC &&
    DateTime.fromISO(fromUTC, { zone: 'UTC' })
      .startOf('day')
      .toISO()
      .substring(0, 19) + 'Z';
  const untilUTCDayEnd =
    untilUTC &&
    DateTime.fromISO(untilUTC, { zone: 'UTC' })
      .endOf('day')
      .toISO()
      .substring(0, 19) + 'Z';

  Object.values<Booking>(bookingData)
    .filter((b) => b.business_id === businessId)
    .filter((b) =>
      b.services.some((bs) =>
        intersects([bs.start, bs.end], [fromUTCDayStart, untilUTCDayEnd])
      )
    )
    .reduce(
      (bookingServices, booking) =>
        booking.services
          .map((bs) => ({
            ...bs,
            booking,
          }))
          .concat(bookingServices),
      []
    )
    .map((bookingService) => ({
      start: bookingService.start,
      end: bookingService.end,
      bookableIds: [...bookingService.bookable_ids],
      booking: bookingService.booking,
    }))
    .forEach((s) => itree.insert(s.start, s.end, s));

  return itree;
};

export const getOverlaps = (
  start: string,
  end: string,
  bookableIds: string[],
  itree: IntervalTree<string, BookingServiceItem>,
  serviceData,
  businessLanguage,
  bookableData
) => {
  return itree
    .search(start, end)
    .filter((e) => e[2].booking?.status === 'confirmed')
    .filter((e) =>
      e[2].booking.services.some((service) =>
        service.bookable_ids.some((bid) => bookableIds.includes(bid))
      )
    )
    .map((e) => e[2].booking)
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .reduce((list: Booking[], curr) => {
      if (list.length > 0) {
        const last = list[list.length - 1];
        if (curr.id !== last.id) {
          list.push(curr);
        }
      } else {
        list.push(curr);
      }
      return list;
    }, [])
    .reduce(
      (bookingServices: ExtendedBookingService[], booking) =>
        booking.services
          .map((bs, serviceIdx) => ({
            ...bs,
            booking,
            serviceIdx,
            serviceName: bs.service_id
              ? getTranslation(
                  serviceData[bs.service_id]?.name,
                  businessLanguage
                )
              : bs.service_name || 'Removed',
            bookableNames: bs.bookable_ids.map(
              (bid) => bookableData[bid]?.name || 'Removed'
            ),
          }))
          .filter((bs) => intersects([bs.start, bs.end], [start, end]))
          .concat(bookingServices),
      []
    );
};

function buildOrDropdown(tree, includeAny, bookableData, skipDisabled = true) {
  const blist = [];
  for (const child of tree.children) {
    if (typeof child === 'object') {
      throw Error('Nesting inside or not supported');
    }

    const bookableId = child.split(':')[1];
    const bookable = bookableData[bookableId];

    if (bookable?.is_deleted) {
      continue;
    }

    if (!bookable?.is_enabled && skipDisabled) {
      continue;
    }

    blist.push({
      id: bookableId,
      name: bookable ? bookable.name : 'Removed',
      is_staff: !!bookable.staff_member_id,
      is_enabled: bookable.is_enabled,
    });
  }

  blist.sort((a, b) => (a.name < b.name ? -1 : 1));

  if (includeAny && blist.length > 1) {
    blist.unshift({ name: 'Any', id: '*' });
  }

  return {
    label: tree.label || 'Resource',
    bookables: blist,
    hide_for_clients: tree.hide_for_clients || false,
  };
}

function buildAndDropdowns(
  tree,
  includeAny,
  bookableData,
  skipDisabled = true
) {
  const alist = [];
  for (const child of tree.children) {
    if (typeof child !== 'object') {
      const bookableId = child.split(':')[1];
      const bookable = bookableData[bookableId];

      if (bookable?.is_deleted) {
        continue;
      }

      if (!bookable?.is_enabled && skipDisabled) {
        continue;
      }

      alist.push({
        label: 'Resource',
        bookables: [
          {
            id: bookableId,
            name: bookable.name,
            is_staff: !!bookable.staff_member_id,
            is_enabled: bookable.is_enabled,
          },
        ],
        hide_for_clients: true,
      });
    } else if (child.op === 'and') {
      throw new Error('Nested and not supported');
    } else if (child.op === 'or') {
      alist.push(buildOrDropdown(child, includeAny, bookableData));
    }
  }
  return alist;
}

export function buildBookableDropdowns(tree, includeAny = true, bookableData) {
  if (!tree) {
    return [];
  }
  if (tree.op === 'or') {
    return [buildOrDropdown(tree, includeAny, bookableData)];
  }
  if (tree.op === 'and') {
    return buildAndDropdowns(tree, includeAny, bookableData);
  }
}

export function groupSubstitute(
  rtree,
  locationId,
  bookableData,
  bookableGroupData
) {
  const subsititutedChildren = rtree['children'].map((child) => {
    if (!child) {
      return child;
    }
    if (typeof child === 'object') {
      return groupSubstitute(
        child,
        locationId,
        bookableData,
        bookableGroupData
      );
    }
    const [type, id] = child.split(':');
    if (type === 'bookable') {
      return child;
    }

    const group = bookableGroupData[id];
    const children = group
      ? group.bookable_ids
          .filter(
            (id) =>
              bookableData[id] &&
              bookableData[id].location_ids.includes(locationId)
          )
          .map((id) => `bookable:${id}`)
      : [];
    return {
      op: 'or',
      label: group ? group.name : 'Removed group',
      children,
      hide_for_clients: group ? group.hide_for_clients : true,
    };
  });

  const newTree = subsititutedChildren.reduce(
    (agg, children) => {
      if (rtree.op === 'or' && typeof children === 'object') {
        agg.children = [...agg.children, ...children.children];
        agg.hide_for_clients =
          agg.hide_for_clients || children.hide_for_clients;
      } else {
        agg.children = [...agg.children, children];
      }

      return agg;
    },
    { ...rtree, children: [] }
  );

  return newTree;
}

export const getBookablesOptionGroups = (
  locationId,
  serviceId,
  serviceData,
  bookableGroupData,
  bookableData
) => {
  if (!['-1', null].includes(serviceId || null)) {
    const serviceLocation = serviceData[serviceId]?.locations?.find(
      (sl) => sl.location_id === locationId
    );

    // If location or service does not exist
    if (!serviceLocation) {
      const requirements = {
        children: [],
        label: 'Resource',
        op: 'or',
      };
      return buildBookableDropdowns(requirements, false, bookableData);
    }

    const requirements = groupSubstitute(
      serviceLocation.requirements,
      locationId,
      bookableData,
      bookableGroupData
    );

    return buildBookableDropdowns(requirements, false, bookableData);
  } else {
    const children = Object.values<any>(bookableData)
      .filter((b) => b.location_ids.includes(locationId))
      .filter((b) => b.is_enabled)
      .map((b) => `bookable:${b.id}`);
    const requirements = {
      children: children,
      label: 'Resource',
      op: 'or',
    };
    return buildBookableDropdowns(requirements, false, bookableData);
  }
};

export function getBookableSelection(
  currentBookableIds,
  serviceId,
  locationId,
  serviceData,
  bookableData,
  bookableGroupData,
  autoSelect = false
) {
  const serviceLocation = serviceData[serviceId].locations.find(
    (sl) => sl.location_id === locationId
  );

  const requirements = groupSubstitute(
    serviceLocation.requirements,
    locationId,
    bookableData,
    bookableGroupData
  );

  const bookableOptions = buildBookableOptionsList(requirements, bookableData);

  const bidPriorityQueue = [...currentBookableIds];

  const usedIds = [];

  const currentSelection = new Array(bookableOptions.length)
    .fill(null)
    .map((_, idx) => currentBookableIds[idx] || null)
    .slice(null, bookableOptions.length);

  const bookableIds = currentSelection.map((currentId, idx) => {
    const positionOptions = bookableOptions[idx].includes(currentId)
      ? [currentId, ...bookableOptions[idx]]
      : bookableOptions[idx].filter((id) => !usedIds.includes(id));

    const bid =
      bidPriorityQueue.find((id) => positionOptions.includes(id)) ||
      positionOptions[0] ||
      null;

    if (
      positionOptions.lenth > 1 &&
      !autoSelect &&
      !bidPriorityQueue.includes(bid)
    ) {
      return null;
    }

    usedIds.push(bid);
    return bid;
  });

  return bookableIds;
}

export function isValidBookableAssignment(
  locationId,
  serviceId,
  bookableIds,
  serviceData,
  bookableData,
  bookableGroupData
) {
  const serviceLocations = serviceData[serviceId]?.locations;
  if (!serviceLocations?.[locationId]) {
    return false;
  }

  const requirements = groupSubstitute(
    serviceLocations[locationId].requirements,
    locationId,
    bookableData,
    bookableGroupData
  );
  const bookableOptions = buildBookableOptionsList(requirements, bookableData);

  return bookableOptions
    .map((bids, idx) => bids.includes(bookableIds[idx]))
    .every((b) => !!b);
}

// interface Itree

export interface BookingScheduleItem {
  start: string;
  end: string;
  type: 'booking';
  bookableIds: string[];
  booking: Booking;
  serviceIdx: number;
  clientName?: string;
}

export interface ScheduleOverrideScheduleItem {
  start: string;
  end: string;
  type: 'blocked';
  bookableIds: string[];
  so?: ScheduleOverride;
  // serviceId?: string;
}

export interface ExternalBusyScheduleItem {
  start: string;
  end: string;
  type: 'busy';
  bookableIds: string[];
  notes: string;
  booking_id: string;
}

export interface RuleScheduleItem {
  start: string;
  end: string;
  type: 'available' | 'unavailable';
  bookableIds: string[];
  rule: Schedule;
  serviceId: string;
}

export type ScheduleItem =
  | BookingScheduleItem
  | ScheduleOverrideScheduleItem
  | ExternalBusyScheduleItem
  | RuleScheduleItem;

// export function buildItree(
//   { fromUTC, untilUTC, bookableIds = [], locationId, serviceId = null },
//   locationData,
//   bookingData: { [s: string]: Booking },
//   scheduleData: { [s: string]: Schedule },
//   scheduleOverrideData: { [s: string]: ScheduleOverride },
//   externalBusyData: { [s: string]: ExternalBusy[] }
// ) {
//   const timezone = locationData[locationId]?.timezone;

//   const slots: ScheduleItem[] = rulesToScheduleItems(
//     Object.values<Schedule>(scheduleData),
//     fromUTC,
//     untilUTC,
//     timezone,
//     locationId,
//     serviceId,
//     bookableIds
//   );

//   Object.values<ScheduleOverride>(scheduleOverrideData)
//     .filter((so) => so.location_id === locationId)
//     .filter((so) => intersects([so.start, so.end], [fromUTC, untilUTC]))
//     .forEach((so) => {
//       slots.push({
//         start: so.start,
//         end: so.end,
//         type: 'blocked',
//         bookableIds: [so.bookable_id],
//         so,
//       });
//     });

//   for (const ebs of Object.values<ExternalBusy[]>(externalBusyData)) {
//     ebs
//       .filter((eb) => !eb.location_id || eb.location_id === locationId)
//       .forEach((eb) => {
//         slots.push({
//           start: eb.start,
//           end: eb.end,
//           type: 'busy',
//           bookableIds: [eb.bookable_id],
//           notes: eb.description,
//           booking_id: eb.booking_id,
//         });
//       });
//   }

//   Object.values<Booking>(bookingData)
//     .filter((b) =>
//       b.services.some((bs) =>
//         intersects([bs.start, bs.end], [fromUTC, untilUTC])
//       )
//     )
//     .reduce(
//       (bookingServices, booking) =>
//         booking.services
//           .map((bs) => ({
//             ...bs,
//             booking,
//           }))
//           .concat(bookingServices),
//       []
//     )
//     .forEach((bookingService, serviceIdx) => {
//       slots.push({
//         start: bookingService.start,
//         end: bookingService.end,
//         type: 'booking',
//         bookableIds: [...bookingService.bookable_ids],
//         booking: bookingService.booking,
//         serviceIdx,
//       });
//     });

//   const itree = new IntervalTree<string, ScheduleItem>();
//   slots.forEach((s) => itree.insert(s.start, s.end, s));
//   return itree;
// }
