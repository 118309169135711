import { combineEpics } from 'redux-observable';
import toasterEpic from 'shared/toaster/redux/epics';
import websocketEpic from 'shared/redux/websockets/epics';

const getRootEpic = () => {
  const rootEpic = combineEpics(websocketEpic, toasterEpic);
  return rootEpic;
};

export default getRootEpic;
