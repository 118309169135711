export const CLIENT_FETCH_REQUESTED = 'CLIENT_FETCH_REQUESTED';
export const CLIENT_FETCH_SUCCEEDED = 'CLIENT_FETCH_SUCCEEDED';
export const CLIENT_FETCH_FAILED = 'CLIENT_FETCH_FAILED';

export const CLIENT_IMPORT_REQUESTED = 'CLIENT_IMPORT_REQUESTED';
export const CLIENT_IMPORT_SUCCEEDED = 'CLIENT_IMPORT_SUCCEEDED';
export const CLIENT_IMPORT_FAILED = 'CLIENT_IMPORT_FAILED';

export const CLIENT_SAVE_REQUESTED = 'CLIENT_SAVE_REQUESTED';
export const CLIENT_SAVE_SUCCEEDED = 'CLIENT_SAVE_SUCCEEDED';
export const CLIENT_SAVE_FAILED = 'CLIENT_SAVE_FAILED';

export const CLIENT_DELETE_REQUESTED = 'CLIENT_DELETE_REQUESTED';
export const CLIENT_DELETE_SUCCEEDED = 'CLIENT_DELETE_SUCCEEDED';
export const CLIENT_DELETE_FAILED = 'CLIENT_DELETE_FAILED';

export const CLIENTS_DELETE_REQUESTED = 'CLIENTS_DELETE_REQUESTED';
export const CLIENTS_DELETE_SUCCEEDED = 'CLIENTS_DELETE_SUCCEEDED';
export const CLIENTS_DELETE_FAILED = 'CLIENTS_DELETE_FAILED';

export const CLIENT_UPDATED = 'CLIENT_UPDATED';
