import { faEllipsisV, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import get from 'lodash.get';
import React from 'react';
import { Field, Form, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Link, Text } from 'shared/typography';
import { UPLOAD_PATH_PREFIX } from 'shared/utils/constants';
import { getCdnUploadUrlFromPath, getFileBlobDespiteGoogleDrive } from 'shared/utils/uploads';
import anaxios from '../../anaxios';
import Button from '../../button';
import Callout from '../../callout';
import DropdownSelect from '../../dropdown-select';
import { FileIcon } from '../../file-icons';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import { getRecaptchaToken, useIsRecaptchaReady } from '../../hooks/use-recaptcha';
import Label from '../../label';
import useModal from '../../modal/use-modal';
import settings from '../../settings';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from '../shared';
const ONE_MEGABYTE = Math.pow(2, 20);
const ONE_KILOBYTE = Math.pow(2, 10);
const UPLOAD_SIZE_LIMIT_BYTES = 10 * Math.pow(2, 20);
const FormUploadPill = ({
  file: {
    name,
    size,
    upload_id
  },
  remove = null
}) => {
  return <Flex alignItems="center" justifyContent="space-between" borderColor="borderColor" borderWidth={1} borderStyle="solid" borderRadius={2} py={1} px={2} flexBasis="300px" flexShrink={0} mr={2} mb={2} data-sentry-element="Flex" data-sentry-component="FormUploadPill" data-sentry-source-file="index.tsx">
      <Flex as="a" href={getCdnUploadUrlFromPath(`${UPLOAD_PATH_PREFIX}${upload_id}/${name}`)} target="_blank" alignItems="center" onClick={e => e.stopPropagation()} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        {upload_id == 'uploading' ? <Box position="relative" height={20} width={20}>
            <Box sx={{
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '20px',
          height: '20px',
          marginTop: '-10px',
          marginLeft: '-10px',
          borderRadius: '50%',
          border: '3px solid transparent',
          borderTopColor: '#666',
          borderRightColor: '#666',
          borderBottomColor: '#666',
          animation: 'spinner 0.6s linear infinite'
        }} />
          </Box> : <FileIcon height={20} name={name} />}

        <Box px={2} sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }} flexGrow={1} fontWeight="heading" data-sentry-element="Box" data-sentry-source-file="index.tsx">
          {name}
        </Box>
      </Flex>
      <Flex flexShrink={0} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Box px={2} sx={{
        whiteSpace: 'nowrap'
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          {size >= ONE_MEGABYTE ? <>{(size / ONE_MEGABYTE).toFixed(2)} M</> : <>{(size / ONE_KILOBYTE).toFixed(2)} K</>}
        </Box>
        {remove && <DropdownSelect usePortal value={null} items={[{
        label: 'Remove',
        value: 'remove',
        iconElement: <FontAwesomeIcon icon={faTimes} />
      }]} onChange={v => {
        if (v === 'remove') {
          remove();
        }
      }} itemToString={item => item} renderToggle={props => <Box onClick={e => e.stopPropagation()}>
                <Link as="button" type="button" fontWeight="heading" sx={{
          textTransform: 'uppercase'
        }} backgroundColor="gray.1" width="24px" height="24px" display="flex" alignItems={'center'} justifyContent={'center'} borderRadius={1000} {...props}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Link>
              </Box>} />}
      </Flex>
    </Flex>;
};
const Uploads = ({
  schema,
  menu,
  style,
  className,
  isCustom = false
}) => {
  const form = useForm();
  const fileInputRef = React.useRef(null);
  const isPublic = useSelector((state: any) => !!state.public);
  const token = useSelector((state: any) => state.session.token);
  const businessId = useSelector((state: any) => state.public ? state.public.business_id : null);
  const isRecaptchaReady = useIsRecaptchaReady();
  const fieldPrefix = React.useContext(FieldPrefixContext);
  const fieldName = [fieldPrefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  const [uploadsErrorModal, showUploadsErrorModal, closeUploadsErrorModal] = useModal();
  const openUploadsErrorModal = React.useCallback((title, filesNames) => {
    let innerContent = null;
    if (filesNames.length == 1) {
      innerContent = <>The file {filesNames[0]} exceeds the 10M size limit</>;
    } else if (filesNames.length > 1) {
      innerContent = <Grid>
            <Text>The following files exceed the 10M size limit</Text>
            <Text ml={3}>
              {filesNames.map((error, idx) => <li key={idx}>{error}</li>)}
            </Text>
          </Grid>;
    } else {
      innerContent = <>
            You are allowed to upload a maximum of {schema.max_number_uploads}{' '}
            files. If you need to share more, you may need to compress them.
          </>;
    }
    showUploadsErrorModal(title, () => <Callout color="warning">{innerContent}</Callout>);
  }, [schema.max_number_uploads, showUploadsErrorModal]);
  const addFiles = React.useCallback((newFiles, currentFiles) => {
    const tooLargeFilesNames = (currentFiles || []).concat(newFiles).filter(file => file.size > UPLOAD_SIZE_LIMIT_BYTES).map(file => {
      if (file.size >= ONE_MEGABYTE) {
        return `${file.name} [${(file.size / ONE_MEGABYTE).toFixed(2)} M]`;
      } else {
        return `${file.name} [${(file.size / ONE_KILOBYTE).toFixed(2)} K]`;
      }
    });
    if ((tooLargeFilesNames || []).length > 0) {
      openUploadsErrorModal(tooLargeFilesNames.length > 1 ? 'The files you are trying to add are too large ' : 'The file you are trying to add is too large', tooLargeFilesNames);
      return;
    }
    if ((currentFiles?.length || 0) + newFiles.length > schema.max_number_uploads) {
      openUploadsErrorModal('Too many files', []);
      return;
    }
    const newFilesWithId = newFiles.map(file => {
      const id = Math.random().toString(36).substring(7);
      form.mutators.push(fieldName, {
        name: file.name,
        upload_id: 'uploading',
        size: file.size,
        type: file.type,
        id
      });
      return {
        file: file as File,
        id
      };
    });
    newFilesWithId.forEach(async ({
      file,
      id
    }) => {
      if (isPublic && !isRecaptchaReady) {
        return;
      }
      let captchaToken = null;
      if (isPublic) {
        captchaToken = await getRecaptchaToken('PUBLIC_UPLOAD');
      }
      getFileBlobDespiteGoogleDrive(file).then(blob => {
        const formData = new FormData();
        formData.append('file', blob);
        if (captchaToken) {
          formData.append('captcha_token', captchaToken);
        }
        if (businessId) {
          formData.append('business_id', businessId);
        }
        const headers = {
          Accept: 'application/json'
        };
        if (!isPublic) {
          headers['Authorization'] = `Bearer ${token}`;
        }
        anaxios.post(`${settings.api2Root}/${isPublic ? 'form-submission-uploads' : 'uploads'}`, formData, {
          headers
        }).then(({
          data: {
            id: upload_id
          }
        }) => {
          const uploads = get(form.getState().values, fieldName);
          const index = (uploads || []).findIndex(upload => upload['id'] == id);
          form.mutators.update(fieldName, index, {
            name: file.name,
            upload_id,
            size: file.size,
            type: file.type
          });
        });
      });
    });
  }, [businessId, fieldName, form, isPublic, isRecaptchaReady, openUploadsErrorModal, schema.max_number_uploads, token]);
  return <>
      <Field name={fieldName} validate={value => {
      if (schema.required && !value) {
        return 'Add a file';
      }
    }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
        {({
        input,
        meta
      }) => <Box style={style} className={className}>
            <Flex mb={2} justifyContent="space-between">
              <Label sx={{
            flexGrow: 1
          }}>
                {schema.label}
              </Label>
              {menu}
            </Flex>
            <Box display="grid" style={{
          gap: '8px'
        }}>
              <Text>
                Upload{' '}
                {schema.max_number_uploads == 1 ? 'one file. Max 10MB' : `up to ${schema.max_number_uploads} files. Max 10MB per file.`}
              </Text>
              {(input.value || []).length > 0 ? <Flex mt={2} flexBasis="50%" sx={{
            overflowX: 'hidden',
            'div:hover > &': {
              overflowX: 'overlay'
            }
          }}>
                  {input.value.map((v, idx) => <FormUploadPill key={idx} file={v} remove={() => form.mutators.remove(fieldName, idx)} />)}
                </Flex> : null}
              {(input.value || []).length < schema.max_number_uploads && <Button width="fit-content" color="secondary" variant="outlined" iconLeft={faUpload} onClick={() => {
            fileInputRef.current['click']();
          }}>
                  Add file
                  <Box disabled={!isRecaptchaReady} as="input" ref={fileInputRef} sx={{
              display: 'none !important'
            }} type="file" name="file" multiple value={''} onChange={(e: any) => addFiles(Array.from(e.target.files), input.values)} />
                </Button>}
            </Box>
            {meta.error && meta.touched && <FormError>{meta.error}</FormError>}

            {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
          </Box>}
      </Field>
      {uploadsErrorModal}
    </>;
};
export default Uploads;
export const toString = value => {
  return (Array.isArray(value) ? [value.map(v => v.name)] : []).join(', ');
};
export const View = ({
  value,
  schema
}) => {
  return <Flex mt={2} flexBasis="50%" sx={{
    overflowX: 'hidden',
    'div:hover > &': {
      overflowX: 'overlay'
    }
  }} data-sentry-element="Flex" data-sentry-component="View" data-sentry-source-file="index.tsx">
      {value.map((v, idx) => <FormUploadPill key={idx} file={v} />)}
    </Flex>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style,
  disabled
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  const maxUploadsItems = [{
    label: '1',
    value: 1
  }, {
    label: '5',
    value: 5
  }, {
    label: '10',
    value: 10
  }];
  return <>
      {!isEditing ? <Form onSubmit={value => null} mutators={arrayMutators as any}>
          {({
        form
      }) => <Uploads schema={value} menu={menu} style={style} className={className} />}
        </Form> : <Grid display={!isEditing ? 'none' : undefined}>
          <LabelField prefix={fieldPrefix} />
          <Field name={fieldPrefix ? `${fieldPrefix}.max_number_uploads` : 'max_number_uploads'}>
            {({
          input
        }) => <Flex alignItems="center" maxHeight="25px">
                <DropdownSelect {...input} renderToggle={(props, {
            isOpen,
            displayString
          }) => <Button {...props} color="secondary">
                      <Text>{displayString}</Text>
                    </Button>} items={maxUploadsItems} itemToString={v => maxUploadsItems.find(i => i.value === v)?.label} />
                <Box ml={2}>Maximum number of files</Box>
              </Flex>}
          </Field>
          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button onClick={toggleMore} size="small" width="100%" variant="outlined">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};