import { Theme, ThemeContext } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { Box, BoxProps, Flex } from 'shared/grid';
import Portal from 'shared/portal';
import { CroppedText } from 'shared/typography';
import { arrow, autoUpdate, flip, FloatingFocusManager, offset, Placement, shift, useClick, useDismiss, useFloating, useId, useInteractions, useListNavigation, useRole, useFocus } from '@floating-ui/react';
import { faToggleLargeOn } from '@fortawesome/pro-duotone-svg-icons';
import { faToggleOff } from '@fortawesome/pro-regular-svg-icons';
import { mergeRefs } from 'react-merge-refs';
import { ButtonProps } from 'shared/button/component';
import { Arrow2 } from 'shared/popper-arrow';
import { Text } from 'shared/typography';
export interface Dropdown2ItemType {
  props: any;
  type?: any;
  offScreen?: boolean;
  noClose?: boolean;
}
export interface Dropdown2Props {
  items: Dropdown2ItemType[];
  usePortal?: boolean;
  renderToggle: (props: Record<string, any>) => React.JSX.Element;
}
export function Dropdown2({
  ref,
  items,
  renderToggle,
  usePortal = true,
  ...rest
}: Dropdown2Props & ButtonProps) {
  const theme = React.useContext(ThemeContext) as Theme;
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  const buttonId = useId();
  const listboxId = useId();
  const MaybePortal = usePortal ? Portal : React.Fragment;
  const arrowRef = React.useRef(null);
  const {
    floatingStyles,
    refs,
    update,
    context,
    placement,
    middlewareData: {
      arrow: {
        x: arrowX,
        y: arrowY
      } = {}
    }
  } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(5), flip(), shift(), arrow({
      element: arrowRef
    })]
    // placement: placement_,
    // strategy: strategy_,
  });
  React.useLayoutEffect(() => {
    // IMPORTANT: When the floating element first opens, this effect runs when
    // the styles have **not yet** been applied to the element. A rAF ensures
    // we wait until the position is ready, and also runs before paint.
    // https://floating-ui.com/docs/react-dom#effects
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        listRef.current[activeIndex]?.scrollIntoView({
          block: 'nearest'
        });
      }
    });
  }, [activeIndex]);

  // Handles opening the floating element via the Choose Emoji button.
  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([useClick(context), useRole(context, {
    role: 'menu'
  }), useDismiss(context), useListNavigation(context, {
    listRef,
    onNavigate: isOpen ? setActiveIndex : undefined,
    activeIndex,
    focusItemOnOpen: false,
    virtual: true,
    loop: true,
    allowEscape: true
  })]);
  const {
    onClick: _ignore1,
    type: _ignore2,
    id,
    ...buttonRest
  } = rest;
  const listWrapperRef = React.useRef(null);
  const ref_ = React.useMemo(() => mergeRefs([refs.reference, ref]), [ref, refs.reference]);
  return <>
      {renderToggle(getReferenceProps({
      ref: refs.setReference,
      id: buttonId,
      'aria-label': 'Choose'
    }))}

      <MaybePortal data-sentry-element="MaybePortal" data-sentry-source-file="index.tsx">
        {isOpen && <FloatingFocusManager context={context} initialFocus={0}>
            <Flex flexDirection="column" boxShadow="medium" borderRadius={2} color="text" maxHeight="50vh" sx={{
          border: `1px solid rgba(27, 31, 35, 0.15)`,
          backgroundColor: 'background',
          outline: 0
        }} aria-labelledby={buttonId} {...getFloatingProps({
          ref: refs.setFloating,
          style: floatingStyles,
          onKeyDown(event) {
            if (event.key === 'Tab') {
              setIsOpen(false);
            }
          },
          onKeyDownCapture(event) {
            if (event.key === 'Enter' || event.key === ' ') {
              event.stopPropagation();
              event.preventDefault();
              const el = listWrapperRef.current.querySelector(`.dropdown-item:nth-child(${activeIndex + 1})`);
              el?.click?.();
            }
          }
        })} zIndex={6002} // Higher than modal
        >
              <Box overflow="auto" role="listbox" id={listboxId} ref={listWrapperRef}>
                {items.map(({
              type,
              props: {
                iconLeft,
                children,
                onClick,
                color,
                label,
                onChange,
                value,
                ...props
              },
              noClose
            }, index) => {
              let onClick_ = onClick;
              if (onChange) {
                onClick_ = () => {
                  onChange(!value);
                  // setIsOpen(false);
                };
              } else {
                onClick_ = e => {
                  onClick?.(e);
                  if (!noClose) {
                    setIsOpen(false);
                  }
                };
              }
              return <Box key={index} as="button" type="button" display="block" width="100%" ref={node => {
                listRef.current[index] = node;
              }} {...getItemProps({
                ...props,
                onClick: onClick_
              })} color="text" px={3} py="12px" sx={{
                backgroundColor: activeIndex === index ? 'gray.0' : 'transparent',
                fontFamily: 'body',
                cursor: 'pointer'
              }} className="dropdown-item">
                        <Flex alignItems="center" style={{
                  // @ts-ignore
                  '--fa-primary-color': theme.colors.primary,
                  '--fa-secondary-color': theme.colors.primary
                }}>
                          {iconLeft && <FontAwesomeIcon icon={iconLeft} />}

                          {type === 'switch' && <FontAwesomeIcon icon={value ? faToggleLargeOn : faToggleOff} />}
                          <Text sx={{
                    wordBreak: 'normal'
                  }} ml={iconLeft || type === 'switch' ? 3 : 0}>
                            {children || label}
                          </Text>
                        </Flex>
                      </Box>;
            })}
              </Box>
              <Arrow2 ref={arrowRef} placement={placement} styles={{
            left: `${arrowX}px`,
            top: `${arrowY}px`
          }} borderWidth={1} arrowSize={8} />
            </Flex>
          </FloatingFocusManager>}
      </MaybePortal>
    </>;
}
interface DropdownProps {
  isOpen?: boolean;
  close?: (any) => void;
  open?: (any) => void;
  children: ReactNode; //React.JSX.Element[] | React.JSX.Element;
  toggleClass?: string;
  toggleStyle?: React.CSSProperties;
  popperClass?: string;
  placement?: Placement;
  offset?: string | number;
  autoClose?: boolean;
  toggle: (props: any, isOpen: boolean, close: (any) => void) => React.JSX.Element;
  flip?: boolean;
  usePortal?: boolean;
  strategy?: string;
  borderWidth?: number;
  arrowSize?: number;
  closeOnBlur?: boolean;
  closeOnClick?: boolean;
}
type DropDownContextType = {
  registerItem: (ref?: React.Ref<HTMLButtonElement>) => void;
};
const DropDownContext = React.createContext<DropDownContextType | null>(null);
export const DropDownItem = ({
  children,
  icon,
  sx,
  ...rest
}: {
  children: any;
  icon?: any;
  onClick?: any;
  href?: any;
} & BoxProps) => {
  // const ref = React.useRef<HTMLButtonElement>(null);

  // const dropDownContext = React.useContext(DropDownContext);

  // if (dropDownContext === null) {
  //   throw new Error('DropDownItem must be used within a DropDown');
  // }

  // const { registerItem } = dropDownContext;

  // React.useEffect(() => {
  //   if (ref && ref.current) {
  //     registerItem(ref);
  //   }
  // }, [ref, registerItem]);
  return <Flex as="button" type="button" px={3} py={2} alignItems="center" width="100%" sx={{
    ...sx,
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: 'gray.2'
    }
  }} {...rest as any} data-sentry-element="Flex" data-sentry-component="DropDownItem" data-sentry-source-file="index.tsx">
      {icon && <Box mr={3}>
          <FontAwesomeIcon fixedWidth icon={icon} />
        </Box>}
      <CroppedText data-sentry-element="CroppedText" data-sentry-source-file="index.tsx">{children}</CroppedText>
    </Flex>;
};
function DropDownItems({
  children,
  // dropDownRef,
  onClose
}: {
  children: React.ReactNode;
  // dropDownRef: React.Ref<HTMLDivElement>;
  onClose: (any) => void;
}) {
  const [items, setItems] = React.useState<React.RefObject<HTMLButtonElement>[]>();
  const [highlightedItem, setHighlightedItem] = React.useState<React.RefObject<HTMLButtonElement>>();
  const registerItem = React.useCallback((itemRef?: React.RefObject<HTMLButtonElement>) => {
    setItems(prev => prev ? [...prev, itemRef] : [itemRef]);
  }, [setItems]);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!items) {
      return;
    }
    const key = event.key;
    if (['Escape', 'ArrowUp', 'ArrowDown', 'Tab'].includes(key)) {
      event.preventDefault();
    }
    if (key === 'Escape' || key === 'Tab') {
      // onClose();
    } else if (key === 'ArrowUp') {
      setHighlightedItem(prev => {
        if (!prev) {
          return items[0];
        }
        const index = items.indexOf(prev) - 1;
        return items[index === -1 ? items.length - 1 : index];
      });
    } else if (key === 'ArrowDown') {
      setHighlightedItem(prev => {
        if (!prev) {
          return items[0];
        }
        return items[items.indexOf(prev) + 1];
      });
    }
  };
  const contextValue = React.useMemo(() => ({
    registerItem
  }), [registerItem]);
  React.useEffect(() => {
    if (items && !highlightedItem) {
      setHighlightedItem(items[0]);
    }
    if (highlightedItem && highlightedItem.current) {
      highlightedItem.current.focus();
    }
  }, [items, highlightedItem]);
  return <DropDownContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="DropDownItems" data-sentry-source-file="index.tsx">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
    // className="dropdown"
    // ref={dropDownRef}
    onKeyDown={handleKeyDown}>
        {children}
      </div>
    </DropDownContext.Provider>;
}
const Dropdown = function Dropdown({
  ref,
  isOpen: isOpenProp,
  close: closeProp,
  open: openProp,
  children,
  popperClass = '',
  placement: placement_ = 'bottom',
  autoClose = true,
  toggle: renderToggle,
  usePortal = false,
  borderWidth = 1,
  arrowSize = 8,
  closeOnBlur = true,
  closeOnClick = false
}: DropdownProps & {
  ref?: React.Ref<unknown>;
}) {
  const [isOpenLocal, setIsOpenLocal] = React.useState(false);
  const isOpen = isOpenProp ?? isOpenLocal;
  const open = React.useCallback(e => {
    e.stopPropagation();
    if (openProp) {
      openProp(e);
    } else {
      setIsOpenLocal(true);
    }
  }, [openProp]);
  const close = React.useCallback(e => {
    e.stopPropagation();
    if (closeProp) {
      closeProp(e);
    } else {
      setIsOpenLocal(false);
    }
  }, [closeProp]);
  const setOpen = React.useCallback(isOpen => {
    if (isOpen) {
      if (openProp) {
        openProp(null);
      } else {
        setIsOpenLocal(true);
      }
    } else {
      if (closeProp) {
        closeProp(null);
      } else {
        setIsOpenLocal(false);
      }
    }
  }, [closeProp, openProp]);
  const toggle = React.useCallback(e => isOpen ? close(e) : open(e), [close, isOpen, open]);
  const onKeyPress = React.useCallback(e => e.key === 'Enter' && toggle(e), [toggle]);
  const arrowRef = React.useRef(null);
  const {
    x,
    y,
    floatingStyles,
    strategy,
    refs,
    update,
    context,
    placement,
    middlewareData: {
      arrow: {
        x: arrowX,
        y: arrowY
      } = {}
    }
  } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift(), arrow({
      element: arrowRef
    })],
    placement: placement_
  });
  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;
  const {
    getReferenceProps,
    getFloatingProps
  } = useInteractions([
  // useHover(context, {
  //   mouseOnly: true,
  //   handleClose: safePolygon({
  //     restMs: 50,
  //   }),
  // }),
  // useFocus(context),
  useClick(context), useRole(context, {
    role: 'menu'
  }), useDismiss(context)]);
  const Wrapper = usePortal ? Portal : React.Fragment;
  return <>
      {renderToggle(getReferenceProps({
      ref: refs.setReference
    }), isOpen, close)}

      <Wrapper>
        {isOpen && <>
            <FloatingFocusManager context={context} modal={false} order={['reference', 'content']} returnFocus={true} closeOnFocusOut={closeOnBlur} initialFocus={-1}>
              <Box {...getFloatingProps({
            className: popperClass,
            ref: refs.setFloating,
            style: floatingStyles,
            'aria-labelledby': labelId,
            'aria-describedby': descriptionId,
            onClick: closeOnClick ? close : undefined
          })} bg="background" zIndex={6002} borderRadius={2} boxShadow="popup" maxWidth="calc(100vw - 16px)" sx={{
            zIndex: 6008,
            border: `${borderWidth}px solid rgba(27, 31, 35, 0.15)`,
            backgroundColor: 'background',
            color: 'text',
            width: 'auto',
            borderRadius: 2,
            boxShadow: 'medium'
          }}>
                {children}
                {/* <DropDownItems onClose={close}>{children}</DropDownItems> */}
                <Arrow2 ref={arrowRef} placement={placement || placement_} styles={{
              left: `${arrowX}px`,
              top: `${arrowY}px`
            }} borderWidth={1} arrowSize={8} />
              </Box>
            </FloatingFocusManager>
          </>}
      </Wrapper>
    </>;
};
export const DropdownMenu = ({
  maxHeight = 'calc(50vh - 50px)',
  ...props
}: DropdownProps & {
  maxHeight?: string;
}) => <Dropdown {...props} data-sentry-element="Dropdown" data-sentry-component="DropdownMenu" data-sentry-source-file="index.tsx">
    <Box as="ul" sx={{
    flexWrap: 'nowrap',
    flexDirection: 'column',
    margin: 0,
    listStyle: 'none',
    position: 'relative',
    display: 'flex',
    lineHeight: '1.6',
    maxHeight,
    overflowY: 'auto',
    minWidth: '200px',
    overflowX: 'hidden',
    li: {
      'a, button': {
        cursor: 'pointer',
        textAlign: 'left',
        width: '100%',
        lineHeight: 1,
        textDecoration: 'none',
        display: 'block',
        padding: '16px 12px 16px 10px',
        color: 'text',
        fontSize: 'regular',
        fontWeight: 'body',
        '&:hover': {
          backgroundColor: 'gray.1'
        },
        svg: {
          mr: 2,
          ml: 1
        }
      }
    },
    input: {
      display: 'inline-block'
    }
  }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
      {props.children}
    </Box>
  </Dropdown>;
export default Dropdown;