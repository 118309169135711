export const SERVICE_FETCH_REQUESTED = 'SERVICE_FETCH_REQUESTED';
export const SERVICE_FETCH_SUCCEEDED = 'SERVICE_FETCH_SUCCEEDED';
export const SERVICE_FETCH_FAILED = 'SERVICE_FETCH_FAILED';

export const SERVICE_SAVE_REQUESTED = 'SERVICE_SAVE_REQUESTED';
export const SERVICE_SAVE_SUCCEEDED = 'SERVICE_SAVE_SUCCEEDED';
export const SERVICE_SAVE_FAILED = 'SERVICE_SAVE_FAILED';

export const SERVICE_DELETE_REQUESTED = 'SERVICE_DELETE_REQUESTED';
export const SERVICE_DELETE_SUCCEEDED = 'SERVICE_DELETE_SUCCEEDED';
export const SERVICE_DELETE_FAILED = 'SERVICE_DELETE_FAILED';

export const SERVICE_UPDATED = 'SERVICE_UPDATED';
