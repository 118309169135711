import { FSA } from 'flux-standard-action';
import { Booking } from '../../utils/time';

import {
  BOOKING_FETCH_REQUESTED,
  BOOKING_FETCH_SUCCEEDED,
  BOOKING_FETCH_FAILED,
  BOOKING_SAVE_REQUESTED,
  BOOKING_SAVE_SUCCEEDED,
  BOOKING_SAVE_FAILED,
  BOOKING_PATCH_REQUEST,
  BOOKING_PATCH_SUCCEEDED,
  BOOKING_PATCH_FAILED,
} from './action-types';

const initialState: {
  status: string;
  data: { [s: string]: Booking };
} = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case BOOKING_FETCH_REQUESTED:
      return {
        ...state,
        status: 'loading',
      };
    case BOOKING_FETCH_SUCCEEDED: {
      let data = action.payload;

      if (__USE_FAKE_DATA__) {
        data = Object.values<any>(action.payload).reduce((agg, cur) => {
          agg[cur.id] = {
            ...cur,
            ...cur.services.map((v) => ({ ...v, service_name: 'Adhoc' })),
            notes: '',
          };
          return agg;
        }, {});
      }

      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case BOOKING_FETCH_FAILED:
      return {
        ...state,
        status: 'failed',
      };
    case BOOKING_PATCH_REQUEST:
    case BOOKING_SAVE_REQUESTED:
      return {
        ...state,
        status: 'saving',
      };
    case BOOKING_PATCH_SUCCEEDED:
    case BOOKING_SAVE_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case BOOKING_PATCH_FAILED:
    case BOOKING_SAVE_FAILED:
      return {
        ...state,
        status: 'failed',
      };
    default:
      return state;
  }
}
