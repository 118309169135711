import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import { LabeledCheckbox } from '../../checkbox';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import { ClientFieldReference, DataSourceField, useOptionItems, FieldPrefixContext, LabelField, NameField, PrivateField, RequiredField } from '../shared';
const Checkboxes = ({
  schema,
  disabled,
  menu,
  style,
  className,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const [items] = useOptionItems(schema);
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  return <Box style={style} className={className} data-sentry-element="Box" data-sentry-component="Checkboxes" data-sentry-source-file="index.tsx">
      <Field name={name} validate={value => {
      if (schema.required && (!value || !value.length)) {
        return 'Select an option';
      }
    }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
        {({
        input,
        meta
      }) => <Box mb={2}>
            <Flex justifyContent="space-between">
              <Label sx={{
            flexGrow: 1
          }}>
                {schema.label}
              </Label>
              {menu}
            </Flex>
          </Box>}
      </Field>

      {items.filter((_, idx) => idx < 100) // Prevent bad things from happening
    .map(({
      label,
      value
    }, idx) => <Box mb={2} key={idx}>
            <Field type="checkbox" name={name} value={value} tabIndex={disabled ? -1 : undefined}>
              {({
          input
        }) => <LabeledCheckbox label={label} {...input} />}
            </Field>
          </Box>)}

      <Field name={name} validate={value => {
      if (schema.required && (!value || !value.length)) {
        return 'Select an option';
      }
    }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
        {({
        input,
        meta
      }) => <Box>
            {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
            {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
          </Box>}
      </Field>
    </Box>;
};
export default Checkboxes;
export const toString = value => {
  return value ? value.join(', ') : '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{value ? value.join(', ') : ''}</Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Form onSubmit={value => null}>
          {({
        form
      }) => <Checkboxes schema={value} disabled={true} menu={menu} style={style} className={className} />}
        </Form> : <Grid sx={{
      display: !isEditing ? 'none' : null
    }}>
          <LabelField prefix={fieldPrefix} />
          <DataSourceField prefix={fieldPrefix} />

          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button onClick={toggleMore} variant="outlined" size="small" width="100%">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};