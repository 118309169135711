import { FSA } from 'flux-standard-action';
import {
  INVOICE_SETTINGS_FETCH_FAILED,
  INVOICE_SETTINGS_FETCH_REQUESTED,
  INVOICE_SETTINGS_FETCH_SUCCEEDED,
  INVOICE_SETTINGS_PATCH_FAILED,
  INVOICE_SETTINGS_PATCH_REQUESTED,
  INVOICE_SETTINGS_PATCH_SUCCEEDED,
  INVOICE_SETTINGS_SAVE_FAILED,
  INVOICE_SETTINGS_SAVE_REQUESTED,
  INVOICE_SETTINGS_SAVE_SUCCEEDED,
} from './action-types';

type state = { status: string; data: { [key: string]: any } };

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case INVOICE_SETTINGS_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case INVOICE_SETTINGS_FETCH_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          [action.payload.business_id]: action.payload,
        },
      };
    }
    case INVOICE_SETTINGS_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case INVOICE_SETTINGS_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case INVOICE_SETTINGS_SAVE_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          [action.payload.business_id]: action.payload,
        },
      };
    }
    case INVOICE_SETTINGS_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case INVOICE_SETTINGS_PATCH_REQUESTED:
      return { ...state, status: 'saving' };
    case INVOICE_SETTINGS_PATCH_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          [action.payload.business_id]: action.payload,
        },
      };
    }
    case INVOICE_SETTINGS_PATCH_FAILED:
      return { ...state, status: 'failed' };

    default:
      return state;
  }
}
