import { FSA } from 'flux-standard-action';
import {
  SCHEDULES_FETCH_REQUESTED,
  SCHEDULES_FETCH_SUCCEEDED,
  SCHEDULES_FETCH_FAILED,
  SCHEDULES_DELETE_REQUESTED,
  SCHEDULES_DELETE_SUCCEEDED,
  SCHEDULES_DELETE_FAILED,
  SCHEDULES_SAVE_REQUESTED,
  SCHEDULES_SAVE_SUCCEEDED,
  SCHEDULES_SAVE_FAILED,
} from './action-types';

type state = {
  status: string;
  data: { [key: string]: any };
};

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case SCHEDULES_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case SCHEDULES_FETCH_SUCCEEDED: {
      let data = action.payload;

      if (__USE_FAKE_DATA__) {
        data = Object.values<any>(action.payload).reduce((agg, cur) => {
          agg[cur.id] = {
            ...cur,
            description: '',
          };
          return agg;
        }, {});
      }

      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case SCHEDULES_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case SCHEDULES_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case SCHEDULES_SAVE_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case SCHEDULES_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case SCHEDULES_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case SCHEDULES_DELETE_SUCCEEDED: {
      const { [action.payload]: _, ...nonDeleted } = state.data;

      return {
        ...state,
        status: 'done',
        data: nonDeleted,
      };
    }
    case SCHEDULES_DELETE_FAILED:
      return { ...state, status: 'failed' };

    default:
      return state;
  }
}
