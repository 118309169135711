/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import Loader from 'shared/loader';
// import SlateRichText from 'shared/slate-rich-text';
import RenderSlate from 'shared/slate-shared/render';
import FormError from '../../form-error';
import { Box, Flex, Grid } from '../../grid';
import Switch from '../../switch';
import { EMPTY_OBJECT } from 'shared/utils/constants';
const SlateRichText = React.lazy(() => import('shared/slate-rich-text'));
const EMPTY_BODY = [{
  type: 'paragraph',
  children: [{
    text: ''
  }]
}];
const Text = ({
  schema,
  menu,
  variables = EMPTY_OBJECT
}) => {
  return <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-component="Text" data-sentry-source-file="index.tsx">
      <Box flex="1" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <RenderSlate value={schema.value} variables={variables} data-sentry-element="RenderSlate" data-sentry-source-file="index.tsx" />
      </Box>
      {menu}
    </Flex>;
};
export default Text;
export const toString = value => {
  return '';
};
export const View = ({
  schema,
  variables = EMPTY_OBJECT
}) => {
  return <RenderSlate value={schema.value} variables={variables} data-sentry-element="RenderSlate" data-sentry-component="View" data-sentry-source-file="index.tsx" />;
};
export const Edit = ({
  isEditing,
  value,
  menu
}) => {
  const fieldPrefix = '';
  const clientSchema = useSelector((state: any) => state.businesses.data[state.userPreferences.currentBusinessId]?.client_schema || []);
  const clientVariables = React.useMemo(() => (clientSchema.filter(v => !['location_ids', 'dont_send_notifications'].includes(v.name)).map(({
    name,
    label
  }) => ({
    value: `client_${name}`,
    label
  })) || []).concat({
    label: 'Business name',
    value: 'business_name'
  }), [clientSchema]);
  return <>
      {!isEditing ? <Text schema={value} menu={menu} /> : <Grid sx={{
      display: !isEditing ? 'none;' : undefined
    }}>
          <Field name={fieldPrefix ? `${fieldPrefix}.value` : 'value'} validate={value => {
        if (!value) {
          return 'Enter some text';
        }
      }}>
            {({
          input,
          meta
        }) => <Box>
                <React.Suspense fallback={<Loader />}>
                  <Box className="text-editor">
                    <SlateRichText value={input.value || EMPTY_BODY} onChange={input.onChange} variables={clientVariables} />
                  </Box>
                </React.Suspense>

                {meta.error && meta.touched && <FormError>{meta.error}</FormError>}
              </Box>}
          </Field>

          <Field name="show_on_print">
            {({
          input: pInput
        }) => <>
                <Flex alignItems="center">
                  <Switch size="small" {...pInput} />
                  <Box ml={2}>Show when printing</Box>
                </Flex>

                {pInput.value && <Field name="has_page_break">
                    {({
              input
            }) => <Flex alignItems="center">
                        <Switch size="small" {...input} />
                        <Box ml={2}>Break page before</Box>
                      </Flex>}
                  </Field>}
              </>}
          </Field>
        </Grid>}
    </>;
};