import { Theme, ThemeContext } from '@emotion/react';
import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { Box, BoxProps } from '../grid';
export function Stamp({
  ref,
  color: color_,
  ...props
}: BoxProps | {
  color?: string;
  ref?: React.Ref<HTMLDivElement>;
}) {
  const theme = React.useContext<Theme>(ThemeContext as any);
  const color = (color_ || 'primary').split('.')[0];
  const colorScaleName = {
    success: 'green',
    warning: 'yellow',
    alert: 'red',
    primary: 'primary_'
  }[color] || color;
  const isDark = theme.mode === 'dark';
  let fontColor;
  let borderColor;
  let backgroundColor;
  if (!isDark) {
    fontColor = themeGet(`colors.${colorScaleName}.9`, colorScaleName)({
      theme
    });
    borderColor = themeGet(`colors.${colorScaleName}.1`, colorScaleName)({
      theme
    });
    backgroundColor = themeGet(`colors.${colorScaleName}.0`, colorScaleName)({
      theme
    });
  } else {
    // fontColor = themeGet(
    //   `colors.${colorScaleName}.1`,
    //   colorScaleName
    // )({ theme });

    // borderColor = themeGet(
    //   `colors.${colorScaleName}.9`,
    //   colorScaleName
    // )({ theme });

    // backgroundColor = themeGet(
    //   `colors.${colorScaleName}.10`,
    //   colorScaleName
    // )({ theme });

    fontColor = themeGet(`colors.${colorScaleName}.8`, colorScaleName)({
      theme
    });

    // borderColor = themeGet(
    //   `colors.${colorScaleName}.8`,
    //   colorScaleName
    // )({ theme });

    borderColor = 'transparent';
    backgroundColor = 'gray.2';
  }
  return <Box ref={ref} __css={{
    padding: '4px 8px',
    borderRadius: 2,
    display: 'inline-block',
    textAlign: 'center',
    fontSize: 1,
    fontWeight: 'heading',
    whiteSpace: 'nowrap',
    backgroundColor: backgroundColor,
    color: fontColor,
    borderColor: borderColor,
    borderWidth: 1,
    borderStyle: 'solid',
    overflow: 'hidden'
  }} {...props} data-sentry-element="Box" data-sentry-component="Stamp" data-sentry-source-file="index.tsx" />;
}
export default Stamp;