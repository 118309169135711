export const FORM_FETCH_REQUESTED = 'FORM_FETCH_REQUESTED';
export const FORM_FETCH_SUCCEEDED = 'FORM_FETCH_SUCCEEDED';
export const FORM_FETCH_FAILED = 'FORM_FETCH_FAILED';

export const FORM_SAVE_REQUESTED = 'FORM_SAVE_REQUESTED';
export const FORM_SAVE_SUCCEEDED = 'FORM_SAVE_SUCCEEDED';
export const FORM_SAVE_FAILED = 'FORM_SAVE_FAILED';

export const FORM_DELETE_REQUESTED = 'FORM_DELETE_REQUESTED';
export const FORM_DELETE_SUCCEEDED = 'FORM_DELETE_SUCCEEDED';
export const FORM_DELETE_FAILED = 'FORM_DELETE_FAILED';

export const FORM_UPDATED = 'FORM_UPDATED';
