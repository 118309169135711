export const STAFF_FETCH_REQUESTED = 'STAFF_FETCH_REQUESTED';
export const STAFF_FETCH_SUCCEEDED = 'STAFF_FETCH_SUCCEEDED';
export const STAFF_FETCH_FAILED = 'STAFF_FETCH_FAILED';

export const STAFF_SAVE_REQUESTED = 'STAFF_SAVE_REQUESTED';
export const STAFF_SAVE_SUCCEEDED = 'STAFF_SAVE_SUCCEEDED';
export const STAFF_SAVE_FAILED = 'STAFF_SAVE_FAILED';

export const STAFF_DELETE_REQUESTED = 'STAFF_DELETE_REQUESTED';
export const STAFF_DELETE_SUCCEEDED = 'STAFF_DELETE_SUCCEEDED';
export const STAFF_DELETE_FAILED = 'STAFF_DELETE_FAILED';
