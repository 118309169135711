import axios from 'shared/anaxios';
import { FORM_ERROR } from 'final-form';
import { DateTime } from 'luxon';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Flex } from 'shared/grid';
import Label from 'shared/label';
import Loader from 'shared/loader';
import { StandardModal } from 'shared/modal';
import { fetchBooking, patchBooking } from 'shared/redux/bookings/actions';
import { LOGIN_SUCCEEDED } from 'shared/redux/session/action-types';
import settings from 'shared/settings';
import Textarea from 'shared/textarea';
import { addToast } from 'shared/toaster/redux/actions';
import { getScrollToErrors } from 'shared/utils/form';
import { Booking } from 'shared/utils/time';
import { useAppDispatch, useAppSelector } from '../../store';
import PayView from './pay';
import DefaultView from './view';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
const BookingView = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const {
    bookingId
  } = useParams();
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  const isOwner = useAppSelector(state => (state.session.bookingIds || []).includes(bookingId));
  const booking: Booking = useAppSelector(state => state.bookings.data[bookingId]);
  const canCancel = isOwner && booking && booking.services.every(s => s.start > DateTime.utc().toISO().substring(0, 19) + 'Z') && ['confirmed', 'requested', 'pencilled_in', 'awaiting_payment'].includes(booking.status) && !business.settings.disable_changes;
  const bookingLocation = useAppSelector(state => state.locations.data[booking?.location_id]);
  const location = React.useMemo(() => bookingLocation || {
    timezone: DateTime.local().zoneName,
    address: ''
  }, [bookingLocation]);
  const code = searchParams.get('c');
  const isLoading = useAppSelector(state => [state.businesses.status, state.locations.status, state.services.status, state.bookables.status, state.bookableGroups.status, state.bookableBusy.status, state.bookings.status].map(v => v || 'loading').includes('loading') || !state.public.businessId);
  React.useEffect(() => {
    if (!booking && bookingId) {
      dispatch(fetchBooking(bookingId));
    }
  }, [booking, bookingId, dispatch]);
  React.useEffect(() => {
    if (code && code !== 'xxxx' && booking) {
      axios.post(`${settings.api2Root}/token-booking`, {
        booking_id: bookingId,
        code
      }, {
        skipErrorReportOnCode: {
          '401': true
        }
      }).then(({
        data
      }) => {
        if (!data) {
          return;
        }
        dispatch({
          type: LOGIN_SUCCEEDED,
          payload: data
        });
      });
    }
  }, [booking, bookingId, code, dispatch]);
  const ensureLocationIdIsSet = React.useCallback(lid => {
    setSearchParams(prev => ({
      ...Object.fromEntries(prev.entries()),
      lid: lid || undefined
    }), {
      replace: true
    });
  }, [setSearchParams]);
  React.useEffect(() => {
    if (booking) {
      ensureLocationIdIsSet(booking.location_id);
    }
  }, [booking, ensureLocationIdIsSet]);
  const [wasLoaded, setWasLoaded] = React.useState(false);
  React.useEffect(() => {
    if (!isLoading) {
      setWasLoaded(true);
    }
  }, [isLoading]);
  const decorators = React.useMemo(() => [getScrollToErrors()], []);
  const cancelBooking = (bookingId, reason) => {
    return dispatch(patchBooking({
      id: booking.id,
      status: 'cancelled',
      reason
    })).then(() => {
      dispatch(addToast('Booking cancelled', 'success'));
      try {
        const event = {
          event: 'booking_cancelled',
          booking_id: booking.id
        };
        if (window['dataLayer']) {
          window['dataLayer'].push(event);
        } else if (window['gtag']) {
          window['gtag']('event', 'booking_cancelled');
        } else if (window['ga']) {
          window['ga']('send', 'event', 'booking', 'cancelled');
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    });
  };
  if (isLoading && !wasLoaded) {
    return <Loader />;
  }
  if (!booking) {
    return <EmptyState heading="Booking not found" />;
  }
  return <>
      {booking?.status === 'awaiting_payment' ? <PayView booking={booking} location={location} canCancel={canCancel} isOwner={isOwner} setShowCancelModal={setShowCancelModal} /> : <DefaultView booking={booking} location={location} canCancel={canCancel} isOwner={isOwner} setShowCancelModal={setShowCancelModal} showCancelModal={showCancelModal} />}

      <StandardModal isOpen={showCancelModal} close={() => setShowCancelModal(false)} title={<FormattedMessage id="Public.BookingPage.cancelConfirmModalHeading" defaultMessage="Are you sure you want to cancel?" />} data-sentry-element="StandardModal" data-sentry-source-file="component.tsx">
        {() => <Form decorators={decorators} onSubmit={values => {
        return cancelBooking(bookingId, values['reason']).then(() => {
          setShowCancelModal(false);
        }).catch(error => {
          if (error?.response?.status >= 500 || !error?.response?.data?.errors) {
            return;
          }
          return {
            [FORM_ERROR]: 'Something went wrong'
          };
        });
      }}>
            {({
          handleSubmit,
          submitting,
          values
        }) => {
          return <form onSubmit={handleSubmit} noValidate>
                  <Field name="reason">
                    {({
                input
              }) => <>
                        <Label mb={2}>
                          <FormattedMessage id="Public.BookingPage.cancelConfirmModalReasonInputLabel" defaultMessage="Reason for cancelling" />
                        </Label>
                        <Textarea {...input} />
                      </>}
                  </Field>

                  <Flex justifyContent="flex-end" mt={3}>
                    <Button color="gray" variant="flat" mr={2} onClick={() => setShowCancelModal(false)}>
                      <FormattedMessage id="Back" defaultMessage={`Back`} />
                    </Button>

                    <Button type="submit" disabled={!values['reason'] || submitting} loading={submitting}>
                      <FormattedMessage id="Cancel booking" defaultMessage="Cancel booking" />
                    </Button>
                  </Flex>
                </form>;
        }}
          </Form>}
      </StandardModal>
    </>;
};
export default BookingView;