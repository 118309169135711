import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { isUUID } from 'shared/utils/uuid';
import { toBase16 } from 'shared/utils/b58';
export const useLocationId = (): [string, (id: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId_ = searchParams.get('lid');
  const locationId = React.useMemo(() => {
    let locationId = locationId_;
    if (locationId && !isUUID(locationId)) {
      if (locationId == 'undefined') {
        locationId = null;
      } else if (locationId.length > 1 && locationId.length < 32) {
        locationId = toBase16(locationId);
      } else if (locationId.length !== 32) {
        // Not uuid
        locationId = null;
      }
    }
    return locationId;
  }, [locationId_]);
  const setLocation = React.useCallback(lid => {
    if (locationId == lid) {
      return;
    }
    setSearchParams(prev => ({
      ...Object.fromEntries(prev.entries()),
      lid: lid || ''
    }), {
      replace: true
    });
  }, [locationId, setSearchParams]);
  return [locationId, setLocation];
};
export const useBookableIds = (): [string[], (ids: readonly string[]) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const bids = searchParams.get('bids') || '';
  const bookableIds = React.useMemo(() => bids.split(',').map(id => id || null), [bids]);
  const setBookableIds = React.useCallback(bookableIds => {
    setSearchParams(prev => {
      return {
        ...Object.fromEntries(prev.entries()),
        bids: bookableIds.join(',')
      };
    }, {
      replace: true
    });
  }, [setSearchParams]);
  return [bookableIds, setBookableIds];
};
export const useDuration = (): [number | null, (duration: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const duration = searchParams.get('duration');
  const setDuration = React.useCallback(duration => {
    setSearchParams(prev => ({
      ...Object.fromEntries(prev.entries()),
      duration: duration || ''
    }), {
      replace: true
    });
  }, [setSearchParams]);
  return [Number(duration) || null, setDuration];
};