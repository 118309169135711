import { FSA } from 'flux-standard-action';
import {
  NOTIFICATIONS_FETCH_FAILED,
  NOTIFICATIONS_FETCH_REQUESTED,
  NOTIFICATIONS_FETCH_SUCCEEDED,
  NOTIFICATIONS_PATCH_FAILED,
  NOTIFICATIONS_PATCH_REQUESTED,
  NOTIFICATIONS_PATCH_SUCCEEDED,
} from './action-types';

type State = {
  total: number;
  unseen: number;
  status: string;
  data: any[];
};

const initialState: State = {
  total: 0,
  unseen: 0,
  status: '',
  data: [],
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case NOTIFICATIONS_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case NOTIFICATIONS_FETCH_SUCCEEDED: {
      return {
        ...state,
        status: 'done',
        total: action.payload.meta.total,
        unseen: action.payload.meta.unseen,
        data: {
          ...state.data,
          ...action.payload.data.reduce(
            (agg: any, cur: any) => (agg[cur.id] = cur) && agg,
            {}
          ),
        },
      };
    }
    case NOTIFICATIONS_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case NOTIFICATIONS_PATCH_REQUESTED:
      return { ...state, status: 'saving' };
    case NOTIFICATIONS_PATCH_SUCCEEDED: {
      // const updates = action.payload;
      // const newlySeen = updates.filter((n: any) => n.seen).length;

      return {
        ...state,
        status: 'done',
        unseen: 0, //Math.max(state.unseen - newlySeen, 0),
        data: {
          ...state.data,
          // ...action.payload.reduce((agg: any, cur: any) => {
          //   agg[cur.id] = { ...state.data[cur.id], ...cur };
          //   return agg;
          // }, {}),
        },
      };
    }
    case NOTIFICATIONS_PATCH_FAILED:
      return { ...state, status: 'failed' };
    default:
      return state;
  }
}
