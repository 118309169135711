import useComponentSize from '../../hooks/component-size';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import useDraw, { toSVG } from '../../use-draw';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from './../shared';
const ASPECT_RATIO = 0.33; // w/h
const STROKE_COLOR = '#333';
const SigDraw = React.memo(function Sigdraw({
  value,
  onChange,
  disabled,
  name
}: any) {
  const initialRef = React.useRef(value);
  const containerRef = React.useRef<HTMLImageElement>(null);
  const {
    width,
    height
  } = useComponentSize(containerRef);
  const {
    setCanvas,
    fromData
  } = useDraw(containerRef, initialRef.current, onChange, '#000000');
  return <Box position="relative" ref={containerRef} height={width * ASPECT_RATIO} width="100%" bg="gray.0" name={name} sx={{
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouch: 'none',
    canvas: {
      pointerEvents: disabled ? 'none' : 'all',
      position: 'absolute'
    }
  }}>
      <canvas height={height} width={width} ref={setCanvas}></canvas>
      <Box p={3} as="button" type="button"
    // color="secondary"
    sx={{
      position: 'absolute',
      bottom: 0,
      right: 0
    }} onClick={e => {
      initialRef.current = [];
      onChange(initialRef.current);
      fromData(initialRef.current);
      e.stopPropagation();
    }}>
        Clear
      </Box>
    </Box>;
});
const EMPTY_ARRAY = [];
const Signature = ({
  schema,
  menu,
  disabled,
  style,
  className,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  return <Field defaultValue={EMPTY_ARRAY} name={name} validate={value => {
    if (schema.required && (!value || value.length === 0)) {
      return 'Signature required';
    }
  }} data-sentry-element="Field" data-sentry-component="Signature" data-sentry-source-file="index.tsx">
      {({
      input,
      meta
    }) => <Box style={style} className={className}>
          <Flex mb={2} justifyContent="space-between">
            <Label sx={{
          flexGrow: 1
        }}>
              {schema.label}
            </Label>
            {menu}
          </Flex>

          <SigDraw disabled={disabled} {...input} />

          {meta.error && meta.touched && <FormError>{meta.error}</FormError>}

          {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
        </Box>}
    </Field>;
};
export default Signature;
export const toString = value => {
  return value || '';
};
export const View = ({
  value
}) => {
  const ref = React.useRef(null);
  const {
    width,
    height
  } = useComponentSize(ref);
  const svgDataUri = React.useMemo(() => {
    let strokes = value || [];

    // Old signatures
    if (!strokes[0]?.color) {
      strokes = (value || []).filter(v => v.length).map(stroke => ({
        color: STROKE_COLOR,
        maxWidth: 2,
        minWidth: 0.5,
        sourceCanvas: {
          width,
          height
        },
        points: stroke.map(({
          x,
          y
        }, idx) => ({
          x,
          y,
          time: idx * 10
        }))
      }));
    }
    return toSVG(strokes, width, height).replace('\n', ' ');
  }, [value, width, height]);
  return <Box as="img" height={width * ASPECT_RATIO} width="100%" ref={ref} alt="drawing" src={`${svgDataUri}`} data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx" />;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style,
  disabled
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Form onSubmit={value => null}>
          {({
        form
      }) => <Signature schema={value} disabled={disabled} menu={menu} style={style} className={className} />}
        </Form> : <Grid display={!isEditing ? 'none' : undefined}>
          <LabelField prefix={fieldPrefix} />
          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button onClick={toggleMore} size="small" width="100%" variant="outlined">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};