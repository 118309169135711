import { base58_to_binary, binary_to_base58 } from '@relocke/base58';
function toHexString(byteArray) {
  return Array.prototype.map.call(byteArray, function (byte) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  }).join('');
}
function toByteArray(hexString) {
  const result = [];
  for (let i = 0; i < hexString.length; i += 2) {
    result.push(parseInt(hexString.substring(i, i + 2), 16));
  }
  return result;
}
export const toBase58 = uuid => {
  return binary_to_base58(toByteArray(uuid));
};
export const toBase16 = base58uuid => {
  try {
    return toHexString(base58_to_binary(base58uuid));
  } catch {
    return null;
  }
};

// const hex = '6d4618d2e6b247f5a8298a73f5ab9479';
// const b58 = 'EVdTe1bkE6qvKyWRXH2jAY';

// console.log(hex, toBase58_(hex), toBase58(hex));
// console.log(b58, toBase16_(b58), toBase16(b58));