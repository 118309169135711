import { FSA } from 'flux-standard-action';
import {
  DATA_LIST_FETCH_REQUESTED,
  DATA_LIST_FETCH_SUCCEEDED,
  DATA_LIST_FETCH_FAILED,
  DATA_LIST_SAVE_REQUESTED,
  DATA_LIST_SAVE_SUCCEEDED,
  DATA_LIST_SAVE_FAILED,
  DATA_LIST_DELETE_REQUESTED,
  DATA_LIST_DELETE_SUCCEEDED,
  DATA_LIST_DELETE_FAILED,
} from './action-types';

type state = {
  status: string;
  data: { [key: string]: any };
};

const initialState: state = {
  status: '',
  data: {},
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case DATA_LIST_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case DATA_LIST_FETCH_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case DATA_LIST_FETCH_FAILED:
      return { ...state, status: 'failed' };

    case DATA_LIST_SAVE_REQUESTED:
      return { ...state, status: 'saving' };
    case DATA_LIST_SAVE_SUCCEEDED:
      return {
        ...state,
        status: 'done',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case DATA_LIST_SAVE_FAILED:
      return { ...state, status: 'failed' };

    case DATA_LIST_DELETE_REQUESTED:
      return { ...state, status: 'deleting' };
    case DATA_LIST_DELETE_SUCCEEDED: {
      const { [action.payload]: _, ...nonDeleted } = state.data;
      return {
        ...state,
        status: 'done',
        data: {
          ...nonDeleted,
        },
      };
    }
    case DATA_LIST_DELETE_FAILED:
      return { ...state, status: 'failed' };

    default:
      return state;
  }
}
