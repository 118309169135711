import arrayToSentence from 'array-to-sentence';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { useSystemPlans } from 'shared/redux/system-plans/hooks';
import { FEATURES } from 'shared/utils/constants';
import { openChangePlanModal } from './redux/globals/actions';
const Upsell = ({
  feature
}) => {
  const dispatch = useDispatch();
  const businessId = useSelector((state: any) => state.userPreferences.currentBusinessId);
  const plans = useSystemPlans({
    businessId
  });
  const featurePlans = React.useMemo(() => {
    return plans.reduce((acc, curr) => {
      if (curr.features.includes(feature)) {
        acc.push(curr.name);
      }
      return acc;
    }, []);
  }, [feature, plans]);
  const featurePlansToText = React.useMemo(() => {
    return arrayToSentence(featurePlans, {
      lastSeparator: ' and '
    });
  }, [featurePlans]);
  const featureLabel: string = FEATURES[feature];
  const verb = featureLabel.endsWith('s') ? 'are' : 'is';
  return <>
      <EmptyState heading={`You don't have ${featureLabel.toLowerCase()} enabled on your plan`} subheading={`${featureLabel} ${verb} only available on ${featurePlansToText} subscriptions`} callToAction={<Button onClick={() => dispatch(openChangePlanModal(businessId))}>
            Upgrade now
          </Button>} data-sentry-element="EmptyState" data-sentry-source-file="upsell.tsx" />
    </>;
};
export default Upsell;