import React from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import Tags from '../../tags';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from '../shared';
const LocationList = ({
  schema,
  disabled,
  menu,
  style,
  className,
  isCustom = false
}) => {
  const locationData = useSelector((state: any) => state.locations.data);
  const currentBusinessId = useSelector((state: any) => state.userPreferences.currentBusinessId);
  const locationOptions = React.useMemo(() => Object.values<any>(locationData).filter(l => l.business_id === currentBusinessId && !l.is_deleted).map(l => ({
    value: l.id,
    label: l.name
  })), [currentBusinessId, locationData]);
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  return <Box style={style} className={className} data-sentry-element="Box" data-sentry-component="LocationList" data-sentry-source-file="index.tsx">
      <Field name={name} validate={value => {
      if (schema.required && (!value || value.length === 0)) {
        return 'Select a location';
      }
    }} data-sentry-element="Field" data-sentry-source-file="index.tsx">
        {({
        input,
        meta
      }) => <Box>
            <Flex mb={2} justifyContent="space-between">
              <Label sx={{
            flexGrow: 1
          }}>
                {schema.label}
              </Label>
              {menu}
            </Flex>
            <Tags {...input} items={locationOptions} itemToString={v => locationData[v]?.name || 'Removed'} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error} />
            {meta.error && meta.touched && <FormError>{meta.error}</FormError>}

            {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
          </Box>}
      </Field>
    </Box>;
};
export default LocationList;
export const toString = value => {
  return value && value.join(', ') || '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{value && value.join(', ') || ''}</Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Form onSubmit={value => null}>
          {({
        form
      }) => <LocationList schema={value} disabled={true} menu={menu} style={style} className={className} />}
        </Form> : <Grid sx={{
      display: !isEditing ? 'none' : null
    }}>
          <LabelField prefix={fieldPrefix} />
          {!hideRequired && <RequiredField prefix={fieldPrefix} />}

          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button onClick={toggleMore} size="small" width="100%" variant="outlined">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};