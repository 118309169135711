import { Theme } from '@emotion/react';
import Color from 'color';
import React from 'react';
import { Box, BoxProps } from '../grid';
import { ThemeContext } from '@emotion/react';
export function InputGroupLabel({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  const theme = React.useContext<Theme>(ThemeContext as any);
  let fontColor;
  const backgroundColor = theme.colors.secondary;
  if (Color(backgroundColor).contrast(Color(theme.colors['black'])) > Color(backgroundColor).contrast(Color(theme.colors['white']))) {
    fontColor = theme.colors['black'];
  } else {
    fontColor = theme.colors['white'];
  }
  return <Box ref={ref} __css={{
    display: 'flex',
    fontFamily: theme.fonts.body,
    margin: 0,
    whiteSpace: 'nowrap',
    padding: '0 1rem',
    // backgroundColor: theme.colors.gray[1],
    textAlign: 'center',
    flex: '0 0 auto',
    alignItems: 'center',
    borderRadius: `${theme.radii[1]}px`,
    lineHeight: 1,
    backgroundColor: backgroundColor,
    color: fontColor,
    '&:first-child': {
      marginRight: '-1px'
    },
    '&:last-child': {
      borderLeft: 0
    }
  }} {...props} data-sentry-element="Box" data-sentry-component="InputGroupLabel" data-sentry-source-file="index.tsx" />;
}
export function InputGroup({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return <Box ref={ref} __css={{
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    button: {
      flexShrink: 0
    },
    '& > :focus + *': {
      borderLeftColor: 'transparent'
    },
    [`& > :not(button):not(${InputGroupLabel}) + :not(:first-child)`]: {
      '&:not(:focus), *:not(:focus)': {
        borderLeftColor: 'transparent'
      }
    },
    '& > :first-child:not(:last-child)': {
      "&, input, select, [role='button'], button": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    '& > :last-child:not(:first-child)': {
      "&, input, select, [role='button'], button": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      }
    }
  }} {...props} data-sentry-element="Box" data-sentry-component="InputGroup" data-sentry-source-file="index.tsx" />;
}
export default InputGroup;