export const BOOKABLE_GROUP_FETCH_REQUESTED = 'BOOKABLE_GROUP_FETCH_REQUESTED';
export const BOOKABLE_GROUP_FETCH_SUCCEEDED = 'BOOKABLE_GROUP_FETCH_SUCCEEDED';
export const BOOKABLE_GROUP_FETCH_FAILED = 'BOOKABLE_GROUP_FETCH_FAILED';

export const BOOKABLE_GROUP_SAVE_REQUESTED = 'BOOKABLE_GROUP_SAVE_REQUESTED';
export const BOOKABLE_GROUP_SAVE_SUCCEEDED = 'BOOKABLE_GROUP_SAVE_SUCCEEDED';
export const BOOKABLE_GROUP_SAVE_FAILED = 'BOOKABLE_GROUP_SAVE_FAILED';

export const BOOKABLE_GROUP_DELETE_REQUESTED =
  'BOOKABLE_GROUP_DELETE_REQUESTED';
export const BOOKABLE_GROUP_DELETE_SUCCEEDED =
  'BOOKABLE_GROUP_DELETE_SUCCEEDED';
export const BOOKABLE_GROUP_DELETE_FAILED = 'BOOKABLE_GROUP_DELETE_FAILED';
