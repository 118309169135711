import setFieldTouched from 'final-form-set-field-touched';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Tags from 'shared/tags';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import { useIsDatalistValid } from '../hooks';
import { ClientFieldReference, DataSourceField, FieldPrefixContext, HelpTextField, LabelField, NameField, PlaceholderField, PrivateField, RequiredField, useOptionItems } from '../shared';
import arrayMutators from 'final-form-arrays';
import { touchErrors } from '../../utils/form';
import { useUpdateEffect } from '../../hooks/use-update-effect';
const TagsField = ({
  schema,
  disabled,
  menu,
  style,
  className,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const [items, valueToDescription] = useOptionItems(schema);
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  return <Field name={name} validate={value => {
    if (schema.required && !value) {
      return 'Select an option';
    }
  }} data-sentry-element="Field" data-sentry-component="TagsField" data-sentry-source-file="index.tsx">
      {({
      input,
      meta
    }) => <Box style={style} className={className}>
          <Flex mb={2} justifyContent="space-between">
            <Label sx={{
          flexGrow: 1
        }}>
              {schema.label}
            </Label>
            {menu}
          </Flex>

          <Tags {...input} tabIndex={disabled ? -1 : undefined} placeholder={schema.placeholder} sx={{
        display: 'block',
        width: '100%'
      }} invalid={meta.touched && meta.error} itemToString={v => v} itemToTooltip={v => valueToDescription[v]} items={items} />
          {meta.error && meta.touched && <FormError>{meta.error}</FormError>}

          {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
        </Box>}
    </Field>;
};
export default TagsField;
export const toString = value => {
  let v = value || [];
  if (!Array.isArray(value)) {
    v = [value];
  }
  return v.join(', ') || '';
};
export const View = ({
  value,
  schema
}) => {
  let v = value || [];
  if (!Array.isArray(value)) {
    v = [value];
  }
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{v.join(', ') || ''}</Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  const wasTouchedRef = React.useRef(false);
  useUpdateEffect(() => {
    wasTouchedRef.current = false;
  }, [value]);
  const isDataListMissing = useIsDatalistValid(value.data_list_id || '');
  return <>
      {!isEditing ? <Form onSubmit={value => null} mutators={{
      ...arrayMutators,
      setFieldTouched
    }} validate={() => {
      const errors = {};
      if (value.data_source === 'data_list_id' && isDataListMissing) {
        errors['onlyField'] = `Missing data list`;
      }
      return errors;
    }}>
          {({
        form
      }) => {
        if (wasTouchedRef.current === false) {
          touchErrors(form);
          wasTouchedRef.current = true;
        }
        return <TagsField schema={{
          ...value,
          name: 'onlyField'
        }} disabled={true} menu={menu} style={style} className={className} />;
      }}
        </Form> : <Grid display={!isEditing ? 'none' : null}>
          <LabelField prefix={fieldPrefix} />
          <DataSourceField prefix={fieldPrefix} />

          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          <PlaceholderField prefix={fieldPrefix} />
          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button onClick={toggleMore} width="100%" variant="outlined" size="small">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};