import { faMapMarker } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { Avatar } from 'shared/avatar';
import EmptyState from 'shared/empty-state';
import { Box, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import { fetchService } from 'shared/redux/services/actions';
import { Heading } from 'shared/typography';
import { useAppDispatch, useAppSelector } from '../../store';
import { BookStepHeading } from '../shared';
import { useLocationId } from '../../hooks';
import { isUUID } from 'shared/utils/uuid';
import { toBase16 } from 'shared/utils/b58';
const Location = ({
  location
}) => {
  const [locationId, setLocationId] = useLocationId();
  return <Box as="button" onClick={() => setLocationId(location.id)} sx={{
    cursor: 'pointer'
  }} data-sentry-element="Box" data-sentry-component="Location" data-sentry-source-file="index.tsx">
      <Grid justifyItems="center" pb={3} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Avatar size={100} image={location.is_virtual ? null : `https://maps.google.com/maps/api/staticmap?key=${__GOOGLE_MAPS_KEY__}&center=${location.gps[0]},${location.gps[1]}&zoom=14&size=150x150&sensor=false&markers=color:red|${location.gps[0]},${location.gps[1]}`} name={location.name} icon={faMapMarker} data-sentry-element="Avatar" data-sentry-source-file="index.tsx" />
        <Heading fontWeight={500} fontSize={3} textAlign="center" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
          {location.name}
        </Heading>
      </Grid>
    </Box>;
};
const LocationSelector = () => {
  const location = useLocation();
  const {
    serviceId: serviceId_
  } = useParams();
  const serviceId = React.useMemo(() => {
    let serviceId = serviceId_;
    if (!isUUID(serviceId)) {
      if (serviceId.length > 1 && serviceId.length < 32) {
        serviceId = toBase16(serviceId);
      } else if (serviceId.length !== 32) {
        // Not uuid
        serviceId = null;
      }
    }
    return serviceId;
  }, [serviceId_]);
  const [locationId, setLocationId] = useLocationId();
  // const location = useAppSelector((state) => state.location);

  const businessId = useAppSelector(state => state.public.businessId);

  // const serviceId = useAppSelector((state) => state.location.payload.serviceId);
  const service = useAppSelector(state => state.services.data[serviceId]);
  const isLoading = useAppSelector(state => [state.businesses.status, state.locations.status].includes('loading'));
  const bookableData = useAppSelector(state => state.bookables.data);
  const locationData = useAppSelector(state => state.locations.data);
  const businessLocationsWithResouces = React.useMemo(() => {
    const locationIdsHavingBookables = Object.values<any>(bookableData).filter(b => b.business_id == businessId).reduce((acc, curr) => {
      curr.location_ids.forEach(lId => {
        if (!acc.includes(lId)) {
          acc.push(lId);
        }
      });
      return acc;
    }, []);
    return Object.values<any>(locationData).filter(l => l.business_id == businessId).filter(l => locationIdsHavingBookables.includes(l.id)).sort((a, b) => a.name < b.name ? -1 : 1);
  }, [bookableData, businessId, locationData]);
  const locationsProvidingThisService = React.useMemo(() => Object.values<any>(locationData).filter(l => !l.is_deleted).filter(l => service?.locations?.find(sl => sl.location_id === l.id)).sort((a, b) => a.name < b.name ? -1 : 1), [locationData, service?.locations]);
  const dispatch = useAppDispatch();
  const [notFound, setNotFound] = React.useState(false);
  React.useEffect(() => {
    if (!service) {
      dispatch(fetchService(serviceId)).catch(e => {
        if (e.response?.status === 404) {
          setNotFound(true);
        }
      });
    }
  }, [dispatch, service, serviceId]);
  const firstAndOnlyLocationId = locationsProvidingThisService.length === 1 ? locationsProvidingThisService[0].id : null;
  React.useEffect(() => {
    if (firstAndOnlyLocationId) {
      setLocationId(firstAndOnlyLocationId);
    }
  }, [dispatch, firstAndOnlyLocationId, setLocationId]);
  if (['/request-prescription'].includes(location.pathname)) {
    return <Grid gridGap={3}>
        <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')}>
          <FormattedMessage id="Public.LocationSelector.pickALocation" defaultMessage="Select a location to continue" />
        </BookStepHeading>
        <Grid gridTemplateColumns="repeat(auto-fit, minmax(150px, 1fr))" width="100%">
          {businessLocationsWithResouces.map((location, idx) => <Location key={idx} location={location} />)}
        </Grid>
      </Grid>;
  }
  if (notFound) {
    return <EmptyState heading={<FormattedMessage id="Public.ServiceView.serviceNotFound" defaultMessage="Service not found" />} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  return <Grid gridGap={3} data-sentry-element="Grid" data-sentry-component="LocationSelector" data-sentry-source-file="index.tsx">
      <BookStepHeading backTo={`/` + (locationId ? `?lid=${locationId}` : '')} data-sentry-element="BookStepHeading" data-sentry-source-file="index.tsx">
        <FormattedMessage id="Public.LocationSelector.pickALocation" defaultMessage="Select a location to continue" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </BookStepHeading>

      <Grid gridTemplateColumns="repeat(auto-fit, minmax(150px, 1fr))" width="100%" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {locationsProvidingThisService.map((location, idx) => <Location key={idx} location={location} />)}
      </Grid>
    </Grid>;
};
export default LocationSelector;