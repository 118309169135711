import React from 'react';
import { Field, Form } from 'react-final-form';
import DateOnlyPicker from '../../date-only-picker';
import { HelpTextField, LabelField, NameField, RequiredField, PrivateField, ClientFieldReference, FieldPrefixContext } from '../shared';
import { DateTime } from 'luxon';
import Button from '../../button';
import HelpText from '../../form-help-text';
import FormError from '../../form-error';
import Label from '../../label';
import { Grid, Box, Flex } from '../../grid';
import Switch from '../../switch';
const DateComponent = ({
  schema,
  disabled,
  menu,
  style,
  className,
  isCustom = false
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  return <Field name={name} validate={value => {
    if (schema.required && !value) {
      return 'Enter a date';
    }
  }} data-sentry-element="Field" data-sentry-component="DateComponent" data-sentry-source-file="index.tsx">
      {({
      input,
      meta
    }) => <Box style={style} className={className}>
          <Flex mb={2} justifyContent="space-between">
            <Label sx={{
          flexGrow: 1
        }}>
              {schema.label}
            </Label>
            {menu}
          </Flex>

          <DateOnlyPicker {...input} forceText={schema.use_text_input} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error} />

          {meta.error && meta.touched && <FormError>{meta.error}</FormError>}

          {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
        </Box>}
    </Field>;
};
export default DateComponent;
export const toString = value => {
  return value ? DateTime.fromISO(value).toFormat('DDD') : '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{value && DateTime.fromISO(value).toFormat('DDD')}</Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Form onSubmit={value => null}>
          {({
        form
      }) => <DateComponent schema={value} disabled={true} menu={menu} style={style} className={className} />}
        </Form> : <Grid sx={{
      display: !isEditing ? 'none' : null
    }}>
          <LabelField prefix={fieldPrefix} />
          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              <Field name={fieldPrefix ? `${fieldPrefix}.use_text_input` : 'use_text_input'}>
                {({
            input
          }) => <Flex alignItems="center">
                    <Switch size="small" {...input} />
                    <Box ml={2}>Use text input instead of date picker</Box>
                  </Flex>}
              </Field>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button type="button" onClick={toggleMore} width="100%" variant="outlined" size="small">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};