import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Box, BoxProps, Flex } from '../grid';
import { Theme, ThemeContext } from '@emotion/react';
import { themeGet } from '@styled-system/theme-get';
interface CalloutProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  theme?: Theme;
}
type Props = CalloutProps & BoxProps;
export function Callout({
  ref,
  color,
  children,
  ...props
}: Props & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  const theme = React.useContext<Theme>(ThemeContext as any);
  const color_ = (color || 'primary').split('.')[0];
  const colorScaleName = {
    success: 'green',
    warning: 'yellow',
    alert: 'red',
    primary: 'primary_'
  }[color_] || color_;
  const isDark = theme.mode === 'dark';
  const fontColor = 'text';
  let backgroundColor;
  let borderColor;
  if (!isDark) {
    // fontColor = themeGet(
    //   `colors.${colorScaleName}.9`,
    //   colorScaleName
    // )({ theme });

    backgroundColor = themeGet(`colors.${colorScaleName}.${isDark ? 6 : 0}`, colorScaleName)({
      theme
    });
    borderColor = 'transparent';
  } else {
    // fontColor = themeGet(
    //   `colors.${colorScaleName}.8`,
    //   colorScaleName
    // )({ theme });

    backgroundColor = 'gray.2';
    borderColor = 'transparent';
    // borderColor = themeGet(
    //   `colors.${colorScaleName}.8`,
    //   colorScaleName
    // )({ theme });
  }
  return <Flex ref={ref} __css={{
    color: 'primary',
    borderRadius: 2,
    px: 3,
    py: 3,
    fontSize: 2,
    fontFamily: color !== 'white' ? 'heading' : 'body',
    position: 'relative',
    border: fontColor,
    backgroundColor,
    '&, & > svg': {
      color: fontColor
    },
    borderColor: borderColor,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    gap: 2
  }} {...props} data-sentry-element="Flex" data-sentry-component="Callout" data-sentry-source-file="index.tsx">
      {['yellow', 'red', 'green'].includes(colorScaleName) && <Box color={`${colorScaleName}.5`}>
          <FontAwesomeIcon icon={{
        yellow: faTriangleExclamation,
        red: faTimes,
        green: faCheckCircle
      }[colorScaleName]} />
        </Box>}
      <Box flexGrow={1} alignItems="center" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {children}
      </Box>
    </Flex>;
}
export const WarningBox = ({
  close,
  children,
  ...rest
}) => <Callout {...rest} color="warning" sx={{
  display: 'flex',
  alignItems: 'center',
  a: {
    color: '#765b1e'
  }
}} data-sentry-element="Callout" data-sentry-component="WarningBox" data-sentry-source-file="index.tsx">
    <Box flexGrow={1} data-sentry-element="Box" data-sentry-source-file="index.tsx">{children}</Box>
    <Box as="button" className="close" onClick={close} alignItems="flex-start" data-sentry-element="Box" data-sentry-source-file="index.tsx">
      <FontAwesomeIcon icon={faTimes} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" />
    </Box>
  </Callout>;
export default Callout;