import axios from '../../anaxios';
import settings from '../../settings';

import {
  SCHEDULES_FETCH_REQUESTED,
  SCHEDULES_FETCH_SUCCEEDED,
  SCHEDULES_FETCH_FAILED,
  SCHEDULES_DELETE_REQUESTED,
  SCHEDULES_DELETE_SUCCEEDED,
  SCHEDULES_DELETE_FAILED,
  SCHEDULES_SAVE_REQUESTED,
  SCHEDULES_SAVE_SUCCEEDED,
  SCHEDULES_SAVE_FAILED,
} from './action-types';

export const fetchSchedules = (businessId) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: SCHEDULES_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/schedules`, {
        params: {
          business_id: businessId,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        // TODO: remove this block
        try {
          const schedules = data.data.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});

          dispatch({
            type: SCHEDULES_FETCH_SUCCEEDED,
            payload: schedules,
          });
        } catch (e) {
          Sentry.captureMessage(
            `Could not parse schedules: ${JSON.stringify(data)}`,
            'error'
          );
          throw e;
        }
      })
      .catch((error) => {
        dispatch({ type: SCHEDULES_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const saveSchedule = (schedule) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: SCHEDULES_SAVE_REQUESTED });

    return axios
      .put(`${settings.api2Root}/schedules/${schedule.id}`, schedule, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: SCHEDULES_SAVE_SUCCEEDED,
          payload: { [data.id]: data },
        });
      })
      .catch((error) => {
        dispatch({ type: SCHEDULES_SAVE_FAILED, error });
        return Promise.reject(error);
      });
  };
};

export const createSchedule = (schedule) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: SCHEDULES_SAVE_REQUESTED });

    return axios
      .post(`${settings.api2Root}/schedules`, schedule, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: SCHEDULES_SAVE_SUCCEEDED,
          payload: { [data.id]: data },
        });
        return data;
      })
      .catch((error) => {
        dispatch({ type: SCHEDULES_SAVE_FAILED, error });
        return Promise.reject(error);
      });
  };
};

export const deleteSchedule = (id) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: SCHEDULES_DELETE_REQUESTED });

    return axios
      .delete(`${settings.api2Root}/schedules/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: SCHEDULES_DELETE_SUCCEEDED,
          payload: id,
        });
      })
      .catch((error) => {
        dispatch({ type: SCHEDULES_DELETE_FAILED, error });
        return Promise.reject(error);
      });
  };
};
