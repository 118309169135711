export const getTranslation = (
  translations_: { [code: string]: string },
  language: string,
  fallback = ''
) => {
  const translations = translations_ || {};
  let value = translations[language];

  if (!value) {
    value = translations['en'];
  }

  if (!value) {
    value = Object.entries<string>(translations).sort(([a], [b]) =>
      a < b ? -1 : 1
    )?.[0]?.[1];
  }

  return value || fallback;
};

export const getPreview = (text, maxLength = 340) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};
