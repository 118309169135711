import { UPLOAD_PATH_PREFIX } from 'shared/utils/constants';

export const dataUrlToBlob = async (dataUrl) => {
  const response = await fetch(dataUrl);
  const blob = await response.blob();
  return blob;
};

export const getFileBlobDespiteGoogleDrive = (file) => {
  return new Promise<Blob>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const dataUri = e.target['result'] as string;

      dataUrlToBlob(dataUri).then((blob) => resolve(blob));
    };
    reader.readAsDataURL(file);
  });
};

export const getCdnUploadUrlFromId = (id) => {
  if (__TARGET_ENV__ === 'dev') {
    return `${__PUBLIC_PATH__}/api2/uploads/${id}`;
  }

  return __TARGET_ENV__ === 'staging'
    ? `https://uploads.backflip.co.za/${id}`
    : `https://uploads.bookem.com/${id}`;
};

export const getCdnUploadUrlFromPath = (path) => {
  if (__TARGET_ENV__ === 'dev') {
    return path;
  }

  const id = path?.replace(UPLOAD_PATH_PREFIX, '');

  return getCdnUploadUrlFromId(id);
};
