import Decimal from 'decimal.js-light';
import React from 'react';
import { useSelector } from 'react-redux';

export const useSystemPlans = ({
  includeHidden = false,
  businessId: businessId_ = null,
} = {}) => {
  const currentBusinessId = useSelector(
    (state: any) => state.userPreferences.currentBusinessId
  );

  const businessId = businessId_ || currentBusinessId;

  const businessLanguage = useSelector(
    (state: any) => state.businesses.data[businessId]?.language
  );

  const billingCurrency = useSelector(
    (state: any) => state.businesses.data[businessId]?.billing_currency
  );

  const planData = useSelector((state: any) => state.plans.data);

  const plans = React.useMemo(
    () =>
      [
        // {
        //   id: undefined,
        //   name: { en: 'Free' },
        //   features: [],
        //   prices: [
        //     {
        //       currency: 'ZAR',
        //       interval: 'month',
        //       base_price: '0',
        //       base_resource_count: 999999,
        //       additional_resource_price: '0',
        //     },
        //     {
        //       currency: 'USD',
        //       interval: 'month',
        //       base_price: '0',
        //       base_resource_count: 999999,
        //       additional_resource_price: '0',
        //     },
        //   ],
        // },
      ]
        .concat(Object.values<any>(planData))
        .filter((v) => (includeHidden ? true : !v.is_hidden))
        .map((v) => ({
          ...v,
          name: v.name[businessLanguage] || v.name.en,
          price: v.prices.find((p) => p.currency === billingCurrency),
        }))
        .filter((v) => v.price) // Blank if billing currebcy is not loeaded
        .sort((a, b) =>
          Number(a.price.base_price) > Number(b.price.base_price) ? 1 : -1
        ),
    [billingCurrency, businessLanguage, includeHidden, planData]
  );

  return plans;
};

export const planCost = (plan, activeBookableCount, billingCurrency) => {
  if (!plan) {
    return {
      total: 0,
      base: 0,
      additional: 0,
      additionalCount: 0,
      additionalUnitCost: 0,
    };
  }

  const price =
    plan.price || plan.prices.find((p) => p.currency === billingCurrency);

  if (activeBookableCount > price?.base_resource_count) {
    const additionalCount = activeBookableCount - price.base_resource_count;
    return {
      total: new Decimal(price.base_price)
        .plus(additionalCount * price.additional_resource_price)
        .toNumber(),
      base: price.base_price,
      additional: additionalCount * price.additional_resource_price,
      additionalCount: activeBookableCount - price.base_resource_count,
      additionalUnitCost: price.additional_resource_price,
    };
  }
  return {
    total: price?.base_price,
    base: price?.base_price,
    additional: 0,
    additionalCount: 0,
  };
};

export const usePlanCost = (planId, activeBookableCount, billingCurrency) => {
  const planData = useSelector((state: any) => state.plans.data);
  const plan = React.useMemo(() => planData[planId], [planData, planId]);

  const cost = React.useMemo(() => {
    return planCost(plan, activeBookableCount, billingCurrency);
  }, [activeBookableCount, billingCurrency, plan]);

  return cost;
};
