export default [
  'Northern Cape',
  'Eastern Cape',
  'Free State',
  'Western Cape',
  'Limpopo',
  'North West',
  'KwaZulu-Natal',
  'Mpumalanga',
  'Gauteng',
];
