// import Component from './component';
import React from 'react';
import { ViewErrorBoundary } from 'shared/error-boundry';
import { BareLayout } from '../../layout';
import { BorderBox } from '../shared';
const Component = React.lazy(() => import('./component'));

// import Component from './component';

// const isServer = typeof window === 'undefined';

// const NoServer = () => {
//   console.log('bbbb');
//   if (isServer) {
//     console.log('HEREeeeeeeeeeeeeeeeeee');
//     throw new Promise((resolve) => {
//       setTimeout(() => {
//         resolve(0);
//       }, 1000);
//     });
//   }
//   return null;
// };

const ViewWrapper = props => <BareLayout data-sentry-element="BareLayout" data-sentry-component="ViewWrapper" data-sentry-source-file="index.tsx">
    <ViewErrorBoundary data-sentry-element="ViewErrorBoundary" data-sentry-source-file="index.tsx">
      <BorderBox p={3} pt={[0, 3]} maxWidth="700px" mx="auto" data-sentry-element="BorderBox" data-sentry-source-file="index.tsx">
        {/* <NoServer /> */}
        <Component {...props} data-sentry-element="Component" data-sentry-source-file="index.tsx" />
      </BorderBox>
    </ViewErrorBoundary>
  </BareLayout>;
export default ViewWrapper;