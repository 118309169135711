function getLang() {
  if (typeof window === 'undefined') {
    return globalThis['LOCALE'] || 'en-ZA'; // TODO: GIANT HACK, needs to use intl context in react
  }

  if (navigator.languages != undefined) return navigator.languages[0];
  else return navigator.language;
}

const getFormatter = (currency, minimumFractionDigits) =>
  new Intl.NumberFormat(getLang(), {
    style: 'currency',
    currency,
    minimumFractionDigits,
  });

// Needs to be react component that reads from context
export const currencyFormat = (amount, currency, minimumFractionDigits = 2) => {
  const hasDecimal = amount % 1 > 0.001;
  const minimumFractionDigits_ =
    hasDecimal && minimumFractionDigits === 0 ? 2 : minimumFractionDigits;

  const formatter = getFormatter(currency, minimumFractionDigits_);

  if (
    formatter.formatToParts &&
    formatter.resolvedOptions().locale === 'en-ZA'
  ) {
    return formatter
      .formatToParts(amount)
      .map(({ type, value }) => {
        if (type === 'decimal') {
          return '.';
        } else if (type === 'group') {
          return ',';
        }

        return value;
      })
      .join('');
  }

  return formatter.format(amount);
};

export const getCurrencyPrefixSuffix = (currency) => {
  const formatter = getFormatter(currency, 0);
  const parts = formatter.formatToParts(0);
  const idx = parts.findIndex(({ type, value }) => type === 'currency');

  if (idx === 0) {
    return [parts[0].value, null];
  }

  return [null, parts[idx].value];
};
