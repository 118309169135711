import { faPenRuler } from '@fortawesome/pro-light-svg-icons';
import { faArrowsH, faAt, faCalendar, faCheckSquare, faChevronCircleDown, faClock, faDotCircle, faGlobe, faGlobeAfrica, faHeading, faList, faMapMarker, faMobile, faPhotoVideo, faRectanglePortrait, faRectangleWide, faSignature, faTags, faUpload } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Box } from 'shared/grid';
import { Link } from 'shared/typography';
import Upsell from 'shared/upsell';
import { EMPTY_OBJECT } from 'shared/utils/constants';
import Callout from '../callout';
import { useHasFeature } from '../hooks/features';
import Label from '../label';
import { StandardModal } from '../modal';
import Address, { Edit as AddressEdit, toString as addressToString, View as AddressView } from './address';
import Checkbox, { Edit as CheckboxEdit, toString as checkboxToString, View as CheckboxView } from './checkbox';
import Checkboxes, { Edit as CheckboxesEdit, toString as checkboxesToString, View as CheckboxesView } from './checkboxes';
import Date, { Edit as DateEdit, toString as dateToString, View as DateView } from './date';
import Diagram, { Edit as DiagramEdit, toString as diagramToString, View as DiagramView } from './diagram';
import Dropdown, { Edit as DropdownEdit, toString as dropdownToString, View as DropdownView } from './dropdown';
import Email, { Edit as EmailEdit, toString as emailToString, View as EmailView } from './email';
import Heading, { Edit as HeadingEdit, toString as headingToString, View as HeadingView } from './heading';
import Language, { Edit as LanguageEdit, toString as languageToString, View as LanguageView } from './language';
import LocationList, { Edit as LocationListEdit, toString as locationListToString, View as LocationListView } from './location-list';
import MultipleChoice, { Edit as MultipleChoiceEdit, toString as multiplechoiceToString, View as MultipleChoiceView } from './multiplechoice';
import PhoneNumber, { Edit as PhoneNumberEdit, toString as phonenumberToString, View as PhoneNumberView } from './phone-number';
import Separator, { Edit as SeparatorEdit, toString as separatorToString, View as SeparatorView } from './separator';
import Signature, { Edit as SignatureEdit, toString as signatureToString, View as SignatureView } from './signature';
import Tags, { Edit as TagsEdit, toString as tagsToString, View as TagsView } from './tags';
import Text, { Edit as TextEdit, toString as textToString, View as TextView } from './text';
import Textarea, { Edit as TextareaEdit, toString as textareaToString, View as TextareaView } from './textarea';
import Textline, { Edit as TextlineEdit, toString as textlineToString, View as TextlineView } from './textline';
import Time, { Edit as TimeEdit, toString as timeToString, View as TimeView } from './time';
import Timezone, { Edit as TimezoneEdit, toString as timezoneToString, View as TimezoneView } from './timezone';
import Uploads, { Edit as UploadsEdit, toString as uploadsToString, View as UploadsView } from './uploads';
export const presentationalElements = ['heading', 'separator', 'text'];
export const fieldTemplates = [{
  icon: faHeading,
  type: 'heading',
  label: 'Heading',
  name: 'heading',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: '',
  value: '',
  group: 'presentation'
}, {
  icon: faPhotoVideo,
  type: 'text',
  label: 'Rich text',
  name: 'text',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: '',
  value: '',
  group: 'presentation'
}, {
  icon: faArrowsH,
  type: 'separator',
  label: 'Separator',
  name: 'separator',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: '',
  group: 'presentation'
}, {
  icon: faRectangleWide,
  type: 'textline',
  label: 'Short text entry',
  name: 'textline',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  placeholder: ''
}, {
  icon: faRectanglePortrait,
  type: 'textarea',
  label: 'Long text entry',
  name: 'text_area',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faMobile,
  type: 'phone-number',
  label: 'Phone number',
  name: 'phone_number',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faAt,
  type: 'email',
  label: 'Email',
  name: 'email',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faChevronCircleDown,
  type: 'dropdown',
  label: 'Dropdown',
  name: 'dropdown',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2'],
  placeholder: 'Select'
}, {
  icon: faCheckSquare,
  type: 'checkbox',
  label: 'Checkbox',
  name: 'checkbox',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faList,
  type: 'checkboxes',
  label: 'Checkboxes',
  name: 'checkboxes',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2']
}, {
  icon: faDotCircle,
  type: 'multiplechoice',
  label: 'Multiple choice',
  name: 'multiplechoice',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2']
}, {
  icon: faCalendar,
  type: 'date',
  label: 'Date',
  name: 'date',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faClock,
  type: 'time',
  label: 'Time',
  name: 'time',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faMapMarker,
  type: 'address',
  label: 'Address',
  name: 'address',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  show_map: false,
  show_on_print: false,
  has_page_break: false
}, {
  icon: faSignature,
  type: 'signature',
  label: 'Signature',
  name: 'signature',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faPenRuler,
  type: 'diagram',
  label: 'Diagram',
  name: 'diagram',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faGlobe,
  type: 'language',
  label: 'Language',
  name: 'language',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faTags,
  type: 'tags',
  label: 'Tags',
  name: 'tags',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  options: ['Option 1', 'Option 2']
}, {
  icon: faGlobeAfrica,
  type: 'timezone',
  label: 'Time zone',
  name: 'timezone',
  removable: true,
  required: false,
  private: false,
  helptext: ''
}, {
  icon: faUpload,
  type: 'uploads',
  label: 'Uploads',
  name: 'uploads',
  removable: true,
  required: false,
  private: false,
  helptext: '',
  max_number_uploads: 1
}];
const typeToStringFn = {
  heading: headingToString,
  separator: separatorToString,
  text: textToString,
  'location-list': locationListToString,
  timezone: timezoneToString,
  tags: tagsToString,
  language: languageToString,
  multiplechoice: multiplechoiceToString,
  checkboxes: checkboxesToString,
  checkbox: checkboxToString,
  dropdown: dropdownToString,
  time: timeToString,
  date: dateToString,
  textarea: textareaToString,
  address: addressToString,
  email: emailToString,
  'phone-number': phonenumberToString,
  textline: textlineToString,
  signature: signatureToString,
  diagram: diagramToString,
  uploads: uploadsToString
};
const typeToInputEditComponent = {
  heading: HeadingEdit,
  text: TextEdit,
  separator: SeparatorEdit,
  'location-list': LocationListEdit,
  timezone: TimezoneEdit,
  tags: TagsEdit,
  language: LanguageEdit,
  multiplechoice: MultipleChoiceEdit,
  checkboxes: CheckboxesEdit,
  checkbox: CheckboxEdit,
  dropdown: DropdownEdit,
  time: TimeEdit,
  date: DateEdit,
  textarea: TextareaEdit,
  address: AddressEdit,
  email: EmailEdit,
  'phone-number': PhoneNumberEdit,
  textline: TextlineEdit,
  signature: SignatureEdit,
  diagram: DiagramEdit,
  uploads: UploadsEdit
};
const typeToInputComponent = {
  heading: Heading,
  text: Text,
  separator: Separator,
  'location-list': LocationList,
  timezone: Timezone,
  tags: Tags,
  language: Language,
  multiplechoice: MultipleChoice,
  checkboxes: Checkboxes,
  checkbox: Checkbox,
  dropdown: Dropdown,
  time: Time,
  date: Date,
  textarea: Textarea,
  address: Address,
  email: Email,
  'phone-number': PhoneNumber,
  textline: Textline,
  signature: Signature,
  diagram: Diagram,
  uploads: Uploads
};
const typeToInputViewComponent = {
  heading: HeadingView,
  text: TextView,
  separator: SeparatorView,
  'location-list': LocationListView,
  timezone: TimezoneView,
  tags: TagsView,
  language: LanguageView,
  multiplechoice: MultipleChoiceView,
  checkboxes: CheckboxesView,
  checkbox: CheckboxView,
  dropdown: DropdownView,
  time: TimeView,
  date: DateView,
  textarea: TextareaView,
  address: AddressView,
  email: EmailView,
  'phone-number': PhoneNumberView,
  textline: TextlineView,
  signature: SignatureView,
  diagram: DiagramView,
  uploads: UploadsView
};
export const toString = (value, schema) => {
  const toString = typeToStringFn[schema.type] || (v => String(v) || '');
  return toString(value);
};
interface InputEditProps {
  className?: string;
  showAdvanced?: boolean;
  hideRequired?: boolean;
  showClientFieldReference?: boolean;
  isUniqueFieldName: any;
  isEditing: boolean;
  value: any;
  menu?: any;
  style?: any;
  disabled?: boolean;
}
const NotFound = () => null;
export const InputEditComponent = (props: InputEditProps) => {
  const InputComponent = typeToInputEditComponent[props.value?.type] || NotFound;
  return <InputComponent {...props} data-sentry-element="InputComponent" data-sentry-component="InputEditComponent" data-sentry-source-file="form-renderer.tsx" />;
};
export const InputComponent = ({
  schema,
  disableAutoComplete,
  variables = EMPTY_OBJECT,
  nonCustomFields,
  getWrapper,
  menu
}: {
  schema: any;
  disableAutoComplete?: boolean;
  variables?: Record<string, string>;
  nonCustomFields?: string[];
  getWrapper?: (input: React.ReactNode) => React.ReactNode;
  menu?: any;
}) => {
  const form = useForm();
  const InputComponent = typeToInputComponent[schema.type] || NotFound;
  return <InputComponent variables={variables} schema={schema} form={form} disableAutoComplete={disableAutoComplete} isCustom={nonCustomFields ? !nonCustomFields.includes(schema.name) : false} getWrapper={getWrapper} menu={menu} data-sentry-element="InputComponent" data-sentry-component="InputComponent" data-sentry-source-file="form-renderer.tsx" />;
};
const UpsellAttachmentsPlaceholder = ({
  schema
}) => {
  const isPublic = useSelector((state: any) => !!state.public);
  const [isUpsellUploadsModalOpen, setIsUpsellUploadsModalOpen] = React.useState(false);
  return <>
      <Box data-sentry-element="Box" data-sentry-source-file="form-renderer.tsx">
        <Label sx={{
        flexGrow: 1
      }} data-sentry-element="Label" data-sentry-source-file="form-renderer.tsx">
          {schema.label}
        </Label>
        <Box data-sentry-element="Box" data-sentry-source-file="form-renderer.tsx">
          {isPublic ? <Callout color="alert">
              <p>File uploads are currently disabled</p>
            </Callout> : <Callout color="alert">
              <p>
                Your plan does not include file uploads.{' '}
                <Link as="button" type="button" onClick={() => setIsUpsellUploadsModalOpen(true)}>
                  Upgrade now
                </Link>{' '}
              </p>
            </Callout>}
        </Box>
      </Box>
      <StandardModal title="Form uploads" isOpen={isUpsellUploadsModalOpen} close={() => setIsUpsellUploadsModalOpen(false)} data-sentry-element="StandardModal" data-sentry-source-file="form-renderer.tsx">
        {() => <Upsell feature="attachments" />}
      </StandardModal>
    </>;
};
const FormRenderer = React.memo(function FormRenderer({
  value: schemaItems,
  disableAutoComplete = false,
  showPrivate = true,
  variables = EMPTY_OBJECT,
  nonCustomFields,
  getWrapper
}: {
  value: any;
  disableAutoComplete?: boolean;
  showPrivate?: boolean;
  variables?: any;
  nonCustomFields?: any;
  getWrapper?: any;
}) {
  const hasAttachments = useHasFeature('attachments');
  return schemaItems.filter(v => showPrivate || !v.private).map((schema, idx) => {
    if (!hasAttachments && schema.name == 'uploads') {
      return <UpsellAttachmentsPlaceholder schema={schema} key={idx} />;
    }
    return <InputComponent getWrapper={getWrapper} variables={variables} schema={schema} key={idx} disableAutoComplete={disableAutoComplete} nonCustomFields={nonCustomFields} />;
  });
});
export default FormRenderer;
const DisplayComponent = ({
  schema,
  value
}) => {
  const InputView = typeToInputViewComponent[schema.type] || NotFound;
  return <InputView schema={schema} value={value} data-sentry-element="InputView" data-sentry-component="DisplayComponent" data-sentry-source-file="form-renderer.tsx" />;
};
export const FormViewOnlyRenderer = ({
  schema,
  values,
  showPresentational = false
}) => {
  const hasAttachments = useHasFeature('attachments');
  if (!values) {
    return null;
  }
  return schema.filter(schemaItem => showPresentational || !presentationalElements.includes(schemaItem.type)).map((schemaItem, idx, arr) => {
    if (!hasAttachments && schemaItem.name == 'uploads') {
      return <UpsellAttachmentsPlaceholder schema={schemaItem} key={idx} />;
    }
    return <Box key={idx} mb={3}>
          {presentationalElements.includes(schemaItem.type) ? <InputComponent schema={schemaItem} /> : <>
              <Label mb={2}>{schemaItem.label}</Label>
              <DisplayComponent value={values[schemaItem.name]} schema={schemaItem} />
            </>}
        </Box>;
  });
};