import React from 'react';
import { Field } from 'react-final-form';
import { Box, Flex, Grid } from 'shared/grid';
import Divider from '../../divider';
import Switch from '../../switch';
const Separator = ({
  menu
}) => {
  return <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-component="Separator" data-sentry-source-file="index.tsx">
      <Box flex="1" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Divider my={3} gradient={false} data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
      </Box>
      {menu}
    </Flex>;
};
export default Separator;
export const toString = value => {
  return '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{schema.label}</Box>;
};
export const Edit = ({
  isEditing,
  menu
}) => {
  return <>
      {!isEditing ? <Separator menu={menu} /> : <Grid>
          <Field name="show_on_print">
            {({
          input: pInput,
          meta
        }) => <>
                <Flex alignItems="center">
                  <Switch size="small" {...pInput} />
                  <Box ml={2}>Show when printing</Box>
                </Flex>

                {pInput.value && <Field name="has_page_break">
                    {({
              input,
              meta
            }) => <Flex alignItems="center">
                        <Switch size="small" {...input} />
                        <Box ml={2}>Break page before</Box>
                      </Flex>}
                  </Field>}
              </>}
          </Field>
        </Grid>}
    </>;
};