import React from 'react';
import { faFileExcel, faFile, faFileArchive, faFileCsv, faFileImage, faFileMusic, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const extensions2 = {
  jpg: faFileImage,
  jpe: faFileImage,
  jpeg: faFileImage,
  jfif: faFileImage,
  png: faFileImage,
  gif: faFileImage,
  tiff: faFileImage,
  svg: faFileImage,
  psd: faFileImage,
  ai: faFileImage,
  dwg: faFileImage,
  iso: faFileArchive,
  mdf: faFileArchive,
  nrg: faFileArchive,
  zip: faFileArchive,
  '7z': faFileArchive,
  '7zip': faFileArchive,
  arj: faFileArchive,
  rar: faFileArchive,
  gz: faFileArchive,
  gzip: faFileArchive,
  bz2: faFileArchive,
  bzip2: faFileArchive,
  tar: faFileArchive,
  xls: faFileExcel,
  doc: faFileWord,
  pdf: faFilePdf,
  ppt: faFilePowerpoint,
  xlsx: faFileExcel,
  docx: faFileWord,
  pdfx: faFilePdf,
  pptx: faFilePowerpoint,
  rtf: faFileWord,
  // txt: faFile,
  // md: faFile,
  markdown: faFile,
  avi: faFileVideo,
  mp2: faFileMusic,
  mp3: faFileMusic,
  mp4: faFileVideo,
  fla: faFileVideo,
  mxf: faFileVideo,
  wav: faFileMusic,
  wma: faFileMusic,
  aac: faFileMusic,
  flac: faFileMusic,
  ogg: faFileMusic,
  webm: faFileMusic,
  // css: 'css',
  csv: faFileCsv
  // html: 'html',
  // json: 'json',
  // js: 'js',
  // xml: 'xml',

  // dbf: 'dbf',
  // exe: 'exe',
};
const colors = {
  jpg: 'red',
  jpe: 'red',
  jpeg: 'red',
  jfif: 'red',
  png: 'red',
  gif: 'red',
  tiff: 'red',
  svg: 'red',
  psd: 'red',
  ai: 'red',
  dwg: 'red',
  iso: '#7e5935',
  mdf: '#7e5935',
  nrg: '#7e5935',
  zip: '#7e5935',
  '7z': '#7e5935',
  '7zip': '#7e5935',
  arj: '#7e5935',
  rar: '#7e5935',
  gz: '#7e5935',
  gzip: '#7e5935',
  bz2: '#7e5935',
  bzip2: '#7e5935',
  tar: '#7e5935',
  xls: 'green',
  doc: '#275293',
  pdf: '#c60101',
  ppt: '#ca4321',
  xlsx: 'green',
  docx: '#275293',
  pdfx: faFilePdf,
  pptx: '#ca4321',
  rtf: '#275293',
  // txt: faFile,
  // md: faFile,
  markdown: faFile,
  avi: 'red',
  mp2: '#275293',
  mp3: '#275293',
  mp4: 'red',
  fla: 'red',
  mxf: 'red',
  wav: '#275293',
  wma: '#275293',
  aac: '#275293',
  flac: '#275293',
  mebm: '#275293',
  ogg: '#275293',
  // css: 'css',
  csv: 'black'
  // html: 'html',
  // json: 'json',
  // js: 'js',
  // xml: 'xml',

  // dbf: 'dbf',
  // exe: 'exe',
};
export function FileIcon({
  name,
  ...props
}) {
  const parts = name?.split('.') || [''];
  const ext = parts[parts.length - 1].toLowerCase();
  const icon = extensions2[ext];
  return <FontAwesomeIcon color={colors[ext] || '#666'} icon={icon || faFile} size="lg" data-sentry-element="FontAwesomeIcon" data-sentry-component="FileIcon" data-sentry-source-file="index.tsx" />;
}