export default [
  {
    label: 'First name',
    required: true,
    placeholder: '',
    show_map: false,
    show_on_print: false,
    has_page_break: false,
    associated_client_field_name: 'firstname',
    helptext: '',
    type: 'textline',
    removable: false,
    value: '',
    name: 'firstname',
  },
  {
    label: 'Last name',
    required: false,
    placeholder: '',
    show_map: false,
    show_on_print: false,
    has_page_break: false,
    associated_client_field_name: 'lastname',
    helptext: '',
    type: 'textline',
    removable: false,
    value: '',
    name: 'lastname',
  },
  {
    label: 'Cellphone',
    required: true,
    placeholder: '',
    show_map: false,
    show_on_print: false,
    has_page_break: false,
    associated_client_field_name: 'cellphone',
    helptext: '',
    type: 'phone-number',
    removable: false,
    value: '',
    name: 'cellphone',
  },
  {
    label: 'Email',
    required: true,
    placeholder: '',
    show_map: false,
    show_on_print: false,
    has_page_break: false,
    associated_client_field_name: 'email',
    helptext: '',
    type: 'email',
    removable: false,
    value: '',
    name: 'email',
  },
  // {
  //   required: false,
  //   removable: true,
  //   helptext: '',
  //   type: 'textarea',
  //   placeholder: '',
  //   show_map: false,
  //   show_on_print: false,
  //   has_page_break: false,
  //   name: 'notes',
  //   associated_client_field_name: '',
  //   value: '',
  //   label: 'Notes',
  // },
];
