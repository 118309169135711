import localforage from 'localforage';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers, Dispatch } from 'redux';
import { configureStore, Tuple } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import { ThunkDispatch, thunk } from 'redux-thunk';
import billableItemsReducer from 'shared/redux/billable-items/reducer';
import bookableBusyReducer from 'shared/redux/bookable-busy/reducer';
import bookableGroupsReducer from 'shared/redux/bookable-groups/reducer';
import bookablesReducer from 'shared/redux/bookables/reducer';
import bookingReducer from 'shared/redux/bookings/reducer';
import businessesReducer from 'shared/redux/businesses/reducer';
import clientsReducer from 'shared/redux/clients/reducer';
import dataListsReducer from 'shared/redux/data-lists/reducer';
import formSubmissionsReducer from 'shared/redux/form-submissions/reducer';
import formsReducer from 'shared/redux/forms/reducer';
import geoipReducer from 'shared/redux/geoip/reducer';
import invoiceSettingsReducer from 'shared/redux/invoice-settings/reducer';
import locationsReducer from 'shared/redux/locations/reducer';
import publicReducer from 'shared/redux/public/reducer';
import schedulesReducer from 'shared/redux/schedules/reducer';
import servicesReducer from 'shared/redux/services/reducer';
import sessionReducer from 'shared/redux/session/reducer';
import staffReducer from 'shared/redux/staff/reducer';
import userNotificationsReducer from 'shared/redux/user-notifications/reducer';
import userPreferencesReducer from 'shared/redux/user-preferences/reducer';
import usersReducer from 'shared/redux/users/reducer';
import toasterReducer from 'shared/toaster/redux/reducer';
import getRootEpic from './root-epic';

const isBrowser = typeof window !== 'undefined';

export const makeStore = (initialState = null) => {
  const reduxStore = isBrowser
    ? localforage.createInstance({
        name: 'redux',
      })
    : null;

  const reducer = combineReducers({
    invoiceSettings: invoiceSettingsReducer,
    billableItems: billableItemsReducer,
    public: publicReducer,
    session: isBrowser
      ? persistReducer({ key: 'session', storage: reduxStore }, sessionReducer)
      : sessionReducer,
    users: usersReducer,
    businesses: businessesReducer,
    locations: locationsReducer,
    userPreferences: isBrowser
      ? persistReducer(
          { key: 'userPreferences', storage: reduxStore },
          userPreferencesReducer
        )
      : userPreferencesReducer,
    userNotifications: userNotificationsReducer,
    schedules: schedulesReducer,
    services: servicesReducer,
    bookings: bookingReducer,
    bookableGroups: bookableGroupsReducer,
    bookables: bookablesReducer,
    clients: clientsReducer,
    staff: staffReducer,
    toaster: toasterReducer,
    geoip: geoipReducer,
    bookableBusy: bookableBusyReducer,
    forms: formsReducer,
    formSubmissions: formSubmissionsReducer,
    dataLists: dataListsReducer,
  });

  const epicMiddleware = createEpicMiddleware();
  const middleware = new Tuple(thunk, epicMiddleware);

  const store = configureStore({
    reducer,
    preloadedState: initialState || globalThis['___INITIAL_STATE__'] || {},
    middleware: () => middleware,
  });

  epicMiddleware.run(getRootEpic());

  const persistor = persistStore(store as any);

  return { store, persistor };
};

export type Store = ReturnType<typeof makeStore>['store'];
export type RootState = ReturnType<
  ReturnType<typeof makeStore>['store']['getState']
>;
export type AppDispatch = Dispatch<Action<any>> &
  ThunkDispatch<RootState, null, Action<any>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
