import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { StandardModal } from 'shared/modal';

// type ShowModalFn

export default function useModal(): [React.JSX.Element | null, (title: string, showModal: (args: {
  close: () => void;
} & Record<string, any>) => React.JSX.Element,
// props?: Record<string, any>,
size?: 'tiny' | 'small' | 'medium' | 'large') => void, () => void] {
  const [modalContent, setModalContent] = useState<null | {
    size?: 'tiny' | 'small' | 'medium' | 'large';
    content: React.JSX.Element;
    title: string;
    // props?: Record<string, any>;
  }>(null);
  const close = useCallback(() => {
    setModalContent(null);
  }, []);
  const modal = useMemo(() => {
    return <StandardModal isOpen={modalContent !== null} close={close} title={modalContent?.title} props={{
      content: modalContent?.content
    }} size={modalContent?.size}>
        {({
        content
      }) => content}
      </StandardModal>;
  }, [modalContent, close]);
  const showModal = useCallback((title: string, getContent: ({
    close,
    ...props
  }: {
    close: () => void;
  } & Record<string, any>) => React.JSX.Element,
  // props: Record<string, any> = {},
  size) => {
    setModalContent({
      size,
      content: getContent({
        close
        // ...props
      }),
      title
      // props,
    });
  }, [close]);
  return [modal, showModal, close];
}