import { Options, Response } from '../anaxios';
export const CODE_TO_MESSAGE: Record<string, {
  report?: boolean;
  message?: [text: string, type: string, timeout?: number];
}> = {
  0: {
    report: false,
    message: ['Network error.', 'alert']
  },
  408: {
    report: false,
    message: ['Request timed out.', 'alert']
  },
  409: {
    report: true,
    message: ['Conflict detected.', 'warning']
  },
  401: {
    report: true,
    message: ['Session expired.', 'warning']
  },
  403: {
    report: true,
    message: ['Permission denied.', 'alert']
  },
  413: {
    report: true,
    message: ['File too large.', 'alert']
  }
};
export const getErrorInterceptor = (logout = () => null, showToast = (message: [text: string, type: string, timeout?: number]) => null, codeToAction: Record<string, {
  report?: boolean;
  message?: [text: string, type: string, timeout?: number];
}> = CODE_TO_MESSAGE) => {
  return (error: Error & {
    config: Options;
    response: Response<any>;
    request: Request;
  }) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    const status = error?.response?.status;
    if (status === 401 && error.request.url.includes(location.host)) {
      logout();
    }
    const action = codeToAction[status];
    const report = () => {
      if (error.config.skipErrorReportOnCode?.[status]) {
        return;
      }
      Sentry.withScope(function (scope) {
        const responseData = error.response?.data;
        const method = error.request?.method;
        const body = error.request?.body;
        const headers = error.request?.headers;
        Object.entries({
          responseData,
          method,
          body,
          headers
        }).forEach(([key, value]) => {
          if (value) {
            scope.setExtra(key, typeof value === 'object' ? JSON.stringify(value) : value);
          }
        });

        // Sentry.captureException(error);
        Sentry.captureMessage(error.message, 'error');
      });
    };
    if (action) {
      if (action.message) {
        showToast(action.message);
      }
      if (action.report) {
        report();
      }
    } else {
      if (status >= 400 && status < 500) {
        showToast(['Whoops, it seems like we have hit a snag. Our team has been notified.', 'alert']);
        report();
      } else if (status >= 500) {
        showToast(['Whoops, it seems like we have hit a snag. Our team has been notified.', 'alert']);
        if (status > 500) {
          report();
        }
      }
    }
    return Promise.reject(error);
  };
};