import { Theme } from '@emotion/react';
import base, { baseColors, addDerivatives, toLuv } from 'shared/theme';

const colors = {
  ...baseColors,
  brand0: toLuv('#333333'),
  brand1: toLuv('#333333'),
  brand2: toLuv('#333333'),
  brand3: toLuv('#333333'),
  // gray: toLuv('#333333'),
  primary: '#333333',
  secondary: '#333333',
  success: '#4BB543',
  warning: '#F0B429',
  alert: '#A61B1B',
  anchor: '#333333',
  anchorHover: '#1D4ED8',
  bodyBackground: '#f9f9f9',
};

const theme: Theme = addDerivatives({
  ...base,
  name: 'Public',
  colors,
  maxGridWidth: '1000px',
});

export default theme;
