import useComponentSize from '../hooks/component-size';
import React, { ReactNode } from 'react';
import FormError from '../form-error';
import { Box, BoxProps, Flex } from '../grid';
import { invalidInput } from '../input-style';
import Label from '../label';
interface InputProps {
  invalid?: boolean;
  label?: any;
}
export type StyledInputProps = InputProps & Omit<BoxProps, 'label'>;
export function StyledInput({
  ref,
  invalid,
  label,
  as = 'input',
  __css,
  ...props
}: StyledInputProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return <Box ref={ref} as={as} tx="inputs" variant="default" type="text" __css={{
    ...(invalid ? invalidInput : {}),
    ...(__css || {})
  }} {...props} data-sentry-element="Box" data-sentry-component="StyledInput" data-sentry-source-file="index.tsx" />;
}
type LabeledInputProps = {
  label?: string | ReactNode;
  error?: string;
  invalid?: boolean;
  prefix?: React.JSX.Element | string;
  suffix?: React.JSX.Element | string;
} & StyledInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'>;
const ContentWrapper = ({
  prefix,
  suffix,
  pr = 0,
  children
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const {
    width
  } = useComponentSize(ref);
  return <Box sx={{
    position: 'relative',
    width: '100%',
    '& > input': {
      paddingLeft: prefix ? `${width}px !important` : null,
      paddingRight: suffix ? `calc(${width + 0}px + ${pr}) !important` : null
    }
  }} data-sentry-element="Box" data-sentry-component="ContentWrapper" data-sentry-source-file="index.tsx">
      {prefix && <Flex ref={ref} px={3} alignItems="center" sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%'
    }}>
          {prefix}
        </Flex>}
      {children}
      {suffix && <Flex ref={ref} pr={3} alignItems="center" sx={{
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%'
    }}>
          {suffix}
        </Flex>}
    </Box>;
};
function LabeledInput({
  ref,
  label,
  error,
  invalid,
  id,
  onFocus,
  onBlur,
  prefix,
  suffix,
  type = 'text',
  pr,
  ...rest
}: LabeledInputProps & {
  ref?: React.Ref<unknown>;
}) {
  const [isFocused, setFocus] = React.useState(false);
  const Wrapper: React.ElementType = ContentWrapper;
  let wrapperProps = {};
  if (prefix || suffix) {
    // Wrapper = ContentWrapper;
    wrapperProps = {
      prefix,
      suffix,
      pr
    };
  }
  return <>
      {label && <Label mb={2} htmlFor={id || rest.name} isFocused={isFocused} invalid={invalid || !!error}>
          {label}
        </Label>}
      <Wrapper {...wrapperProps} data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
        <StyledInput type={type} id={id || rest.name} ref={ref} invalid={invalid || !!error} onFocus={e => {
        onFocus?.(e);
        setFocus(true);
      }} onBlur={e => {
        onBlur?.(e);
        setFocus(false);
      }} __css={{
        borderRadius: 2,
        width: Wrapper ? '100%' : undefined
      }} pr={pr} {...rest} data-sentry-element="StyledInput" data-sentry-source-file="index.tsx" />
      </Wrapper>
      {error && <FormError>{error}</FormError>}
    </>;
}
export default LabeledInput;