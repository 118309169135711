import throttle from 'lodash.throttle';
import React from 'react';
import { Bezier } from './bezier';
import { Point } from './point';
import useComponentSize from 'shared/hooks/component-size';
import { isAndroid } from '../utils/detect';
const contextAttributes: CanvasRenderingContext2DSettings = {
  // alpha: true,
  // desynchronized: true, // breaks chrome on android
};
export interface PointGroup {
  color: string;
  points: Point[];
  minWidth: number;
  maxWidth: number;
  sourceCanvas: {
    width: number;
    height: number;
  };
}
const velocityFilterWeight = 0.7;
const throttleMs = 16; // in milisecondss
const minDistance = 2; // in pixels
const dotSize = function dotSize(minWidth, maxWidth): number {
  return (minWidth + maxWidth) / 2;
};
const backgroundColor = 'rgba(0,0,0,0)';
const distance = (start: Point, end: Point): number => {
  return Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
};
const strokeWidth = (velocity: number, minWidth, maxWidth): number => {
  return Math.max(maxWidth / (velocity + 1), minWidth);
};
const scaledDistance = (p1, p2, width, height): number => {
  return Math.sqrt(Math.pow((p1.x - p2.x) * width, 2) + Math.pow((p1.y - p2.y) * height, 2));
};
const scaledVelocity = (start: Point, end: Point, width, height): number => {
  return end.time !== start.time ? scaledDistance(start, end, width, height) / (end.time - start.time) : 0;
};

// const dpr =  Math.max(window.devicePixelRatio || 1, 1);
// const dpr = 2; //window.devicePixelRatio || 1;

const addPoint = (point: Point, width, height, minWidth, maxWidth, strokeStats): Bezier | null => {
  const lastPoints = strokeStats.lastPoints;
  lastPoints.push(point);
  if (lastPoints.length > 2) {
    // To reduce the initial lag make it work with 3 points
    // by copying the first point to the beginning.
    if (lastPoints.length === 3) {
      lastPoints.unshift(lastPoints[0]);
    }

    // _points array will always have 4 points here.
    const endPoint = lastPoints[2];
    const startPoint = lastPoints[1];
    const velocity = velocityFilterWeight * scaledVelocity(startPoint, endPoint, width, height) + (1 - velocityFilterWeight) * strokeStats.lastVelocity;
    const newWidth = strokeWidth(velocity, minWidth, maxWidth);
    const widths = {
      end: newWidth,
      start: strokeStats.lastWidth
    };
    strokeStats.lastVelocity = velocity;
    strokeStats.lastWidth = newWidth;
    const curve = Bezier.fromPoints(lastPoints, widths);

    // Remove the first element from the list, so that there are no more than 4 points at any time.
    lastPoints.shift();
    return curve;
  }
  return null;
};
const resetStrokeStats = (strokeStats, minWidth, maxWidth) => {
  strokeStats.lastPoints = [];
  strokeStats.lastVelocity = 0;
  strokeStats.lastWidth = (minWidth + maxWidth) / 2;
};
const _fromData = (pointGroups: PointGroup[], drawCurve, drawDot, strokeStats): void => {
  for (const group of pointGroups) {
    const {
      color,
      points,
      sourceCanvas: {
        width,
        height
      },
      minWidth,
      maxWidth
    } = group;
    if (points.length > 1) {
      for (let j = 0; j < points.length; j += 1) {
        const point = points[j];
        if (j === 0) {
          resetStrokeStats(strokeStats, minWidth, maxWidth);
        }
        const curve = addPoint(point, width, height, minWidth, maxWidth, strokeStats);
        if (curve) {
          drawCurve({
            color,
            curve
          }, minWidth, maxWidth);
        }
      }
    } else {
      resetStrokeStats(strokeStats, minWidth, maxWidth);

      // Points shouldn't be empty but it has happened
      if (points[0]) {
        drawDot({
          color,
          point: points[0]
        }, minWidth, maxWidth);
      }
    }
  }
};
const getInitialStrokeStats = (minWidth, maxWidth) => ({
  lastPoints: [],
  // Stores up to 4 most recent points; used to generate a new curve
  lastVelocity: 0,
  lastWidth: (minWidth + maxWidth) / 2
});
export const cropAnnotation = (pointGroups, width, height) => {
  if (pointGroups.length === 0) {
    return [pointGroups, 700, 200];
  }
  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;
  let maxY = Number.MIN_VALUE;
  for (const group of pointGroups) {
    for (const point of group.points) {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
      maxX = Math.max(maxX, point.x);
      maxY = Math.max(maxY, point.y);
    }
  }
  const padding = 0.03;
  const newWidth = maxX - minX + padding;
  const newHeight = maxY - minY + padding;
  const newAnnotation = pointGroups.map(group => ({
    ...group,
    points: group.points.map(point => ({
      ...point,
      x: (point.x - minX + padding / 2) / newWidth,
      y: (point.y - minY + padding / 2) / newHeight
    }))
  }));
  return [newAnnotation, width * newWidth, height * newHeight];
};
export const toSVG = (pointGroups, width, height, strokeStats = getInitialStrokeStats(0.5, 1.5)): string => {
  const ratio = 1 || Math.max(window.devicePixelRatio || 1, 1);
  const minX = 0;
  const minY = 0;
  const maxX = width / ratio;
  const maxY = height / ratio;
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

  // let newWidth = width;
  // let newHeight = height;

  // if (doCrop) {
  //   minX = Number.MAX_VALUE;
  //   minY = Number.MAX_VALUE;
  //   maxX = Number.MIN_VALUE;
  //   maxY = Number.MIN_VALUE;

  //   for (const group of pointGroups) {
  //     for (const point of group.points) {
  //       minX = Math.min(minX, point.x);
  //       minY = Math.min(minY, point.y);
  //       maxX = Math.max(maxX, point.x);
  //       maxY = Math.max(maxY, point.y);
  //     }
  //   }

  //   newWidth = (maxX - minX) * width;
  //   newHeight = (maxY - minY) * height;

  //   minX *= width / ratio;
  //   minY *= height / ratio;
  //   maxX *= width / ratio;
  //   maxY *= height / ratio;

  //   // add padding
  //   const padding = 0.1;
  //   const deltaX = maxX - minX;
  //   const deltaY = maxY - minY;
  //   minX -= deltaX * padding;
  //   minY -= deltaY * padding;
  //   maxX += deltaX * padding;
  //   maxY += deltaY * padding;

  //   console.log({
  //     minX,
  //     minY,
  //     maxX,
  //     maxY,
  //   });
  // }

  // const drawCurveManualBezier = ({
  //   color,
  //   curve,
  // }: {
  //   color: string;
  //   curve: Bezier;
  // }): void => {
  //   const widthDelta = curve.endWidth - curve.startWidth;
  //   // '2' is just an arbitrary number here. If only lenght is used, then
  //   // there are gaps between curve segments :/
  //   const drawSteps = Math.floor(curve.length(width, height)) * 2;

  //   let attr = '';

  //   for (let i = 0; i < drawSteps; i += 1) {
  //     // Calculate the Bezier (x, y) coordinate for this step.
  //     const t = i / drawSteps;
  //     const tt = t * t;
  //     const ttt = tt * t;
  //     const u = 1 - t;
  //     const uu = u * u;
  //     const uuu = uu * u;

  //     let x = uuu * curve.startPoint.x * width_;
  //     x += 3 * uu * t * curve.control1.x * width_;
  //     x += 3 * u * tt * curve.control2.x * width_;
  //     x += ttt * curve.endPoint.x * width_;

  //     let y = uuu * curve.startPoint.y * height;
  //     y += 3 * uu * t * curve.control1.y * height;
  //     y += 3 * u * tt * curve.control2.y * height;
  //     y += ttt * curve.endPoint.y * height;

  //     const width = Math.min(
  //       curve.startWidth + ttt * widthDelta,
  //       curve.endWidth
  //     );

  //     attr += `M ${x},${y}`;
  //     attr += `a ${x},${y} ${2 * Math.PI} 0 0 ${width} ${width}`;
  //   }

  //   attr += `Z`;

  //   const path = document.createElement('path');
  //   path.setAttribute('d', attr);
  //   // path.setAttribute('stroke-width', (curve.endWidth * 2.25).toFixed(3));
  //   path.setAttribute('stroke', color);
  //   path.setAttribute('fill', color);
  //   path.setAttribute('stroke-linecap', 'round');
  //   svg.appendChild(path);
  // };

  const drawCurveBezier = ({
    color,
    curve
  }: {
    color: string;
    curve: Bezier;
  }) => {
    const path = document.createElement('path');

    // Need to check curve for NaN values, these pop up when drawing
    // lines on the canvas that are not continuous. E.g. Sharp corners
    // or stopping mid-stroke and than continuing without lifting mouse.
    /* eslint-disable no-restricted-globals */

    if (isNaN(curve.control1.x) || isNaN(curve.control1.y) || isNaN(curve.control2.x) || isNaN(curve.control2.y)) {
      return;
    }
    const attr = `M ${(curve.startPoint.x * width).toFixed(3)},${(curve.startPoint.y * height).toFixed(3)} ` + `C ${(curve.control1.x * width).toFixed(3)},${(curve.control1.y * height).toFixed(3)} ` + `${(curve.control2.x * width).toFixed(3)},${(curve.control2.y * height).toFixed(3)} ` + `${(curve.endPoint.x * width).toFixed(3)},${(curve.endPoint.y * height).toFixed(3)}`;
    path.setAttribute('d', attr);
    path.setAttribute('stroke-width', (curve.endWidth * 2.25).toFixed(3));
    path.setAttribute('stroke', color);
    path.setAttribute('fill', 'none');
    path.setAttribute('stroke-linecap', 'round');
    // const g = document.createElement('g');
    // g.setAttribute('opacity', '0.5');
    // g.appendChild(path);
    svg.appendChild(path);
  };
  const drawDot = ({
    color,
    point
  }: {
    color: string;
    point: Point;
  }, minWidth, maxWidth) => {
    const circle = document.createElement('circle');
    const dotSize_ = dotSize(minWidth, maxWidth);
    circle.setAttribute('r', dotSize_.toString());
    circle.setAttribute('cx', (point.x * width).toString());
    circle.setAttribute('cy', (point.y * height).toString());
    circle.setAttribute('fill', color);
    svg.appendChild(circle);
  };

  // const pointGroups_ = pointGroups.map((group) => ({
  //   ...group,
  //   points: group.points.reduce((out, end) => {
  //     if (out.length === 0) {
  //       out.push(end);
  //     } else {
  //       const start = out[out.length - 1];
  //       const sd = scaledDistance(end, start, width, height);
  //       const parts = (sd / 4) | 0;

  //       // add some more points for smoothness
  //       if (sd > 4) {
  //         const t = end.time - start.time;
  //         const dx = end.x - start.x;
  //         const dy = end.y - start.y;

  //         for (let idx = 1; idx < parts - 1; idx++) {
  //           const f = idx / parts;

  //           const p = {
  //             x: start.x + dx * f,
  //             y: start.y + dy * f,
  //             time: start.time + t * f,
  //           };

  //           out.push(p);
  //         }
  //       }

  //       out.push(end);
  //     }
  //     return out;
  //   }, []),
  // }));

  _fromData(pointGroups, drawCurveBezier, drawDot, strokeStats);
  const prefix = 'data:image/svg+xml;base64,';
  const header = '<svg' + ' xmlns="http://www.w3.org/2000/svg"' + ' xmlns:xlink="http://www.w3.org/1999/xlink"' + ` viewBox="${minX} ${minY} ${maxX} ${maxY}"` +
  // ` width="${maxX}"` +
  // ` height="${maxY}"` +
  '>';
  let body = svg.innerHTML;

  // IE hack for missing innerHTML property on SVGElement
  if (body === undefined) {
    const dummy = document.createElement('dummy');
    const nodes = svg.childNodes;
    dummy.innerHTML = '';

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < nodes.length; i += 1) {
      dummy.appendChild(nodes[i].cloneNode(true));
    }
    body = dummy.innerHTML;
  }
  const footer = '</svg>';
  const data = header + body + footer;
  return prefix + btoa(data);
};
const reducePrecision = (n: number, decimals = 4) => {
  const d = Math.pow(10, decimals);
  return Math.round(n * d) / d;
};
const noop = () => null;
const useDraw = (containerRef, initialData, setData, penColor = '#000000', minWidth = 0.5, maxWidth = 2.0, onStroke = noop) => {
  const dpr = Math.max(window.devicePixelRatio || 1, 1);
  const [canvas, setCanvas_] = React.useState<HTMLCanvasElement>(null);
  React.useEffect(() => {
    document.body.style.userSelect = 'none';
    document.body.style.webkitUserSelect = 'none';
    return () => {
      document.body.style.userSelect = null;
      document.body.style.webkitUserSelect = null;
    };
  }, []);

  // Stop safari on ipad from bringing up maginifaction
  React.useEffect(() => {
    if (!canvas) {
      return;
    }
    const fn = e => {
      e.preventDefault();
    };
    canvas.addEventListener('touchstart', fn, {
      capture: true
    });
    return () => {
      canvas.removeEventListener('touchstart', fn, {
        capture: true
      });
    };
  }, [canvas]);
  const setCanvas = React.useCallback(canvas => {
    if (canvas) {
      const ctx = canvas.getContext('2d', {
        // desynchronized: !isAndroid(), // Chrome on Android shows a black screen if true
      });
      ctx.scale(dpr, dpr);

      // ctx.clearRect(0, 0, canvas.width, canvas.height);

      // ctx.rect(0, 0, canvas.width, canvas.height);
      // ctx.fillStyle = 'transparent';
      // ctx.fill();
      // window.alert('here');

      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.webkitUserSelect = 'none';
      canvas.style.userSelect = 'none';
    }
    setCanvas_(canvas);
  }, [dpr]);
  const {
    width: canvasWidth,
    height: canvasHeight
  } = useComponentSize({
    current: canvas
  });

  // const ctx = React.useMemo(
  //   () => canvas && (canvas.getContext('2d') as CanvasRenderingContext2D),
  //   [canvas]
  // );

  const lastCommitedStrokeIdxRef = React.useRef<number>(0);
  const pointerDownRef = React.useRef<Map<number, string>>(new Map());
  const mouseButtonDownRef = React.useRef<boolean>(false);
  // Stores all points in groups (one group per line or dot)
  const dataRef = React.useRef<PointGroup[]>([...initialData]);
  const strokeStatsRef = React.useRef(getInitialStrokeStats(minWidth, maxWidth));
  const startTime = React.useMemo(() => new Date().getTime(), []);
  const createPoint = React.useCallback((x: number, y: number) => {
    const rect = containerRef.current.getBoundingClientRect();
    return {
      x: reducePrecision((x - rect.left) / canvas.width) * dpr,
      y: reducePrecision((y - rect.top) / canvas.height) * dpr,
      time: new Date().getTime() - startTime
    };
  }, [canvas?.height, canvas?.width, containerRef, dpr, startTime]);
  const drawCurveSegment = React.useCallback((x: number, y: number, width: number): void => {
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d', contextAttributes);
    // ctx.scale(dpr, dpr);

    ctx.moveTo(x, y);
    ctx.arc(x, y, width, 0, 2 * Math.PI, false);
  }, [canvas]);
  const drawDot = React.useCallback(({
    color,
    point
  }: {
    color: string;
    point: Point;
  }, minWidth, maxWidth): void => {
    if (!canvas) {
      console.error('no canvas');
      return;
    }
    const ctx = canvas.getContext('2d', contextAttributes);
    // ctx.scale(dpr, dpr);
    const width = dotSize(minWidth, maxWidth);
    ctx.beginPath();
    ctx.fillStyle = color;
    drawCurveSegment(point.x * canvas.width, point.y * canvas.height, width);
    ctx.closePath();
    ctx.fill();
  }, [canvas, drawCurveSegment]);
  const drawCurve = React.useCallback(({
    color,
    curve
  }: {
    color: string;
    curve: Bezier;
  }, minWidth, maxWidth): void => {
    if (!canvas) {
      console.error('no canvas');
      return;
    }
    const ctx = canvas.getContext('2d', contextAttributes);
    // ctx.scale(dpr, dpr);

    const widthDelta = curve.endWidth - curve.startWidth;
    // '2' is just an arbitrary number here. If only length is used, then
    // there are gaps between curve segments :/
    const drawSteps = Math.floor(curve.length(canvas.width, canvas.height)) * 2;
    ctx.beginPath();
    ctx.fillStyle = color;
    const width_ = canvas.width;
    const height = canvas.height;
    for (let i = 0; i < drawSteps; i += 1) {
      // Calculate the Bezier (x, y) coordinate for this step.
      const t = i / drawSteps;
      const tt = t * t;
      const ttt = tt * t;
      const u = 1 - t;
      const uu = u * u;
      const uuu = uu * u;
      let x = uuu * curve.startPoint.x * width_;
      x += 3 * uu * t * curve.control1.x * width_;
      x += 3 * u * tt * curve.control2.x * width_;
      x += ttt * curve.endPoint.x * width_;
      let y = uuu * curve.startPoint.y * height;
      y += 3 * uu * t * curve.control1.y * height;
      y += 3 * u * tt * curve.control2.y * height;
      y += ttt * curve.endPoint.y * height;
      const width = Math.min(curve.startWidth + ttt * widthDelta, maxWidth) * dpr;
      drawCurveSegment(x, y, width);
    }
    ctx.closePath();
    ctx.fill();
  }, [canvas, dpr, drawCurveSegment]);
  const strokeUpdate = React.useCallback((event: PointerEvent | MouseEvent | Touch) => {
    const data = dataRef.current;
    if (data.length === 0) {
      // This can happen if clear() was called while a signature is still in progress,
      // or if there is a race condition between start/update events.
      // _strokeBegin(event);

      const newPointGroup = {
        color: penColor,
        minWidth,
        maxWidth,
        points: [],
        sourceCanvas: {
          width: canvas.width,
          height: canvas.height
        }
      };
      data.push(newPointGroup);
      resetStrokeStats(strokeStatsRef.current, minWidth, maxWidth);
      return;
    }
    if (!canvas) {
      console.log('no canvas');
      return;
    }
    const point = createPoint(event.clientX, event.clientY);
    const lastPointGroup = data[data.length - 1];
    const lastPoints = lastPointGroup.points;
    const lastPoint = lastPoints.length > 0 && lastPoints[lastPoints.length - 1];
    const isLastPointTooClose = lastPoint ? distance(lastPoint, point) <= minDistance / canvas.width : false;
    const color = lastPointGroup.color;

    // Skip this point if it's too close to the previous one
    if (!lastPoint || !(lastPoint && isLastPointTooClose)) {
      const curve = addPoint(point, canvas.width / dpr, canvas.height / dpr, lastPointGroup.minWidth, lastPointGroup.maxWidth, strokeStatsRef.current);
      if (!lastPoint) {
        drawDot({
          color,
          point
        }, lastPointGroup.minWidth, lastPointGroup.maxWidth);
      } else if (curve) {
        drawCurve({
          color,
          curve
        }, lastPointGroup.minWidth, lastPointGroup.maxWidth);
      }
      lastPoints.push({
        time: point.time,
        x: point.x,
        y: point.y
      });
    }
  }, [canvas, createPoint, penColor, minWidth, maxWidth, dpr, drawDot, drawCurve]);
  const strokeBegin = React.useCallback((event: PointerEvent | MouseEvent | Touch) => {
    const _data = dataRef.current;
    const newPointGroup = {
      color: penColor,
      minWidth,
      maxWidth,
      points: [],
      sourceCanvas: {
        width: canvas.width,
        height: canvas.height
      }
    };
    _data.push(newPointGroup);
    resetStrokeStats(strokeStatsRef.current, minWidth, maxWidth);
    strokeUpdate(event);
  }, [strokeUpdate, canvas?.height, canvas?.width, maxWidth, minWidth, penColor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const strokeMoveUpdate = React.useCallback(throttleMs ? throttle(strokeUpdate, throttleMs, {
    leading: true,
    trailing: true
  }) : strokeUpdate, [strokeUpdate]);
  const strokeEnd = React.useCallback((event: PointerEvent | MouseEvent | Touch): void => {
    strokeUpdate(event);
    setData([...dataRef.current]);
    onStroke();
  }, [strokeUpdate, setData, onStroke]);
  const clear = React.useCallback((): void => {
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d', contextAttributes);
    // ctx.scale(dpr, dpr);

    // Clear canvas using background color
    ctx.fillStyle = backgroundColor;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    dataRef.current = [];
    resetStrokeStats(strokeStatsRef.current, minWidth, maxWidth);
  }, [canvas, maxWidth, minWidth]);
  const fromData = React.useCallback((pointGroups: PointGroup[]): void => {
    clear();
    _fromData(pointGroups, drawCurve, drawDot, strokeStatsRef.current);
    dataRef.current = pointGroups;
  }, [drawCurve, drawDot, clear]);

  // Event handlers
  const handlePointerDown = React.useCallback((event: PointerEvent): void => {
    pointerDownRef.current.set(event.pointerId, event.pointerType);
    if (pointerDownRef.current.size > 1 && event.pointerType !== 'pen') {
      return;
    }
    if (event.button === 0) {
      mouseButtonDownRef.current = true;
      strokeBegin(event);
      lastCommitedStrokeIdxRef.current = dataRef.current.length - 2;
    }
  }, [strokeBegin]);
  const handlePointerMove = React.useCallback((event: PointerEvent): void => {
    if (pointerDownRef.current.size > 1 && event.pointerType !== 'pen') {
      return;
    }
    if (mouseButtonDownRef.current) {
      strokeMoveUpdate(event);
      lastCommitedStrokeIdxRef.current = dataRef.current.length - 2;
    }
  }, [strokeMoveUpdate]);
  const handlePointerUp = React.useCallback((event: PointerEvent): void => {
    pointerDownRef.current.delete(event.pointerId);
    if (event.button === 0 && mouseButtonDownRef.current) {
      mouseButtonDownRef.current = false;
      strokeEnd(event);
      lastCommitedStrokeIdxRef.current = dataRef.current.length - 1;
    }
  }, [strokeEnd]);

  // TODO: WIP
  const handlePointerCancel = React.useCallback((event: PointerEvent): void => {
    pointerDownRef.current.delete(event.pointerId);

    // window.alert('here');
    // Buggy
    // if (lastCommitedStrokeIdxRef.current !== 0) {
    //   dataRef.current.splice(lastCommitedStrokeIdxRef.current + 1);
    //   fromData(dataRef.current);
    // }
  }, []);
  const initCanvas = React.useCallback(() => {
    if (!canvas) {
      return;
    }
    if (canvas.offsetHeight !== 0) {
      canvas.width = canvas.offsetWidth * dpr;
      canvas.height = canvas.offsetHeight * dpr;
    }
    const pointGroups = dataRef.current;
    if (pointGroups) {
      fromData(pointGroups);
    } else {
      clear();
    }
  }, [canvas, clear, dpr, fromData]);
  React.useEffect(() => {
    initCanvas();
  }, [initCanvas, canvasWidth, canvasHeight]);
  React.useEffect(() => {
    if (!canvas) {
      return;
    }
    mouseButtonDownRef.current = false;
    pointerDownRef.current.clear();
    const handlePointerDown_ = handlePointerDown;
    const handlePointerMove_ = handlePointerMove;
    const handlePointerUp_ = handlePointerUp;
    const handlePointerCancel_ = handlePointerCancel;

    // This causes weird zoom bug?
    // https://bookem.atlassian.net/jira/software/projects/BKM/boards/2?selectedIssue=BKM-173
    // canvas.style.touchAction = 'pinch-zoom';
    // canvas.style['msTouchAction'] = 'pinch-zoom';
    canvas.style.touchAction = 'none';
    canvas.style['msTouchAction'] = 'none';
    canvas.addEventListener('pointerdown', handlePointerDown_, {
      passive: true,
      capture: true
    });
    canvas.addEventListener('pointermove', handlePointerMove_, {
      passive: true,
      capture: true
    });
    document.addEventListener('pointerup', handlePointerUp_, {
      passive: true,
      capture: true
    });
    document.addEventListener('pointercancel', handlePointerCancel_, {
      passive: true,
      capture: true
    });
    return () => {
      canvas.style.touchAction = 'auto';
      canvas.style['msTouchAction'] = 'auto';
      canvas.removeEventListener('pointerdown', handlePointerDown_, {
        capture: true
      });
      canvas.removeEventListener('pointermove', handlePointerMove_, {
        capture: true
      });
      document.removeEventListener('pointerup', handlePointerUp_, {
        capture: true
      });
      document.removeEventListener('pointercancel', handlePointerCancel_, {
        capture: true
      });
    };
  }, [handlePointerDown, handlePointerMove, handlePointerUp, canvas, initCanvas, handlePointerCancel]);
  return {
    fromData,
    setCanvas,
    canvas
  };
};
export default useDraw;