import React from 'react';
import { storageFactory } from '../storage-factory';
const local = storageFactory(() => localStorage);
const session = storageFactory(() => sessionStorage);
export function useLocalState<T>(defaultValue, key): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState<T>(() => defaultValue);
  React.useEffect(() => {
    setState(JSON.parse(local.getItem(key) || 'null') || defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);
  React.useEffect(() => {
    local.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
export function useSessionState<T>(defaultValue, key): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState<T>(() => defaultValue);
  React.useEffect(() => {
    setState(JSON.parse(session.getItem(key) || 'null') || defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);
  React.useEffect(() => {
    session.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}