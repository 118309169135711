import React from 'react';
import { Field, Form } from 'react-final-form';
import isEmail from 'shared/utils/is-email';
import { HelpTextField, LabelField, NameField, PlaceholderField, RequiredField, PrivateField, ClientFieldReference, FieldPrefixContext } from '../shared';
import Button from '../../button';
import Input from '../../input';
import HelpText from '../../form-help-text';
import FormError from '../../form-error';
import Label from '../../label';
import { Grid, Box, Flex } from '../../grid';
import { disableAutoCompleteInput } from 'shared/utils/form';
const Email = ({
  schema,
  disabled,
  menu,
  style,
  className,
  disableAutoComplete,
  isCustom = false,
  getWrapper
}) => {
  const prefix = React.useContext(FieldPrefixContext);
  // const name = [prefix, schema.name].filter((v) => v).join('.');
  const name = [prefix, isCustom && 'custom_fields', schema.name].filter(v => v).join('.');
  const [Wrapper, wrapperProps] = getWrapper?.(name) || [React.Fragment, {}];
  return <Field name={name} validate={value => {
    if (schema.required && !value) {
      return 'Enter an email';
    }
    if (value && !isEmail(value)) {
      return 'Enter a valid email';
    }
  }} data-sentry-element="Field" data-sentry-component="Email" data-sentry-source-file="index.tsx">
      {({
      input,
      meta
    }) => <Box style={style} className={className}>
          <Flex mb={2} justifyContent="space-between">
            <Label sx={{
          flexGrow: 1
        }}>
              {schema.label}
            </Label>
            {menu}
          </Flex>
          <Wrapper {...wrapperProps}>
            <Input {...disableAutoCompleteInput(input, disableAutoComplete)} tabIndex={disabled ? -1 : undefined} type="email" invalid={meta.touched && meta.error} placeholder={schema.placeholder} />
          </Wrapper>
          {meta.error && meta.touched && <FormError>{meta.error}</FormError>}

          {schema.helptext && <HelpText>{schema.helptext}</HelpText>}
        </Box>}
    </Field>;
};
export default Email;
export const toString = value => {
  return value || '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{value}</Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style,
  disableAutoComplete,
  getWrapper
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const toggleMore = React.useCallback(() => setShowMore(v => !v), []);
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Form onSubmit={value => null}>
          {({
        form
      }) => <Email getWrapper={getWrapper} schema={value} disabled={true} menu={menu} style={style} className={className} disableAutoComplete={disableAutoComplete} />}
        </Form> : <Grid sx={{
      display: !isEditing ? 'none' : null
    }}>
          <LabelField prefix={fieldPrefix} />
          {!hideRequired && <RequiredField prefix={fieldPrefix} />}
          <PlaceholderField prefix={fieldPrefix} />
          <HelpTextField prefix={fieldPrefix} />
          {showMore && <>
              {showClientFieldReference && removable && <PrivateField prefix={fieldPrefix} />}
              <NameField prefix={fieldPrefix} isUniqueFieldName={isUniqueFieldName} removable={removable} />
              {showClientFieldReference && removable && <ClientFieldReference prefix={fieldPrefix} schema={value} />}
            </>}

          <Button onClick={toggleMore} size="small" width="100%" variant="outlined">
            {showMore ? 'Show less ...' : 'Show more ...'}
          </Button>
        </Grid>}
    </>;
};