import { ThemeProvider } from '@emotion/react';
import get from 'lodash.get';
import merge from 'lodash.merge';
import React from 'react';
const getTheme = (baseTheme, mode) => {
  // const modeTheme = get(baseTheme, `modes.${mode}`, {});
  // const newTheme = merge({}, baseTheme, modeTheme);

  const newTheme = merge({}, baseTheme, get(baseTheme.modes, mode, baseTheme));
  return newTheme;
};
const adjustedTheme = mode => baseTheme => getTheme(baseTheme, mode);
const ColorMode = ({
  children,
  mode = null
}) => {
  const theme = React.useMemo(() => adjustedTheme(mode), [mode]);
  if (!mode) {
    return children;
  }
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="ColorMode" data-sentry-source-file="color-modes.tsx">{children}</ThemeProvider>;
};
export default ColorMode;