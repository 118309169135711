import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { BoxProps, Flex } from 'shared/grid';
const IconWrapper = function IconWrapper({
  ref,
  icon,
  size = 2,
  ...rest
}: BoxProps & {
  icon: IconProp;
  size?: number;
}) {
  return <Flex ref={ref} flexShrink={0} justifyContent="center" alignItems="center" color="primary" sx={{
    backgroundColor: 'gray.0',
    borderRadius: '999px',
    width: `${30 * size}px`,
    minWidth: `${30 * size}px`,
    height: `${30 * size}px`,
    fontSize: 2
  }} {...rest}>
      <FontAwesomeIcon icon={icon} fixedWidth size={`${size}x` as any} />
    </Flex>;
};
export default IconWrapper;