import axios from '../../anaxios';

import {
  IP_LOCATION_FETCH_REQUESTED,
  IP_LOCATION_FETCH_SUCCEEDED,
  IP_LOCATION_FETCH_FAILED,
} from './action-types';

import settings from 'shared/settings';

export const fetchIPLocation = () => {
  return (dispatch) => {
    dispatch({ type: IP_LOCATION_FETCH_REQUESTED });

    const endpoint = ['staging', 'production'].includes(__TARGET_ENV__)
      ? `https://api.bkm.app/json/`
      : `${__PUBLIC_PATH__}/json`;

    return axios
      .get(endpoint)
      .then(({ data }) => {
        dispatch({
          type: IP_LOCATION_FETCH_SUCCEEDED,
          payload: data,
        });
        return data;
      })
      .catch((error) => {
        dispatch({ type: IP_LOCATION_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};
