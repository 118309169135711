import BookView from './components/book';
import BookingView from './components/booking';
import ErrorPage from './components/error-view';
import FormView from './components/form';
import LocationsView from './components/locations';
import NotificationsView from './components/notifications';
import PayAccountView from './components/pay-account';
import PayInvoiceView from './components/pay-invoice';
import RequestPrescriptionView from './components/request-prescription';
import ServiceView from './components/service';
import ServicesView from './components/services';
import TestView from './components/test';
const routes = [
// {
//   path: '/',
//   element: <>Lolo</>,
// },
{
  path: '/',
  element: <ServicesView />,
  errorElement: <ErrorPage />
}, {
  path: '/services/:serviceId',
  element: <ServiceView />
}, {
  path: '/services/:serviceId/book',
  element: <BookView />
}, {
  path: '/bookings/:bookingId',
  element: <BookingView />
}, {
  path: '/contact',
  element: <LocationsView />
}, {
  path: '/request-prescription',
  element: <RequestPrescriptionView />
}, {
  path: '/forms/:formId',
  element: <FormView />
}, {
  path: '/pay-account/:clientId',
  element: <PayAccountView />
}, {
  path: '/pay-invoice/:invoiceId',
  element: <PayInvoiceView />
}, {
  path: '/test',
  element: <TestView />
}, {
  path: `/notifications/:clientId`,
  element: <NotificationsView />
}];
export default routes;