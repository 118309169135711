import { UPLOAD_PATH_PREFIX } from './constants';

export const getCdnImageUrlFromId = (id, useImgix = true) => {
  if (__TARGET_ENV__ === 'dev') {
    return `${__PUBLIC_PATH__}/api2/uploads/${id}`;
  }

  if (useImgix) {
    return __TARGET_ENV__ === 'staging'
      ? `https://uploads.backflip.co.za/${id}`
      : `https://uploads.bookem.com/${id}`;
  } else {
    return __TARGET_ENV__ === 'staging'
      ? `https://bookem-staging.imgix.net/${id}`
      : `https://bookem.imgix.net/${id}`;
  }
};

export const getCdnImageUrlFromPath = (path, useImgix = true) => {
  if (__TARGET_ENV__ === 'dev' || !path.startsWith(UPLOAD_PATH_PREFIX)) {
    return path;
  }

  const id = path?.replace(UPLOAD_PATH_PREFIX, '');

  return getCdnImageUrlFromId(id);
};

export function fileToDataUri(file): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });

    reader.readAsDataURL(file);
  });
}

export function filterImage(imageToFilter, filter) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const canvasWidth = imageToFilter.width;
  const canvasHeight = imageToFilter.height;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  context.drawImage(imageToFilter, 0, 0, canvasWidth, canvasHeight);

  const sourceImageData = context.getImageData(0, 0, canvasWidth, canvasHeight);
  const blankOutputImageData = context.createImageData(
    canvasWidth,
    canvasHeight
  );

  const outputImageData = applyFilter(
    sourceImageData,
    blankOutputImageData,
    filter
  );

  context.putImageData(outputImageData, 0, 0);

  return canvas.toDataURL('image/png');
}

function applyFilter(sourceImageData, outputImageData, filter) {
  if (filter === 'noFilter') {
    return sourceImageData;
  } else if (filter === 'threshold') {
    return applyThreshold(sourceImageData);
  } else if (filter === 'sharpen') {
    return applyConvolution(
      sourceImageData,
      outputImageData,
      [0, -1, 0, -1, 5, -1, 0, -1, 0]
    );
  } else if (filter === 'blur') {
    return applyConvolution(sourceImageData, outputImageData, [
      1 / 16,
      2 / 16,
      1 / 16,
      2 / 16,
      4 / 16,
      2 / 16,
      1 / 16,
      2 / 16,
      1 / 16,
    ]);
  } else {
    return sourceImageData;
  }
}

function applyThreshold(sourceImageData, threshold = 127) {
  const src = sourceImageData.data;

  for (let i = 0; i < src.length; i += 4) {
    const r = src[i];
    const g = src[i + 1];
    const b = src[i + 2];
    const a = src[i + 3];

    if (a === 0) {
      continue;
    }

    // thresholding the current value
    const v = 0.2126 * r + 0.7152 * g + 0.0722 * b >= threshold ? 255 : 0;
    src[i] = src[i + 1] = src[i + 2] = v;
    src[i + 3] = v === 255 ? 0 : 255;
  }

  return sourceImageData;
}

function applyConvolution(sourceImageData, outputImageData, kernel) {
  const src = sourceImageData.data;
  const dst = outputImageData.data;

  const srcWidth = sourceImageData.width;
  const srcHeight = sourceImageData.height;

  const side = Math.round(Math.sqrt(kernel.length));
  const halfSide = Math.floor(side / 2);

  // padding the output by the convolution kernel
  const w = srcWidth;
  const h = srcHeight;

  // iterating through the output image pixels
  for (let y = 0; y < h; y++) {
    for (let x = 0; x < w; x++) {
      let r = 0,
        g = 0,
        b = 0,
        a = 0;

      // calculating the weighed sum of the source image pixels that
      // fall under the convolution kernel
      for (let cy = 0; cy < side; cy++) {
        for (let cx = 0; cx < side; cx++) {
          const scy = y + cy - halfSide;
          const scx = x + cx - halfSide;

          if (scy >= 0 && scy < srcHeight && scx >= 0 && scx < srcWidth) {
            const srcOffset = (scy * srcWidth + scx) * 4;
            const wt = kernel[cy * side + cx];
            r += src[srcOffset] * wt;
            g += src[srcOffset + 1] * wt;
            b += src[srcOffset + 2] * wt;
            a += src[srcOffset + 3] * wt;
          }
        }
      }

      const dstOffset = (y * w + x) * 4;

      dst[dstOffset] = r;
      dst[dstOffset + 1] = g;
      dst[dstOffset + 2] = b;
      dst[dstOffset + 3] = a;
    }
  }
  return outputImageData;
}
