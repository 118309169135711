import { faEnvelope, faGlobe, faHome } from '@fortawesome/pro-regular-svg-icons';
import { useLocationId } from './hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import Button from 'shared/button';
import { Box, Container, Flex, Grid } from 'shared/grid';
import { StandardModal } from 'shared/modal';
import PoweredBy from 'shared/powered-by';
import { logout } from 'shared/redux/session/actions';
import { updatePreferences } from 'shared/redux/user-preferences/actions';
import { Heading } from 'shared/typography';
import { languages } from 'shared/utils/constants';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { useAppDispatch, useAppSelector } from './store';
export const Header = () => {
  const dispatch = useAppDispatch();
  const [isLanguageModalOpen, setLanguageModalOpen] = React.useState(false);
  const setLanguage = React.useCallback(language => {
    dispatch(updatePreferences({
      language
    }));
  }, [dispatch]);
  const isSessionValid = useAppSelector(state => state.session.tokenExp > new Date().getTime() / 1000);
  const [locationId] = useLocationId();
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  return <>
      <Flex bg="background" width="100%" justifyContent={['space-between']} alignItems="center" boxShadow="medium" zIndex={3} flexWrap={['wrap', 'nowrap']} data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
        <Container py={[2, 3]} data-sentry-element="Container" data-sentry-source-file="layout.tsx">
          <Flex justifyContent="space-between" alignItems="center" flexWrap={['nowrap', 'nowrap']} data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
            <Flex data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
              <Flex as={ReachLink} justifyContent="center" alignItems="center" to={`/` + (locationId ? `?lid=${locationId}` : '')} data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
                {business.logo_image_id && <Box as="img" mr={2} height="30px" src={getCdnImageUrlFromId(business.logo_image_id)} alt="logo" />}
                {!(business.settings.show_page_logo_only && business.logo_image_id) && <Heading fontSize={3} color="text" sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflowWrap: 'break-word'
              }}>
                    {business.name}
                  </Heading>}
              </Flex>
            </Flex>

            <Flex data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
              {business.url && <Button as="a" mr={2} color="gray" variant="flat" className="hide-for-small-only" target="_blank" href={business.url} iconLeft={faHome} />}

              {isSessionValid && <Button mr={2} variant="flat" onClick={() => dispatch(logout())}>
                  Sign out
                </Button>}
              <Button as={ReachLink} className="hide-for-small-only" mr={2} variant="outlined" color="gray" to={`/contact?lid=${locationId}`} iconLeft={faEnvelope} data-sentry-element="Button" data-sentry-source-file="layout.tsx">
                <FormattedMessage id="Contact us" defaultMessage="Contact us" data-sentry-element="FormattedMessage" data-sentry-source-file="layout.tsx" />
              </Button>
              <Button as={ReachLink} className="show-for-small-only" variant="flat" color="gray" to={`/contact?lid=${locationId}`} iconLeft={faEnvelope} data-sentry-element="Button" data-sentry-source-file="layout.tsx" />

              <Button className="hide-for-small-only" color="gray" variant="outlined" size="small" onClick={() => setLanguageModalOpen(true)} iconLeft={faGlobe} data-sentry-element="Button" data-sentry-source-file="layout.tsx" />

              <Button className="show-for-small-only" color="gray" variant="flat" size="small" onClick={() => setLanguageModalOpen(true)} iconLeft={faGlobe} data-sentry-element="Button" data-sentry-source-file="layout.tsx" />
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <StandardModal close={() => setLanguageModalOpen(false)} isOpen={isLanguageModalOpen} title={<FormattedMessage id="Public.selectLanguage" defaultMessage="Select a language" />} data-sentry-element="StandardModal" data-sentry-source-file="layout.tsx">
        {() => <Grid>
            {Object.entries(languages).map(([code, language], idx) => <Button variant="outlined" key={idx} width="100%" onClick={() => {
          setLanguage(code);
          setLanguageModalOpen(false);
        }}>
                {language}
              </Button>)}
          </Grid>}
      </StandardModal>
    </>;
};
export const BareLayout = ({
  children
}) => <>
    <Header data-sentry-element="Header" data-sentry-source-file="layout.tsx" />
    <Container px={[0, 3]} py={3} flex={[1, 0]} display="flex" sx={{
    flexDirection: 'row',
    '& > *': {
      flexGrow: 1
    }
  }} data-sentry-element="Container" data-sentry-source-file="layout.tsx">
      {children}
    </Container>
    <PoweredBy medium="embed" my={3} data-sentry-element="PoweredBy" data-sentry-source-file="layout.tsx" />
  </>;
const Layout = ({
  children
}) => {
  const isServer = typeof window === 'undefined';
  const inIframe = isServer ? false : window.location !== window.top.location;
  const business = useAppSelector(state => state.businesses.data[state.public.businessId]);
  return <>
      {!inIframe && <Header />}
      {!inIframe && business.cover_image_id && <>
          <Box sx={{
        height: '10rem',
        backgroundColor: '#fff',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        position: 'relative',
        backgroundImage: business.cover_image_id ? `url(${getCdnImageUrlFromId(business.cover_image_id)})` : `linear-gradient(
              to left,
              #f5f6f6,
              rgba(229, 232, 231, 0) 100%
            )`,
        a: {
          display: 'flex',
          justifyContent: 'center',
          h1: {
            display: 'block',
            marginTop: '0.5rem'
          },
          img: {
            display: 'block',
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
          }
        }
      }} />
        </>}

      <Flex flex="1" data-sentry-element="Flex" data-sentry-source-file="layout.tsx">
        <Container backgroundColor="bodyBackground" flex="1" display="flex" flexDirection="column" data-sentry-element="Container" data-sentry-source-file="layout.tsx">
          {children}
          <PoweredBy pt={2} data-sentry-element="PoweredBy" data-sentry-source-file="layout.tsx" />
        </Container>
      </Flex>
    </>;
};
export default Layout;