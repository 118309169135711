import React from 'react';
import { useFloating, autoUpdate, offset, flip, shift, arrow, useHover, useFocus, useDismiss, useRole, useInteractions, useClientPoint } from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import Portal from './portal';
import { Box } from './grid';
import ColorMode from './color-modes';
import { Arrow2 } from './popper-arrow';
import { useMergeRefs } from 'shared/hooks';
interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  content: any;
  children: any;
  colorMode?: string;
  followCursor?: boolean;
  disabled?: boolean;
  offset?: [number, number];
  onTrigger?: () => void;
  ref?: React.Ref<any>;
}
const Tooltip = function Tooltip({
  children,
  content,
  placement: placemant_ = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  initialOpen = false,
  colorMode = 'dark',
  followCursor = false,
  disabled = false,
  offset: offset_,
  onTrigger,
  ref: propRef
}: TooltipOptions) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen_ = setControlledOpen ?? setUncontrolledOpen;
  const setOpen = React.useCallback((open: boolean) => {
    setOpen_(open);
    if (open && onTrigger) {
      onTrigger();
    }
  }, [onTrigger, setOpen_]);
  const arrowRef = React.useRef(null);
  const offsetVal = offset_ ? Math.max(...offset_) : 5;
  const {
    context,
    floatingStyles,
    refs,
    middlewareData: {
      arrow: {
        x: arrowX,
        y: arrowY
      } = {}
    },
    placement
  } = useFloating({
    placement: placemant_,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(offsetVal), flip({
      crossAxis: placemant_.includes('-'),
      fallbackAxisSideDirection: 'start',
      padding: 5
    }), shift({
      padding: 5
    }), arrow({
      element: arrowRef
    })]
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clientPoint = followCursor && useClientPoint(context);
  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
    delay: 500
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'tooltip'
  });
  const {
    getReferenceProps,
    getFloatingProps
  } = useInteractions([followCursor && clientPoint, hover, focus, dismiss, role].filter(Boolean));
  const originalChildRef = (children as any).props.ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, originalChildRef]);
  const clonedChildren = React.cloneElement(children as any, getReferenceProps({
    ...children.props,
    ref,
    'data-state': open ? 'open' : 'closed'
  }));
  return <>
      {clonedChildren}
      {open && !disabled && <Portal>
          <ColorMode mode={colorMode}>
            <Box py={1} px={2} bg="background" boxShadow="medium" color="gray" zIndex={6002} borderRadius={2} ref={refs.setFloating} style={{
          ...floatingStyles,
          pointerEvents: 'none'
        }} {...getFloatingProps({})}>
              {content}
              <Arrow2 ref={arrowRef} placement={placement} styles={{
            left: `${arrowX}px`,
            top: `${arrowY}px`
          }} borderWidth={1} arrowSize={8} />
            </Box>
          </ColorMode>
        </Portal>}
    </>;
};
export default Tooltip;