import React from 'react';
import { useAppDispatch } from '../../store';
import axios from 'shared/anaxios';
import settings from 'shared/settings';
import Button from 'shared/button';
import { addToast } from 'shared/toaster/redux/actions';
import { Heading, Text } from 'shared/typography';
import { Grid } from 'shared/grid';
import { useParams } from 'react-router-dom';
const NotificationsView = () => {
  const {
    clientId
  } = useParams();
  const [notFound, setNotFound] = React.useState(false);
  const [dontSendNotifications, setDontSendNotifications] = React.useState(false);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    axios.get(`${settings.api2Root}/client-notification-preferences/${clientId}`).then(({
      data
    }) => {
      setDontSendNotifications(data.dont_send_notifications);
    }).catch(() => {
      setNotFound(true);
    });
  }, [clientId, dispatch]);
  const toggleDontSendNotifications = React.useCallback(() => {
    axios.put(`${settings.api2Root}/client-notification-preferences/${clientId}`, {
      dont_send_notifications: !dontSendNotifications
    }).then(({
      data
    }) => {
      setDontSendNotifications(data.dont_send_notifications);
      dispatch(addToast(data.dont_send_notifications ? 'Unsubscribed' : 'Subscribed', 'success'));
    });
  }, [clientId, dispatch, dontSendNotifications]);
  if (notFound) {
    return <>Profile not found</>;
  }
  return <Grid data-sentry-element="Grid" data-sentry-component="NotificationsView" data-sentry-source-file="component.tsx">
      {dontSendNotifications ? <>
          <Heading>Subscribe to booking updates?</Heading>
          <Text variant="warning">
            You are not currently receiving any booking related reminders or
            notifications, subscribing will keep you updated. Would you like to
            receive booking updates?
          </Text>
        </> : <>
          <Heading>Unsubscribe from all emails and SMSes?</Heading>
          <Text variant="warning">
            Unsubscribing means you won't be notified of upcoming bookings, are
            you sure you want to continue?
          </Text>
        </>}
      <Button onClick={toggleDontSendNotifications} data-sentry-element="Button" data-sentry-source-file="component.tsx">
        {dontSendNotifications ? 'Yes, subscribe me' : 'Yes, unsubscribe me'}
      </Button>
    </Grid>;
};
export default NotificationsView;