export const FORM_SUBMISSIONS_FETCH_REQUESTED =
  'FORM_SUBMISSIONS_FETCH_REQUESTED';
export const FORM_SUBMISSIONS_FETCH_SUCCEEDED =
  'FORM_SUBMISSIONS_FETCH_SUCCEEDED';
export const FORM_SUBMISSIONS_FETCH_FAILED = 'FORM_SUBMISSIONS_FETCH_FAILED';

export const FORM_SUBMISSIONS_SAVE_REQUESTED =
  'FORM_SUBMISSIONS_SAVE_REQUESTED';
export const FORM_SUBMISSIONS_SAVE_SUCCEEDED =
  'FORM_SUBMISSIONS_SAVE_SUCCEEDED';
export const FORM_SUBMISSIONS_SAVE_FAILED = 'FORM_SUBMISSIONS_SAVE_FAILED';

export const FORM_SUBMISSIONS_DELETE_REQUESTED =
  'FORM_SUBMISSIONS_DELETE_REQUESTED';
export const FORM_SUBMISSIONS_DELETE_SUCCEEDED =
  'FORM_SUBMISSIONS_DELETE_SUCCEEDED';
export const FORM_SUBMISSIONS_DELETE_FAILED = 'FORM_SUBMISSIONS_DELETE_FAILED';
