import axios from '../../anaxios';
import settings from '../../settings';

import {
  FORM_FETCH_REQUESTED,
  FORM_FETCH_SUCCEEDED,
  FORM_FETCH_FAILED,
  FORM_SAVE_REQUESTED,
  FORM_SAVE_SUCCEEDED,
  FORM_SAVE_FAILED,
  FORM_DELETE_REQUESTED,
  FORM_DELETE_SUCCEEDED,
  FORM_DELETE_FAILED,
} from './action-types';

export const fetchForms = ({ businessId }) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: FORM_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/forms`, {
        params: {
          business_id: businessId,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        // const forms = data.data.reduce((acc, form) => {
        //   acc[form.id] = form;
        //   return acc;
        // }, {});

        dispatch({ type: FORM_FETCH_SUCCEEDED, payload: data });
      })
      .catch((error) => {
        dispatch({ type: FORM_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const fetchForm = (formId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: FORM_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/forms/${formId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const forms = {
          [data[0].id]: data,
        };

        dispatch({ type: FORM_FETCH_SUCCEEDED, payload: forms });
        return data;
      })
      .catch((error) => {
        dispatch({ type: FORM_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const saveForm = (form) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: FORM_SAVE_REQUESTED });

    return axios
      .put(`${settings.api2Root}/forms/${form.id}`, form, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const forms = { [data[0].id]: data };

        dispatch({ type: FORM_SAVE_SUCCEEDED, payload: forms });
      })
      .catch((error) => {
        dispatch({ type: FORM_FETCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const patchForm = (form) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: FORM_SAVE_REQUESTED });

    return axios
      .patch(`${settings.api2Root}/forms/${form.id}`, form, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const forms = { [data[0].id]: data };

        dispatch({ type: FORM_SAVE_SUCCEEDED, payload: forms });
      })
      .catch((error) => {
        dispatch({ type: FORM_FETCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const createForm = (form) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: FORM_SAVE_REQUESTED });

    return axios
      .post(`${settings.api2Root}/forms`, form, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const forms = { [data[0].id]: data };

        dispatch({ type: FORM_SAVE_SUCCEEDED, payload: forms });
        return data;
      })
      .catch((error) => {
        dispatch({ type: FORM_FETCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const deleteForm = (formId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: FORM_DELETE_REQUESTED });

    return axios
      .delete(`${settings.api2Root}/forms/${formId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const forms = { [data[0].id]: data };
        dispatch({ type: FORM_DELETE_SUCCEEDED, payload: forms });
      })
      .catch((error) => {
        dispatch({ type: FORM_DELETE_FAILED });
        return Promise.reject(error);
      });
  };
};
