import axios from '../../anaxios';
import settings from '../../settings';
import { isUUID } from '../../utils/uuid';

import {
  SERVICE_FETCH_REQUESTED,
  SERVICE_FETCH_SUCCEEDED,
  SERVICE_FETCH_FAILED,
  SERVICE_SAVE_REQUESTED,
  SERVICE_SAVE_SUCCEEDED,
  SERVICE_SAVE_FAILED,
  SERVICE_DELETE_REQUESTED,
  SERVICE_DELETE_SUCCEEDED,
  SERVICE_DELETE_FAILED,
} from './action-types';

export const fetchServices = (businessId, isPublic = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: SERVICE_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/services`, {
        params: {
          business_id: businessId,
          public: isPublic,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const services = data.reduce((acc, service) => {
          acc[service.id] = service;
          return acc;
        }, {});

        dispatch({ type: SERVICE_FETCH_SUCCEEDED, payload: services });
      })
      .catch((error) => {
        dispatch({ type: SERVICE_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const fetchService = (serviceId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    if (!isUUID(serviceId)) {
      return Promise.reject(new Error('Invalid service ID'));
    }

    dispatch({ type: SERVICE_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/services/${serviceId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const services = {
          [data.id]: data,
        };

        dispatch({ type: SERVICE_FETCH_SUCCEEDED, payload: services });
        return data;
      })
      .catch((error) => {
        dispatch({ type: SERVICE_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const saveService = (service) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;
    const oldService = store.services.data[service.id];
    dispatch({
      type: SERVICE_SAVE_REQUESTED,
      payload: { [service.id]: service },
    });

    return axios
      .put(`${settings.api2Root}/services/${service.id}`, service, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: SERVICE_SAVE_SUCCEEDED,
          payload: { [data.id]: data },
        });
      })
      .catch((error) => {
        dispatch({
          type: SERVICE_FETCH_FAILED,
          payload: { [service.id]: oldService },
        });
        return Promise.reject(error);
      });
  };
};

export const patchService = (id, changes) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;
    const oldService = store.services.data[id];
    const newService = { ...oldService, ...changes };
    dispatch({ type: SERVICE_SAVE_REQUESTED, payload: { [id]: newService } });

    return axios
      .patch(`${settings.api2Root}/services/${id}`, changes, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: SERVICE_SAVE_SUCCEEDED,
          payload: { [data.id]: data },
        });
      })
      .catch((error) => {
        dispatch({ type: SERVICE_SAVE_FAILED, payload: { [id]: oldService } });
        return Promise.reject(error);
      });
  };
};

export const createService = (service) => {
  return (dispatch, getState) => {
    const store = getState();
    const token = store.session.token;

    dispatch({ type: SERVICE_SAVE_REQUESTED });

    return axios
      .post(`${settings.api2Root}/services`, service, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const services = { [data.id]: data };

        dispatch({ type: SERVICE_SAVE_SUCCEEDED, payload: services });
        return data;
      })
      .catch((error) => {
        dispatch({ type: SERVICE_FETCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const deleteService = (serviceId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: SERVICE_DELETE_REQUESTED });

    return axios
      .delete(`${settings.api2Root}/services/${serviceId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        dispatch({ type: SERVICE_DELETE_SUCCEEDED, payload: serviceId });
      })
      .catch((error) => {
        dispatch({ type: SERVICE_DELETE_FAILED });
        return Promise.reject(error);
      });
  };
};
