import settings from '../../settings';
import axios from '../../anaxios';
import {
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGOUT,
  SET_PROMO_CODE,
} from './action-types';
import { fetchCurrentUser } from '../users/actions';

export const login = ({
  username = null,
  password = null,
  access_token = null,
  method = 'bookem',
}) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUESTED,
    });

    if (method == 'google') {
      return axios
        .post(
          `${settings.api2Root}/token-google`,
          {
            access_token,
          },
          { skipErrorResponseIntercept: true }
        )
        .then(({ data }) => dispatch({ type: LOGIN_SUCCEEDED, payload: data }))
        .catch((error) => {
          dispatch({ type: LOGIN_FAILED, payload: error });
          return Promise.reject(error);
        });
    } else {
      const fd = new FormData();
      fd.append('username', username);
      fd.append('password', password);

      return axios
        .post(`${settings.api2Root}/token`, fd, {
          skipErrorResponseIntercept: true,
        })
        .then(({ data }) => dispatch({ type: LOGIN_SUCCEEDED, payload: data }))
        .catch((error) => {
          dispatch({ type: LOGIN_FAILED, payload: error });
          return Promise.reject(error);
        });
    }
  };
};

export const refreshSession = () => {
  return (dispatch, getState) => {
    const state = getState();
    const refreshToken = state.session.refreshToken;

    if (!refreshToken) {
      // return Promise.reject(new Error('No refresh token'));
      return;
    }

    return axios
      .post(
        `${settings.api2Root}/token-refresh`,
        {
          refresh_token: refreshToken,
        },
        { skipErrorResponseIntercept: true }
      )
      .then(({ data }) => dispatch({ type: LOGIN_SUCCEEDED, payload: data }))
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch({ type: LOGOUT });
        }

        dispatch({ type: LOGIN_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    const state = getState();
    const refreshToken = state.session.refreshToken;

    dispatch({ type: LOGOUT });

    if (refreshToken) {
      return axios.post(
        `${settings.api2Root}/token-revoke`,
        {
          refresh_token: refreshToken,
        },
        { skipErrorResponseIntercept: true }
      );
    }
  };
};

export const acceptInvite = (code) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUESTED,
    });
    return axios
      .post(
        `${settings.api2Root}/accept-invite`,
        { code },
        { skipErrorResponseIntercept: true }
      )
      .then(({ data }) => {
        {
          dispatch({ type: LOGIN_SUCCEEDED, payload: data });
          setTimeout(() => {
            dispatch(fetchCurrentUser());
          });
        }
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const confirmEmail = (code) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUESTED,
    });

    return axios
      .post(
        `${settings.api2Root}/confirm-email`,
        { code },
        { skipErrorResponseIntercept: true }
      )
      .then(({ data }) => {
        dispatch({ type: LOGIN_SUCCEEDED, payload: data });
        setTimeout(() => {
          dispatch(fetchCurrentUser());
        });
        return { data };
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const switchUser = (userId) => {
  return (dispatch, getState) => {
    dispatch({
      type: LOGIN_REQUESTED,
    });

    const state = getState();
    const token = state.session.token;

    return axios
      .post(
        `${settings.api2Root}/token-switch-user`,
        { user_id: userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(({ data }) => {
        dispatch({ type: LOGIN_SUCCEEDED, payload: data });
        setTimeout(() => {
          dispatch(fetchCurrentUser());
        });
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const setPromoCode = (code) => ({ type: SET_PROMO_CODE, payload: code });
