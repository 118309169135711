import React from 'react';
import { Box, BoxProps } from 'shared/grid';
import { trimTextNode } from '../typography';
interface LabelProps {
  invalid?: boolean;
  isFocused?: boolean;
}
export function Label({
  ref = null,
  invalid = false,
  isFocused = false,
  ...props
}: BoxProps & LabelProps) {
  return <Box tx="text" variant="label" as="label" ref={ref} __css={{
    color: invalid ? 'alert' : undefined,
    display: 'block',
    fontSize: 2
    // ...trimTextNode({ lineHeight: Number(props.lineHeight) || 1.6 }),
  }} {...props} data-sentry-element="Box" data-sentry-component="Label" data-sentry-source-file="index.tsx" />;
}
export default Label;