import React, { useState, useCallback } from 'react';
import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect';
export const getSize = el => {
  if (!el) {
    return {
      width: 0,
      height: 0
    };
  }
  return {
    width: el.offsetWidth,
    height: el.offsetHeight
  };
};
function useComponentSize(ref, minDelta = 1) {
  const [componentSize, setComponentSize] = useState(getSize(ref ? ref.current : {}));
  const handleResize = useCallback(() => {
    if (ref.current) {
      React.startTransition(() => {
        const size = getSize(ref.current);
        // setComponentSize(size);
        // Prevent resize loop?
        setComponentSize(oldSize => {
          const xD = Math.abs(size.width - oldSize.width);
          const yD = Math.abs(size.height - oldSize.height);
          if (xD < minDelta) {
            size.width = oldSize.width;
          }
          if (yD < minDelta) {
            size.height = oldSize.height;
          }
          return size;
        });
      });
    }
  }, [minDelta, ref]);
  useIsomorphicLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    handleResize();
    if (typeof ResizeObserver === 'function') {
      const observedEl = ref.current;
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(observedEl);
      return () => {
        resizeObserver.disconnect();
      };
    } else {
      window.addEventListener('resize', handleResize);
      return function () {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [ref.current]);
  return componentSize;
}
export default useComponentSize;
export const useMaxHeight = ref => {
  const {
    height
  } = useComponentSize(ref);
  const [maxHeight, setMaxHeight] = React.useState(height);
  const isResizingRef = React.useRef(false);
  React.useEffect(() => {
    const onResize = () => {
      isResizingRef.current = true;
      setMaxHeight(0);
      setTimeout(() => {
        isResizingRef.current = false;
      }, 1000);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  React.useEffect(() => {
    if (!isResizingRef.current && height > maxHeight) {
      setMaxHeight(height);
    }
  }, [height, maxHeight]);
  return maxHeight;
};