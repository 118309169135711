import React from 'react';
import { Field } from 'react-final-form';
import Input from '../../input';
import { Heading as Heading_ } from 'shared/typography';
import { Flex, Box, Grid } from 'shared/grid';
import Switch from '../../switch';
const Heading = ({
  schema,
  menu,
  style,
  className
}) => {
  return <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-component="Heading" data-sentry-source-file="index.tsx">
      <Box flex="1" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Heading_ as="h2" fontSize={4} data-sentry-element="Heading_" data-sentry-source-file="index.tsx">
          {schema.value}
        </Heading_>
      </Box>
      {menu}
    </Flex>;
};
export default Heading;
export const toString = value => {
  return '';
};
export const View = ({
  value,
  schema
}) => {
  return <Box data-sentry-element="Box" data-sentry-component="View" data-sentry-source-file="index.tsx">{schema.value}</Box>;
};
export const Edit = ({
  isEditing,
  className,
  isUniqueFieldName,
  hideRequired,
  showClientFieldReference,
  value,
  menu,
  style
}) => {
  const removable = value.removable;
  const fieldPrefix = '';
  return <>
      {!isEditing ? <Heading schema={value} menu={menu} style={style} className={className} /> : <Grid sx={{
      display: !isEditing ? 'none' : null
    }}>
          <Field name={fieldPrefix ? `${fieldPrefix}.value` : 'value'} validate={value => {
        if (!value) {
          return 'Enter a some heading text';
        }
      }}>
            {({
          input,
          meta
        }) => <Box>
                <Input {...input} label="Heading text" type="text" error={meta.touched && meta.error} />
              </Box>}
          </Field>

          <Field name="show_on_print">
            {({
          input: pInput,
          meta
        }) => <>
                <Flex alignItems="center">
                  <Switch size="small" {...pInput} />
                  <Box ml={2}>Show when printing</Box>
                </Flex>

                {pInput.value && <Field name="has_page_break">
                    {({
              input,
              meta
            }) => <Flex alignItems="center">
                        <Switch size="small" {...input} />
                        <Box ml={2}>Break page before</Box>
                      </Flex>}
                  </Field>}
              </>}
          </Field>
        </Grid>}
    </>;
};